import React, {FC, useEffect} from "react";
import {useIntl} from "react-intl";
import {Button, Dropdown} from "react-bootstrap";

export interface CustomFilterProps {
    content: JSX.Element
    getCustomFilter: any,
    resetCustomFilter: any,
    setCustomFilterData: any,
    setIsLoading: any
}

export const TableFilter: FC<CustomFilterProps> = ({
                                                       content,
                                                       getCustomFilter,
                                                       resetCustomFilter,
                                                       setCustomFilterData,
                                                       setIsLoading
                                                   }) => {
    const intl = useIntl()

    return (
        <>
                <Dropdown className="ms-3">
                    <Dropdown.Toggle variant="light">
                         <span className="svg-icon svg-icon-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                             viewBox="0 0 24 24" fill="none">
                                            <path
                                                d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z"
                                                fill="currentColor"/>
                                        </svg>
                                    </span>
                        {intl.formatMessage({id: 'TABLE.FILTER.BUTTON'})}
                    </Dropdown.Toggle>

                    <Dropdown.Menu
                        className="menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-200px py-3 w-300px w-md-325px">
                        <div className="px-7 py-4">
                            <div
                                className="fs-6 text-dark fw-bolder">{intl.formatMessage({id: 'TABLE.FILTER.FILTER_OPTIONS'})}</div>
                        </div>
                        <div className="separator border-gray-200"></div>
                        <div className="px-7 py-5" data-kt-user-table-filter="form">
                            {content}
                            <div className="d-flex justify-content-end">
                                <button type="button"
                                        className="btn btn-sm btn-light btn-active-light-primary fw-bold me-2 px-6"
                                        data-kt-menu-dismiss="true"
                                        onClick={async () => {
                                            setIsLoading(true)
                                            await resetCustomFilter()
                                            await setCustomFilterData([])
                                        }
                                        }>
                                    {intl.formatMessage({id: 'TABLE.FILTER.BUTTON.RESET'})}
                                </button>
                                <button type="button" className="btn btn-sm btn-primary fw-bold px-6"
                                        data-kt-menu-dismiss="true"
                                        onClick={ async () => {
                                            setIsLoading(true)
                                            await setCustomFilterData(getCustomFilter())
                                        }}>
                                    {intl.formatMessage({id: 'TABLE.FILTER.BUTTON.APPLY'})}
                                </button>
                            </div>
                        </div>
                    </Dropdown.Menu>
                </Dropdown>
                
                {/*<button type="button" className="btn btn-light btn-active-light-primary ms-3 d-flex"*/}
                {/*        data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">*/}
                {/*                    <span className="svg-icon svg-icon-2">*/}
                {/*                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"*/}
                {/*                             viewBox="0 0 24 24" fill="none">*/}
                {/*                            <path*/}
                {/*                                d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z"*/}
                {/*                                fill="currentColor"/>*/}
                {/*                        </svg>*/}
                {/*                    </span>*/}
                {/*    {intl.formatMessage({id: 'TABLE.FILTER.BUTTON'})}*/}
                {/*</button>*/}
                {/*<div className="menu menu-sub menu-sub-dropdown w-300px w-md-325px"*/}
                {/*     data-kt-menu="true">*/}
                {/*    <div className="px-7 py-4">*/}
                {/*        <div*/}
                {/*            className="fs-6 text-dark fw-bolder">{intl.formatMessage({id: 'TABLE.FILTER.FILTER_OPTIONS'})}</div>*/}
                {/*    </div>*/}
                {/*    <div className="separator border-gray-200"></div>*/}
                {/*    <div className="px-7 py-5" data-kt-user-table-filter="form">*/}
                {/*        {content}*/}
                {/*        <div className="d-flex justify-content-end">*/}
                {/*            <button type="button"*/}
                {/*                    className="btn btn-sm btn-light btn-active-light-primary fw-bold me-2 px-6"*/}
                {/*                    data-kt-menu-dismiss="true"*/}
                {/*                    onClick={async () => {*/}
                {/*                        setIsLoading(true)*/}
                {/*                        await resetCustomFilter()*/}
                {/*                        await setCustomFilterData([])*/}
                {/*                    }*/}
                {/*                    }>*/}
                {/*                {intl.formatMessage({id: 'TABLE.FILTER.BUTTON.RESET'})}*/}
                {/*            </button>*/}
                {/*            <button type="button" className="btn btn-sm btn-primary fw-bold px-6"*/}
                {/*                    data-kt-menu-dismiss="true"*/}
                {/*                    onClick={ async () => {*/}
                {/*                        setIsLoading(true)*/}
                {/*                        await setCustomFilterData(getCustomFilter())*/}
                {/*                    }}>*/}
                {/*                {intl.formatMessage({id: 'TABLE.FILTER.BUTTON.APPLY'})}*/}
                {/*            </button>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
        </>
    )
}

type FilterRowProps = {
    labelText: string,
    content: any
}

export const TableFilterRow: FC<FilterRowProps> = React.memo(({labelText, content}) => {
    
    return (
        <div className="mb-6">
            <label className="form-label fs-7 fw-bold">{labelText}</label>
            {content}
        </div>
    )
})