import {Route, Routes} from "react-router-dom";
import React, {useEffect} from "react";
import {Detail, Roles} from "./";
import {useDispatch} from "react-redux";
import {rolesActions} from "./../";

export const RolesPage = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(rolesActions.showOrganisationRoleModal({state:false}))
            dispatch(rolesActions.showEditUsersRoleModal({state: false}))
        }
    }, [])

    return (
        <Routes>
            <Route path=':roleId' element={<Detail/>}/>
            <Route path='/' element={<Roles/>}/>
        </Routes>
    )
}
