import axios, {AxiosResponse} from "axios";
import {ActionResponse} from "../../infrastructure/models/actionResponse";
import {UpdateProfileModel} from "./models";
import {LookupKeyValueModel} from "../../infrastructure/models/lookupKeyValueModel";
import {accountApis} from "./accountApis";

const checkEmailVerification = async () => {
    try {
        const response = await accountApis.checkEmailVerification()
        return {
            isSucceed: true,
            data: response.data
        } as ActionResponse<boolean>
    } catch (e: any) {
        return {
            isSucceed: false,
            errors: e.response?.data?.errors,
        } as ActionResponse<boolean>
    }
}

const resetPassword = async ({oldPassword, newPassword}: { oldPassword: string, newPassword: string }) => {
    try {
        await accountApis.resetPassword({oldPassword, newPassword})
        return {
            isSucceed: true,
        } as ActionResponse
    } catch (e: any) {
        return {
            isSucceed: false,
            errors: e.response?.data?.errors,
            message: e.response.data
        } as ActionResponse
    }
}

const updateProfile = async (model: UpdateProfileModel) => {
    try {
        await accountApis.updateProfile({model})
        return {
            isSucceed: true,
        } as ActionResponse
    } catch (e: any) {
        return {
            isSucceed: false,
            errors: e.response?.data?.errors,
            message: e.response.data
        } as ActionResponse
    }
}

const updateProfilePhoto = async (photo: File) => {
    const formData = new FormData();
    formData.append('file', photo);

    try {
        const response = await accountApis.updateProfilePhoto({photo})
        return {
            isSucceed: true,
            data: response.data
        } as ActionResponse<string>
    } catch (e: any) {
        return {
            isSucceed: false,
            message: e.response.data
        } as ActionResponse<string>
    }
}

const getProfile = async () => {
    try {
        const response = await accountApis.getProfile()
        return {
            isSucceed: true,
            data: response.data,
        } as ActionResponse<UpdateProfileModel>
    } catch (e: any) {
        return {
            isSucceed: false,
        } as ActionResponse<UpdateProfileModel>
    }
}

const getCountries = async () => {
    try {
        const response = await accountApis.getCountries()
        return {
            isSucceed: true,
            data: response.data,
        } as ActionResponse<LookupKeyValueModel[]>
    } catch (e: any) {
        return {
            isSucceed: false,
        } as ActionResponse<LookupKeyValueModel[]>
    }
}

const getTimeZones = async () => {
    try {
        const response = await accountApis.getTimeZones()
        return {
            isSucceed: true,
            data: response.data,
        } as ActionResponse<LookupKeyValueModel[]>
    } catch (e: any) {
        return {
            isSucceed: false,
        } as ActionResponse<LookupKeyValueModel[]>
    }
}

export const accountActions =
    {
        checkEmailVerification,
        resetPassword,
        updateProfile,
        updateProfilePhoto,
        getProfile,
        getCountries,
        getTimeZones
    }