import React, {useEffect, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {useNavigate, useSearchParams} from 'react-router-dom'
import {Form, Formik} from 'formik'
import {useIntl} from "react-intl";
import {FormInputGroup} from "../../../components/forms/FormInputGroup";
import {FormActionButtons} from "../../../components/forms/FormActionButtons";
import {ActionResponse} from "../../../infrastructure/models/actionResponse";
import {authActions} from "../";
import {AUTH_LOGIN_URL} from "../../../infrastructure/variables/urls";
import {PasswordMeterComponent} from "../../../../_metronic/assets/ts/components";
import {addValidationErrors} from "../../../infrastructure/utils/formikUtils";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";
import {toastify} from "../../../libs/toastify";

export const ResetPasswordPage = () => {
    const intl = useIntl()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [tokenValid, setTokenValid] = useState(false)
    const [searchParams] = useSearchParams();
    const reCaptcha = useGoogleReCaptcha()

    const emailParameterName = "email"
    const tokenParameterName = "token"

    const initialValues = {
        email: searchParams.get(emailParameterName) ?? "",
        password: '',
        passwordConfirm: '',
        token: searchParams.get(tokenParameterName) ?? "",
    }

    const validationSchema = Yup.object().shape({
        password: Yup.string()
            .min(8, intl.formatMessage({id: 'VALIDATION.MIN_LENGTH'}, {length: 8}))
            .max(30, intl.formatMessage({id: 'VALIDATION.MAX_LENGTH'}, {length: 30}))
            .required(intl.formatMessage({id: 'VALIDATION.REQUIRED'}, {name: intl.formatMessage({id: 'AUTH.RESET_PASSWORD.INPUT.PASSWORD'})}))
            .matches(/(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_])(?=^.*[^\s].*$).*$/, intl.formatMessage({id: 'AUTH.REGISTER.VALIDATION.PASSWORD_NOT_STRONG'})),
        passwordConfirm: Yup.string()
            .required(intl.formatMessage({id: 'VALIDATION.CONFIRMATION'}, {name: intl.formatMessage({id: 'AUTH.RESET_PASSWORD.INPUT.PASSWORD'})}))
            .when('password', {
                is: (val: string) => (!!(val && val.length > 0)),
                then: Yup.string().oneOf([Yup.ref('password')], intl.formatMessage({id: 'VALIDATION.CONFIRMATION_NOT_MATCH'}, {
                    name: intl.formatMessage({id: 'AUTH.RESET_PASSWORD.INPUT.PASSWORD'}),
                    confirmName: intl.formatMessage({id: 'AUTH.RESET_PASSWORD.INPUT.PASSWORD_CONFIRM'})
                })),
            })
    })

    useEffect(() => {

        PasswordMeterComponent.bootstrap()
        
        async function checkTokenValid() {
            const token = searchParams.get(tokenParameterName) ?? "";
            const email = searchParams.get(emailParameterName) ?? "";

            if (token == null) {
                navigate("/error/404");
            } else {

                const response: ActionResponse = await authActions.resetPasswordTokenVerify(email, token)

                if (response.isSucceed) {
                    setTokenValid(true);
                } else {
                    navigate("/error/404");
                }
            }
        }

        checkTokenValid()

    }, []);

    useEffect(() => {
        
        if (tokenValid)
            PasswordMeterComponent.bootstrap()
        
    }, [tokenValid]);

    return (
        <>
            {
                tokenValid &&
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={async (values, {setStatus, setSubmitting, setFieldError}) => {
                        if (!reCaptcha.executeRecaptcha) {
                            toastify.error(intl.formatMessage({id: "AUTH.LOGIN.MESSAGE.RE_CAPTCHA_NOT_FOUND"}))
                            return
                        }

                        const reCAPTCHAToken = await reCaptcha.executeRecaptcha()
                        
                        setLoading(true)
                        
                        setTimeout(async () => {
                            const response = await authActions.resetPassword({
                                email: values.email,
                                password: values.password,
                                token: values.token,
                                reCAPTCHAToken: reCAPTCHAToken
                            })
                            
                            if (response.isSucceed) {
                                navigate(AUTH_LOGIN_URL)
                            } else {

                                addValidationErrors(setFieldError, response?.errors)
                                
                                setStatus({
                                    isSucceed: false,
                                    message: intl.formatMessage({id: "ERROR.ERROR_OCCURRED"})
                                })

                                setSubmitting(false)
                            }
                            setLoading(false)
                        }, 500)
                    }}

                >
                    {(formik) => (
                        <Form className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'>
                            <div className='text-center mb-10'>
                                {/* begin::Title */}
                                <h1 className='text-dark mb-3'>{intl.formatMessage({id: 'AUTH.RESET_PASSWORD.TITLE'})}</h1>
                                {/* end::Title */}
                            </div>

                            {/* begin::Title */}
                            {formik.status && (
                                <div
                                    className={clsx('alert mb-5', formik.status.isSucceed ? 'alert-success' : 'alert-danger')}>
                                    {formik.status.message}
                                </div>
                            )}
                            {/* end::Title */}

                            {/* begin::Form group */}
                            <FormInputGroup
                                labelResourceName={"AUTH.RESET_PASSWORD.INPUT.EMAIL"}
                                inputType={"email"}
                                fieldProps={formik.getFieldProps('email')}
                                validationCondition={(formik.touched.email && formik.errors.email)}
                                validationMessage={formik.errors.email}
                                readOnly={true}
                            />
                            {/* end::Form group */}

                            <div className='mb-5' data-kt-password-meter='true'>
                                <div className='mb-1'>
                                    {/* begin::Form group */}
                                    <FormInputGroup
                                        labelResourceName={"AUTH.RESET_PASSWORD.INPUT.PASSWORD"}
                                        inputType={"password"}
                                        fieldProps={formik.getFieldProps('password')}
                                        validationCondition={(formik.touched.password && formik.errors.password)}
                                        validationMessage={formik.errors.password}
                                    />
                                    {/* end::Form group */}

                                    {/* begin::Meter */}
                                    <div
                                        className='d-flex align-items-center mb-3'
                                        data-kt-password-meter-control='highlight'
                                        style={{marginTop: "-10px"}}
                                    >
                                        <div
                                            className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                                        <div
                                            className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                                        <div
                                            className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                                        <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
                                    </div>
                                    <div className='text-muted'>
                                        {intl.formatMessage({id: 'AUTH.REGISTER.PASSWORD_METER_DESC'})}
                                    </div>
                                    {/* end::Meter */}
                                </div>
                            </div>
                            {/* begin::Form group */}
                            <FormInputGroup
                                labelResourceName={"AUTH.RESET_PASSWORD.INPUT.PASSWORD_CONFIRM"}
                                inputType={"password"}
                                fieldProps={formik.getFieldProps('passwordConfirm')}
                                validationCondition={(formik.touched.passwordConfirm && formik.errors.passwordConfirm)}
                                validationMessage={formik.errors.passwordConfirm}
                            />
                            {/* end::Form group */}

                            {/* begin::Action */}
                            <FormActionButtons
                                formik={formik}
                                loading={loading}
                                submitButtonResourceName={"AUTH.RESET_PASSWORD.BUTTON.SUBMIT"}
                                showCancelButton={true}
                                cancelLinkTo={"/auth/login"}
                                cancelButtonResourceName={"FORM.CANCEL"}
                                fullWidthButton={false}
                                buttonSize={"btn-lg"}
                            />
                            {/* end::Action */}
                        </Form>
                    )}
                </Formik>
            }
        </>
    )
}
