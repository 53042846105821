import {useSelector} from "react-redux";
import {useIntl} from "react-intl";
import React, {useState} from "react";
import {Form, Formik} from "formik";
import * as Yup from "yup";
import clsx from "clsx";
import {useAppDispatch} from "../../../../../../../infrastructure/hooks/reduxHooks";
import {organisationSelectors} from "../../../../../organisationSelectors";
import {destinationsActions} from "../../../destinationsActions";
import {FormActionButtons, FormGroup, FormInputGroup, FormModal} from "../../../../../../../components/forms";
import {destinationsSelectors} from "../../../destinationsSelectors";
import {toastify} from "../../../../../../../libs/toastify";
import {DotNetIntegration} from "../dotNetIngration/DotNetIntegration";

interface SoftwarePackage {
    packageKey: string,
    name: string,
    imageUrl: string,
    selectable: boolean
}

const softwarePackages: SoftwarePackage[] = [
    {
        packageKey: "dotnet",
        name: "ASP.NET Core",
        imageUrl: "aspnetcore.svg",
        selectable: true
    },
    {
        packageKey: "java",
        name: "Spring Boot",
        imageUrl: "java-spring.svg",
        selectable: false
    },
    {
        packageKey: "django",
        name: "Django",
        imageUrl: "django.svg",
        selectable: false
    },
    {
        packageKey: "go",
        name: "Go",
        imageUrl: "go.svg",
        selectable: false
    },
    {
        packageKey: "php",
        name: "PHP",
        imageUrl: "php.svg",
        selectable: false
    },
    {
        packageKey: "nodejs",
        name: "Node JS",
        imageUrl: "nodejs.svg",
        selectable: false
    }
]

export const CreateSoftwarePackageModal = () => {

    const intl = useIntl()
    const dispatch = useAppDispatch()

    const modalState = useSelector(destinationsSelectors.getCreateCmsModalState())
    const organisationId = useSelector(organisationSelectors.getSelectedOrganisationId())

    const [step, setStep] = useState(1)
    const [selectedPackage, setSelectedPackage] = useState("dotnet")
    const [formValues, setFormValues] = useState({
        name: ''
    })

    const [loading, setLoading] = useState(false)

    const [softwarePackageId, setSoftwarePackageId] = useState('')

    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .min(3, intl.formatMessage({id: 'VALIDATION.MIN_LENGTH'}, {length: 3}))
            .max(75, intl.formatMessage({id: 'VALIDATION.MAX_LENGTH'}, {length: 75}))
            .required(intl.formatMessage({id: 'VALIDATION.REQUIRED'}, {name: intl.formatMessage({id: 'ORGANISATION.MODAL.DESTINATION.CREATE_SOFTWARE_PACKAGE.INPUT.NAME'})})),
    })


    const closeModal = () => {
        dispatch(destinationsActions.showCreateSoftwarePackageModal({state: false}))
        dispatch(destinationsActions.refreshSoftwarePackagesTable())
    }

    return (
        <>
            <FormModal
                modalState={modalState as boolean}
                closeModal={closeModal}
                title={intl.formatMessage({id: "ORGANISATION.MODAL.DESTINATION.CREATE_SOFTWARE_PACKAGE.TITLE"})}
                subTitle={intl.formatMessage({id: "ORGANISATION.MODAL.DESTINATION.CREATE_SOFTWARE_PACKAGE.SUBTITLE"})}
            >
                <Formik
                    initialValues={formValues}
                    enableReinitialize={true}
                    validationSchema={validationSchema}
                    onSubmit={async (values, {setStatus, setSubmitting}) => {

                        if (step == 1) {

                            setLoading(true)

                            setTimeout(async () => {
                                const response = await dispatch(destinationsActions.createSoftwarePackage({
                                    organisationId,
                                    name: values.name
                                })).unwrap()

                                if (response.isSucceed) {
                                    setSoftwarePackageId(response.data.softwarePackageId)
                                    setStep(2)
                                    setSubmitting(false)
                                    setLoading(false)
                                } else {
                                    setLoading(false)
                                    setSubmitting(false)

                                    switch (response.message) {
                                        case "DestinationHasAlreadyExist":
                                            toastify.error(intl.formatMessage({id: "ORGANISATIONS.DESTINATION.MESSAGE.DESTINATION_HAS_ALREADY_EXIST"}))
                                            break;
                                        default:
                                            toastify.error()
                                    }
                                }
                            }, 500)
                        } else if (step == 2) {
                            closeModal()
                        }

                    }}
                >
                    {(formik) => (
                        <Form className="form">
                            {
                                step == 1 &&
                                <>
                                    <FormInputGroup
                                        labelResourceName={"ORGANISATION.MODAL.DESTINATION.CREATE_SOFTWARE_PACKAGE.INPUT.NAME"}
                                        fieldProps={formik.getFieldProps('name')}
                                        placeholderResourceName={"ORGANISATION.MODAL.DESTINATION.CREATE_SOFTWARE_PACKAGE.INPUT.NAME.PLACEHOLDER"}
                                        validationCondition={formik.touched.name && formik.errors.name}
                                        validationMessage={formik.errors.name}
                                    />
                                    <FormGroup
                                        labelResourceName={"ORGANISATION.MODAL.DESTINATION.CREATE_SOFTWARE_PACKAGE.INPUT.SOFTWARE_PACKAGE"}
                                    >
                                        <div className="row mt-2">
                                            {
                                                softwarePackages.map((packageItem: SoftwarePackage) => {
                                                    return (
                                                        <div key={packageItem.packageKey} className="col-md-6 col-lg-12 col-xxl-4 mb-6">
                                                            <div
                                                                className={clsx("btn btn-outline btn-outline-dashed d-flex text-start",
                                                                    selectedPackage == packageItem.packageKey ? "btn-active-light-primary active" : "",
                                                                    packageItem.selectable ? "" : "disabled"
                                                                )}
                                                                onClick={() => setSelectedPackage(packageItem.packageKey)}
                                                            >
                                                                <div className="w-100">
                                                                    <div className="d-flex justify-content-center mt-2">
                                                                        <img src={`/assets/integrations/${packageItem.imageUrl}`} style={{
                                                                            width: 50
                                                                        }}/>
                                                                    </div>
                                                                    <div className="fs-4 fw-bold text-gray-800 mb-1 mt-2 d-flex justify-content-center">
                                                                        {packageItem.name}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </FormGroup>
                                </>

                            }
                            {
                                step == 2 &&
                                <>
                                    <DotNetIntegration organisationId={organisationId} softwarePackageId={softwarePackageId}/>
                                    <div className="my-6 fst-italic">
                                        * {intl.formatMessage({id: "ORGANISATION.MODAL.DESTINATION.CREATE_SOFTWARE_PACKAGE.MESSAGE.NEW_TOKEN"})}
                                    </div>
                                </>

                            }
                            <FormActionButtons
                                formik={formik}
                                loading={loading}
                                submitButtonResourceName={
                                    step == 1 ? "FORM.CREATE" : "FORM.FINISH"
                                }
                                fullWidthButton={false}
                                buttonSize={"btn-lg"}
                                showCancelButton={step == 1}
                                closeModal={closeModal}
                            />
                        </Form>
                    )}
                </Formik>
            </FormModal>
        </>
    )
}