import {DataRequest} from "../../../../components/tables";
import {rulesReducerActions} from "./rulesSlice";
import {ActionResponse} from "../../../../infrastructure/models/actionResponse";
import {CreateAgentRuleModel, CreateAgentRuleModelPort, CreateCmsExtensionRuleModel, RuleModel, UpdateAgentRuleModel, UpdateCmsExtensionRuleModel} from "./models";
import {DestinationTypeEnum} from "../destinations/models";
import {ruleApis} from "./rulesApis";
import {createAsyncThunk} from "@reduxjs/toolkit";
import {CreateSoftwarePackageRuleModel} from "./models/CreateSoftwarePackageRuleModel";
import {UpdateSoftwarePackageRuleModel} from "./models/UpdateSoftwarePackageRuleModel";

const showRuleModal = createAsyncThunk(
    'rules/showRuleModal',
    async ({state = true, type = null, ruleId = null, refreshTableWhenModalClosed = false}: {
        state?: boolean,
        type: DestinationTypeEnum | null,
        ruleId?: string | null,
        refreshTableWhenModalClosed?: boolean
    }, {dispatch}) => {
        dispatch(rulesReducerActions.setShowRuleModal({state, type, ruleId, refreshTableWhenModalClosed}));
    })

const getRules = createAsyncThunk(
    'rules/getRules',
    async ({
               organisationId,
               destinationId = undefined,
               type = undefined,
               dataRequest = undefined
           }: {
        organisationId: string,
        destinationId?: string | undefined,
        type?: DestinationTypeEnum | undefined,
        dataRequest?: DataRequest | undefined
    }, {dispatch}) => {
        const response = await ruleApis.getRules({organisationId, destinationId, type, dataRequest})
        dispatch(rulesReducerActions.setRules(response.data))
    })

const refreshRulesTable = createAsyncThunk(
    'rules/refreshRulesTable',
    async (_, {dispatch}) => {
        dispatch(rulesReducerActions.setRulesRefreshTable())
    })


const createAgentRule = async (model: CreateAgentRuleModel) => {
    try {
        await ruleApis.createAgentRule(model)
        return {
            isSucceed: true
        } as ActionResponse
    } catch (e: any) {
        return {
            isSucceed: false,
            errors: e.response?.data?.errors,
            message: e.response.data
        } as ActionResponse
    }
}

const createCmsExtensionRule = async (model: CreateCmsExtensionRuleModel) => {
    try {
        await ruleApis.createCmsExtensionRule(model)
        return {
            isSucceed: true
        } as ActionResponse
    } catch (e: any) {
        return {
            isSucceed: false,
            errors: e.response?.data?.errors,
            message: e.response.data
        } as ActionResponse
    }
}

const createSoftwarePackageRule = async (model: CreateSoftwarePackageRuleModel) => {
    try {
        await ruleApis.createSoftwarePackageRule(model)
        return {
            isSucceed: true
        } as ActionResponse
    } catch (e: any) {
        return {
            isSucceed: false,
            errors: e.response?.data?.errors,
            message: e.response.data
        } as ActionResponse
    }
}

const updateAgentRule = async (model: UpdateAgentRuleModel) => {
    try {
        await ruleApis.updateAgentRule(model)
        return {
            isSucceed: true
        } as ActionResponse
    } catch (e) {
        return {
            isSucceed: false,
        } as ActionResponse
    }
}

const updateCmsExtensionRule = async (model: UpdateCmsExtensionRuleModel) => {
    try {
        await ruleApis.updateCmsExtensionRule(model)
        return {
            isSucceed: true
        } as ActionResponse
    } catch (e) {
        return {
            isSucceed: false,
        } as ActionResponse
    }
}

const updateSoftwarePackageRule = async (model: UpdateSoftwarePackageRuleModel) => {
    try {
        await ruleApis.updateSoftwarePackageRule(model)
        return {
            isSucceed: true
        } as ActionResponse
    } catch (e) {
        return {
            isSucceed: false,
        } as ActionResponse
    }
}

const getRule = async (organisationId: string, ruleId: string) => {
    try {
        const response = await ruleApis.getRule({organisationId, ruleId});
        return {
            isSucceed: true,
            data: response.data
        } as ActionResponse<RuleModel>
    } catch (e: any) {
        return {
            isSucceed: false,
            errors: e.response?.data?.errors,
            message: e.response.data
        } as ActionResponse<RuleModel>
    }
}

const getRoles = async (organisationId: string) => {
    return ruleApis.getRoles({organisationId})
}

const getDestinations = async (organisationId: string) => {
    return ruleApis.getDestinations({organisationId})
}

const checkRuleDeletable = async (organisationId: string, ruleId: string) => {
    try {
        const response = await ruleApis.checkRuleDeletable({organisationId, ruleId});
        return {
            isSucceed: true,
            data: response.data
        } as ActionResponse<boolean>
    } catch {
        return {
            isSucceed: false,
        } as ActionResponse<boolean>
    }
}

const deleteRule = async (organisationId: string, ruleId: string) => {
    try {
        await ruleApis.deleteRule({organisationId, ruleId});
        return {
            isSucceed: true,
        } as ActionResponse
    } catch {
        return {
            isSucceed: false
        } as ActionResponse
    }
}

const activateRule = async ({organisationId, ruleId, state}: { organisationId: string, ruleId: string, state: boolean }) => {
    try {
        await ruleApis.activateRule({organisationId, ruleId, state})
        return {
            isSucceed: true,
        } as ActionResponse
    } catch {
        return {
            isSucceed: false
        } as ActionResponse
    }
}

export const rulesActions =
    {
        showRuleModal,
        getRules,
        refreshRulesTable,
        createAgentRule,
        createCmsExtensionRule,
        createSoftwarePackageRule,
        updateAgentRule,
        updateCmsExtensionRule,
        updateSoftwarePackageRule,
        getRule,
        getRoles,
        getDestinations,
        checkRuleDeletable,
        deleteRule,
        activateRule
    }