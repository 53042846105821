import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {Navigate, Route, Routes, useParams} from "react-router-dom";
import {InviteUsersModal} from "../subpages/users/components/inviteUsers/InviteUsersModal";
import {EditUserRolesModal} from "../subpages/users/components/editUserRoles/EditUserRolesModal";
import {ChangeUserTypeModal} from "../subpages/users/components/changeUserType/changeUserTypeModal";
import {organisationActions, organisationReducerActions, organisationSelectors} from "../";
import {rolesSelectors} from "../subpages/roles/";
import {OrganisationPageTitle} from "../components/OrganisationPageTitle";
import {PageLoader} from "../../../components/loaders";
import {usersSelectors} from "../subpages/users/";
import {RoleModal} from "../subpages/roles/components/roleModal/RoleModal";
import {useAppDispatch} from "../../../infrastructure/hooks/reduxHooks";
import {UserType} from "../../../infrastructure/enums/userType";
import {Header} from "./header/Header";
import {Agents, DestinationsPage, Detail} from "../subpages/destinations/components";
import {RulesPage} from "../subpages/rules/components";
import {UsersPage} from "../subpages/users/components";
import {RolesPage} from "../subpages/roles/components";
import {ImageFromBase64} from "../../../components/images";
import {useIntl} from "react-intl";
import {SubscriptionPage} from "../subpages/subscription/components/SubscriptionPage";
import {DashboardPage} from "../subpages/dashboard/components";
import {Cms} from "../subpages/destinations/components/cmsExtension/Cms";
import {SoftwarePackage} from "../subpages/destinations/components/softwarePackage";


export const OrganisationPage = () => {

    const dispatch = useAppDispatch()
    const intl = useIntl()
    const {id} = useParams()
    const organisation = useSelector(organisationSelectors.getSelectedOrganisation())
    const showInviteUsersModal = useSelector(usersSelectors.getInviteUsersModalState())
    const showRoleModal = useSelector(rolesSelectors.getRoleModalState())
    const showEditUserRolesModal = useSelector(rolesSelectors.getEditUserRolesModalState())
    const showChangeUserTypeModal = useSelector(usersSelectors.getChangeUserTypeModalState())
    const [isLoading, setIsLoading] = useState(true)

    const getOrganisation = () => dispatch(organisationActions.getOrganisation(id as string))

    useEffect(() => {
        setIsLoading(true)
        const promise = getOrganisation()

        return () => {
            promise.abort()
            dispatch(organisationReducerActions.setSelectedOrganisation(null))
        }

    }, [id])

    useEffect(() => {
        setIsLoading(true)
        if (organisation.name) {
            setIsLoading(false)
        }
    }, [organisation])

    return (
        <>
            {
                (!isLoading && organisation) &&
                <>
                    <OrganisationPageTitle/>

                    {organisation.userType == UserType.User
                        ?
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-5">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="d-flex my-5">
                                                {organisation.logo &&
                                                    <ImageFromBase64 className="w-150px mx-auto"
                                                                     base64String={organisation.logo}/>}
                                                {!organisation.logo &&
                                                    <span className="svg-icon svg-icon-primary svg-icon-5x mx-auto">
                                                        <svg
                                                            width={24}
                                                            height={24}
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                  <rect
                                      x={2}
                                      y={2}
                                      width={9}
                                      height={9}
                                      rx={2}
                                      fill="currentColor"
                                  />
                                  <rect
                                      opacity="0.3"
                                      x={13}
                                      y={2}
                                      width={9}
                                      height={9}
                                      rx={2}
                                      fill="currentColor"
                                  />
                                  <rect
                                      opacity="0.3"
                                      x={13}
                                      y={13}
                                      width={9}
                                      height={9}
                                      rx={2}
                                      fill="currentColor"
                                  />
                                  <rect
                                      opacity="0.3"
                                      x={2}
                                      y={13}
                                      width={9}
                                      height={9}
                                      rx={2}
                                      fill="currentColor"
                                  />
                              </svg>
                                                    </span>
                                                }
                                            </div>
                                            <div className="text-center">
                                                <span className="d-block fs-2 fw-bold">{organisation.name}</span>
                                                <span className="d-block fs-5 mt-2">
                                                    {intl.formatMessage({id: "ORGANISATION.DASHBOARD.YOU_ARE_A_USER_OF_THE_ORGANISATION"})}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <>
                            <Header/>
                            <Routes>
                                <Route path='dashboard/*' element={<DashboardPage/>}/>
                                {/*<Route path='destinations/*' element={<DestinationsPage/>}/>*/}
                                <Route path='/agents/rules' element={<Agents/>}/>
                                <Route path='/agents/:destinationId/*' element={<Detail/>}/>
                                <Route path='/agents/' element={<Agents/>}/>
                                <Route path='/agents/*' element={<Agents/>}/>
                                <Route path='/cms/:destinationId/*' element={<Detail/>}/>
                                <Route path='/cms/*' element={<Cms/>}/>
                                <Route path='/software-packages/:destinationId/*' element={<Detail/>}/>
                                <Route path='/software-packages/*' element={<SoftwarePackage/>}/>
                                <Route path='rules/*' element={<RulesPage/>}/>
                                <Route path='users/*' element={<UsersPage/>}/>
                                <Route path='roles/*' element={<RolesPage/>}/>
                                {/*<Route path='subscription/*' element={<SubscriptionPage/>}/>*/}
                                <Route index element={<Navigate to='dashboard/'/>}/>
                            </Routes>
                        </>
                    }

                </>
            }
            {
                isLoading &&
                <PageLoader/>
            }

            {showInviteUsersModal && <InviteUsersModal/>}
            {showRoleModal && <RoleModal/>}
            {showEditUserRolesModal && <EditUserRolesModal/>}
            {showChangeUserTypeModal && <ChangeUserTypeModal/>}
        </>
    )
}