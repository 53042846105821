import {setLanguage, useLang, languages} from "../../../../_metronic/i18n/Metronici18n";
import clsx from "clsx";
import {useState} from "react";

export const Languages = () => {
    const locale = useLang()
    const currentLanguage = languages.find((x) => x.lang === locale)
    const [showDropdownMenu, setShowDropDownMenu] = useState(false)

    const toggleDropDownMenu = () => {
        setShowDropDownMenu(!showDropdownMenu)
    }

    return (
        <>

            <div>
                <button type="button" className="btn btn-light rotate" data-kt-menu-offset="30px, 30px"
                        onClick={toggleDropDownMenu}
                >
                    <span className='symbol symbol-20px me-4'>
                <img className='rounded-1' src={currentLanguage?.flag} alt='metronic'/>
              </span>
                    {currentLanguage?.name}
                    <span className="svg-icon svg-icon-3 rotate-180 ms-3 me-0">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24"
                             height="24" viewBox="0 0 24 24" fill="none">
                            <path
                                d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z"
                                fill="currentColor"></path>
                        </svg>
                    </span>
                </button>

                <div
                    className={clsx("menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-200px position-absolute py-3", showDropdownMenu ? "show" : "")}>

                    {languages.map((l) => (
                        <div
                            className='menu-item px-3'
                            key={l.lang}
                            onClick={() => {
                                setLanguage(l.lang)
                            }}
                        >
                            <a
                                href='#'
                                className={clsx('menu-link d-flex px-5', {active: l.lang === currentLanguage?.lang})}
                            >
              <span className='symbol symbol-20px me-4'>
                <img className='rounded-1' src={l.flag} alt='metronic'/>
              </span>
                                {l.name}
                            </a>
                        </div>
                    ))}

                </div>
            </div>

            {/*<select classNameName="form-select form-select-solid" defaultValue={locale} onChange={(x) => setLanguage(x.target.value)}>*/}
            {/*    {languages.map((l) => {*/}
            {/*        return (<option value={l.lang}>{l.name}</option>)*/}
            {/*    })}*/}
            {/*</select>*/}
        </>
    )
}