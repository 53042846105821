/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect} from 'react'
import {useSelector} from "react-redux";
import {organisationSelectors} from "../organisation/";
import {useNavigate} from "react-router-dom";
import * as urls from "../../infrastructure/variables/urls";
import {format} from "react-string-format";

export const DashboardPage: FC = () => {
    const navigate = useNavigate()
    const organisations = useSelector(organisationSelectors.getOrganisations())

    useEffect(() => {
        if(organisations)
        {
            if (organisations && organisations?.length > 0)
                navigate(format(urls.ORGANISATION_DASHBOARD_URL, organisations[0].id));
            else
                navigate(urls.WELCOME_URL)
        }
    }, [organisations])
    
    return (
        <>
            {/*<PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>*/}
            {/*Dashboard*/}
        </>
    )
}
