import {GuideAccordion, GuideAccordionItem} from "./components";
import {useAppDispatch} from "../../../../../../infrastructure/hooks/reduxHooks";
import {destinationsActions} from "../../../destinations";
import {format} from "react-string-format";
import * as urls from "../../../../../../infrastructure/variables/urls";
import {useNavigate} from "react-router-dom";
import {ORGANISATION_DESTINATION_AGENTS_URL} from "../../../../../../infrastructure/variables/urls";
import {useSelector} from "react-redux";
import {organisationSelectors} from "../../../../organisationSelectors";
import {organisationActions} from "../../../../organisationActions";


export const Guide_tr = () => {

    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const organisationId = useSelector(organisationSelectors.getSelectedOrganisationId())

    return (
        <>
            <div>
                <h4 className="fs-3 text-gray-800 w-bolder mb-6">
                    Hızlı Kurulum
                </h4>
            </div>

            <ul className="nav nav-pills nav-pills-custom mb-3 mt-10" role="tablist">

                <li className="nav-item mb-3 me-3 me-lg-6 quick-install" role="presentation"
                    onClick={() => {
                        navigate(format(urls.ORGANISATION_DESTINATION_AGENTS_URL, organisationId));
                        dispatch(destinationsActions.showCreateAgentModal({state: true}))
                    }}
                >
                    <span className="nav-link btn btn-outline btn-flex btn-color-muted btn-active-color-primary flex-column overflow-hidden w-200px h-90px pt-5 pb-2 
                        active" id="kt_stats_widget_16_tab_link_1" data-bs-toggle="pill" aria-selected="true" role="tab">
                        <div className="nav-icon mb-3">
                            <img src="/assets/integrations/windows.svg" width="30" height="30"/>
                            <img src="/assets/integrations/linux.svg" className="ms-14" width="30" height="30"/>
                        </div>
                        <div className="nav-text text-gray-800 fw-bold fs-6 lh-1 mt-1">
                            Windows / Linux Server
                        </div>
                        <span className="bullet-custom position-absolute bottom-0 w-100 h-4px bg-primary"></span>
                    </span>
                </li>

                <li className="nav-item mb-3 me-3 me-lg-6 quick-install" role="presentation"
                    onClick={() => {
                        navigate(format(urls.ORGANISATION_DESTINATION_CMS_URL, organisationId));
                        dispatch(destinationsActions.showCreateCmsModal({state: true}))
                    }}
                >
                    <span className="nav-link btn btn-outline btn-flex btn-color-muted btn-active-color-primary flex-column overflow-hidden w-200px h-90px pt-5 pb-2 
                        active" id="kt_stats_widget_16_tab_link_1" data-bs-toggle="pill" aria-selected="true" role="tab">
                        <div className="nav-icon mb-3">
                            <img src="/assets/integrations/wordpress.png" width="30" height="30"/>
                            <img src="/assets/integrations/joomla.png" className="ms-6" width="30" height="30"/>
                            <img src="/assets/integrations/drupal.png" className="ms-6" width="30" height="30"/>
                        </div>
                        <div className="nav-text text-gray-800 fw-bold fs-6 lh-1 mt-1">
                            CMS / Wordpress
                        </div>
                        <span className="bullet-custom position-absolute bottom-0 w-100 h-4px bg-primary"></span>
                    </span>
                </li>

                <li
                    onClick={() => {
                        navigate(format(urls.ORGANISATION_DESTINATION_SOFTWARE_PACKAGES_URL, organisationId));
                        dispatch(destinationsActions.showCreateSoftwarePackageModal({state: true}))
                    }}
                    className="nav-item mb-3 me-3 me-lg-6 quick-install" role="presentation">
                    <span className="nav-link btn btn-outline btn-flex btn-color-muted btn-active-color-primary flex-column overflow-hidden w-200px h-90px pt-5 pb-2 
                        active" id="kt_stats_widget_16_tab_link_1" data-bs-toggle="pill" aria-selected="true" role="tab">
                        <div className="nav-icon mb-3">
                            <img src="/assets/integrations/aspnetcore.svg" width="30" height="30"/>
                            <img src="/assets/integrations/php.svg" className="ms-6" width="30" height="30"/>
                            <img src="/assets/integrations/phython.svg" className="ms-6" width="30" height="30"/>                        </div>
                        <div className="nav-text text-gray-800 fw-bold fs-6 lh-1 mt-1">
                            Software Integration
                        </div>
                        <span className="bullet-custom position-absolute bottom-0 w-100 h-4px bg-primary"></span>
                    </span>
                </li>

            </ul>

            <div>
                <h4 className="fs-3 text-gray-800 w-bolder mt-10">
                    Kurulum Rehberi
                </h4>
            </div>

            <GuideAccordion title="Bunları biliyor musunuz?">
                <GuideAccordionItem title="Sunucuma gelen başarısız giriş denemelerini nasıl görebilirim?">
                    Aşağıdaki bilgilerle sunucunuza gelen başarısız giriş denemelerini ve saldırılarını görebilirsiniz.
                    <br/>
                    <br/>
                    Debian/Ubuntu:
                    <div className="bg-black text-gray-400 p-7 rounded position-relative text-break">
                        <span>
                            grep "Failed password" /var/log/auth.log
                        </span>
                    </div>
                    <br/>
                    RadHat/Centos:
                    <div className="bg-black text-gray-400 p-7 rounded position-relative text-break">
                        <span>
                            egrep "Failed|Failure" /var/log/secure
                        </span>
                    </div>
                </GuideAccordionItem>
                <GuideAccordionItem title="WordPress sitenizde bulunan güvenlik açığı oluşturabilecek adresler nelerdir?">
                    <ul>
                        <li>www.siteadi.com/wp-admin/*</li>
                        <li>www.siteadi.com/wp-login.php</li>
                        <li>www.siteadi.com/wp-json/wp/v2/users/1</li>
                    </ul>

                    WordPress sitenizde bulunan yukarıdaki örnek linkleri protect remote ile saldırılara karşı erişime kapatabilirsiniz.
                </GuideAccordionItem>
            </GuideAccordion>

            <GuideAccordion title="Sunucu entegrasyonu nasıl yapılır?">
                <GuideAccordionItem title="1. Adım: Sunucu Tanımlama">
                    <>
                        <div className="fw-bolder mb-2">Windows Server:</div>
                        <ol className="ps-4">
                            <li className="my-3">İlk olarak "Hedefler" bölümünden "Yeni Hedef" butonunun üzerine gelerek "Sunucu" ya tıklayarak yeni bir
                                hedef tanımlamanız gerekmektedir. Burada sunucunuza bir ad belirledikten sonra Windows seçimini yaparak
                                ilerleyebilirsiniz.
                            </li>
                            <li className="my-3">İkinci adımda sunucunuzun statik bir IP adresi varsa bunu girebiliriniz. Sunucunuz internete IPv6
                                üzerinden çıkıyorsa IPv6 adresini değilse varsayılan IPV4 adresini girebilirsiniz. Bu seçim zorunlu değildir, statik IP
                                adresi girmeniz sunucularımızın servislerimize erişiminde ekstra güvenlik sağlayacaktır.
                            </li>
                            <li className="my-3">Üçüncü adımda sunucumuzda bulunan PowerShell'i yönetici olarak çalıştırmanız gerekmektedir. Bunun için
                                PowerShell yazılımına sağ tıklayarak "Run as Administrator" seçebilirsiniz. Script'i buraya kopyaladıktan sonra enter
                                tuşuna basarak çalıştırabilirsiniz.
                            </li>
                            <li className="my-3">Script'i çalıştırdığınızda kurulum işlemi başlayacaktır. Kurulum işlemi için ZIP dosyası indirilecek,
                                kurulum yapılacak ve Protect Remote Agent servisi çalışır hale getirilecektir.
                            </li>
                            <li className="my-3">Kurulum işleminin başarıyla tamamlandığını anlamak için Protect Remote web arayüzüne geldiğimizde
                                "Offline" olarak görünen sunucunun artık "Online" olduğunu göreceğiz. Bu işlem en fazla 30 sn. kadar sürebilir.
                            </li>
                            <li className="my-3">Sunucu tarafında yapmamız gereken işlemler ve entegrasyon tamamlandı. Uzak masaüstü bağlantısı,
                                veritabanı gibi erişimleri kapatmak için ekip ve kural tanımları yapılması gerekmektedir. Bunlar için diğer maddelere
                                bakabilirsiniz.
                            </li>
                        </ol>
                        <div className="fw-bolder mb-2">Linux Server:</div>
                        <ol className="ps-4">
                            <li className="my-3">İlk olarak "Hedefler" bölümünden "Yeni Hedef" butonunun üzerine gelerek "Sunucu" ya tıklayarak yeni bir
                                hedef tanımlamanız gerekmektedir. Burada sunucunuza bir ad belirledikten sonra Linux seçimi ile ilerlememiz gerekmektedir.
                                Kullandığımız linux işletim sisteminin çekirdiğine göre Debian Based yada Redhat Based seçebilirsiniz.
                            </li>
                            <li className="my-3">İkinci adımda sunucunuzun statik bir IP adresi varsa bunu girebiliriniz. Sunucunuz internete IPv6
                                üzerinden çıkıyorsa IPv6 adresini değilse varsayılan IPV4 adresini girebilirsiniz. Bu seçim zorunlu değildir, statik IP
                                adresi girmeniz sunucularımızın servislerimize erişiminde ekstra güvenlik sağlayacaktır.
                            </li>
                            <li className="my-3">Üçüncü adımda karşımıza çıkan script'i sunucumuzun komut satırında (bash) çalıştırmanız gerekmektedir.
                                Çalıştırmadan önce bağlandığımız kullanıcın root yetkisine sahip olduğundan emin olmanız gerekmektedir. Bunun için "sudo
                                su" komutu ile yönetici yetkisine sahip olabilirsiniz.
                            </li>
                            <li className="my-3">Script'i çalıştırdığınızda kurulum işlemi başlayacaktır. Kurulum işlemi için ZIP dosyası indirilecek,
                                kurulum yapılacak ve Protect Remote Agent servisi çalışır hale getirilecektir.
                            </li>
                            <li className="my-3">Kurulum işleminin başarıyla tamamlandığını anlamak için Protect Remote web arayüzüne geldiğimizde
                                "Offline" olarak görünen sunucunun artık "Online" olduğunu göreceğiz. Bu işlem en fazla 30 sn. kadar sürebilir.
                            </li>
                            <li className="my-3">Sunucu tarafında yapmamız gereken işlemler ve entegrasyon tamamlandı. Uzak masaüstü bağlantısı,
                                veritabanı gibi erişimleri kapatmak için ekip ve kural tanımları yapılması gerekmektedir. Bunlar için diğer maddelere
                                bakabilirsiniz.
                            </li>
                            <li className="my-3">Sunucunuz üzerinde uygulamalarınızı Docker üzerinden yayınlıyorsanız ilgili hedefin ayarlarına giderek
                                "Docker" desteğini açmayı unutmayınız.
                            </li>
                        </ol>
                    </>
                </GuideAccordionItem>
                <GuideAccordionItem title="2. Adım: Ekip Oluşturma">
                    <>
                        Organizasyonunuz içerisinde erişim yetkileri kullanıcılar üzerinden değil ekipler üzerinden verilmektedir. Bu nedenle bir
                        kullanıcın erişim yetkisine sahip olması için mutlaka bir ekip içerisinde yer alması gerekmektedir. Bir kullanıcı erişmesi gereken
                        bir yere erişemediğinde ilk kontrol edilmesi gereken kullanıcının ekip içerisinde yer alıp almadığıdır.
                        <ol className="ps-4 mt-4">
                            <li className="my-3">İlk olarak "Ekipler" bölümünden "Yeni Ekip" butonuna tıklayarak yeni bir ekip tanımlamanız
                                gerekmektedir.
                            </li>
                            <li className="my-3">Ekip adı olarak herhangi bir ad belirledikten sonra ekibi belirgin yapacak uygun bir renk seçimi de
                                yapabilirsiniz.
                            </li>
                            <li className="my-3">Ekibi oluşturduktan sonra "Kullanıcılar" sekmesine giderek ilgili kullanıcının yanındaki "Ekipleri
                                Düzenle" butonu üzerinden kullanıları bu ekibe dahil edebilirsiniz.
                            </li>
                        </ol>
                    </>
                </GuideAccordionItem>
                <GuideAccordionItem title="3. Adım: Kural Tanımlama ve Portların Kapatılması">
                    <>
                        Kimlerin hangi portlara erişebileceği ve hangi portların dışarıya kapatılacağı kural tanımlamaları ile yapılmaktadır. Örneğin
                        Linux sunucunuzun SSH erişimini yada Windows sunucunuzun RDP (Uzakmasaüstü bağlantısı) erişimini kapatmak için bir kural
                        tanımlayabilirsiniz.
                        <br/><br/>
                        Windows için RDP, Linux için ise SSH portunu kapatacak bir kural tanımlayalım.
                        <ol className="ps-4 mt-4">
                            <li className="my-3">İlk olarak "Kurallar" bölümünden "Yeni Kural" butonunun üzerine gelin.</li>
                            <li className="my-3">Uygulayacağımız kuralı belirten "RDP Erişimi" yada "SSH Erişimi" şeklinde bir ad verebilirsiniz.</li>
                            <li className="my-3">"Hedefler" seçiminden uyguladığınız bu kuralın hangi sunucularınızda geçerli olacağını seçebilirsiniz.
                            </li>
                            <li className="my-3">"Ekipler" seçiminden uyguladığınız bu kuralın hangi ekipler için geçerli olacağını seçebilirsiniz. Burada
                                unutmamanız gereken yetki vermek istediğiniz kullanıcıların mutlaka bu ekip içerisinde yer alması gerektiğidir. Aksi
                                durumda kullanıcılar erişim sağlayamayacaklardır.
                            </li>
                            <li className="my-3">Örnek olarak SSH yada RDP erişimini kapatacağımızdan port tipi olarak TCP seçiyoruz. SSH kapatmak için 22
                                portunu, RDP (Uzakmasaüstü bağlantısı) içinse 3389 portunu girebilirsiniz. Unutmayın bu portlar varsayılan portlardır.
                                Sunucunuzda bu erişimlerin portlarını değiştirdiyseniz veya hizmet sağlayıcınız farklı bir port üzerinden hizmet veriyorsa
                                bu portları girmeniz gerekmektedir.
                            </li>
                            <li className="my-3">Oluştur diyerek kuralı tanımlıyoruz. Kural aktif edildiğinde sunucunuza SSH/RDP erişimi sadece yetkili
                                kullanıcılar tarafından yapılabilecektir. Bu işlem en fazla 30 sn. kadar sürebilir.
                            </li>
                            <li className="my-3">Yetkilendirilmiş kullanıcıların erişim sağlayabilmesi için masaüstü uygulaması, web tarayıcısı veya mobil
                                uygulama üzerinden Protect Remote uygulaması ile aktif olarak pingliyor olması gerekmektedir.
                            </li>
                        </ol>
                    </>
                </GuideAccordionItem>
            </GuideAccordion>

            <GuideAccordion title="Wordpress entegrasyonu nasıl yapılır?">
                <GuideAccordionItem title="1. Adım: Wordpress Site Tanımlama">
                    <>
                        <ol>
                            <li className="my-3">İlk olarak "Hedefler" bölümünden "Yeni Hedef" butonunun üzerine gelerek "CMS / Wordpress" e tıklayarak yeni
                                bir
                                hedef tanımlamanız gerekmektedir. Burada sitenize bir ad belirledikten sonra CMS Paketleri içerisinden "Wordpress" i seçmeniz
                                gerekmektedir.
                            </li>
                            <li className="my-3">
                                İkinci adımda wordpress sitenize kuracağınız eklentiyi indirmeniz ve wordpress sitenize kurmanız gerekmektedir. Bunun için
                                eklentiyi bilgisayarınıza indirdikten sonra wordpress yönetim panelininizin "Eklentiler" bölümüne giderek "Eklenti Yükle"
                                butonuna tıklayıp indirmiş olduğunuz eklentiyi eklemeniz gerekmektedir.
                            </li>
                            <li className="my-3">
                                Üçüncü adımda Protect Remote arayüzünden aldığınız "Token" ı Wordpress sitenizin yönetim panelinden "Ayarlar &gt; Protect
                                Remote" sayfasına gelip "Token" alanına yapıştırınız.
                            </li>
                            <li className="my-3">
                                Burada "Login Page", "Admin Panel" ve "Allow admin-ajax.php" seçimlerini göreceksiniz.<br/><br/>
                                Login Page: Wordpress admin panelinizin giriş sayfasını (wp-login.php) yetkisiz erişimlere kapatır.<br/>
                                Admin Panel: Wordpress admin panelinizin tamamını (wp-admin klasörü) yetkisiz erişimlere kapatır.
                                Allow admin-ajax.php: Bazı eklentiler "wp-admin" klasörü altında bulunan "admin-ajax.php" dosyasına istek yapmaktadır. Bu
                                güvenlik açıklarına sebep olmaktadır, varsayılan olarak açılması tavsiye edilmez. Ancak bir eklentiniz bunu kullanıyorsa ve bu
                                adresi engellemek istemiyorsanız erişime açabilirsiniz. Varsayılan olarak bu ayarı kapalı tutmanız tavsiye ederiz.
                                <br/><br/>
                                wp-login.php: Wordpress login adresini değiştirmek isterseniz buraya farklı bir adres tanımlayabilirsiniz.
                                <br/><br/>
                                İlgili ayarları yapıp kaydettikten sonra wordpress entegrasyonu tamamlanmış olacaktır.
                            </li>
                        </ol>
                    </>
                </GuideAccordionItem>
                <GuideAccordionItem title="2. Adım: Ekip Oluşturma">
                    <>
                        Organizasyonunuz içerisinde erişim yetkileri kullanıcılar üzerinden değil ekipler üzerinden verilmektedir. Bu nedenle bir
                        kullanıcın erişim yetkisine sahip olması için mutlaka bir ekip içerisinde yer alması gerekmektedir. Bir kullanıcı erişmesi gereken
                        bir yere erişemediğinde ilk kontrol edilmesi gereken kullanıcının ekip içerisinde yer alıp almadığıdır.
                        <ol className="ps-4 mt-4">
                            <li className="my-3">CMS / Wordpress hedef oluşturduğunuzda varsayılan olarak ilgili hedefe ait bir ekip otomatik olarak
                                oluşturulmaktadır. "Ekipler" sekmesine gittiğiniz "Hedef Adınız Team" şeklinde bir ekip oluşturulduğunu göreceksiniz.
                            </li>
                            <li className="my-3">
                                Erişmesi gereken tüm kullanıcıların bu ekip içerisinde tanımlanması gerekmektedir. Dilerseniz yeni bir ekip oluşturup bunu
                                kurallar sekmesinde de ayrıca belirtebilirsiniz. (Bir sonraki madde içerisinde detaylı olarak anlatılacaktır.)
                            </li>
                            <li className="my-3">"Kullanıcılar" sekmesine giderek ilgili kullanıcının yanındaki "Ekipleri
                                Düzenle" butonu üzerinden kullanıları bu ekibe dahil edebilirsiniz. Bir kullanıcı ilgili sitenin admin arayüzüne
                                erişemediğinde ilk kontrol etmememiz gereken kullanıcının yetkilendirilmiş ekip içerisinde yer alıp almadığıdır.
                            </li>
                        </ol>
                    </>
                </GuideAccordionItem>
                <GuideAccordionItem title="3. Adım: Kural Tanımlama">
                    <>
                        Kimlerin hangi sitelere erişebileceği kural tanımlamaları ile yapılmaktadır.
                        <ol className="ps-4 mt-4">
                            <li className="my-3">CMS / Wordpress hedef oluşturduğunuzda varsayılan olarak ilgili hedefe ait bir kural otomatik olarak oluşturulmaktadır.
                                "Kurallar" sekmesine gittiğiniz "Hedef Adınız Rule" şeklinde bir kural oluşturulduğunu göreceksiniz.
                            </li>
                            <li>
                                İlgili kuralı görüntülediğinizde "Hedefler" ve "Ekipler" tanımlamalarını göreceksiniz. Burada oluşturduğunuz hedefin ilgili ekip ile
                                eşleştirildiğini göreceksiniz.
                            </li>
                            <li className="my-3">"Hedefler" seçiminden uyguladığınız bu kuralın hangi wordpress siteler için geçerli olacağını seçebilirsiniz.
                            </li>
                            <li className="my-3">"Ekipler" seçiminden uyguladığınız bu kuralın hangi ekipler için geçerli olacağını seçebilirsiniz. Burada
                                unutmamanız gereken yetki vermek istediğiniz kullanıcıların mutlaka bu ekip içerisinde yer alması gerektiğidir. Aksi
                                durumda kullanıcılar erişim sağlayamayacaklardır.
                            </li>
                            <li className="my-3">Yetkilendirilmiş kullanıcıların erişim sağlayabilmesi için masaüstü uygulaması, web tarayıcısı veya mobil
                                uygulama üzerinden Protect Remote uygulaması ile aktif olarak pingliyor olması gerekmektedir.
                            </li>
                        </ol>
                    </>
                </GuideAccordionItem>
            </GuideAccordion>

            <GuideAccordion title="Yazılım entegrasyonu nasıl yapılır?">
                <GuideAccordionItem title="1. Adım: Yazılım Paketi Tanımlama">
                    <>
                        <ol>
                            <li className="my-3">İlk olarak "Hedefler" bölümünden "Yeni Hedef" butonunun üzerine gelerek "Yazılım Paketi" ne tıklayarak yeni bir hedef
                                tanımlamanız gerekmektedir. Burada uygulamanıza bir ad belirledikten sonra Yazılım Paketleri içerisinden ilgili paketi seçmeniz
                                gerekmektedir.
                            </li>
                            <li className="my-3">
                                İkinci adımda entegrasyonu nasıl yapacağınız ile ilgili kod bilgileri yer almaktadır, bu aşamalarla ilgili kodları yazılımınıza belirtilen
                                şekilde entegre ediniz.
                            </li>
                            <li className="my-3">
                                Protect Remote arayüzünden aldığınız "Token" ı uygulamanız içerisine belirtilen şekilde eklediğinizden emin olun.
                            </li>
                        </ol>
                    </>
                </GuideAccordionItem>
                <GuideAccordionItem title="2. Adım: Ekip Oluşturma">
                    <>
                        Organizasyonunuz içerisinde erişim yetkileri kullanıcılar üzerinden değil ekipler üzerinden verilmektedir. Bu nedenle bir
                        kullanıcın erişim yetkisine sahip olması için mutlaka bir ekip içerisinde yer alması gerekmektedir. Bir kullanıcı erişmesi gereken
                        bir yere erişemediğinde ilk kontrol edilmesi gereken kullanıcının ekip içerisinde yer alıp almadığıdır.
                        <ol className="ps-4 mt-4">
                            <li className="my-3">CMS / Wordpress hedef oluşturduğunuzda varsayılan olarak ilgili hedefe ait bir ekip otomatik olarak
                                oluşturulmaktadır. "Ekipler" sekmesine gittiğiniz "Hedef Adınız Team" şeklinde bir ekip oluşturulduğunu göreceksiniz.
                            </li>
                            <li className="my-3">
                                Erişmesi gereken tüm kullanıcıların bu ekip içerisinde tanımlanması gerekmektedir. Dilerseniz yeni bir ekip oluşturup bunu
                                kurallar sekmesinde de ayrıca belirtebilirsiniz. (Bir sonraki madde içerisinde detaylı olarak anlatılacaktır.)
                            </li>
                            <li className="my-3">"Kullanıcılar" sekmesine giderek ilgili kullanıcının yanındaki "Ekipleri
                                Düzenle" butonu üzerinden kullanıları bu ekibe dahil edebilirsiniz. Bir kullanıcı ilgili sitenin admin arayüzüne
                                erişemediğinde ilk kontrol etmememiz gereken kullanıcının yetkilendirilmiş ekip içerisinde yer alıp almadığıdır.
                            </li>
                        </ol>
                    </>
                </GuideAccordionItem>
                <GuideAccordionItem title="3. Adım: Kural Tanımlama">
                    <>
                        Kimlerin hangi uygulamalara erişebileceği kural tanımlamaları ile yapılmaktadır.
                        <ol className="ps-4 mt-4">
                            <li className="my-3">Yazılım paketi hedefi oluşturduğunuzda varsayılan olarak ilgili hedefe ait bir kural otomatik olarak oluşturulmaktadır.
                                "Kurallar" sekmesine gittiğiniz "Hedef Adınız Rule" şeklinde bir kural oluşturulduğunu göreceksiniz.
                            </li>
                            <li>
                                İlgili kuralı görüntülediğinizde "Hedefler" ve "Ekipler" tanımlamalarını göreceksiniz. Burada oluşturduğunuz hedefin ilgili ekip ile
                                eşleştirildiğini göreceksiniz.
                            </li>
                            <li className="my-3">"Hedefler" seçiminden uyguladığınız bu kuralın hangi uygulamalar için geçerli olacağını seçebilirsiniz.
                            </li>
                            <li className="my-3">"Ekipler" seçiminden uyguladığınız bu kuralın hangi ekipler için geçerli olacağını seçebilirsiniz. Burada
                                unutmamanız gereken yetki vermek istediğiniz kullanıcıların mutlaka bu ekip içerisinde yer alması gerektiğidir. Aksi
                                durumda kullanıcılar erişim sağlayamayacaklardır.
                            </li>
                            <li className="my-3">Yetkilendirilmiş kullanıcıların erişim sağlayabilmesi için masaüstü uygulaması, web tarayıcısı veya mobil
                                uygulama üzerinden Protect Remote uygulaması ile aktif olarak pingliyor olması gerekmektedir.
                            </li>
                        </ol>
                    </>
                </GuideAccordionItem>
            </GuideAccordion>
        </>
    )
}