import {DetailPageTitle} from "./DetailPageTitle";
import {useSelector} from "react-redux";
import {organisationSelectors} from "../../../../";
import {Navigate, Route, Routes, useParams} from "react-router-dom";
import {DestinationInfo} from "./DestinationInfo";
import {Navigation} from "./navigation/Navigation";
import {useIntl} from "react-intl";
import {destinationsSelectors} from "../../destinationsSelectors";
import {DestinationTypeEnum} from "../../models";
import {Rules} from "../../../rules/components/rules/Rules";
import {Script} from "./Script";
import {Settings} from "./settings";
import {WordpressIntegration} from "../cmsExtension";
import {ThreeDotsLoader} from "../../../../../../components/loaders";
import {DotNetIntegration} from "../softwarePackage";
import {format} from "react-string-format";
import * as urls from "../../../../../../../app/infrastructure/variables/urls";
import {Roles} from "../../../roles/components";

export const Detail = () => {

    const intl = useIntl()

    const organisationId = useSelector(organisationSelectors.getSelectedOrganisationId())
    const {destinationId} = useParams()
    const detailInfo = useSelector(destinationsSelectors.getDestinationDetailInfo())

    return (
        <>
            {!detailInfo.name &&
                <ThreeDotsLoader/>
            }
            <div className={!detailInfo.name ? "d-none" : ""}>
                <DetailPageTitle/>
                <div className="d-flex flex-column flex-lg-row">
                    <div className="flex-column flex-lg-row-auto w-100 w-lg-200px w-xl-300px mb-10">
                        <DestinationInfo/>
                    </div>
                    <div className="flex-lg-row-fluid ms-lg-10">

                        <Navigation
                            tabs={
                                [
                                    {
                                        show: detailInfo.destinationType == DestinationTypeEnum.Agent,
                                        text: intl.formatMessage({id: "ORGANISATIONS.DESTINATION_DETAIL.TABS.RULES"}),
                                        url: "rules",
                                        hasSubPages: true
                                    },
                                    {
                                        show: detailInfo.destinationType == DestinationTypeEnum.Agent || detailInfo.destinationType == DestinationTypeEnum.CmsExtension,
                                        text: intl.formatMessage({id: "ORGANISATIONS.DESTINATION_DETAIL.TABS.SETTINGS"}),
                                        url: "settings",
                                        hasSubPages: true
                                    },
                                    {
                                        show: true,
                                        text: intl.formatMessage({id: "ORGANISATIONS.DESTINATION_DETAIL.TABS.INTEGRATION"}),
                                        url: "integration",
                                        hasSubPages: true
                                    },
                                    {
                                        show: detailInfo.destinationType == DestinationTypeEnum.CmsExtension,
                                        text: intl.formatMessage({id: "ORGANISATIONS.DESTINATION_DETAIL.TABS.ROLES"}),
                                        url: "roles",
                                        hasSubPages: true
                                    }
                                ]
                            }
                            back={{
                                url: (() => {
                                    switch (detailInfo.destinationType) {
                                        case DestinationTypeEnum.Agent:
                                            return format(urls.ORGANISATION_DESTINATION_AGENTS_URL, organisationId)
                                        case DestinationTypeEnum.CmsExtension:
                                            return format(urls.ORGANISATION_DESTINATION_CMS_URL, organisationId)
                                        case DestinationTypeEnum.SoftwarePackage:
                                            return format(urls.ORGANISATION_DESTINATION_SOFTWARE_PACKAGES_URL, organisationId)
                                        default:
                                            return ""
                                    }
                                })()
                            }}
                        />

                        <Routes>
                            {
                                detailInfo.destinationType == DestinationTypeEnum.Agent &&
                                <>
                                    <Route path='rules' element={
                                        <div className="card card-flush mb-6 mb-xl-9">
                                            <div className="card-body p-9 pt-4">
                                                <Rules type={DestinationTypeEnum.Agent} destinationId={destinationId as string}/>
                                            </div>
                                        </div>
                                    }/>
                                    <Route path='integration' element={
                                        <div className="card card-flush m-0 mb-xl-9">
                                            <div className="card-body">
                                                <Script/>
                                            </div>
                                        </div>
                                    }/>
                                    <Route path='settings' element={
                                        <Settings/>
                                    }/>
                                    <Route index element={<Navigate to='rules'/>}/>
                                </>
                            }

                            {
                                detailInfo.destinationType == DestinationTypeEnum.CmsExtension &&
                                <>
                                    <Route path='integration' element={
                                        <div className="card card-flush m-0 mb-xl-9">
                                            <div className="card-body">
                                                <WordpressIntegration
                                                    organisationId={organisationId}
                                                    destinationId={destinationId!}
                                                />
                                            </div>
                                        </div>
                                    }/>
                                    <Route path='settings' element={
                                        <Settings/>
                                    }/>
                                    <Route path='roles' element={
                                        <Roles/>
                                    }/>
                                    <Route index element={<Navigate to='settings'/>}/>
                                </>
                            }

                            {
                                detailInfo.destinationType == DestinationTypeEnum.SoftwarePackage &&
                                <>
                                    <Route path='integration' element={
                                        <div className="card card-flush m-0 mb-xl-9">
                                            <div className="card-body">
                                                <DotNetIntegration organisationId={organisationId} softwarePackageId={destinationId!}/>
                                            </div>
                                        </div>
                                    }/>
                                    <Route index element={<Navigate to='integration'/>}/>
                                </>
                            }
                        </Routes>
                    </div>
                </div>
            </div>
        </>
    )
}