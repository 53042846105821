import axios, {AxiosResponse} from "axios";
import {UpdateProfileModel} from "./models";
import {LookupKeyValueModel} from "../../infrastructure/models/lookupKeyValueModel";

const checkEmailVerification = async () => await axios.get<boolean, AxiosResponse<boolean>>('/account/check-email-verification');

const resetPassword = async ({oldPassword, newPassword}: { oldPassword: string, newPassword: string }) => axios.patch('/account/password', {oldPassword, newPassword});

const updateProfile = async ({model}: { model: UpdateProfileModel }) => axios.patch('/account/profile', model)

const updateProfilePhoto = async ({photo}: { photo: File }) => {
    const formData = new FormData();
    formData.append('file', photo);
    return await axios.patch<string, AxiosResponse<string>>(`/account/photo`,
        formData,
        {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
}

const getProfile = async () => await axios.get<UpdateProfileModel, AxiosResponse<UpdateProfileModel>>('/account/profile');

const getCountries = async () => await axios.get<LookupKeyValueModel[], AxiosResponse<LookupKeyValueModel[]>>('/lookups/countries');

const getTimeZones = async () => await axios.get<LookupKeyValueModel[], AxiosResponse<LookupKeyValueModel[]>>('/lookups/timezones');

export const accountApis = {
    checkEmailVerification,
    resetPassword,
    updateProfile,
    updateProfilePhoto,
    getProfile,
    getCountries,
    getTimeZones
}