import React, {FC} from "react";
import {FormValidation} from "./FormValidation";
import {FormLabel} from "./FormLabel";
import {Link} from "react-router-dom";
import {useIntl} from "react-intl";

interface Props {
    showLabel?: boolean,
    labelResourceName: string,
    link?: PropsLink,
    informationMessage?: string,
    validationCondition?: string | false | undefined,
    validationMessage?: string,
}

interface PropsLink {
    textResourceName: string
    to: string,
}

export const FormGroup: FC<Props> = ({
                                         children,
                                         link,
                                         showLabel = true,
                                         labelResourceName,
                                         informationMessage,
                                         validationCondition,
                                         validationMessage
                                     }) => {

    const intl = useIntl()

    return (
        <div className="d-flex flex-column mb-8">

            {
                showLabel && (
                    <>
                        {link ? (
                            <div className="d-flex flex-stack mb-2">
                                <FormLabel
                                    resourceName={labelResourceName}
                                />
                                <Link
                                    to={link.to}
                                    className='link-primary fs-6 fw-bolder'
                                    style={{marginLeft: '5px'}}
                                >
                                    {intl.formatMessage({id: link.textResourceName})}
                                </Link>
                            </div>
                        ) : (
                            <FormLabel
                                resourceName={labelResourceName}
                            />
                        )}
                    </>
                )
            }

            {children}

            {informationMessage && <span className="fs-7 text-muted mt-2">{informationMessage}</span>}

            {
                (validationCondition && validationMessage) &&
                <FormValidation
                    condition={validationCondition}
                    propertyResourceName={labelResourceName}
                    errorMessage={validationMessage}
                />
            }
        </div>
    )
}