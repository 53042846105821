import {GuideAccordion, GuideAccordionItem} from "./components";
import {format} from "react-string-format";
import * as urls from "../../../../../../infrastructure/variables/urls";
import {destinationsActions} from "../../../destinations";
import {useNavigate} from "react-router-dom";
import {useAppDispatch} from "../../../../../../infrastructure/hooks/reduxHooks";
import {useSelector} from "react-redux";
import {organisationSelectors} from "../../../../organisationSelectors";

export const Guide_de = () => {

    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const organisationId = useSelector(organisationSelectors.getSelectedOrganisationId())
    
    return (
        <>
            <div>
                <h4 className="fs-3 text-gray-800 w-bolder mb-6">
                    Schnellinstallation
                </h4>
            </div>

            <ul className="nav nav-pills nav-pills-custom mb-3 mt-10" role="tablist">

                <li className="nav-item mb-3 me-3 me-lg-6 quick-install" role="presentation"
                    onClick={() => {
                        navigate(format(urls.ORGANISATION_DESTINATION_AGENTS_URL, organisationId));
                        dispatch(destinationsActions.showCreateAgentModal({state: true}))
                    }}
                >
                    <span className="nav-link btn btn-outline btn-flex btn-color-muted btn-active-color-primary flex-column overflow-hidden w-200px h-90px pt-5 pb-2 
                        active" id="kt_stats_widget_16_tab_link_1" data-bs-toggle="pill" aria-selected="true" role="tab">
                        <div className="nav-icon mb-3">
                            <img src="/assets/integrations/windows.svg" width="30" height="30"/>
                            <img src="/assets/integrations/linux.svg" className="ms-14" width="30" height="30"/>
                        </div>
                        <div className="nav-text text-gray-800 fw-bold fs-6 lh-1 mt-1">
                            Windows / Linux Server
                        </div>
                        <span className="bullet-custom position-absolute bottom-0 w-100 h-4px bg-primary"></span>
                    </span>
                </li>

                <li className="nav-item mb-3 me-3 me-lg-6 quick-install" role="presentation"
                    onClick={() => {
                        navigate(format(urls.ORGANISATION_DESTINATION_CMS_URL, organisationId));
                        dispatch(destinationsActions.showCreateCmsModal({state: true}))
                    }}
                >
                    <span className="nav-link btn btn-outline btn-flex btn-color-muted btn-active-color-primary flex-column overflow-hidden w-200px h-90px pt-5 pb-2 
                        active" id="kt_stats_widget_16_tab_link_1" data-bs-toggle="pill" aria-selected="true" role="tab">
                        <div className="nav-icon mb-3">
                            <img src="/assets/integrations/wordpress.png" width="30" height="30"/>
                            <img src="/assets/integrations/joomla.png" className="ms-6" width="30" height="30"/>
                            <img src="/assets/integrations/drupal.png" className="ms-6" width="30" height="30"/>
                        </div>
                        <div className="nav-text text-gray-800 fw-bold fs-6 lh-1 mt-1">
                            CMS / Wordpress
                        </div>
                        <span className="bullet-custom position-absolute bottom-0 w-100 h-4px bg-primary"></span>
                    </span>
                </li>

                <li
                    onClick={() => {
                        navigate(format(urls.ORGANISATION_DESTINATION_SOFTWARE_PACKAGES_URL, organisationId));
                        dispatch(destinationsActions.showCreateSoftwarePackageModal({state: true}))
                    }}
                    className="nav-item mb-3 me-3 me-lg-6 quick-install" role="presentation">
                    <span className="nav-link btn btn-outline btn-flex btn-color-muted btn-active-color-primary flex-column overflow-hidden w-200px h-90px pt-5 pb-2 
                        active" id="kt_stats_widget_16_tab_link_1" data-bs-toggle="pill" aria-selected="true" role="tab">
                        <div className="nav-icon mb-3">
                            <img src="/assets/integrations/aspnetcore.svg" width="30" height="30"/>
                            <img src="/assets/integrations/php.svg" className="ms-6" width="30" height="30"/>
                            <img src="/assets/integrations/phython.svg" className="ms-6" width="30" height="30"/>
                        </div>
                        <div className="nav-text text-gray-800 fw-bold fs-6 lh-1 mt-1">
                            Software Integration
                        </div>
                        <span className="bullet-custom position-absolute bottom-0 w-100 h-4px bg-primary"></span>
                    </span>
                </li>

            </ul>

            <div>
                <h4 className="fs-3 text-gray-800 w-bolder mt-10">
                    Installationsanleitung
                </h4>
            </div>
            
            <GuideAccordion title="Kennen Sie diese?">
                <GuideAccordionItem title="Wie kann ich fehlgeschlagene Anmeldeversuche auf meinem Server anzeigen?" >
                    Mit den folgenden Informationen können Sie fehlgeschlagene Anmeldeversuche und Angriffe auf Ihrem Server anzeigen.
                    <br/>
                    <br/>
                    Debian/Ubuntu:
                    <div className="bg-black text-gray-400 p-7 rounded position-relative text-break">
                        <span>
                            grep "Failed password" /var/log/auth.log
                        </span>
                    </div>
                    <br/>
                    RadHat/Centos:
                    <div className="bg-black text-gray-400 p-7 rounded position-relative text-break">
                        <span>
                            egrep "Failed|Failure" /var/log/secure
                        </span>
                    </div>
                </GuideAccordionItem>
                <GuideAccordionItem title="Was sind die Adressen, die Sicherheitslücken auf Ihrer WordPress-Website verursachen könnten?" >
                    <ul>
                        <li>www.deineseite.com/wp-admin/*</li>
                        <li>www.deineseite.com/wp-login.php</li>
                        <li>www.deineseite.com/wp-json/wp/v2/users/1</li>
                    </ul>

                    Sie können den Zugriff auf die oben genannten Beispiel-Links, die Sicherheitslücken auf Ihrer WordPress-Website verursachen könnten, durch Verwendung der Funktion 'Remote schützen' vor Angriffen blockieren.
                </GuideAccordionItem>
            </GuideAccordion>
            <br/>
            
            <div>
                <h4 className="fs-3 text-gray-800 w-bolder mb-6">Installationsanleitung</h4>
            </div>

            <GuideAccordion title="Wie erfolgt die Integration des Servers?">
                <GuideAccordionItem title="Schritt 1: Serverdefinition">
                    <>
                        <div className="fw-bolder mb-2">Windows Server:</div>
                        <ol className="ps-4">
                            <li className="my-3">Gehen Sie zunächst zum Abschnitt "Reiseziele", bewegen Sie den Mauszeiger über die Schaltfläche "Neues Ziel" und klicken Sie auf "Server", um ein neues Ziel zu definieren. Nachdem Sie Ihrem Server einen Namen gegeben haben, können Sie mit der Auswahl von Windows fortfahren.</li>
                            <li className="my-3">Im zweiten Schritt können Sie die statische IP-Adresse Ihres Servers eingeben, falls vorhanden. Wenn Ihr Server über IPv6 ins Internet geht, geben Sie die IPv6-Adresse ein, andernfalls die Standard-IPv4-Adresse. Diese Auswahl ist optional, das Eingeben einer statischen IP-Adresse erhöht jedoch die Sicherheit beim Zugriff auf unsere Dienste über Ihre Server.</li>
                            <li className="my-3">Im dritten Schritt müssen Sie PowerShell auf Ihrem Server als Administrator ausführen. Klicken Sie dazu mit der rechten Maustaste auf die PowerShell-Software und wählen Sie "Als Administrator ausführen". Nach dem Kopieren des Skripts hierher können Sie es durch Drücken der Eingabetaste ausführen.</li>
                            <li className="my-3">Wenn Sie das Skript ausführen, beginnt der Installationsvorgang. Für den Installationsvorgang wird eine ZIP-Datei heruntergeladen, die Installation wird durchgeführt und der Protect Remote Agent-Dienst wird gestartet.</li>
                            <li className="my-3">Um festzustellen, ob die Installation erfolgreich abgeschlossen wurde, sehen Sie auf der Protect Remote-Web-Oberfläche nach. Der zuvor als "Offline" angezeigte Server wird jetzt als "Online" angezeigt. Dieser Vorgang kann maximal 30 Sekunden dauern.</li>
                            <li className="my-3">Die Schritte und die Integration auf der Serverseite sind abgeschlossen. Um den Zugriff auf Remote-Desktop-Verbindungen, Datenbanken usw. zu sperren, müssen Teams und Regeln definiert werden. Sie können die weiteren Punkte für diese Schritte überprüfen.</li>
                        </ol>
                        <div className="fw-bolder mb-2">Linux Server:</div>
                        <ol className="ps-4">
                            <li className="my-3">Gehen Sie zunächst zum Abschnitt "Reiseziele", bewegen Sie den Mauszeiger über die Schaltfläche "Neues Ziel" und klicken Sie auf "Server", um ein neues Ziel zu definieren. Nachdem Sie Ihrem Server einen Namen gegeben haben, müssen Sie mit der Auswahl von Linux fortfahren. Je nach Kernel Ihres verwendeten Linux-Betriebssystems können Sie Debian Based oder Redhat Based auswählen.</li>
                            <li className="my-3">Im zweiten Schritt können Sie die statische IP-Adresse Ihres Servers eingeben, falls vorhanden. Wenn Ihr Server über IPv6 ins Internet geht, geben Sie die IPv6-Adresse ein, andernfalls die Standard-IPv4-Adresse. Diese Auswahl ist optional, das Eingeben einer statischen IP-Adresse erhöht jedoch die Sicherheit beim Zugriff auf unsere Dienste über Ihre Server.</li>
                            <li className="my-3">Im dritten Schritt müssen Sie das auf Ihrem Server erscheinende Skript in der Kommandozeile (Bash) ausführen. Stellen Sie sicher, dass der verbundene Benutzer über Root-Rechte verfügt, bevor Sie das Skript ausführen. Verwenden Sie dazu den Befehl "sudo su", um Administratorrechte zu erhalten.</li>
                            <li className="my-3">Wenn Sie das Skript ausführen, beginnt der Installationsvorgang. Für den Installationsvorgang wird eine ZIP-Datei heruntergeladen, die Installation wird durchgeführt und der Protect Remote Agent-Dienst wird gestartet.</li>
                            <li className="my-3">Um festzustellen, ob die Installation erfolgreich abgeschlossen wurde, sehen Sie auf der Protect Remote-Web-Oberfläche nach. Der zuvor als "Offline" angezeigte Server wird jetzt als "Online" angezeigt. Dieser Vorgang kann maximal 30 Sekunden dauern.</li>
                            <li className="my-3">Die Schritte und die Integration auf der Serverseite sind abgeschlossen. Um den Zugriff auf Remote-Desktop-Verbindungen, Datenbanken usw. zu sperren, müssen Teams und Regeln definiert werden. Sie können die weiteren Punkte für diese Schritte überprüfen.</li>
                            <li className="my-3">Wenn Sie Ihre Anwendungen auf Ihrem Server über Docker bereitstellen, vergessen Sie nicht, die "Docker"-Unterstützung in den Einstellungen des entsprechenden Ziels zu aktivieren.</li>
                        </ol>
                    </>
                </GuideAccordionItem>
                <GuideAccordionItem title="Schritt 2: Teamerstellung">
                    <>
                        Der Zugriff in Ihrer Organisation erfolgt nicht über Benutzer, sondern über Teams. Daher muss ein Benutzer, um Zugriffsrechte zu haben, unbedingt Mitglied eines Teams sein. Wenn ein Benutzer nicht auf einen bestimmten Bereich zugreifen kann, sollte zuerst überprüft werden, ob der Benutzer Mitglied des Teams ist.
                        <ol className="ps-4 mt-4">
                            <li className="my-3">Klicken Sie zunächst auf die Schaltfläche "Neues Team" im Abschnitt "Teams", um ein neues Team zu definieren.</li>
                            <li className="my-3">Nachdem Sie einen beliebigen Namen für das Team festgelegt haben, können Sie auch eine passende Farbauswahl treffen, um das Team hervorzuheben.</li>
                            <li className="my-3">Nachdem Sie das Team erstellt haben, können Sie im Bereich "Benutzer" zum entsprechenden Benutzer gehen und über die Schaltfläche "Teams bearbeiten" Benutzer diesem Team hinzufügen.</li>
                        </ol>
                    </>
                </GuideAccordionItem>
                <GuideAccordionItem title="Schritt 3: Regeldefinition und Schließen der Ports">
                    <>
                        Die Festlegung von Regeln bestimmt, wer auf welche Ports zugreifen kann und welche Ports nach außen geschlossen werden. Zum Beispiel können Sie eine Regel definieren, um den SSH-Zugriff auf Ihren Linux-Server oder den RDP (Remote Desktop Connection)-Zugriff auf Ihren Windows-Server zu sperren.
                        <br/><br/>
                        Lassen Sie uns eine Regel erstellen, um für Windows den RDP-Zugriff und für Linux den SSH-Zugriff zu schließen.
                        <ol className="ps-4 mt-4">
                            <li className="my-3">Gehen Sie zunächst zum Abschnitt "Regeln", bewegen Sie den Mauszeiger über die Schaltfläche "Neue Regel" und klicken Sie darauf.</li>
                            <li className="my-3">Vergeben Sie einen Namen für die Regel wie "RDP-Zugriff" oder "SSH-Zugriff".</li>
                            <li className="my-3">Von der "Reiseziele"-Auswahl können Sie auswählen, für welche Ihrer Server diese Regel gültig sein soll.</li>
                            <li className="my-3">Über die Auswahl "Teams" können Sie auswählen, für welche Teams diese Regel gültig sein soll. Hierbei ist es wichtig zu beachten, dass die Benutzer, denen Sie Berechtigungen erteilen möchten, unbedingt Mitglieder dieses Teams sein müssen. Andernfalls können Benutzer nicht auf den Server zugreifen.</li>
                            <li className="my-3">Wählen Sie beispielsweise für den SSH- oder RDP-Zugriff den Porttyp als TCP aus. Geben Sie für das Schließen von SSH den Port 22 und für RDP (Remote Desktop Connection) den Port 3389 ein. Beachten Sie, dass dies Standardports sind. Wenn Sie die Ports für diese Verbindungen auf Ihrem Server geändert haben oder Ihr Dienstanbieter über einen anderen Port Dienstleistungen erbringt, müssen Sie diese Ports eingeben.</li>
                            <li className="my-3">Definieren Sie die Regel durch Klicken auf "Erstellen". Wenn die Regel aktiviert ist, kann auf Ihren Server nur von autorisierten Benutzern über den SSH/RDP-Zugriff zugegriffen werden. Dieser Vorgang kann maximal 30 Sekunden dauern.</li>
                            <li className="my-3">Damit autorisierte Benutzer Zugriff erhalten können, muss die Protect Remote-Anwendung auf dem Desktop, im Webbrowser oder in der mobilen Anwendung aktiv mit dem Protect Remote-Dienst pingen.</li>
                        </ol>
                    </>
                </GuideAccordionItem>
            </GuideAccordion>

            <GuideAccordion title="Wie wird die Integration von Wordpress durchgeführt?">
                <GuideAccordionItem title="Schritt 1: Wordpress Site-Definition">
                    <>
                        <ol>
                            <li className="my-3">Gehen Sie zunächst zum Abschnitt "Reiseziele", bewegen Sie den Mauszeiger über die Schaltfläche "Neues Ziel" und klicken Sie auf "CMS / Wordpress", um ein neues Ziel zu definieren. Nachdem Sie Ihrer Website einen Namen gegeben haben, wählen Sie "Wordpress" aus den CMS-Paketen.</li>
                            <li className="my-3">Im zweiten Schritt müssen Sie das Plugin herunterladen, das Sie auf Ihrer Wordpress-Site installieren möchten. Laden Sie das Plugin auf Ihren Computer herunter und fügen Sie es dann über den Abschnitt "Plugins" im Administrationsbereich von Wordpress hinzu, indem Sie auf die Schaltfläche "Plugin hochladen" klicken.</li>
                            <li className="my-3">Im dritten Schritt fügen Sie den von der Protect Remote-Schnittstelle erhaltenen "Token" in das Feld "Token" auf der Seite "Einstellungen &gt; Protect Remote" im Administrationsbereich Ihrer Wordpress-Site ein.</li>
                            <li className="my-3">Hier sehen Sie die Auswahlmöglichkeiten "Login Page", "Admin Panel" und "Allow admin-ajax.php".<br/><br/>
                                Login Page: Sperrt den unbefugten Zugriff auf die Anmeldeseite Ihres Wordpress-Admin-Panels (wp-login.php).<br/>
                                Admin Panel: Sperrt den unbefugten Zugriff auf Ihren gesamten Wordpress-Admin-Panel (wp-admin-Ordner).<br/>
                                Allow admin-ajax.php: Einige Plugins senden Anfragen an die Datei "admin-ajax.php" im Ordner "wp-admin", was Sicherheitslücken verursachen kann. Es wird standardmäßig nicht empfohlen, dies zu öffnen. Wenn jedoch ein Plugin dies benötigt und Sie den Zugriff nicht blockieren möchten, können Sie dies aktivieren. Standardmäßig empfehlen wir, diese Einstellung geschlossen zu halten.<br/><br/>
                                wp-login.php: Wenn Sie die Wordpress-Anmeldeseite ändern möchten, können Sie hier eine andere Adresse festlegen.<br/><br/>
                                Nachdem Sie die entsprechenden Einstellungen vorgenommen und gespeichert haben, ist die Wordpress-Integration abgeschlossen.
                            </li>
                        </ol>
                    </>
                </GuideAccordionItem>
                <GuideAccordionItem title="Schritt 2: Teamerstellung">
                    <>
                        Der Zugriff in Ihrer Organisation erfolgt nicht über Benutzer, sondern über Teams. Daher muss ein Benutzer, um Zugriffsrechte zu haben, unbedingt Mitglied eines Teams sein. Wenn ein Benutzer nicht auf einen bestimmten Bereich zugreifen kann, sollte zuerst überprüft werden, ob der Benutzer Mitglied des Teams ist.
                        <ol className="ps-4 mt-4">
                            <li className="my-3">Wenn Sie ein CMS / Wordpress-Ziel erstellen, wird standardmäßig automatisch ein Team für das entsprechende Ziel erstellt. Gehen Sie zur Registerkarte "Teams", um festzustellen, dass ein Team mit dem Namen "Zielname Team" erstellt wurde.</li>
                            <li className="my-3">Alle Benutzer, die darauf zugreifen müssen, müssen diesem Team zugeordnet werden. Sie können auch ein neues Team erstellen und dies im Abschnitt "Regeln" separat angeben (was im nächsten Abschnitt ausführlicher erläutert wird).</li>
                            <li className="my-3">Gehen Sie zur Registerkarte "Benutzer", wählen Sie den entsprechenden Benutzer aus und fügen Sie ihn über die Schaltfläche "Teams bearbeiten" dem Team hinzu. Wenn ein Benutzer keinen Zugriff auf die Admin-Oberfläche Ihrer Wordpress-Site hat, sollten Sie zuerst überprüfen, ob der Benutzer in einem autorisierten Team ist.</li>
                        </ol>
                    </>
                </GuideAccordionItem>
                <GuideAccordionItem title="Schritt 3: Regeldefinition">
                    <>
                        Die Festlegung von Regeln bestimmt, wer auf welche Websites zugreifen kann.
                        <ol className="ps-4 mt-4">
                            <li className="my-3">Wenn Sie ein CMS / Wordpress-Ziel erstellen, wird standardmäßig automatisch eine Regel für das entsprechende Ziel erstellt. Gehen Sie zur Registerkarte "Regeln", um festzustellen, dass eine Regel mit dem Namen "Zielname Regel" erstellt wurde.</li>
                            <li>Sie sehen die Definitionen für "Ziele" und "Teams", wenn Sie die entsprechende Regel anzeigen. Hier sehen Sie, dass das erstellte Ziel mit dem entsprechenden Team übereinstimmt.</li>
                            <li className="my-3">Von der Auswahl "Ziele" aus können Sie auswählen, für welche Wordpress-Sites diese Regel gelten soll.</li>
                            <li className="my-3">Von der Auswahl "Teams" aus können Sie auswählen, für welche Teams diese Regel gelten soll. Hier ist es wichtig zu beachten, dass die Benutzer, denen Sie Berechtigungen erteilen möchten, unbedingt Mitglieder dieses Teams sein müssen. Andernfalls können Benutzer nicht auf die Site zugreifen.</li>
                            <li className="my-3">Damit autorisierte Benutzer Zugriff erhalten können, muss die Protect Remote-Anwendung auf dem Desktop, im Webbrowser oder in der mobilen Anwendung aktiv mit dem Protect Remote-Dienst pingen.</li>
                        </ol>
                    </>
                </GuideAccordionItem>
            </GuideAccordion>


            <GuideAccordion title="Wie erfolgt die Softwareintegration?">
                <GuideAccordionItem title="Schritt 1: Softwarepaketdefinition">
                    <>
                        <ol>
                            <li className="my-3">Gehen Sie zunächst zum Abschnitt "Reiseziele", bewegen Sie den Mauszeiger über die Schaltfläche "Neues Ziel" und klicken Sie auf "Softwarepaket", um ein neues Ziel zu definieren. Geben Sie Ihrer Anwendung einen Namen und wählen Sie dann das entsprechende Paket aus den Softwarepaketen aus.</li>
                            <li className="my-3">Im zweiten Schritt finden Sie Informationen zur Integration, und integrieren Sie die Codeinformationen gemäß den angegebenen Schritten in Ihre Software.</li>
                            <li className="my-3">Stellen Sie sicher, dass Sie den von der Protect Remote-Schnittstelle erhaltenen "Token" wie angegeben in Ihre Anwendung einfügen.</li>
                        </ol>
                    </>
                </GuideAccordionItem>
                <GuideAccordionItem title="Schritt 2: Teamerstellung">
                    <>
                        Der Zugriff in Ihrer Organisation erfolgt nicht über Benutzer, sondern über Teams. Daher muss ein Benutzer, um Zugriffsrechte zu haben, unbedingt Mitglied eines Teams sein. Wenn ein Benutzer nicht auf einen bestimmten Bereich zugreifen kann, sollte zuerst überprüft werden, ob der Benutzer Mitglied des Teams ist.
                        <ol className="ps-4 mt-4">
                            <li className="my-3">Wenn Sie ein Softwarepaketziel erstellen, wird standardmäßig automatisch ein Team für das entsprechende Ziel erstellt. Gehen Sie zur Registerkarte "Teams", um festzustellen, dass ein Team mit dem Namen "Zielname Team" erstellt wurde.</li>
                            <li className="my-3">Alle Benutzer, die darauf zugreifen müssen, müssen diesem Team zugeordnet werden. Sie können auch ein neues Team erstellen und dies im Abschnitt "Regeln" separat angeben (was im nächsten Abschnitt ausführlicher erläutert wird).</li>
                            <li className="my-3">Gehen Sie zur Registerkarte "Benutzer", wählen Sie den entsprechenden Benutzer aus und fügen Sie ihn über die Schaltfläche "Teams bearbeiten" dem Team hinzu. Wenn ein Benutzer keinen Zugriff auf die Admin-Oberfläche Ihrer Softwareanwendung hat, sollten Sie zuerst überprüfen, ob der Benutzer in einem autorisierten Team ist.</li>
                        </ol>
                    </>
                </GuideAccordionItem>
                <GuideAccordionItem title="Schritt 3: Regeldefinition">
                    <>
                        Die Festlegung von Regeln bestimmt, wer auf welche Anwendungen zugreifen kann.
                        <ol className="ps-4 mt-4">
                            <li className="my-3">Wenn Sie ein Softwarepaketziel erstellen, wird standardmäßig automatisch eine Regel für das entsprechende Ziel erstellt. Gehen Sie zur Registerkarte "Regeln", um festzustellen, dass eine Regel mit dem Namen "Zielname Regel" erstellt wurde.</li>
                            <li>Sie sehen die Definitionen für "Ziele" und "Teams", wenn Sie die entsprechende Regel anzeigen. Hier sehen Sie, dass das erstellte Ziel mit dem entsprechenden Team übereinstimmt.</li>
                            <li className="my-3">Von der Auswahl "Ziele" aus können Sie auswählen, für welche Anwendungen diese Regel gelten soll.</li>
                            <li className="my-3">Von der Auswahl "Teams" aus können Sie auswählen, für welche Teams diese Regel gelten soll. Hier ist es wichtig zu beachten, dass die Benutzer, denen Sie Berechtigungen erteilen möchten, unbedingt Mitglieder dieses Teams sein müssen. Andernfalls können Benutzer nicht auf die Anwendung zugreifen.</li>
                            <li className="my-3">Damit autorisierte Benutzer Zugriff erhalten können, muss die Protect Remote-Anwendung auf dem Desktop, im Webbrowser oder in der mobilen Anwendung aktiv mit dem Protect Remote-Dienst pingen.</li>
                        </ol>
                    </>
                </GuideAccordionItem>
            </GuideAccordion>

        </>
    );
};