import {RootState} from "../../../../../setup";

const getCreateAgentModalState = () => ({destinations: {createAgentModal}}: RootState) => createAgentModal.show
const getCreateCmsModalState = () => ({destinations: {createCmsModal}}: RootState) => createCmsModal.show
const getCreateSoftwarePackageModalState = () => ({destinations: {createSoftwarePackageModal}}: RootState) => createSoftwarePackageModal.show
const getEditDestinationModal = () => ({destinations: {editDestinationModal}}: RootState) => editDestinationModal
const getEditDestinationModalState = () => ({destinations: {editDestinationModal}}: RootState) => editDestinationModal.show
const getEditDestinationModalDestinationId = () => ({destinations: {editDestinationModal}}: RootState) => editDestinationModal.destinationId
const getDestinationDetailInfo = () => ({destinations: {detail}}: RootState) => detail.info
const getAgents = () => ({destinations: {agentsTable}}: RootState) => agentsTable.data
const getAgentsTableRefreshState = () => ({destinations: {agentsTable}}: RootState) => agentsTable.refreshTable
const getCms = () => ({destinations: {cmsTable}}: RootState) => cmsTable.data
const getCmsTableRefreshState = () => ({destinations: {cmsTable}}: RootState) => cmsTable.refreshTable
const getSoftwarePackages = () => ({destinations: {softwarePackagesTable}}: RootState) => softwarePackagesTable.data
const getSoftwarePackagesTableRefreshState = () => ({destinations: {softwarePackagesTable}}: RootState) => softwarePackagesTable.refreshTable

export const destinationsSelectors = {
    getCreateAgentModalState,
    getCreateCmsModalState,
    getCreateSoftwarePackageModalState,
    getEditDestinationModal,
    getEditDestinationModalState,
    getEditDestinationModalDestinationId,
    getDestinationDetailInfo,
    getAgents,
    getAgentsTableRefreshState,
    getCms,
    getCmsTableRefreshState,
    getSoftwarePackages,
    getSoftwarePackagesTableRefreshState
}