import clsx from 'clsx'
import React, {FC} from 'react'
import {HeaderUserMenu} from '../../../partials'
import {useLayout} from '../../core'
import {ImageFromBase64} from "../../../../app/components/images";
import {useSelector} from "react-redux";
import {authSelectors} from "../../../../app/pages/auth";
import {useLang} from "../../../i18n/Metronici18n";
import {KTSVG} from "../../../helpers";

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarButtonHeightClass = 'w-30px h-30px w-md-40px h-md-40px',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px',
  toolbarButtonIconSizeClass = 'svg-icon-1'

const Topbar: FC = () => {
  const {config} = useLayout()
    
    const selectedLang = useLang()
    const user = useSelector(authSelectors.getUser())
    

  return (
    <div className='d-flex align-items-stretch flex-shrink-0'>
      {/* begin::User */}
      <div
        className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
        id='kt_header_user_menu_toggle'
      >
        {/* begin::Toggle */}
        <div
          className={clsx('cursor-pointer symbol', toolbarUserAvatarHeightClass)}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
        >
            {
                user.photo &&
                <ImageFromBase64 base64String={user.photo}/>
            }
            {
                !user.photo &&
                <div className="symbol-label fs-2 fw-semibold text-primary">
                    {
                        user.fullName
                            ? user.fullName.charAt(0).toLocaleUpperCase(selectedLang)
                            : user.email.charAt(0).toLocaleUpperCase(selectedLang)
                    }
                </div>
            }
        </div>
        <HeaderUserMenu />
        {/* end::Toggle */}
      </div>
      {/* end::User */}
        {/* begin::Aside Toggler */}
        {config.header.left === 'menu' && (
            <div className='d-flex align-items-center d-lg-none ms-2 me-n3' title='Show header menu'>
                <div
                    className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'
                    id='kt_header_menu_mobile_toggle'
                >
                    <KTSVG path='/media/icons/duotune/text/txt001.svg' className='svg-icon-1' />
                </div>
            </div>
        )}
    </div>
  )
}

export {Topbar}
