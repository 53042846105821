import {Card} from "react-bootstrap";
import {DestinationsPageTitle, DestinationCard} from "../destinations";
import {useIntl} from "react-intl";
import {useSelector} from "react-redux";
import React, {useEffect} from "react";
import {destinationsActions, destinationsReducerActions, destinationsSelectors} from "../../";
import {DataRequest, Table} from "../../../../../../components/tables";
import {organisationSelectors} from "../../../../";
import {
    ButtonWithDropdown,
    ButtonWithDropdownItem,
    ButtonWithDropdownTitle
} from "../../../../../../components/buttons";
import {swal} from "../../../../../../libs/sweetAlert";
import {format} from "react-string-format";
import * as urls from "../../../../../../infrastructure/variables/urls";
import {useAppDispatch} from "../../../../../../infrastructure/hooks/reduxHooks";
import {useNavigate} from "react-router-dom";
import {CreateSoftwarePackageModal} from "./createSoftwarePackageModal/CreateSoftwarePackageModal";
import {EditDestinationModal} from "../editDestinationModal/EditDestinationModal";

export const SoftwarePackage = () => {

    const intl = useIntl()
    const navigate = useNavigate()
    const dispatch = useAppDispatch();
    const organisationId = useSelector(organisationSelectors.getSelectedOrganisationId())
    const softwarePackages = useSelector(destinationsSelectors.getSoftwarePackages());
    const showCreateSoftwarePackageModalState = useSelector(destinationsSelectors.getCreateSoftwarePackageModalState())
    const showEditDestinationModalState = useSelector(destinationsSelectors.getEditDestinationModalState())

    const createSoftwarePackage = () => {
        dispatch(destinationsActions.showCreateSoftwarePackageModal({}))
    }

    const editDestination = (agentId: string) => {
        dispatch(destinationsActions.showEditDestinationModal({
            state: true,
            destinationId: agentId,
            refreshTableWhenModalClosed: true
        }))
    }

    const getSofwarePackages = (dataRequest: DataRequest | undefined = undefined) => {
        dispatch(destinationsActions.getSoftwarePackages({organisationId, dataRequest}))
    }

    const deleteSoftwarePackage = async (destinationId: string) => {
        dispatch(destinationsActions.deleteSoftwarePackage({organisationId, destinationId}))
    }

    const columns = [
        {
            Header: "Id",
            accessor: "id",
        },
        {
            Header: intl.formatMessage({id: "ORGANISATION.DESTINATIONS.TABLE.COLUMN.NAME"}),
            accessor: "name",
            width: 'auto',
            Cell: ({cell}: any) => {

                const destinationId = cell.row.original.id
                const name = cell.row.original.name
                const type = cell.row.original.destinationTypeDetail

                return (
                    <DestinationCard
                        name={name}
                        type={type}
                        useBackgroundForIcons={true}
                        showCursorPointer={true}
                        onClick={() => navigate(format(urls.ORGANISATION_DESTINATION_SOFTWARE_PACKAGE_DETAIL_URL, organisationId, destinationId))}
                    />
                )
            }
        },
        {
            Header: intl.formatMessage({id: "ORGANISATION.DESTINATIONS.TABLE.COLUMN.STATUS"}),
            accessor: "isOnline",
            width: 'auto',
            Cell: ({cell}: any) => {
                    return <span className="badge badge-secondary">Software Package</span>
            }
        },
        {
            Header: intl.formatMessage({id: "TABLE.COLUMN.ACTIONS"}),
            Cell: ({cell}: any) => {

                const destinationId = cell.row.original.id

                return (
                    <ButtonWithDropdown
                        text={intl.formatMessage({id: 'TABLE.ACTIONS.BUTTON.VIEW'})}
                        linkTo={format(urls.ORGANISATION_DESTINATION_SOFTWARE_PACKAGE_DETAIL_URL, organisationId, destinationId)}
                    >
                        <ButtonWithDropdownTitle text={intl.formatMessage({id: 'TABLE.ACTIONS.TITLE.ACTIONS'})}/>
                        <ButtonWithDropdownItem
                            text={intl.formatMessage({id: 'TABLE.ACTIONS.BUTTON.EDIT'})}
                            onClick={() => editDestination(destinationId)}
                        />
                        <ButtonWithDropdownItem
                            text={intl.formatMessage({id: 'ORGANISATION.DESTINATIONS.TABLE.ACTIONS.BUTTON.DELETE'})}
                            type='danger'
                            linkTo=""
                            onClick={async (e) => {
                                e.currentTarget.style.pointerEvents = "none"
                                const deletableResponse = await destinationsActions.checkDestinationDeletable(organisationId, destinationId)

                                if (deletableResponse.data) {
                                    const result = await swal.question(
                                        intl.formatMessage({id: 'SWEETALERT.MESSAGE.CONFIRM'}),
                                        intl.formatMessage({id: 'ORGANISATION.DESTINATIONS.TABLE.MESSAGE.DELETE_CONFIRM'})
                                    )
                                    if (result.isConfirmed) {
                                        await deleteSoftwarePackage(destinationId)
                                    } else {
                                        e.currentTarget.style.pointerEvents = ""
                                    }
                                } else {
                                    const result = await swal.questionWithConfirm(
                                        intl.formatMessage({id: 'SWEETALERT.MESSAGE.CONFIRM'}),
                                        intl.formatMessage({id: 'ORGANISATION.DESTINATIONS.TABLE.MESSAGE.DELETE_CONFIRM_FORCE'})
                                    )
                                    if (result.isConfirmed) {
                                        await deleteSoftwarePackage(destinationId)
                                    } else {
                                        e.currentTarget.style.pointerEvents = ""
                                    }
                                }

                            }}
                        />
                    </ButtonWithDropdown>
                )
            }
        }
    ]

    const toolbar = () => {
        return (
            <>
                <button type="button" className="btn btn-sm btn-light-primary" onClick={createSoftwarePackage}>
                    <span className="svg-icon svg-icon-3">
						<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                             viewBox="0 0 24 24" fill="none">
													<rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5"
                                                          fill="currentColor"></rect>
													<rect x="10.8891" y="17.8033" width="12" height="2" rx="1"
                                                          transform="rotate(-90 10.8891 17.8033)"
                                                          fill="currentColor"></rect>
													<rect x="6.01041" y="10.9247" width="12" height="2" rx="1"
                                                          fill="currentColor"></rect>
												</svg>
											</span>
                    {intl.formatMessage({id: 'ORGANISATION.DESTINATIONS.TABLE.TOOLBAR.BUTTON.NEW_DESTINATION.SOFTWARE_PACKAGE'})}
                </button>
            </>
        )
    }

    useEffect(() => {
        return () => {
            dispatch(destinationsReducerActions.setSofwarePackages(null))
        }
    }, [])

    return (
        <>
            <DestinationsPageTitle/>

            <Card>
                <Card.Body>
                    <Table
                        columns={columns}
                        dataContext={softwarePackages}
                        events={{
                            getData: getSofwarePackages,
                            goToPage: getSofwarePackages,
                            refreshState: useSelector(destinationsSelectors.getSoftwarePackagesTableRefreshState())
                        }}
                        toolbar={toolbar()}
                    />
                </Card.Body>
            </Card>

            {showCreateSoftwarePackageModalState && <CreateSoftwarePackageModal/>}
            {showEditDestinationModalState && <EditDestinationModal/>}
        </>
    )
}