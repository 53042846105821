import {useSelector} from "react-redux";
import {Outlet, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {authSelectors} from "../pages/auth";
import {WELCOME_URL} from "./variables/urls";

export const RedirectToWelcomePageIfThereIsNoOrganisation = () => {
    const user = useSelector(authSelectors.getUser())
    const navigate = useNavigate()
    const [hasOrganisation, setHasOrganisation] = useState(false)


    useEffect(() => {
        if (user && user.organisations) {
            setHasOrganisation(true)
        } else {
            setHasOrganisation(false)
            navigate(WELCOME_URL)
        }

    }, [])

    return (
        <>
            {hasOrganisation && <Outlet/>}
        </>
    )
}