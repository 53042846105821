import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import {destinationsActions} from "../../../destinationsActions";
import {ScriptCodeCard} from "../../../../../../../components/cards";

export const WordpressIntegration = ({organisationId, destinationId}: { organisationId: string, destinationId: string }) => {
    const intl = useIntl()

    const [token, setToken] = useState(intl.formatMessage({id: "ORGANISATIONS.DESTINATION.TOKEN.LOADING"}))


    useEffect(() => {
        if (destinationId) {
            setTimeout(async () => {
                const response = await destinationsActions.getCmsExtensionToken({organisationId, cmsExtensionId: destinationId!})
                if (response.isSucceed) {
                    setToken(response.data)
                }
            }, 500)
        }
    }, [destinationId])

    return (
        <>
            <div className="fw-bolder fs-6">
                {intl.formatMessage({id: "ORGANISATION.DESTINATION.MESSAGE.STEP1"})}
            </div>

            <div>
                {intl.formatMessage({id: "ORGANISATION.DESTINATION.CMS.INTEGRATION.WORDPRESS.MESSAGE.DOWNLOAD"})}
            </div>

            <div className="d-flex justify-content-center my-6">
                <a href="https://download.protectremote.com/cms-plugins/wordpress/protect-remote-0.0.6.zip" target="_blank"
                   className="btn btn-sm btn-outline btn-outline-dashed btn-outline-dark btn-active-light-dark">
                    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
                        <path
                            d="M216 0h80c13.3 0 24 10.7 24 24v168h87.7c17.8 0 26.7 21.5 14.1 34.1L269.7 378.3c-7.5 7.5-19.8 7.5-27.3 0L90.1 226.1c-12.6-12.6-3.7-34.1 14.1-34.1H192V24c0-13.3 10.7-24 24-24zm296 376v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h146.7l49 49c20.1 20.1 52.5 20.1 72.6 0l49-49H488c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z"/>
                    </svg>
                    <span className="ms-2">{intl.formatMessage({id: "ORGANISATION.DESTINATION.CMS.INTEGRATION.BUTTON.DOWNLOAD"})}</span>
                </a>

            </div>

            <div className="fw-bolder fs-6">
                {intl.formatMessage({id: "ORGANISATION.DESTINATION.MESSAGE.STEP2"})}
            </div>

            <div>
                {intl.formatMessage({id: "ORGANISATION.DESTINATION.CMS.INTEGRATION.WORDPRESS.MESSAGE.TOKEN"})}
            </div>

            <div className="mt-5">
                <div className="w-100">
                    <ScriptCodeCard text={token}/>
                </div>
            </div>
        </>
    )
}