import React, {useEffect, useState} from 'react'
import {useAppDispatch} from '../../../../infrastructure/hooks/reduxHooks'
import * as device from 'react-device-detect'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../../setup'
import {AuthState} from '../../authSlice'
import {useIntl} from 'react-intl'
import {authActions} from '../../authActions'
import {Form, Formik} from 'formik'
import {addValidationErrors} from '../../../../infrastructure/utils/formikUtils'
import PinField from 'react-pin-field'
import {FormActionButtons} from '../../../../components/forms'
import clsx from 'clsx'
import {toastify} from '../../../../libs/toastify'

export const TwoStepVerification = () => {
  const dispatch = useAppDispatch()
  const intl = useIntl()

  const authState = useSelector<RootState>(({auth}) => auth) as AuthState

  const counterInitialValue = 90

  const [loading, setLoading] = useState(false)
  const [code, setCode] = useState('')
  const [counter, setCounter] = useState(counterInitialValue)

  const logout = () => {
    dispatch(authActions.logout({forgetDevice: false}))
    dispatch(authActions.setVerificationCodeAction({verificationCode: null}))
  }

  const initialValues = {
    email: '',
    password: '',
  }
  
  useEffect(() => {
    dispatch(authActions.registerDevice())

    return () => {
      dispatch(authActions.setVerificationCodeAction({verificationCode: null}))
    }
  }, [])

  useEffect(() => {
    if (authState.accessToken) {
      const interval = setInterval(() => {
        setCounter((counter) => counter - 1)
      }, 1000)

      return () => {
        clearInterval(interval)
        setCounter(counterInitialValue)
      }
    }
  }, [authState.accessToken])

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={async (values, {setStatus, setSubmitting, setFieldError}) => {
          setLoading(true)
          setTimeout(async () => {
            const response = await dispatch(
              authActions.verifyDevice({verificationCode: code})
            ).unwrap()
            // response basarili ise redux doldugu icin login islemi react router uzerinden otomatik gerceklesiyor.

            if (!response.isSucceed) {
              addValidationErrors(setFieldError, response.errors)
              setLoading(false)
              setSubmitting(false)

              switch (response.message) {
                case 'WrongVerificationCode':
                  setStatus(intl.formatMessage({id: 'AUTH.LOGIN.TWO_STEP.VALIDATION'}))
                  break
                default:
                  setStatus(intl.formatMessage({id: 'ERROR.ERROR_OCCURRED'}))
              }
            } else {
              dispatch(authActions.setVerificationCodeAction({verificationCode: null}))
            }
          }, 500)
        }}
      >
        {(formik) => (
          <>
            <div className='text-center mb-10'>
              <h1 className='text-dark mb-3'>
                {intl.formatMessage({id: 'AUTH.LOGIN.TWO_STEP.TITLE'})}
              </h1>

              <div className='text-muted fw-semibold fs-5 mb-5 mt-10'>
                {intl.formatMessage({id: 'AUTH.LOGIN.TWO_STEP.DESCRIPTION'})}
              </div>

              <div className='fw-bold text-dark fs-3'>{formik.values.email}</div>
            </div>

            {formik.status && (
              <div className='mb-lg-15 alert alert-danger'>
                <div className='alert-text font-weight-bold'>{formik.status}</div>
              </div>
            )}
            <Form className='form w-100'>
              <div className='d-flex flex-wrap flex-stack mb-5'>
                <PinField
                  length={6}
                  className="form-control bg-transparent h-50px h-md-60px w-45px w-md-60px fs-2qx text-center mx-md-1 my-2"
                  autoFocus
                  validate='0123456789'
                  inputMode='numeric'
                  onChange={(pin: string) => setCode(pin)}
                />
              </div>

              {/* begin::Action */}
              <FormActionButtons
                formik={formik}
                loading={loading}
                submitButtonResourceName={'AUTH.LOGIN.TWO_STEP.BUTTON.CONFIRM'}
                showCancelButton={false}
                fullWidthButton={true}
                buttonSize={'btn-lg'}
                disableSubmitButton={code.length != 6 || loading == true}
              />
              {/* end::Action */}

              <div className='d-flex mt-3'>
                <button
                  type='button'
                  className={clsx(
                    'btn btn-sm btn-dark me-auto cursor-pointer',
                    counter > 0 && 'disabled'
                  )}
                  style={{width: '49%'}}
                  disabled={counter > 0}
                  onClick={async () => {
                    setCounter(counterInitialValue)
                    const response = await authActions.resendVerificationCode()

                    if (response.isSucceed) {
                      toastify.success(
                        intl.formatMessage({
                          id: 'AUTH.LOGIN.TWO_STEP.MESSAGE.VERIFICATION_CODE_RESENT',
                        })
                      )
                    } else {
                      if (response.message == 'TooManyRetry') {
                        toastify.error(
                          intl.formatMessage({id: 'AUTH.LOGIN.TWO_STEP.MESSAGE.TOO_MANY_RETRY'})
                        )
                      }
                    }
                  }}
                >
                  {intl.formatMessage({id: 'AUTH.LOGIN.TWO_STEP.BUTTON.NEW_CODE'})}
                </button>

                <button
                  type='button'
                  className='btn btn-sm btn-secondary'
                  style={{width: '49%'}}
                  onClick={() => logout()}
                >
                  {intl.formatMessage({id: 'AUTH.LOGIN.BUTTON.LOGOUT'})}
                </button>
              </div>

              {counter > 0 && (
                <span className='d-block text-center fs-6 mt-3 text-muted'>
                  {intl.formatMessage({id: 'AUTH.LOGIN.TWO_STEP.COUNTER'}, {seconds: counter})}
                </span>
              )}
            </Form>
          </>
        )}
      </Formik>
    </>
  )
}
