import Select, {OptionsOrGroups} from "react-select";
import React, {FC, ReactNode} from "react";
import {useIntl} from "react-intl";
import {FormatOptionLabelMeta} from "react-select/dist/declarations/src/Select";
import {FieldInputProps} from "formik";
import {FormikErrors} from "formik/dist/types";

interface Props {
    fieldProps: FieldInputProps<any>,
    options: any,
    placeHolderResourceName?: string,
    isMulti?: boolean
    maxSelectionLength?: number
    formatOptionLabel?: (data: any, formatOptionLabelMeta: FormatOptionLabelMeta<any>) => ReactNode,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => Promise<FormikErrors<any>> | Promise<void>
}

export const FormReactSelect: FC<Props> = ({
                                               fieldProps,
                                               options,
                                               placeHolderResourceName,
                                               isMulti = false,
                                               maxSelectionLength,
                                               formatOptionLabel,
                                               setFieldValue
                                           }) => {
    const intl = useIntl()

    return (
        <>
            <Select
                {...(fieldProps ? {...fieldProps} : {})}
                isMulti={isMulti}
                styles={{
                    control: (provided, state) => ({
                        ...provided,
                        boxShadow: "none",
                        borderColor: '#cccccc',
                        fontSize: 15,
                        "&:hover": {
                            border: "1px solid #cccccc",
                            boxShadow: "none",
                        }
                    } as any),
                    menu: (provided, state) => ({
                        ...provided,
                        border: "none",
                        boxShadow: "none",
                        fontSize: 14
                    } as any),
                    multiValue: (provided, state) => ({
                        ...provided,
                        background: "#f6f4f4",
                        fontSize: 16
                    }) as any
                }}
                placeholder={intl.formatMessage({id: placeHolderResourceName ?? 'FORM.SELECT'})}
                {...(formatOptionLabel ? {formatOptionLabel} : {})}
                options={options}
                name={fieldProps?.name}
                isOptionDisabled={() => (isMulti && (maxSelectionLength && maxSelectionLength > 0)) ? fieldProps?.value?.length >= maxSelectionLength : false}
                value={
                    isMulti
                        ? options?.filter((item: any) => fieldProps?.value?.map((x: any) => x).includes(item.value))
                        : options?.find((option: any) => option.value === fieldProps?.value)
                }
                onChange={(options: any) => {
                    if (isMulti) {
                        setFieldValue(fieldProps.name, options.map((option: any) => option.value))
                    } else {
                        setFieldValue(fieldProps.name, options.value)
                    }
                }}

                onBlur={fieldProps.onBlur}
            />
        </>
    )
}