import {toastify} from "../../libs/toastify";
import {devicesReducerActions} from "./devicesSlice";
import {ActionResponse} from "../../infrastructure/models/actionResponse";
import {DeviceModel} from "./models";
import {devicesApis} from "./devicesApis";
import {createAsyncThunk} from "@reduxjs/toolkit";

const getDevices = createAsyncThunk(
    'devices/getDevices',
    async (arg, {dispatch}) => {
        const response = await devicesApis.getDevices();
        dispatch(devicesReducerActions.setDevices(response.data))
    }
)

const getDevice = async (userDeviceId: string) => {
    try {
        const response = await devicesApis.getDevice({userDeviceId});
        return {
            isSucceed: true,
            data: response.data
        } as ActionResponse<DeviceModel>
    } catch (e: any) {
        return {
            isSucceed: false,
            errors: e.response?.data?.errors,
            message: e.response.data
        } as ActionResponse<DeviceModel>
    }
}

const removeDevice = createAsyncThunk(
    'devices/remoteDevice',
    async ({userDeviceId}: { userDeviceId: string }, {dispatch}) => {
        try {
            await devicesApis.removeDevice({userDeviceId})

            dispatch(devicesReducerActions.setSelectedDeviceId(null))
            dispatch(devicesActions.getDevices())

            return {
                isSucceed: true
            } as ActionResponse
        } catch (e: any) {
            return {
                isSucceed: false
            } as ActionResponse
        }
    })

export const devicesActions =
    {
        getDevices,
        getDevice,
        removeDevice
    }