import React from 'react'
import {MenuItem} from './MenuItem'
import {useIntl} from 'react-intl'
import {useDispatch, useSelector} from "react-redux";
import {organisationActions, organisationSelectors} from "../../../../app/pages/organisation/";
import {Link, useLocation, useNavigate} from "react-router-dom";
import clsx from "clsx";
import {ImageFromBase64} from "../../../../app/components/images";

export function MenuInner() {
    const intl = useIntl()
    const dispatch = useDispatch()
    const location = useLocation();
    const navigate = useNavigate()

    const organisations = useSelector(organisationSelectors.getOrganisations())
    const selectedOrganisationId = useSelector(organisationSelectors.getSelectedOrganisationId())
    return (
        <>
            {/*<MenuItem title={intl.formatMessage({id: 'MENU.DASHBOARD'})} to='/dashboard'/> {location?.state}*/}
            {/*<MenuItem title={intl.formatMessage({id: 'MENU.ORGANISATIONS'})} to='/organisations'/>*/}

            <div
                data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                data-kt-menu-placement="bottom-start"
                className="menu-item menu-lg-down-accordion menu-here-bg me-0 me-lg-2"
            >
                {/*begin:Menu link*/}
                <span className="menu-link">
                    <span className="menu-title">{intl.formatMessage({id: 'MENU.ORGANISATIONS'})}</span>
                    <span className="menu-arrow d-lg-none"/>
                </span>
                {/*end:Menu link*/}
                {/*begin:Menu sub*/}
                <div
                    className={clsx("menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown p-0 w-100", (organisations && organisations.length > 0) ? "w-lg-600px" : "w-lg-200px")}>
                    {/*begin:Dashboards menu*/}
                    <div className="menu-state-bg menu-extended" data-kt-menu-dismiss="true">
                        {/*begin:Row*/}
                        <div className="row">
                            {/*begin:Col*/}
                            {(organisations && organisations.length > 0) &&
                                <div className="col-lg-7 mb-3 mb-lg-0 py-3 px-3 py-lg-3 px-lg-3" style={{maxHeight: 320, overflowY: "auto"}}>
                                    {/*begin:Row*/}
                                    <div className="row">
                                        {/*begin:Col*/}
                                        {
                                            organisations?.map(organisation => {
                                                return (
                                                    <div className="col-lg-12 mb-1" key={organisation.id}>
                                                        {/*begin:Menu item*/}
                                                        <div className="menu-item p-0 ps-3 m-0">
                                                            {/*begin:Menu link*/}
                                                            <div
                                                                onClick={() => {
                                                                    // dispatch(organisationActions.setOrganisation(null))
                                                                    navigate(`/organisation/${organisation.id}`)
                                                                    // setTimeout(() => {navigate(`/organisation/${organisation.id}`)}, 250)
                                                                }}
                                                                // to={`/organisation/${organisation.id}`}
                                                                className={clsx("menu-link", organisation.id == selectedOrganisationId ? "active" : "")}
                                                            >
                  <span className="menu-custom-icon d-flex flex-center flex-shrink-0 rounded w-40px h-40px me-3">
                    {/*begin::Svg Icon | path: icons/duotune/general/gen025.svg*/}
                      <span className="svg-icon svg-icon-primary svg-icon-1">
                      {
                          organisation.logo
                              ?
                              <ImageFromBase64 base64String={organisation.logo} className="w-100"/>
                              :
                              <svg
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                              >
                                  <rect
                                      x={2}
                                      y={2}
                                      width={9}
                                      height={9}
                                      rx={2}
                                      fill="currentColor"
                                  />
                                  <rect
                                      opacity="0.3"
                                      x={13}
                                      y={2}
                                      width={9}
                                      height={9}
                                      rx={2}
                                      fill="currentColor"
                                  />
                                  <rect
                                      opacity="0.3"
                                      x={13}
                                      y={13}
                                      width={9}
                                      height={9}
                                      rx={2}
                                      fill="currentColor"
                                  />
                                  <rect
                                      opacity="0.3"
                                      x={2}
                                      y={13}
                                      width={9}
                                      height={9}
                                      rx={2}
                                      fill="currentColor"
                                  />
                              </svg>
                      }
                    </span>
                      {/*end::Svg Icon*/}
                  </span>
                                                                <span className="d-flex flex-column">
                    <span className="fs-6 fw-bold text-gray-800">{organisation.name}</span>
                  </span>
                                                            </div>
                                                            {/*end:Menu link*/}
                                                        </div>
                                                        {/*end:Menu item*/}
                                                    </div>
                                                )
                                            })
                                        }
                                        {/*end:Col*/}
                                        {/*begin:Col*/}
                                    </div>
                                    {/*end:Row*/}
                                    {/*<div className="separator separator-dashed mx-5 mt-1 mb-5" />*/}
                                    {/*begin:Landing*/}
                                    {/*<div className="d-flex flex-stack flex-wrap flex-lg-nowrap gap-2 mx-5 justify-content-center">*/}
                                    {/*    <a*/}
                                    {/*        href="../../demo1/dist/landing.html"*/}
                                    {/*        className="btn btn-sm fw-bold"*/}
                                    {/*    >*/}
                                    {/*        Organizasyon Oluştur*/}
                                    {/*    </a>*/}
                                    {/*</div>*/}
                                    {/*end:Landing*/}
                                </div>}
                            {/*end:Col*/}
                            {/*begin:Col*/}
                            <div
                                className={clsx("menu-more bg-light py-3 px-3 py-lg-6 px-lg-6 rounded-end", (organisations && organisations.length > 0) && "col-lg-5")}>
                                {/*begin:Heading*/}
                                {
                                    (organisations && organisations.length > 0) &&
                                    <h4 className="fs-6 fs-lg-4 text-gray-800 fw-bold mt-3 mb-3 ms-4">
                                        {intl.formatMessage({id: 'MENU.ORGANISATIONS.ACTIONS.TITLE.ACTIONS'})}
                                    </h4>
                                }

                                {/*end:Heading*/}
                                {/*begin:Menu item*/}
                                <div className="menu-item p-0 m-0">
                                    {/*begin:Menu link*/}
                                    <a
                                        onClick={() => dispatch(organisationActions.showCreateOrganisationModal())}
                                        className="menu-link py-2"
                                    >
                                        <span className="menu-title">
                                            {intl.formatMessage({id: 'MENU.ORGANISATIONS.ACTIONS.LINK.CREATE_ORGANISATION'})}
                                        </span>
                                    </a>
                                    {/*end:Menu link*/}
                                </div>
                                {/*end:Menu item*/}
                                {/*begin:Menu item*/}
                                <div className="menu-item p-0 m-0">
                                    {/*begin:Menu link*/}
                                    <a
                                        onClick={() => dispatch(organisationActions.showMyInvitationsModal({
                                            state: true,
                                            requestedFromExternalUrl: false
                                        }))}
                                        className="menu-link py-2"
                                    >
                                        <span
                                            className="menu-title">{intl.formatMessage({id: 'MENU.ORGANISATIONS.ACTIONS.MY_INVITATIONS'})}</span>
                                    </a>
                                    {/*end:Menu link*/}
                                </div>
                                {/*end:Menu item*/}
                            </div>
                            {/*end:Col*/}
                        </div>
                        {/*end:Row*/}
                    </div>
                    {/*end:Dashboards menu*/}
                </div>
                {/*end:Menu sub*/}
            </div>

            <MenuItem title={intl.formatMessage({id: 'MENU.DEVICES'})} to='/devices'/>

            <MenuItem title={intl.formatMessage({id: 'MENU.DOWNLOAD_APPS'})} to={intl.formatMessage({id: 'MENU.DOWNLOAD_APPS_URL'})} type="anchor_blank"></MenuItem>
        </>
    )
}
