/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect} from 'react'
import {Form, Formik} from 'formik'
import * as Yup from 'yup'
import {Link, useLocation, useNavigate, useSearchParams} from 'react-router-dom'
import {PasswordMeterComponent} from '../../../../_metronic/assets/ts/components'
import {useIntl} from 'react-intl'
import {FormInputGroup} from '../../../components/forms/FormInputGroup'
import {FormInput} from '../../../components/forms/FormInput'
import {FormValidation} from '../../../components/forms/FormValidation'
import {FormActionButtons} from '../../../components/forms/FormActionButtons'
import {authActions} from '../'
import {addValidationErrors} from '../../../infrastructure/utils/formikUtils'
import * as urls from '../../../infrastructure/variables/urls'
import {useAppDispatch} from '../../../infrastructure/hooks/reduxHooks'
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3'
import {toastify} from '../../../libs/toastify'
import {v4 as uuidv4} from 'uuid'

export const RegistrationPage = () => {
  const [loading, setLoading] = useState(false)
  const dispatch = useAppDispatch()
  const intl = useIntl()
  const location = useLocation()
  const navigate = useNavigate()
  const reCaptcha = useGoogleReCaptcha()

  const [searchParams] = useSearchParams()

  const [formValues, setFormValues] = useState({
    email: '',
    password: '',
    passwordConfirm: '',
    acceptTerms: false,
  })

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(intl.formatMessage({id: 'VALIDATION.EMAIL_FORMAT'}))
      .min(10, intl.formatMessage({id: 'VALIDATION.MIN_LENGTH'}, {length: 10}))
      .max(100, intl.formatMessage({id: 'VALIDATION.MAX_LENGTH'}, {length: 100}))
      .required(
        intl.formatMessage(
          {id: 'VALIDATION.REQUIRED'},
          {name: intl.formatMessage({id: 'AUTH.REGISTER.INPUT.EMAIL'})}
        )
      ),
    password: Yup.string()
      .min(8, intl.formatMessage({id: 'VALIDATION.MIN_LENGTH'}, {length: 8}))
      .max(30, intl.formatMessage({id: 'VALIDATION.MAX_LENGTH'}, {length: 30}))
      .required(
        intl.formatMessage(
          {id: 'VALIDATION.REQUIRED'},
          {name: intl.formatMessage({id: 'AUTH.REGISTER.INPUT.PASSWORD'})}
        )
      )
      .matches(
        /(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_])(?=^.*[^\s].*$).*$/,
        intl.formatMessage({id: 'AUTH.REGISTER.VALIDATION.PASSWORD_NOT_STRONG'})
      ),
    passwordConfirm: Yup.string()
      .required(
        intl.formatMessage(
          {id: 'VALIDATION.CONFIRMATION'},
          {name: intl.formatMessage({id: 'AUTH.REGISTER.INPUT.PASSWORD'})}
        )
      )
      .when('password', {
        is: (val: string) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref('password')],
          intl.formatMessage(
            {id: 'VALIDATION.CONFIRMATION_NOT_MATCH'},
            {
              name: intl.formatMessage({id: 'AUTH.REGISTER.INPUT.PASSWORD'}),
              confirmName: intl.formatMessage({id: 'AUTH.REGISTER.INPUT.PASSWORD_CONFIRM'}),
            }
          )
        ),
      }),
    acceptTerms: Yup.bool()
      .required(
        intl.formatMessage({id: 'AUTH.REGISTER.VALIDATION.I_AGREE_THE_TERMS_AND_CONDITIONS'})
      )
      .oneOf(
        [true],
        intl.formatMessage({id: 'AUTH.REGISTER.VALIDATION.I_AGREE_THE_TERMS_AND_CONDITIONS'})
      ),
  })

  useEffect(() => {
    PasswordMeterComponent.bootstrap()

    const emailFromQuery = searchParams.get('email')

    if (emailFromQuery) {
      setFormValues({...formValues, email: emailFromQuery})
    }
  }, [])

  return (
    <Formik
      initialValues={formValues}
      validationSchema={validationSchema}
      enableReinitialize={true}
      onSubmit={async (values, {setStatus, setSubmitting, setFieldError}) => {
        if (!reCaptcha.executeRecaptcha) {
          toastify.error(intl.formatMessage({id: 'AUTH.LOGIN.MESSAGE.RE_CAPTCHA_NOT_FOUND'}))
          return
        }

        const reCAPTCHAToken = await reCaptcha.executeRecaptcha()

        setLoading(true)

        setTimeout(async () => {
          const response = await dispatch(
            authActions.register({
              email: values.email,
              password: values.password,
              reCAPTCHAToken: reCAPTCHAToken,
            })
          ).unwrap()

          if (response?.isSucceed) {
            setTimeout(() => {
              const verificationCode = uuidv4()
              dispatch(authActions.setVerificationCodeAction({verificationCode}))

              // @ts-ignore
              const returnUrl = location?.state?.returnUrl

              if (returnUrl) {
                navigate(urls.AUTH_LOGIN_URL, {state: {returnUrl: returnUrl, isNew: true}})
              } else {
                navigate(urls.AUTH_LOGIN_URL, {state: {isNew: true}})
              }
            }, 1000)
          } else {
            addValidationErrors(setFieldError, response?.errors)
            setLoading(false)
            setSubmitting(false)

            switch (response?.message) {
              case 'EmailAlreadyRegistered':
                setStatus(
                  intl.formatMessage({id: 'AUTH.REGISTER.MESSAGE.EMAIL_ALREADY_REGISTERED'})
                )
                break
            }
          }
        }, 500)
      }}
    >
      {(formik) => (
        <Form className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'>
          {/* begin::Heading */}
          <div className='mb-10 text-center'>
            {/* begin::Title */}
            <h1 className='text-dark mb-3'>{intl.formatMessage({id: 'AUTH.REGISTER.TITLE'})}</h1>
            {/* end::Title */}

            {/* begin::Link */}
            <div className='text-gray-400 fw-bold fs-4'>
              {intl.formatMessage({id: 'AUTH.REGISTER.ALREADY_HAVE_AN_ACCOUNT'})}
              <Link to='/auth/login' className='link-primary fw-bolder' style={{marginLeft: '5px'}}>
                {intl.formatMessage({id: 'AUTH.REGISTER.SIGNIN'})}
              </Link>
            </div>
            {/* end::Link */}
          </div>
          {/* end::Heading */}

          {formik.status && (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>{formik.status}</div>
            </div>
          )}

          {/* begin::Form group Email */}
          <FormInputGroup
            labelResourceName={'AUTH.REGISTER.INPUT.EMAIL'}
            fieldProps={formik.getFieldProps('email')}
            validationCondition={formik.touched.email && formik.errors.email}
            validationMessage={formik.errors.email}
          />
          {/* end::Form group */}

          {/* begin::Form group Password */}
          <div className='mb-5' data-kt-password-meter='true'>
            <div className='mb-1'>
              <FormInputGroup
                inputType={'password'}
                labelResourceName={'AUTH.REGISTER.INPUT.PASSWORD'}
                fieldProps={formik.getFieldProps('password')}
                validationCondition={formik.touched.password && formik.errors.password}
                validationMessage={formik.errors.password}
              />
              {/* begin::Meter */}
              <div
                className='d-flex align-items-center mb-3'
                data-kt-password-meter-control='highlight'
                style={{marginTop: '-10px'}}
              >
                <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
              </div>
              <div className='text-muted'>
                {intl.formatMessage({id: 'AUTH.REGISTER.PASSWORD_METER_DESC'})}
              </div>
              {/* end::Meter */}
            </div>
          </div>
          {/* end::Form group */}

          {/* begin::Form group Confirm password */}
          <FormInputGroup
            inputType={'password'}
            labelResourceName={'AUTH.REGISTER.INPUT.PASSWORD_CONFIRM'}
            fieldProps={formik.getFieldProps('passwordConfirm')}
            validationCondition={formik.touched.passwordConfirm && formik.errors.passwordConfirm}
            validationMessage={formik.errors.passwordConfirm}
          />
          {/* end::Form group */}

          {/* begin::Form group */}
          <div className='fv-row mb-10'>
            <div className='form-check form-check-custom form-check-solid'>
              <FormInput
                inputType={'checkbox'}
                fieldProps={formik.getFieldProps('acceptTerms')}
                className='form-check-input'
              />
              <label className='form-check-label fw-bold text-gray-700 fs-6' htmlFor='acceptTerms'>
                {/*<Link to='/auth/terms' className='ms-1 link-primary'>*/}
                  {intl.formatMessage({id: 'AUTH.REGISTER.I_AGREE_THE_TERMS_AND_CONDITIONS'})}
                {/*</Link>*/}
              </label>
              {formik.touched.acceptTerms && formik.errors.acceptTerms && (
                <FormValidation
                  condition={formik.touched.acceptTerms && formik.errors.acceptTerms}
                  propertyResourceName={'acceptTerms'}
                  errorMessage={formik.errors.acceptTerms}
                />
              )}
            </div>
          </div>
          {/* end::Form group */}

          {/* begin::Action */}
          <FormActionButtons
            formik={formik}
            loading={loading}
            submitButtonResourceName={'AUTH.REGISTER.BUTTON.SUBMIT'}
            cancelButtonResourceName={'FORM.CANCEL'}
            showCancelButton={true}
            cancelLinkTo={'/auth/login'}
            fullWidthButton={true}
            buttonSize={'btn-lg'}
          />
          {/* end::Action */}
        </Form>
      )}
    </Formik>
  )
}
