import {useSelector} from "react-redux";
import {organisationSelectors} from "../../../../organisationSelectors";
import {useParams} from "react-router-dom";
import {AgentIntegration} from "../agent/agentIntegration/AgentIntegration";

export const Script = () => {
    const organisationId = useSelector(organisationSelectors.getSelectedOrganisationId())
    const {destinationId} = useParams()
    return (
        <div className="w-100">
            <AgentIntegration organisationId={organisationId} agentId={destinationId!}/>
        </div>
    )
}