import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {OrganisationStateModel} from "./models/";
import {OrganisationModel} from "./models/OrganisationModel";
import {MyInvitationsModelInvitation} from "./models/MyInvitationsModel";
import {UserType} from "../../infrastructure/enums/userType";

const initial: OrganisationStateModel = {
    showCreateOrganisationModal: false,
    // selectedTab: null,
    organisations: null,
    selectedOrganisation: {
        id: '',
        logo: '',
        name: '',
        planName: '',
        planKey: 'freemium',
        userCount: 0,
        destinationCount: 0,
        createdDate: '',
        userType: UserType.User,
        features: {
            isUpgradable: false,
            usersLimit: 0,
            rolesLimit: 0,
            destinationsLimit: 0,
            rulesLimit: 0,
            multiPortsCountInRules: 0,
            hasFailover: false,
            hasGitHubActionsIntegration: false,
            hasCloudFlareIntegration: false
        }
    },
    myInvitations: {
        showModal: false,
        requestedFromExternalUrl: false,
        invitations: null
    }
}

const {actions, reducer} = createSlice({
    name: 'organisation',
    initialState: initial,
    reducers: {
        setShowCreateOrganisationModal: (state, {payload}) => {
            state.showCreateOrganisationModal = payload
        },
        // setSelectedTab: (state, action: PayloadAction<string>) => {
        //     state.selectedTab = action.payload ?? null
        // },
        setSelectedOrganisation: (state, action: PayloadAction<OrganisationModel | null>) => {
            state.selectedOrganisation = action?.payload ?? initial.selectedOrganisation
        },
        setSelectedOrganisationLogo: (state, {payload}) => {
            state.selectedOrganisation = {...state.selectedOrganisation, logo: payload}
        },
        setOrganisations: (state, action: PayloadAction<OrganisationModel[] | null>) => {
            state.organisations = action.payload ?? initial.organisations
        },
        setMyInvitationsModalState: (state, action: PayloadAction<{ state: boolean, requestedFromExternalUrl: boolean }>) => {
            state.myInvitations.requestedFromExternalUrl = action.payload.requestedFromExternalUrl ?? initial.myInvitations.requestedFromExternalUrl
            state.myInvitations.showModal = action.payload.state ?? initial.myInvitations.showModal
        },
        setMyInvitations: (state, action: PayloadAction<MyInvitationsModelInvitation[] | null>) => {
            state.myInvitations.invitations = action.payload ?? initial.myInvitations.invitations
        },
        setOrganisationInfo: (state, action: PayloadAction<{ userCount: number, destinationCount: number }>) => {
            state.selectedOrganisation = {...state.selectedOrganisation, userCount: action.payload.userCount, destinationCount: action.payload.destinationCount}
        }
    }
})

export const organisationReducer = reducer
export const organisationReducerActions = actions