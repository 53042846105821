import React, {FC, KeyboardEventHandler, RefObject, useEffect} from "react";
import {FieldInputProps} from "formik";
import {FormLabel} from "./FormLabel";
import {FormValidation} from "./FormValidation";
import {FormInput} from "./FormInput";
import {FormTextArea} from "./FormTextArea";
import {Link} from "react-router-dom";
import {useIntl} from "react-intl";
import {FormSelect, FormSelectItem} from "./FormSelect";

interface Props {
    inputType?: 'text' | 'textarea' | 'password' | 'email' | 'select' | 'number',
    rows?: number,
    selectItems?: FormSelectItem[]
    labelResourceName: string,
    link?: PropsLink,
    showLabel?: boolean,
    placeholderResourceName?: string,
    fieldProps?: FieldInputProps<any>,
    informationMessage?: string,
    validationCondition?: string | false | undefined,
    validationMessage?: string,
    onKeyUp?: KeyboardEventHandler<HTMLInputElement> | KeyboardEventHandler<HTMLTextAreaElement> | undefined,
    readOnly?: boolean,
    disabled?: boolean,
    focus?: boolean
}

type PropsLink = {
    textResourceName: string
    to: string,
    state?:any
}

export const FormInputGroup: FC<Props> = ({
                                              children,
                                              inputType = 'text',
                                              rows = 3,
                                              selectItems = [],
                                              labelResourceName,
                                              link,
                                              showLabel = true,
                                              placeholderResourceName,
                                              fieldProps,
                                              informationMessage,
                                              validationCondition,
                                              validationMessage,
                                              onKeyUp,
                                              readOnly = false,
                                              disabled = false,
                                              focus = false
                                          }) => {

    const intl = useIntl()

    return (
        <div className="d-flex flex-column mb-8">
            {
                showLabel && (
                    <>
                        {link ? (
                            <div className="d-flex flex-stack mb-2">
                                <FormLabel
                                    resourceName={labelResourceName}
                                />
                                <Link
                                    to={link.to}
                                    className='link-primary fs-6 fw-bolder'
                                    state={link.state}
                                    style={{marginLeft: '5px'}}
                                >
                                    {intl.formatMessage({id: link.textResourceName})}
                                </Link>
                            </div>
                        ) : (
                            <FormLabel
                                resourceName={labelResourceName}
                            />
                        )}
                    </>
                )
            }

            {children && children}

            {!children &&
                <>
                    {(inputType == 'text' || inputType == 'password' || inputType == 'email' || inputType == 'number') &&
                        <FormInput
                            inputType={inputType}
                            fieldProps={fieldProps}
                            placeholderResourceName={placeholderResourceName}
                            onKeyUp={onKeyUp as KeyboardEventHandler<HTMLInputElement>}
                            readOnly={readOnly}
                            focus={focus}
                        />
                    }

                    {inputType == 'textarea' &&
                        <FormTextArea
                            rows={rows}
                            fieldProps={fieldProps}
                            placeholderResourceName={placeholderResourceName}
                            onKeyUp={onKeyUp as KeyboardEventHandler<HTMLTextAreaElement>}
                            readOnly={readOnly}
                            focus={focus}
                        />
                    }

                    {inputType == 'select' &&
                        <FormSelect
                            items={selectItems}
                            fieldProps={fieldProps}
                            placeholderResourceName={placeholderResourceName}
                            disabled={disabled}
                        />
                    }
                </>
            }

            {informationMessage && <span className="fs-7 text-muted mt-2">{informationMessage}</span>}

            {
                (validationCondition && validationMessage) &&
                <FormValidation
                    condition={validationCondition}
                    propertyResourceName={labelResourceName}
                    errorMessage={validationMessage}
                />
            }
        </div>
    )
}