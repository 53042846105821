export interface InviteUsersModel {
    showInviteUsersModal: boolean,
    searchResult: SearchResultModel[]
}

export interface SearchResultModel {
    inviteState: InviteState,
    name: string,
    email: string,
    photo: string,
    userType: string
}

export enum InviteState {
    HasNoUser = "HasNoUser",
    HasUser = "HasUser",
    HasUserInTheOrganisation = "HasUserInTheOrganisation",
    HasUserInTheOrganisationAsInvite = "HasUserInTheOrganisationAsInvite",
    HasUserInTheOrganisationAsRejectedInvite = "HasUserInTheOrganisationAsRejectedInvite",
    HasUnclaimedUser = "HasUnclaimedUser",
    HasUnclaimedUserAsInvite = "HasUnclaimedUserAsInvite",
    OrganisationBlocked = "OrganisationBlocked"
}