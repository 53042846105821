import {
    CreateRoleModel,
    EditUserRolesModel,
    RoleDetailModel,
    RoleModel,
    RolesModel,
    RoleUsersModel,
    UpdateRoleModel
} from "./models";
import axios, {AxiosResponse} from "axios";
import {DataRequest, DataResponse} from "../../../../components/tables";

const createRole = async (model: CreateRoleModel) => await axios.post(`/organisations/${model.organisationId}/roles`, {
    name: model.name,
    colorCode: model.colorCode
})

const updateRole = async (model: UpdateRoleModel) => await axios.patch(`/organisations/${model.organisationId}/roles/${model.roleId}`, {
    name: model.name,
    colorCode: model.colorCode
})

const getRoles = async ({
                            organisationId,
                            dataRequest = undefined
                        }: { organisationId: string, dataRequest?: DataRequest | undefined }) => await axios.get<DataResponse<RolesModel>, AxiosResponse<DataResponse<RolesModel>>>(`/organisations/${organisationId}/roles`, {
    params: {
        searchText: dataRequest?.searchText,
        page: dataRequest?.page ?? 1,
        pageSize: dataRequest?.pageSize ?? 10
    }
})

const getRole = async ({
                           organisationId,
                           roleId
                       }: { organisationId: string, roleId: string }) =>
    await axios.get<RoleModel, AxiosResponse<RoleModel>>(`/organisations/${organisationId}/roles/${roleId}`)

const getRoleUsers = async ({
                                organisationId,
                                roleId,
                                dataRequest = undefined
                            }: { organisationId: string, roleId: string, dataRequest?: DataRequest | undefined }) => {
    let params = {
        searchText: dataRequest?.searchText,
        page: dataRequest?.page ?? 1,
        pageSize: dataRequest?.pageSize ?? 10
    } as any

    dataRequest?.customFilter?.map((filter, index) => {
        params[`customFilter[${index}].name`] = filter.name
        params[`customFilter[${index}].value`] = filter.value
    })

    return await axios.get<DataResponse<RoleUsersModel>, AxiosResponse<DataResponse<RoleUsersModel>>>(`/organisations/${organisationId}/roles/${roleId}/users`, {
        params
    });
}

const getRoleDetailInfo = async ({
                                     organisationId,
                                     roleId
                                 }: { organisationId: string, roleId: string }) =>
    axios.get<RoleDetailModel, AxiosResponse<RoleDetailModel>>(`/organisations/${organisationId}/roles/${roleId}/detail`)

const getUserRoles = async ({
                                organisationId,
                                organisationUserId
                            }: { organisationId: string, organisationUserId: string }) =>
    await axios.get<EditUserRolesModel[], AxiosResponse<EditUserRolesModel[]>>(`/organisations/${organisationId}/users/${organisationUserId}/roles`)

const updateUserRoles = async ({
                                   organisationId,
                                   organisationUserId,
                                   roleIds
                               }: { organisationId: string, organisationUserId: string, roleIds: string[] }) =>
    axios.put(`/organisations/${organisationId}/users/${organisationUserId}/roles`, roleIds)

const removeUserFromTheRole = async ({
                                         organisationId,
                                         organisationUserId,
                                         organisationRoleId
                                     }: { organisationId: string, organisationUserId: string, organisationRoleId: string }) =>
    axios.delete(`/organisations/${organisationId}/users/${organisationUserId}/roles/${organisationRoleId}`)

const checkRoleDeletable = async ({
                                      organisationId,
                                      roleId
                                  }: { organisationId: string, roleId: string }) => await axios.get<boolean, AxiosResponse<boolean>>(`/organisations/${organisationId}/roles/${roleId}/check-deletable`)

const deleteRole = async ({organisationId, roleId}:{organisationId: string, roleId: string}) => axios.delete(`/organisations/${organisationId}/roles/${roleId}`)

export const roleApis = {
    createRole,
    updateRole,
    getRoles,
    getRole,
    getRoleUsers,
    getRoleDetailInfo,
    getUserRoles,
    updateUserRoles,
    removeUserFromTheRole,
    checkRoleDeletable,
    deleteRole
}