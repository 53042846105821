import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {DataResponse} from "../../../../components/tables";
import {DestinationsStateModel, DestinationDetailModel, DestinationsModel} from "./models/";

const initial: DestinationsStateModel = {
    createAgentModal: {
        show: false,
    },
    createCmsModal: {
        show: false,
    },
    createSoftwarePackageModal: {
        show: false,
    },
    editDestinationModal: {
        show: false,
        destinationId: '',
        refreshTableWhenModalClosed: false
    },
    detail: {
        info: {
            name: '',
            ipAddress: '',
            destinationType: undefined,
            destinationTypeDetail: undefined
        },
        settings: {
            failoverRdp: false,
            failoverSsh: false,
            docker: false,
            cloudflareIsActive: false,
            githubIsActive: false,
        }
    },
    agentsTable: {
        data: null,
        refreshTable: false
    },
    cmsTable: {
        data: null,
        refreshTable: false
    },
    softwarePackagesTable: {
        data: null,
        refreshTable: false
    }
}

const {actions, reducer} = createSlice({
    name: 'destinations',
    initialState: initial,
    reducers: {
        setShowCreateAgentModal: (state, action: PayloadAction<{ state: boolean }>) => {
            state.createAgentModal = {
                show: action.payload.state ?? initial.createAgentModal.show
            }
        },
        setShowCreateCmsModal: (state, action: PayloadAction<{ state: boolean }>) => {
            state.createCmsModal = {
                show: action.payload.state ?? initial.createCmsModal.show
            }
        },
        setShowSoftwarePackageModal: (state, action: PayloadAction<{ state: boolean }>) => {
            state.createSoftwarePackageModal = {
                show: action.payload.state ?? initial.createSoftwarePackageModal.show
            }
        },
        setShowEditDestinationModal: (state, action: PayloadAction<{ state: boolean, destinationId?: string, refreshTableWhenModalClosed?: boolean }>) => {
            state.editDestinationModal = {
                show: action.payload.state,
                destinationId: action.payload.destinationId ?? initial.editDestinationModal.destinationId,
                refreshTableWhenModalClosed: action.payload.refreshTableWhenModalClosed ?? initial.editDestinationModal.refreshTableWhenModalClosed
            }
        },
        setDestinationDetailInfo: (state, action: PayloadAction<DestinationDetailModel | null>) => {
            state.detail.info = action.payload ?? initial.detail.info
        },
        setAgents: (state, action: PayloadAction<DataResponse<DestinationsModel> | null>) => {
            state.agentsTable = {
                data: action.payload ?? initial.agentsTable.data,
                refreshTable: false
            }
        },
        setAgentsRefreshTable: (state) => {
            state.agentsTable.refreshTable = true
        },
        setCms: (state, action: PayloadAction<DataResponse<DestinationsModel> | null>) => {
            state.cmsTable = {
                data: action.payload ?? initial.cmsTable.data,
                refreshTable: false
            }
        },
        setCmsRefreshTable: (state) => {
            state.cmsTable.refreshTable = true
        },
        setSofwarePackages: (state, action: PayloadAction<DataResponse<DestinationsModel> | null>) => {
            state.softwarePackagesTable = {
                data: action.payload ?? initial.softwarePackagesTable.data,
                refreshTable: false
            }
        },
        setSofWarePackagesRefreshTable: (state) => {
            state.softwarePackagesTable.refreshTable = true
        }
    }
})

export const destinationsReducer = reducer
export const destinationsReducerActions = actions