import axios, {AxiosResponse} from "axios";
import {MyInvitationsModelInvitation, OrganisationModel} from "./models";
import {InviteResponse} from "../../infrastructure/enums/inviteResponse";

const createOrganisation = async ({name}: { name: string }) => await axios.post<string, AxiosResponse<string>>('/organisations', {name});

const getOrganisation = async ({organisationId}: { organisationId: string }) => await axios.get<OrganisationModel, AxiosResponse<OrganisationModel>>(`/organisations/${organisationId}`);

const updateOrganisationLogo = async ({
                                          organisationId,
                                          logo
                                      }: { organisationId: string, logo: File }) => {

    const formData = new FormData();
    formData.append('organisationId', organisationId)
    formData.append('file', logo);

    return await axios.patch<string, AxiosResponse<string>>(`/organisations/${organisationId}/logo`,
        formData,
        {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
}

const getOrganisations = async () => await axios.get<OrganisationModel[], AxiosResponse<OrganisationModel[]>>(`/user/organisations`);

const deleteOrganisation = async ({organisationId}: { organisationId: string }) => await axios.delete(`/organisations/${organisationId}`);

const getOrganisationInvites = async () => await axios.get<MyInvitationsModelInvitation[], AxiosResponse<MyInvitationsModelInvitation[]>>(`/user/organisation-invites`);

const responseOrganisationInvites = async ({inviteId, response}: { inviteId: string, response: InviteResponse }) =>
    await axios.patch(`/user/organisation-invites/${inviteId}`,
        {
            response
        });


export const organisationApis = {
    createOrganisation,
    getOrganisation,
    updateOrganisationLogo,
    getOrganisations,
    deleteOrganisation,
    getOrganisationInvites,
    responseOrganisationInvites
}