import axios, {AxiosResponse} from "axios";
import {Dispatch} from "redux";
import {ActionResponse} from "../../../../infrastructure/models/actionResponse";
import {DataRequest, DataResponse} from "../../../../components/tables/Table";
import {rolesReducerActions} from "./rolesSlice";
import {
    CreateRoleModel,
    UpdateRoleModel,
    RoleModel
} from "./models/";
import {toastify} from "../../../../libs/toastify";
import {roleApis} from "./rolesApis";
import {createAsyncThunk} from "@reduxjs/toolkit";
import {organisationActions} from "../../organisationActions";

const showOrganisationRoleModal = createAsyncThunk(
    'roles/showOrganisationRoleModal',
    async ({state = true, roleId = null, refreshTableWhenModalClosed = false, setSelectedTabAsRoles = true}: {
        state?: boolean,
        roleId?: string | null,
        refreshTableWhenModalClosed?: boolean,
        setSelectedTabAsRoles?: boolean
    }, {dispatch}) => {
        dispatch(rolesReducerActions.setShowRoleModal({state, roleId, refreshTableWhenModalClosed}));
        // if (setSelectedTabAsRoles) {
        //     dispatch(organisationActions.setSelectedTab('roles'));
        // }
    })

const createRole = async (model: CreateRoleModel) => {
    try {
        await roleApis.createRole(model)
        return {
            isSucceed: true
        } as ActionResponse
    } catch (e: any) {
        return {
            isSucceed: false,
            errors: e.response?.data?.errors,
            message: e.response.data
        } as ActionResponse
    }
}

const updateRole = async (model: UpdateRoleModel) => {
    try {
        await roleApis.updateRole(model)
        return {
            isSucceed: true
        } as ActionResponse
    } catch (e: any) {
        return {
            isSucceed: false,
            errors: e.response?.data?.errors,
            message: e.response.data
        } as ActionResponse
    }
}

const getRoles = createAsyncThunk(
    'roles/getRoles',
    async ({
               organisationId,
               dataRequest = undefined
           }: { organisationId: string, dataRequest?: DataRequest | undefined }, {dispatch}) => {
        const response = await roleApis.getRoles({organisationId, dataRequest});
        dispatch(rolesReducerActions.setRoles(response.data))
    })

const getRole = async (organisationId: string, roleId: string) => {
    try {
        const response = await roleApis.getRole({organisationId, roleId})
        return {
            isSucceed: true,
            data: response.data
        } as ActionResponse<RoleModel>
    } catch (e: any) {
        return {
            isSucceed: false,
            errors: e.response?.data?.errors,
            message: e.response.data
        } as ActionResponse<RoleModel>
    }
}

const getRoleUsers = createAsyncThunk(
    'roles/getRoleUsers',
    async ({
               organisationId,
               roleId,
               dataRequest = undefined
           }: { organisationId: string, roleId: string, dataRequest: DataRequest | undefined }, {dispatch}) => {
        const response = await roleApis.getRoleUsers({organisationId, roleId, dataRequest})
        dispatch(rolesReducerActions.setRoleDetailUsers(response.data))
    })

const getRoleDetailInfo = createAsyncThunk(
    'roles/getRoleDetailInfo',
    async ({organisationId, roleId}: { organisationId: string, roleId: string }, {dispatch}) => {
        const response = await roleApis.getRoleDetailInfo({organisationId, roleId});
        dispatch(rolesReducerActions.setRoleDetailInfo(response.data))
    })

const showEditUsersRoleModal = createAsyncThunk(
    'roles/showEditUsersRoleModal',
    async ({state, organisationUserId = null}: { state: boolean, organisationUserId?: string | null }, {dispatch}) => {
        dispatch(rolesReducerActions.setShowEditUserRolesModal({showModal: state, organisationUserId}));
        if (!state) {
            dispatch(rolesReducerActions.setEditUsersRolesModalUserRoles([]))
        }
    })

const getUserRoles = createAsyncThunk(
    'roles/getUserRoles',
    async ({
               organisationId,
               organisationUserId
           }: { organisationId: string, organisationUserId: string }, {dispatch}) => {
        const response = await roleApis.getUserRoles({organisationId, organisationUserId});
        dispatch(rolesReducerActions.setEditUsersRolesModalUserRoles(response.data))
    })

const refreshUserRolesTable = createAsyncThunk(
    'roles/refreshUserRolesTable',
    async (_, {dispatch}) => {
        dispatch(rolesReducerActions.setRolesRefreshTable())
    })

const updateUserRoles = async (organisationId: string, organisationUserId: string, roleIds: string[]) => {
    try {
        await roleApis.updateUserRoles({organisationId, organisationUserId, roleIds});
        return {
            isSucceed: true,
        } as ActionResponse
    } catch {
        return {
            isSucceed: false,
        } as ActionResponse
    }
}

const removeUserFromTheRole = async (organisationId: string, organisationUserId: string, organisationRoleId: string) => {
    try {
        await roleApis.removeUserFromTheRole({organisationId, organisationUserId, organisationRoleId});
        return {
            isSucceed: true,
        } as ActionResponse
    } catch {
        return {
            isSucceed: false,
        } as ActionResponse
    }
}

const checkRoleDeletable = async (organisationId: string, roleId: string) => {
    try {
        const response = await roleApis.checkRoleDeletable({organisationId, roleId})
        return {
            isSucceed: true,
            data: response.data
        } as ActionResponse<boolean>
    } catch {
        return {
            isSucceed: false,
        } as ActionResponse<boolean>
    }
}

const deleteRole = async (organisationId: string, roleId: string) => {
    try {
        await roleApis.deleteRole({organisationId, roleId})
        return {
            isSucceed: true,
        } as ActionResponse
    } catch {
        return {
            isSucceed: false
        } as ActionResponse
    }
}

export const rolesActions =
    {
        showOrganisationRoleModal,
        createRole,
        updateRole,
        getRoles,
        refreshUserRolesTable,
        getRole,
        getRoleUsers,
        getRoleDetailInfo,
        showEditUsersRoleModal,
        getUserRoles,
        updateUserRoles,
        removeUserFromTheRole,
        checkRoleDeletable,
        deleteRole
    }