interface props {
    base64String: string
    className?: string
}


export const ImageFromBase64 = ({base64String, className}: props) => {


    if (base64String) {

        const firstChar = base64String.substring(0, 3);

        let base64Prefix;

        switch (firstChar) {
            case "/9j":
                base64Prefix = "image/png";
                break;
            case "iVB":
                base64Prefix = "image/jpeg";
                break;
            case "PHN":
                base64Prefix = "image/svg+xml";
                break;
            case "PD9":
                base64Prefix = "image/svg+xml";
                break;
            default:
                break;
        }

        return (
            <img
                src={(base64Prefix ? "data:" + base64Prefix : "") + ";base64," + base64String}
                {...(className ? {className: className} : {})}
            />
        )
    } else {
        return (
            <></>
        )
    }
}