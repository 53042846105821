import React, {useCallback, useEffect, useRef, useState} from "react";
import axios from "axios";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import Switch from "react-switch";
import {useIntl} from "react-intl";
import {useSelector} from "react-redux";
import {RootState} from "../../../../setup";
import {AuthState} from "../../../../app/pages/auth";
import * as Sentry from '@sentry/browser';
import {useWindowWidth} from "@react-hook/window-size";


export const Ping = (
    {
        myIpv4Address,
        setMyIpv4Address,
        myIpv6Address,
        setMyIpv6Address,
    }: {
        myIpv4Address: any,
        setMyIpv4Address: any,
        myIpv6Address: any,
        setMyIpv6Address: any
    }
) => {

    const intl = useIntl()
    const windowWidth = useWindowWidth()

    const progressTimer: any = useRef()

    const [ping, setPing] = useState(false)
    const [initial, setInitial] = useState(true)

    const authState = useSelector<RootState>(({auth}) => auth) as AuthState

    useEffect(() => {

        setTimeout(() => {
            setInitial(false)
            if (localStorage.getItem("ping") == "true") {
                setPing(true)
            }
        }, 350)

    }, [])

    useEffect(() => {

        if (initial)
            return

        if (ping) {

            if (progressTimer.current) {
                clearInterval(progressTimer.current)
            }

            sendPing()
            progressTimer.current = setInterval(sendPing, 10000)
            localStorage.setItem("ping", "true");
        } else {
            clearInterval(progressTimer.current)
            localStorage.removeItem("ping");
            stopPing()
        }

        return () => {
            clearInterval(progressTimer.current)
        }

    }, [ping, authState.accessToken])


    const sendPing = useCallback(async () => {

        try {

            const ipResponse = await axios.post("/ping", {}, {
                baseURL: process.env.REACT_APP_PING_API_URL

            })
            if (ipResponse.data?.toString()?.toLowerCase() == "code-0652") {

                try {
                    const ipV4Response = await axios.post(`/ping`, {},
                        {
                            baseURL: process.env.REACT_APP_IPV4_API_URL
                        })

                    const ipv4v6Response = await axios.post("/ping", {
                        code0652: ipV4Response.data?.code0652Token
                    }, {
                        baseURL: process.env.REACT_APP_PING_API_URL

                    })

                    if (ipv4v6Response.data?.ipAddressV4) setMyIpv4Address(ipv4v6Response.data.ipAddressV4)
                    if (ipv4v6Response.data?.ipAddressV6) setMyIpv6Address(ipv4v6Response.data.ipAddressV6)

                } catch (ex) {
                    Sentry.captureException(ex);
                    const justIpv6Response = await axios.post("/ping", {
                            code0652: "code-0652"
                        },
                        {
                            baseURL: process.env.REACT_APP_PING_API_URL
                        })
                    setMyIpv4Address('')
                    if (justIpv6Response.data?.ipAddressV6) setMyIpv6Address(justIpv6Response.data.ipAddressV6)
                }

            } else {
                setMyIpv4Address(ipResponse.data.ipAddressV4)
            }


        } catch (ex) {
            Sentry.captureException(ex);
            setMyIpv4Address('')
            setMyIpv6Address('')
        }
    }, [authState.accessToken])

    const stopPing = useCallback(async () => {

        try {
            await axios.post("/stop-ping", {}, {
                baseURL: process.env.REACT_APP_PING_API_URL
            })
        } catch (ex) {
            Sentry.captureException(ex);
        } finally {
            setMyIpv4Address('')
            setMyIpv6Address('')
            setPing(false)
        }
    }, [authState.accessToken])

    return (
        <>
            <div className="form-check form-switch form-check-custom form-check-solid me-4 justify-content-end">
                <label className="form-check-label">
                    <div className="d-flex h-100">
                        {ping &&
                            <div className="text-end align-items-center"
                                 style={{fontSize: (myIpv4Address && myIpv6Address) ? 12 : "inherit"}}>
                                {(windowWidth > 600 && myIpv4Address) && <div>IPv4: {myIpv4Address}</div>}
                                {(windowWidth > 600 && myIpv6Address) && <div>IPv6: {myIpv6Address}</div>}
                            </div>
                        }
                        <div className="d-flex align-items-center ms-3 me-2">
                            {(ping && (myIpv4Address || myIpv6Address)) ? "Online" : "Offline"}
                        </div>
                    </div>

                </label>
                <OverlayTrigger
                    placement={"bottom"}
                    overlay={
                        <Tooltip>
                            {intl.formatMessage({id: "ORGANISATION.HEADER.PING.TOOLTIP"})}
                        </Tooltip>
                    }
                >
                    <svg width={16} fill="gray" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                        <path
                            d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zM262.655 90c-54.497 0-89.255 22.957-116.549 63.758-3.536 5.286-2.353 12.415 2.715 16.258l34.699 26.31c5.205 3.947 12.621 3.008 16.665-2.122 17.864-22.658 30.113-35.797 57.303-35.797 20.429 0 45.698 13.148 45.698 32.958 0 14.976-12.363 22.667-32.534 33.976C247.128 238.528 216 254.941 216 296v4c0 6.627 5.373 12 12 12h56c6.627 0 12-5.373 12-12v-1.333c0-28.462 83.186-29.647 83.186-106.667 0-58.002-60.165-102-116.531-102zM256 338c-25.365 0-46 20.635-46 46 0 25.364 20.635 46 46 46s46-20.636 46-46c0-25.365-20.635-46-46-46z"/>
                    </svg>
                </OverlayTrigger>
                <Switch className="form-check-input ms-2" onChange={() => setPing(!ping)}
                        checked={ping} {...((ping && (!myIpv4Address && !myIpv6Address)) ? {onColor: "#888888"} : {})} />
            </div>
        </>
    )
}