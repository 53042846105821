import axios, {AxiosResponse} from "axios";
import {LoginModel} from "../../pages/auth/models/LoginModel";

const login = async (email: string, password: string, reCAPTCHAToken: string) =>
    await axios.post<LoginModel, AxiosResponse<LoginModel>>('/auth/login', {
        email,
        password,
        reCAPTCHAToken
    })

const register = async (email: string, password: string, timeZone: string, reCAPTCHAToken: string) =>
    await axios.post<LoginModel, AxiosResponse<LoginModel>>('/auth/register', {
        email,
        password,
        timeZone,
        reCAPTCHAToken
    })

const forgotPassword = async ({email, reCAPTCHAToken}: { email: string, reCAPTCHAToken: string }) =>
    await axios.post('/auth/forgot-password', {email, reCAPTCHAToken});

const resetPassword = async ({email, password, token, reCAPTCHAToken}: {
    email: string,
    password: string,
    token: string,
    reCAPTCHAToken: string
}) =>
    await axios.post('/auth/reset-password', {email, password, token, reCAPTCHAToken});

const resetPasswordTokenVerify = async ({email, token}: { email: string, token: string }) =>
    await axios.post('/auth/reset-password/verify', {email, token});

const getUserAndAccessToken = async () =>
    await axios.get<LoginModel, AxiosResponse<LoginModel>>('/auth/get-user');

const registerDevice = async ({name, deviceType, deviceInfo}
                                  : {
    name: string,
    deviceType: string,
    deviceInfo: {
        deviceType: string,
        vendor: string,
        model: string,
        osName: string,
        osVersion: string,
        browserName: string,
        browserVersion: string,
        browserEngine: string,
        browserEngineVersion: string
    }
}) =>
    await axios.post<LoginModel, AxiosResponse<LoginModel>>('/user-device', {
        name,
        deviceType,
        deviceInfo
    })

const verifyDevice = async ({verificationCode}: { verificationCode: string }) =>
    await axios.post<LoginModel, AxiosResponse<LoginModel>>('/user-device/verify', {
        verificationCode
    })

const resendVerificationCode = async () =>
    await axios.post('/user-device/send-verification-code')

const forgetDevice = async () =>
    await axios.delete('/user-device/forget')

const refreshToken = async ({ accessToken, refreshToken}: { accessToken: string, refreshToken: string }) => {
    const axiosInstance = axios.create();
    return await axiosInstance.post<LoginModel, AxiosResponse<LoginModel>>('/auth/refresh-token',
        {
            refreshToken
        },
        {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        })
}

export const authApis = {
    login,
    register,
    forgotPassword,
    resetPassword,
    resetPasswordTokenVerify,
    getUserAndAccessToken,
    registerDevice,
    verifyDevice,
    resendVerificationCode,
    forgetDevice,
    refreshToken
}