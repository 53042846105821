import {FormActionButtons, FormGroup, FormInputGroup, FormModal} from "../../../../../../../components/forms";
import {useSelector} from "react-redux";
import {destinationsSelectors} from "../../../destinationsSelectors";
import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import {Form, Formik} from "formik";
import * as Yup from "yup";
import {destinationsActions} from "../../../destinationsActions";
import {useAppDispatch} from "../../../../../../../infrastructure/hooks/reduxHooks";
import {organisationSelectors} from "../../../../../organisationSelectors";
import clsx from "clsx";
import {WordpressIntegration} from "../../cmsExtension";
import {toastify} from "../../../../../../../libs/toastify";
import {UserCard} from "../../../../../../../components/tables";
import {SettingsGroupSeparator} from "../../detail/settings";
import {authSelectors} from "../../../../../../auth";
import {UsersModel} from "../../../../users/models";

interface CmsPackage {
    packageKey: string,
    name: string,
    imageUrl: string,
    selectable: boolean
}

const cmsPackages: CmsPackage[] = [
    {
        packageKey: "wordpress",
        name: "Wordpress",
        imageUrl: "wordpress.png",
        selectable: true
    },
    {
        packageKey: "joomla",
        name: "Joomla",
        imageUrl: "joomla.png",
        selectable: false
    },
    {
        packageKey: "drupal",
        name: "Drupal",
        imageUrl: "drupal.png",
        selectable: false
    }
]

export const CreateCmsModal = () => {

    const intl = useIntl()
    const dispatch = useAppDispatch()

    const organisationId = useSelector(organisationSelectors.getSelectedOrganisationId())
    const user = useSelector(authSelectors.getUser())
    const [step, setStep] = useState(1)
    const [cmsExtensionId, setCmsExtensionId] = useState('')
    const [formValues, setFormValues] = useState({
        name: ''
    })

    const [selectedPackage, setSelectedPackage] = useState("wordpress")
    const [loading, setLoading] = useState(false)
    const [organisationUsers, setOrganisationUsers] = useState<UsersModel[]>([])
    const [selectedUserIds, setSelectedUserIds] = useState<string[]>([])
    const [emails, setEmails] = useState<string[]>([])
    const [isEmailsValid, setIsEmailsValid] = useState(true);
    const [emailsCount, setEmailsCount] = useState(0);
    const [searchUserText, setSearchUserText] = useState("")


    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .min(3, intl.formatMessage({id: 'VALIDATION.MIN_LENGTH'}, {length: 3}))
            .max(75, intl.formatMessage({id: 'VALIDATION.MAX_LENGTH'}, {length: 75}))
            .required(intl.formatMessage({id: 'VALIDATION.REQUIRED'}, {name: intl.formatMessage({id: 'ORGANISATION.MODAL.DESTINATION.CREATE_CMS.INPUT.NAME'})})),
    })

    const modalState = useSelector(destinationsSelectors.getCreateCmsModalState())

    const closeModal = () => {
        dispatch(destinationsActions.showCreateCmsModal({state: false}))
        dispatch(destinationsActions.refreshCmsTable())
    }

    const getOrganisationUsers = async () => {
        const response = await destinationsActions.getOrganisationUsersForCreatingDestination({organisationId, currentUserEmail: user.email})
        setOrganisationUsers(response)
    }

    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/

    const addEmailsToInvite = async (emailsAsString: string) => {
        const emailsAsArray = emailsAsString.split(/[\n;]/).filter(email => email.trim());

        for (const email of emailsAsArray) {
            if (!emailRegex.test(email.trim())) {
                setIsEmailsValid(false);
                return;
            }
        }
        setIsEmailsValid(true)
        setEmailsCount(emailsAsArray.length)
        setEmails(emailsAsArray)
    }

    useEffect(() => {
        if (user.id) {
            getOrganisationUsers()
        }
    }, [user.id])

    return (
        <>
            <FormModal
                modalState={modalState as boolean}
                closeModal={closeModal}
                title={intl.formatMessage({id: "ORGANISATION.MODAL.DESTINATION.CREATE_CMS.TITLE"})}
                subTitle={intl.formatMessage({id: "ORGANISATION.MODAL.DESTINATION.CREATE_CMS.SUBTITLE"})}
            >
                <Formik
                    initialValues={formValues}
                    enableReinitialize={true}
                    validationSchema={validationSchema}
                    onSubmit={async (values, {setStatus, setSubmitting}) => {

                        if (step == 1) {

                            setLoading(true)

                            setTimeout(async () => {

                                const response = await destinationsActions.checkDestinationName({organisationId, name: values.name})

                                if (response.isSucceed) {
                                    // name exists check
                                    if (response.data) {
                                        setStep(2)
                                        setLoading(false)
                                    } else {
                                        toastify.error(intl.formatMessage({id: "ORGANISATIONS.DESTINATION.MESSAGE.DESTINATION_HAS_ALREADY_EXIST"}))
                                        setLoading(false)
                                        setSubmitting(false)
                                    }
                                } else {
                                    setLoading(false)
                                    setSubmitting(false)
                                    toastify.error()
                                }
                            }, 500)
                        } else if (step == 2) {

                            const response = await dispatch(destinationsActions.createCmsExtension({
                                organisationId,
                                name: values.name,
                                userIds: selectedUserIds,
                                emails: emails
                            })).unwrap()

                            if (response.isSucceed) {
                                setCmsExtensionId(response.data.cmsExtensionId)
                                setTimeout(() => {
                                    setStep(3)
                                    setSubmitting(false)
                                    setLoading(false)
                                }, 250)
                                return
                            } else {
                                setLoading(false)
                                setSubmitting(false)

                                switch (response.message) {
                                    case "DestinationHasAlreadyExist":
                                        toastify.error(intl.formatMessage({id: "ORGANISATIONS.DESTINATION.MESSAGE.DESTINATION_HAS_ALREADY_EXIST"}))
                                        break;
                                    default:
                                        toastify.error()
                                }
                            }

                        } else if (step == 3) {
                            closeModal()
                        }

                    }}
                >
                    {(formik) => (
                        <Form className="form">
                            {
                                step == 1 &&
                                <>
                                    <FormInputGroup
                                        labelResourceName={"ORGANISATION.MODAL.DESTINATION.CREATE_CMS.INPUT.NAME"}
                                        fieldProps={formik.getFieldProps('name')}
                                        placeholderResourceName={"ORGANISATION.MODAL.DESTINATION.CREATE_CMS.INPUT.NAME.PLACEHOLDER"}
                                        validationCondition={formik.touched.name && formik.errors.name}
                                        validationMessage={formik.errors.name}
                                    />
                                    <FormGroup
                                        labelResourceName={"ORGANISATION.MODAL.DESTINATION.CREATE_CMS.INPUT.CMS_PACKAGE"}
                                    >
                                        <div className="row mt-2 justify-content-center">
                                            {
                                                cmsPackages.map((packageItem: CmsPackage) => {
                                                    return (
                                                        <div key={packageItem.packageKey} className="col-md-6 col-lg-12 col-xxl-3">
                                                            <div
                                                                className={clsx("btn  mmm                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    btn-outline btn-outline-dashed d-flex text-start",
                                                                    selectedPackage == packageItem.packageKey ? "btn-active-light-primary active" : "",
                                                                    packageItem.selectable ? "" : "disabled"
                                                                )}
                                                                onClick={() => setSelectedPackage(packageItem.packageKey)}
                                                            >
                                                                <div className="w-100">
                                                                    <div className="d-flex justify-content-center">
                                                                        <img src={`/assets/integrations/${packageItem.imageUrl}`} style={{
                                                                            width: 50
                                                                        }}/>
                                                                    </div>
                                                                    <div className="fs-4 fw-bold text-gray-800 mb-2 mt-2 d-flex justify-content-center">
                                                                        {packageItem.name}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </FormGroup>
                                </>

                            }
                            {
                                step == 2 &&
                                <>
                                    <div className="fs-5">
                                        {intl.formatMessage({id: "ORGANISATION.MODAL.DESTINATION.CREATE_CMS.USERS.MESSAGE.TEAM_CREATED"}, {
                                            team: <b>{formik.values.name} Team</b>
                                        })}
                                    </div>

                                    <div className="separator separator-content border-dark mt-12 mb-8"><span className="w-250px fw-bold">
                                        {intl.formatMessage({id: "ORGANISATION.MODAL.DESTINATION.CREATE_CMS.USERS.MESSAGE.TITLE.ADD_USER"})}
                                    </span>
                                    </div>

                                    <div className="my-7">
                                        <input
                                            className="form-control"
                                            placeholder={intl.formatMessage({id: "ORGANISATION.MODAL.DESTINATION.CREATE_CMS.USERS.INPUT.SEARCH.PLACEHOLDER"})}
                                            onKeyUp={(e) => setSearchUserText(e.currentTarget.value)}
                                        />
                                    </div>
                                    <div className="scroll-y mh-250px mb-3">
                                        <div className="d-flex">
                                            <div className="d-flex align-items-center">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" checked={true} disabled={true}/>
                                                </div>
                                            </div>
                                            <div className="flex-grow-1 ps-3">
                                                <UserCard
                                                    name={user.fullName}
                                                    email={user.email}
                                                    unclaimedUser={false}
                                                    photo={user.photo}
                                                />
                                            </div>
                                        </div>
                                        {
                                            organisationUsers.filter(x => x.name.includes(searchUserText) || x.email.includes(searchUserText)).map((user: UsersModel) => {
                                                return (
                                                    <>
                                                        <SettingsGroupSeparator/>
                                                        <div className="d-flex">
                                                            <div className="d-flex align-items-center">
                                                                <div className="form-check">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="checkbox"
                                                                        onClick={(e) => {
                                                                            if (e.currentTarget.checked) {
                                                                                setSelectedUserIds([...selectedUserIds, user.id])
                                                                            } else {
                                                                                setSelectedUserIds(selectedUserIds.filter(x => x != user.id))
                                                                            }
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="flex-grow-1 ps-3">
                                                                <UserCard
                                                                    name={user.name}
                                                                    email={user.email}
                                                                    unclaimedUser={false}
                                                                    photo={user.photo}
                                                                />
                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            })
                                        }
                                    </div>

                                    <div className="separator separator-content border-dark my-12"><span className="w-350px fw-bold">
                                        {intl.formatMessage({id: "ORGANISATION.MODAL.DESTINATION.CREATE_CMS.USERS.MESSAGE.TITLE.ADD_EMAILS"})}
                                    </span></div>

                                    <div className="fs-6 mt-3 mb-5">
                                        {intl.formatMessage({id: "ORGANISATION.MODAL.DESTINATION.CREATE_CMS.USERS.MESSAGE.ADD_EMAILS"})}
                                    </div>

                                    <div className="mb-5">
                                        <FormInputGroup
                                            inputType="textarea"
                                            labelResourceName="FORM.CREATE"
                                            showLabel={false}
                                            rows={5}
                                            placeholderResourceName={"ORGANISATION.MODAL.INVITE_USERS.INPUT.EMAILS.PLACEHOLDER"}
                                            informationMessage={isEmailsValid ? `${intl.formatMessage({id: "ORGANISATION.MODAL.INVITE_USERS.MESSAGE.EMAIL_COUNT"})} ${emailsCount}` : ""}
                                            validationCondition={!isEmailsValid && "ok"}
                                            validationMessage={intl.formatMessage({id: "ERROR.INVALID_EMAIL"})}
                                            onKeyUp={(e: React.KeyboardEvent<HTMLTextAreaElement>) => addEmailsToInvite(e.currentTarget?.value)}
                                            focus={true}
                                        />
                                    </div>
                                </>
                            }
                            {
                                step == 3 &&
                                <>
                                    <WordpressIntegration
                                        organisationId={organisationId}
                                        destinationId={cmsExtensionId}
                                    />

                                    <div className="my-6 fst-italic">
                                        * {intl.formatMessage({id: "ORGANISATION.MODAL.DESTINATION.CREATE_CMS.MESSAGE.NEW_TOKEN"})}
                                    </div>
                                </>
                            }
                            <FormActionButtons
                                formik={formik}
                                loading={loading}
                                submitButtonResourceName={
                                    (() => {
                                        switch (step) {
                                            case 1:
                                                return "FORM.NEXT";
                                            case 2:
                                                return "FORM.CREATE";
                                            case 3:
                                                return "FORM.FINISH";
                                        }
                                    })()
                                }
                                cancelButtonResourceName={
                                    (() => {
                                        switch (step) {
                                            case 1:
                                                return "FORM.CANCEL";
                                            case 2:
                                                return "FORM.BACK";
                                        }
                                    })()
                                }
                                cancelButtonOnClick={
                                    () => (() => {
                                        switch (step) {
                                            case 1:
                                                return closeModal();
                                            case 2:
                                                return setStep(1);
                                        }
                                    })()
                                }
                                fullWidthButton={false}
                                buttonSize={"btn-lg"}
                                showCancelButton={step == 1 || step == 2}
                                closeModal={closeModal}
                            />
                        </Form>
                    )}
                </Formik>
            </FormModal>
        </>
    )
}