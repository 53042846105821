import React, {FC} from "react";
import {useIntl} from "react-intl";

interface Props {
    resourceName: string
}

export const FormLabel: FC<Props> = ({resourceName}) => {

    const intl = useIntl();
    
    return (
    <label className="d-flex align-items-center fs-6 fw-bold mb-2">
        {intl.formatMessage({id: resourceName})}
    </label>
    
)
}