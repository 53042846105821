import {Route, Routes} from "react-router-dom"
import {Navigation} from "./Navigation"
import {ChangePasswordPage} from "../subpages/ChangePasswordPage"
import {ProfilePage} from "../subpages/ProfilePage"
import {useIntl} from "react-intl";
import {UserPhoto} from "./UserPhoto";
import {useSelector} from "react-redux";
import {authSelectors} from "../../auth";

export const AccountPage = () => {

    const user = useSelector(authSelectors.getUser())
    const intl = useIntl()

    return (
        <>
            <div className="d-flex flex-column flex-lg-row">
                <div className="flex-column flex-lg-row-auto w-100 w-lg-325px mb-10">
                    <div className="card card-flush">
                        <div className="card-body px-0">
                            {/*<div className="card-body pt-15 px-0">*/}
                            <div className="d-flex flex-column text-center mb-9 px-9">
                                <div className="symbol symbol-80px symbol-lg-150px">
                                    {<UserPhoto/>}
                                </div>
                                <div className="text-center">
                                    <span
                                       className="text-gray-800 fw-bold fs-4">{user.fullName}</span>
                                    <span className="text-muted d-block fw-semibold">{user.email}</span>
                                </div>

                                {/*<div className="row mb-4 mt-8">*/}
                                {/*    <div className="col text-center">*/}
                                {/*        <div className="text-gray-800 fw-bold fs-3">*/}
                                {/*            <span className="m-0 counted" data-kt-countup="true"*/}
                                {/*                  data-kt-countup-value="642" data-kt-initialized="1">3</span>*/}
                                {/*        </div>*/}
                                {/*        <span className="text-gray-500 fs-8 d-block fw-bold">ORGANİZASYON</span>*/}
                                {/*    </div>*/}
                                {/*    <div className="col text-center">*/}
                                {/*        <div className="text-gray-800 fw-bold fs-3">*/}
                                {/*            <span className="m-0 counted" data-kt-countup="true"*/}
                                {/*                  data-kt-countup-value="12" data-kt-initialized="1">4</span>*/}
                                {/*        </div>*/}
                                {/*        <span className="text-gray-500 fs-8 d-block fw-bold">AKTİF CİHAZ</span>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                            </div>
                            <div className="m-0">
                                <Navigation
                                    tabs={
                                        [
                                            {
                                                text: intl.formatMessage({id: 'MENU.MYPROFILE'}),
                                                url: `/account/profile`,
                                                hasSubPages: true,
                                                svg: <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 496 512"><path d="M248 104c-53 0-96 43-96 96s43 96 96 96 96-43 96-96-43-96-96-96zm0 144c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48zm0-240C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 448c-49.7 0-95.1-18.3-130.1-48.4 14.9-23 40.4-38.6 69.6-39.5 20.8 6.4 40.6 9.6 60.5 9.6s39.7-3.1 60.5-9.6c29.2 1 54.7 16.5 69.6 39.5-35 30.1-80.4 48.4-130.1 48.4zm162.7-84.1c-24.4-31.4-62.1-51.9-105.1-51.9-10.2 0-26 9.6-57.6 9.6-31.5 0-47.4-9.6-57.6-9.6-42.9 0-80.6 20.5-105.1 51.9C61.9 339.2 48 299.2 48 256c0-110.3 89.7-200 200-200s200 89.7 200 200c0 43.2-13.9 83.2-37.3 115.9z"/></svg>
                                            },
                                            {
                                                text: intl.formatMessage({id: 'MENU.CHANGE_PASSWORD'}),
                                                url: `/account/change-password`,
                                                hasSubPages: true,
                                                svg: <i className="fas fa-unlock-alt"></i>
                                            }
                                        ]
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex-lg-row-fluid ms-lg-10">
                    {/*<div className="w-100 flex-lg-row-fluid mx-lg-13">*/}
                    <div className="card card-flush mb-10">
                        <div className="card-body py-12">
                            <Routes>
                                <Route path='profile' element={<ProfilePage/>}/>
                                <Route path='change-password' element={<ChangePasswordPage/>}/>
                            </Routes>
                        </div>
                    </div>
                    {/*</div>*/}
                </div>
            </div>
        </>
    )
}
