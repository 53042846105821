import {RulePortRow} from "./RuleModal";
import {FormikProps, getIn} from "formik";
import {FormInput, FormSelect, FormValidation} from "../../../../../../components/forms";
import {PortTypeEnum} from "../../models";
import React, {useEffect, useState} from "react";
import {useIntl} from "react-intl";
import {v4 as uuidv4} from "uuid";

const removeRow = (formik: FormikProps<{
    name: string,
    ports: RulePortRow[],
    roles: string[],
    destinations: string[]
}>, rowId: string) => {
    const newPorts = (formik.values.ports as RulePortRow[]).filter((port: any) => port.rowId != rowId);

    if (newPorts.length == 0) {
        newPorts.push({
            rowId: uuidv4(),
            portType: '',
            portRange: false,
        } as RulePortRow)
    }


    formik.setFieldValue('ports', newPorts)
}

export const PortRow = ({
                            formik,
                            item,
                            index
                        }: {
    formik: FormikProps<{ name: string, ports: RulePortRow[], roles: string[], destinations: string[] }>,
    item: RulePortRow,
    index: number
}) => {

    const intl = useIntl()


    const portTypeFieldName = 'portType'
    const portType = `ports[${index}].${portTypeFieldName}`;
    const touchedPortType = getIn(formik.touched, portType);
    const errorPortType = getIn(formik.errors, portType);

    const portRangeFieldName = 'portRange'
    const portRange = `ports[${index}].${portRangeFieldName}`;
    const touchedPortRange = getIn(formik.touched, portRange);
    const errorPortRange = getIn(formik.errors, portRange);

    const portFieldName = 'port'
    const port = `ports[${index}].${portFieldName}`;
    const touchedPort = getIn(formik.touched, port);
    const errorPort = getIn(formik.errors, port);

    const portStartFieldName = 'portStart'
    const portStart = `ports[${index}].${portStartFieldName}`;
    const touchedPortStart = getIn(formik.touched, portStart);
    const errorPortStart = getIn(formik.errors, portStart);

    const portEndFieldName = 'portEnd'
    const portEnd = `ports[${index}].${portEndFieldName}`;
    const touchedPortEnd = getIn(formik.touched, portEnd);
    const errorPortEnd = getIn(formik.errors, portEnd);

    const descriptionFieldName = 'description'
    const description = `ports[${index}].${descriptionFieldName}`;
    const touchedDescription = getIn(formik.touched, description);
    const errorDescription = getIn(formik.errors, description);


    return (
        <tr key={item.rowId} className="h-70px">
            <td>

                <FormSelect
                    items={
                        [
                            {
                                text: "TCP",
                                value: PortTypeEnum.TCP
                            },
                            {
                                text: "UDP",
                                value: PortTypeEnum.UDP
                            },
                            {
                                text: "TCP / UDP",
                                value: PortTypeEnum.Both
                            },
                        ]
                    }
                    onChange={formik.handleChange}
                    defaultValue={item?.portType.toString()}
                    name={portType}
                />
                <FormValidation
                    errorMessage={errorPortType}
                    condition={errorPortType}
                    propertyResourceName={"ORGANISATION.MODAL.RULE.INPUT.PORT_TYPE"}
                />
            </td>
            <td>
                <div
                    className="form-check form-switch form-check-custom form-check-solid mt-2">
                    <input className="form-check-input"
                           type="checkbox" value=""
                           id={`portChecked_${index}`}
                           defaultChecked={item.portRange}
                           onChange={async (e) => {
                               formik.setFieldValue(port, '')
                               formik.setFieldValue(portStart, '')
                               formik.setFieldValue(portEnd, '')
                               await formik.handleChange(e)
                               setTimeout(() => {
                                   formik.setFieldError(port, '')
                                   formik.setFieldError(portStart, '')
                                   formik.setFieldError(portEnd, '')
                               }, 10)


                           }}
                           name={portRange}
                    />
                    <label className="form-check-label"
                           htmlFor={`portChecked_${index}`}>
                        {
                            item.portRange
                                ? intl.formatMessage({id: "ORGANISATION.MODAL.RULE.INPUT.PORT_RANGE"})
                                : intl.formatMessage({id: "ORGANISATION.MODAL.RULE.INPUT.PORT"})
                        }
                    </label>
                </div>
            </td>
            {
                !item.portRange &&
                <td>
                    <FormInput
                        inputType='number'
                        placeholderResourceName={"ORGANISATION.MODAL.RULE.INPUT.PORT"}
                        defaultValue={item.port?.toString()}
                        name={port}
                        onChange={formik.handleChange}
                    />

                    <FormValidation
                        errorMessage={errorPort}
                        condition={errorPort}
                        propertyResourceName={"ORGANISATION.MODAL.RULE.INPUT.PORT_START"}
                    />
                </td>
            }
            {
                item.portRange &&
                <>
                    <td>
                        <table className="w-100">
                            <tr className="line-h-100px">
                                <td className="pe-2">
                                    <FormInput
                                        inputType='number'
                                        placeholderResourceName={"ORGANISATION.MODAL.RULE.INPUT.PORT_START"}
                                        defaultValue={item.portStart?.toString()}
                                        name={portStart}
                                        onChange={formik.handleChange}
                                    />
                                </td>
                                <td className="ps-2">
                                    <FormInput
                                        inputType='number'
                                        placeholderResourceName={"ORGANISATION.MODAL.RULE.INPUT.PORT_END"}
                                        defaultValue={item.portEnd?.toString()}
                                        name={portEnd}
                                        onChange={formik.handleChange}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2}>
                                    <>
                                        <FormValidation
                                            errorMessage={errorPortStart}
                                            condition={errorPortStart}
                                            propertyResourceName={"ORGANISATION.MODAL.RULE.INPUT.PORT_START"}
                                        />
                                        <FormValidation
                                            errorMessage={errorPortEnd}
                                            condition={errorPortEnd}
                                            propertyResourceName={"ORGANISATION.MODAL.RULE.INPUT.PORT_END"}
                                        />
                                    </>
                                </td>
                            </tr>
                        </table>
                    </td>
                </>
            }
            <td>
                <FormInput
                    inputType='text'
                    placeholderResourceName={"ORGANISATION.MODAL.RULE.INPUT.DESCRIPTION"}
                    defaultValue={item.description?.toString()}
                    name={description}
                    onChange={formik.handleChange}
                />
                <FormValidation
                    errorMessage={errorDescription}
                    condition={errorDescription}
                    propertyResourceName={"ORGANISATION.MODAL.RULE.INPUT.DESCRIPTION"}
                />
            </td>
            <td>
                {
                    (formik.values.ports.length > 1 ||
                        item.portType || item.port || item.portStart || item.portEnd || item.description)
                    &&
                    <span className="cursor-pointer mt-3 d-block" onClick={() => removeRow(formik, item.rowId)}>
                        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 352 512" fill="red"><path d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"/></svg>
                    </span>
                }
            </td>
        </tr>
    )
}