import axios, {AxiosResponse} from "axios";
import {PackagesModel} from "./models/PackagesModel";
import {PaymentModel} from "./models/PaymentModel";
import {SubscriptionStateModelBillingInfo, SubscriptionStateModelInfo} from "./models/SubscriptionStateModel";

const getPackages = async () => axios.get<PackagesModel[], AxiosResponse<PackagesModel[]>>(`/packages`)

const buyPackage = async ({organisationId, packageId, priceLookupKey}: { organisationId: string, packageId: string, priceLookupKey: string }) =>
    axios.post<PaymentModel, AxiosResponse<PaymentModel>>(`organisations/${organisationId}/payment/subscription`, {
        packageId,
        lookupKeys: [priceLookupKey]
    })

const successPayment = async ({organisationId, session}: { organisationId: string, session: string }) =>
    axios.post(`/organisations/${organisationId}/payment/success`, {session})

const cancelSubscription = async ({organisationId}: { organisationId: string }) =>
    axios.post(`/organisations/${organisationId}/payment/cancel-subscription`)

const reactivateSubscription = async ({organisationId}: { organisationId: string }) =>
    axios.post(`/organisations/${organisationId}/payment/activate-subscription`)

const getSubscriptionInfo = async ({organisationId}: { organisationId: string }) =>
    axios.get<SubscriptionStateModelInfo, AxiosResponse<SubscriptionStateModelInfo>>(`/organisations/${organisationId}/subscription/info`)

const getBillingInfo = async ({organisationId}: { organisationId: string }) =>
    axios.get<SubscriptionStateModelBillingInfo, AxiosResponse<SubscriptionStateModelBillingInfo>>(`/organisations/${organisationId}/payment/billing/info`)

const editBillingInfo = async ({organisationId}: { organisationId: string }) =>
    axios.post(`/organisations/${organisationId}/payment/billing/edit`)

export const subscriptionApis = {
    getPackages,
    buyPackage,
    successPayment,
    cancelSubscription,
    reactivateSubscription,
    getSubscriptionInfo,
    getBillingInfo,
    editBillingInfo
}
