import {useIntl} from "react-intl";
import {PageTitle} from "../../../../_metronic/layout/core";
import React, {useEffect, useState} from "react";
import clsx from "clsx";
import {toastify} from "../../../libs/toastify";
import {FormActionButtons, FormInputGroup, FormSelectItem} from "../../../components/forms";
import {Form, Formik} from "formik";
import {ActionResponse} from "../../../infrastructure/models/actionResponse";
import * as Yup from "yup";
import {accountActions} from "./../";
import {DateFormats, TimeFormats} from "../../../../_metronic/i18n/Metronici18n";
import {FormLoader} from "../../../components/loaders";
import {authActions} from "../../auth/";
import {useAppDispatch} from "../../../infrastructure/hooks/reduxHooks";
import {addValidationErrors} from "../../../infrastructure/utils/formikUtils";

export const ProfilePage = () => {

    const dispatch = useAppDispatch()
    const [loading, setLoading] = useState(false)
    const [pageLoading, setPageLoading] = useState(true)
    const intl = useIntl()

    const [countries, setCountries] = useState<FormSelectItem[]>([]);
    const [timezones, setTimeZones] = useState<FormSelectItem[]>([]);

    const [formValues, setFormValues] = useState({
        firstName: '',
        lastName: '',
        countryId: null,
        timeFormat: '',
        dateFormat: '',
        timeZone: ''
    });

    const fillLookupData = async () => {
        const timeZoneResponse = await accountActions.getTimeZones()
        const countryResponse = await accountActions.getCountries()

        if (timeZoneResponse.isSucceed && countryResponse.isSucceed) {
            const countrySelectItems = countryResponse.data.map(x => {
                return {
                    value: x.id,
                    text: x.fullName
                } as FormSelectItem
            });

            setCountries(countrySelectItems)

            const timeZoneSelectItems = timeZoneResponse.data.map(x => {
                return {
                    value: x.id,
                    text: x.name
                } as FormSelectItem
            });

            setTimeZones(timeZoneSelectItems)

        }
    }

    useEffect(() => {
        (async () => {
            await fillLookupData();

            const userResponse = await accountActions.getProfile();

            if (userResponse.isSucceed) {
                const data = userResponse.data;
                setFormValues({
                    firstName: data.firstName,
                    lastName: data.lastName,
                    timeFormat: data.timeFormat,
                    dateFormat: data.dateFormat,
                    timeZone: data.timeZone,
                    countryId: data.countryId
                })

                setPageLoading(false)
            }
        })()
    }, [])

    const validationSchema = Yup.object().shape({
        firstName: Yup.string()
            .min(2, intl.formatMessage({id: 'VALIDATION.MIN_LENGTH'}, {length: 2}))
            .max(30, intl.formatMessage({id: 'VALIDATION.MAX_LENGTH'}, {length: 30}))
            .required(intl.formatMessage({id: 'VALIDATION.REQUIRED'}, {name: intl.formatMessage({id: 'ACCOUNT.PROFILE.INPUT.FIRST_NAME'})})),
        lastName: Yup.string()
            .min(2, intl.formatMessage({id: 'VALIDATION.MIN_LENGTH'}, {length: 2}))
            .max(30, intl.formatMessage({id: 'VALIDATION.MAX_LENGTH'}, {length: 30}))
            .required(intl.formatMessage({id: 'VALIDATION.REQUIRED'}, {name: intl.formatMessage({id: 'ACCOUNT.PROFILE.INPUT.LAST_NAME'})})),
    })

    return (
        <>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'ACCOUNT.PROFILE.TITLE'})}</PageTitle>

            {pageLoading && <FormLoader/>}

            {!pageLoading &&
                <Formik
                    initialValues={formValues}
                    enableReinitialize={true}
                    validationSchema={validationSchema}
                    onSubmit={async (values, {setStatus, setSubmitting, setFieldError}) => {

                        setLoading(true)
                        setTimeout(async () => {

                            const response: ActionResponse = await accountActions.updateProfile({
                                firstName: values.firstName,
                                lastName: values.lastName,
                                timeFormat: values.timeFormat,
                                dateFormat: values.dateFormat,
                                timeZone: values.timeZone,
                                countryId: values.countryId
                            })

                            if (response.isSucceed) {
                                toastify.success(intl.formatMessage({id: "ACCOUNT.PROFILE.MESSAGE.SUCCESS"}));
                                await dispatch(authActions.refreshUserAndAccessToken()).unwrap();
                                setSubmitting(false)
                            } else {
                                addValidationErrors(setFieldError, response.errors)
                                setSubmitting(false)
                            }
                            setLoading(false)
                        }, 500)
                    }}
                >
                    {(formik) => (
                        <Form className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'>
                            <div className='text-center mb-10'>
                                <h1 className='text-dark mb-3'>{intl.formatMessage({id: 'ACCOUNT.PROFILE.TITLE'})}</h1>
                            </div>

                            {formik.status && (
                                <div
                                    className={clsx('alert mb-5', formik.status.isSucceed ? 'alert-success' : 'alert-danger')}>
                                    {formik.status.message}
                                </div>
                            )}

                            <div className="row">
                                <div className="col-xl-6">
                                    <FormInputGroup
                                        labelResourceName={"ACCOUNT.PROFILE.INPUT.FIRST_NAME"}
                                        inputType={"text"}
                                        fieldProps={formik.getFieldProps('firstName')}
                                        validationCondition={(formik.touched.firstName && formik.errors.firstName)}
                                        validationMessage={formik.errors.firstName}
                                    />
                                </div>
                                <div className="col-xl-6">
                                    <FormInputGroup
                                        labelResourceName={"ACCOUNT.PROFILE.INPUT.LAST_NAME"}
                                        inputType={"text"}
                                        fieldProps={formik.getFieldProps('lastName')}
                                        validationCondition={(formik.touched.lastName && formik.errors.lastName)}
                                        validationMessage={formik.errors.lastName}
                                    />
                                </div>
                            </div>


                            <FormInputGroup
                                labelResourceName={"ACCOUNT.PROFILE.INPUT.COUNTRY"}
                                inputType={"select"}
                                selectItems={countries}
                                fieldProps={formik.getFieldProps('countryId')}
                                validationCondition={(formik.touched.countryId && formik.errors.countryId)}
                                validationMessage={formik.errors.countryId}
                            />

                            <FormInputGroup
                                labelResourceName={"ACCOUNT.PROFILE.INPUT.TIME_ZONE"}
                                inputType={"select"}
                                selectItems={timezones}
                                fieldProps={formik.getFieldProps('timeZone')}
                                validationCondition={(formik.touched.timeZone && formik.errors.timeZone)}
                                validationMessage={formik.errors.timeZone}
                            />

                            <FormInputGroup
                                labelResourceName={"ACCOUNT.PROFILE.INPUT.DATE_FORMAT"}
                                inputType={"select"}
                                selectItems={DateFormats}
                                fieldProps={formik.getFieldProps('dateFormat')}
                                validationCondition={(formik.touched.dateFormat && formik.errors.dateFormat)}
                                validationMessage={formik.errors.dateFormat}
                            />

                            <FormInputGroup
                                labelResourceName={"ACCOUNT.PROFILE.INPUT.TIME_FORMAT"}
                                inputType={"select"}
                                selectItems={TimeFormats}
                                fieldProps={formik.getFieldProps('timeFormat')}
                                validationCondition={(formik.touched.timeFormat && formik.errors.timeFormat)}
                                validationMessage={formik.errors.timeFormat}
                            />

                            <FormActionButtons
                                formik={formik}
                                loading={loading}
                                submitButtonResourceName={"ACCOUNT.PROFILE.BUTTON.SUBMIT"}
                                showCancelButton={false}
                                fullWidthButton={false}
                                buttonSize={"btn-lg"}
                            />
                        </Form>
                    )}
                </Formik>
            }
        </>
    )
}
            