import {FormModal} from "../../../../../../components/forms/FormModal";
import {FormInputGroup} from "../../../../../../components/forms/FormInputGroup";
import {useIntl} from "react-intl";
import {useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {FormCancelButton} from "../../../../../../components/forms/FormCancelButton";
import {FormSubmitButton} from "../../../../../../components/forms/FormSubmitButton";
import {SearchResult} from "./SearchResult";
import {toastify} from "../../../../../../libs/toastify";
import {usersActions, usersReducerActions, usersSelectors} from "../../../../subpages/users/";
import {useAsyncDebounce} from "react-table";
import {ThreeDotsLoader} from "../../../../../../components/loaders";
import {format} from "react-string-format";
import {useLocation, useNavigate, useNavigationType} from "react-router-dom";
import {organisationSelectors, organisationActions} from "../../../../";
import {useAppDispatch} from "../../../../../../infrastructure/hooks/reduxHooks";

export const InviteUsersModal = () => {
    const intl = useIntl();
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const location = useLocation();
    const modalState = useSelector(usersSelectors.getInviteUsersModalState())
    const organisationId = useSelector(organisationSelectors.getSelectedOrganisationId()) as string
    const searchResult = useSelector(usersSelectors.getInviteUsersSearchResult())
    const [emailsCount, setEmailsCount] = useState(0);
    const [isEmailsValid, setIsEmailsValid] = useState(true);
    const [loading, setLoading] = useState(false)
    const [searchLoading, setSearchLoading] = useState(false)

    const closeModal = () => {
        dispatch(usersActions.showInviteUsersModal({state: false}))

        if (location.pathname.split('/').slice(-1).includes("users")) {
            dispatch(usersActions.refreshOrganisationUsersTable())
        }
    }

    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/

    const searchUser = useAsyncDebounce(async (email: string) => {
        const emails = email.split(/[\n;]/).filter(email => email.trim());

        for (const email of emails) {
            if (!emailRegex.test(email.trim())) {
                setIsEmailsValid(false);
                return;
            }
        }
        setIsEmailsValid(true)
        setEmailsCount(emails.length)
        setSearchLoading(true)
        dispatch(usersActions.searchUsersForInvite({organisationId, emails, currentResult: searchResult}))
    }, 500);

    const sendInvites = async (users: string[]) => {
        setLoading(true)
        const response = await dispatch(usersActions.sendUserInvites({organisationId, emails: searchResult})).unwrap()
        if (response.isSucceed) {
            closeModal()
            toastify.success(intl.formatMessage({id: "ORGANISATION.MODAL.INVITE_USERS.MESSAGE.INVITES_SENT"}))
        } else {
            setLoading(false)

            switch (response.message) {
                case "InviteLimitExceededPerRequest":
                    toastify.error(intl.formatMessage({id: "ORGANISATION.MODAL.INVITE_USERS.MESSAGE.MAX_INVITE_NUMBER_PER_REQUEST"}))
                    break;
                case "InviteLimitExceededPerUser":
                    toastify.error(intl.formatMessage({id: "ORGANISATION.MODAL.INVITE_USERS.MESSAGE.MAX_INVITE_NUMBER_PER_USER"}))
                    break;
                default:
                    toastify.error()
            }
        }
    }

    useEffect(() => {
        return () => {
            dispatch(usersReducerActions.setSearchResultForInvite([]))
        }
    }, [])

    useEffect(() => {
        setSearchLoading(false)
    }, [searchResult])

    return (
        <>
            <FormModal
                modalState={modalState}
                closeModal={closeModal}
                title={intl.formatMessage({id: "ORGANISATION.MODAL.INVITE_USERS.TITLE"})}
                subTitle={intl.formatMessage({id: "ORGANISATION.MODAL.INVITE_USERS.SUBTITLE"})}
            >
                <>
                    <div className="form">
                        <FormInputGroup
                            inputType="textarea"
                            labelResourceName="FORM.CREATE"
                            showLabel={false}
                            placeholderResourceName={"ORGANISATION.MODAL.INVITE_USERS.INPUT.EMAILS.PLACEHOLDER"}
                            informationMessage={isEmailsValid ? `${intl.formatMessage({id: "ORGANISATION.MODAL.INVITE_USERS.MESSAGE.EMAIL_COUNT"})} ${emailsCount}` : ""}
                            validationCondition={!isEmailsValid && "ok"}
                            validationMessage={intl.formatMessage({id: "ERROR.INVALID_EMAIL"})}
                            onKeyUp={(e: React.KeyboardEvent<HTMLTextAreaElement>) => searchUser(e.currentTarget?.value)}
                            focus={true}
                        />
                    </div>
                    {searchLoading && <ThreeDotsLoader/>}
                    {(!searchLoading && isEmailsValid) && <SearchResult/>}
                    <div className="d-flex flex-center mt-15">
                        <FormCancelButton onClick={closeModal}/>
                        <FormSubmitButton
                            loading={loading}
                            disabled={searchResult.length === 0 || !isEmailsValid}
                            onClick={() => sendInvites(searchResult.map((r: any) => r.email))}
                            buttonResourceName={"ORGANISATION.MODAL.INVITE_USERS.BUTTON.SEND_INVITES"}
                        />
                    </div>
                </>
            </FormModal>
        </>
    )
}