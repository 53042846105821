import {FormModal} from "../../../../components/forms/FormModal";
import {FormInputGroup} from "../../../../components/forms/FormInputGroup";
import {FormActionButtons} from "../../../../components/forms/FormActionButtons";
import {Form, Formik, useFormik} from "formik";
import * as Yup from 'yup'
import {useIntl} from "react-intl";
import {MutableRefObject, useEffect, useRef, useState} from "react";
import {organisationActions, organisationSelectors} from "../../";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {format} from 'react-string-format';
import {ORGANISATION_DETAIL_URL} from "../../../../infrastructure/variables/urls";
import {addValidationErrors} from "../../../../infrastructure/utils/formikUtils";
import {toastify} from "../../../../libs/toastify";
import {useAppDispatch} from "../../../../infrastructure/hooks/reduxHooks";
import {authActions} from "../../../auth";
import {subscriptionReducerActions} from "../../subpages/subscription";
import {subscriptionActions} from "../../subpages/subscription/subscriptionActions";

export const CreateOrganisationModal = () => {

    const intl = useIntl()
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const modalState = useSelector(organisationSelectors.getCreateOrganisationModalState())

    const initialValues = {
        name: ''
    }

    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .min(3, intl.formatMessage({id: 'VALIDATION.MIN_LENGTH'}, {length: 3}))
            .max(75, intl.formatMessage({id: 'VALIDATION.MAX_LENGTH'}, {length: 30}))
            .required(intl.formatMessage({id: 'VALIDATION.REQUIRED'}, {name: intl.formatMessage({id: 'ORGANISATIONS.MODAL.CREATE_ORGANISATION.INPUT.NAME'})})),
    })

    const closeModal = () => {
        dispatch(organisationActions.showCreateOrganisationModal(false))
        setLoading(false)
    }

    const nameRef = useRef() as MutableRefObject<HTMLInputElement>;
    useEffect(() => {
        nameRef?.current && nameRef?.current?.focus()
    }, []);

    return (
        <>
            <FormModal
                modalState={modalState}
                closeModal={closeModal}
                title={intl.formatMessage({id: 'ORGANISATIONS.MODAL.CREATE_ORGANISATION.TITLE'})}
            >
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={async (values, {setSubmitting, setFieldError}) => {
                        setLoading(true);

                        setTimeout(async () => {
                            const response = await dispatch(organisationActions.createOrganisation({name: values.name})).unwrap()
                            if (response.isSucceed) {
                                await dispatch(authActions.refreshUserAndAccessToken()).unwrap();
                                setTimeout(async () => {
                                    closeModal()
                                    navigate(format(ORGANISATION_DETAIL_URL, response.data))
                                    dispatch(subscriptionActions.showFreemiumModal({}))
                                }, 500)
                            } else {
                                setLoading(false)
                                setSubmitting(false)
                                if (response.errors) {
                                    addValidationErrors(setFieldError, response.errors)
                                }
                            }
                        }, 500)
                    }}
                >
                    {(formik) => (
                        <Form className="form">
                            <FormInputGroup
                                labelResourceName={"ORGANISATIONS.MODAL.CREATE_ORGANISATION.INPUT.NAME"}
                                fieldProps={formik.getFieldProps('name')}
                                validationCondition={(formik.touched.name && formik.errors.name)}
                                validationMessage={formik.errors.name}
                                focus={true}
                            />

                            <FormActionButtons
                                formik={formik}
                                loading={loading}
                                closeModal={closeModal}
                                submitButtonResourceName={"FORM.CREATE"}
                            />
                        </Form>
                    )}
                </Formik>
            </FormModal>
        </>
    )
}