import {Card} from "react-bootstrap";
import {DataRequest, Table} from "../../../../../../components/tables/Table";
import React, {useEffect} from "react";
import {useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {organisationSelectors} from "../../../../";
import {OrganisationModel} from "../../../../models/OrganisationModel";
import * as urls from "../../../../../../infrastructure/variables/urls";
import {format} from "react-string-format";
import {swal} from "../../../../../../libs/sweetAlert";
import {
    ButtonWithDropdown,
    ButtonWithDropdownItem,
    ButtonWithDropdownTitle
} from "../../../../../../components/buttons/ButtonWithDropdown";
import {rolesActions, rolesReducerActions, rolesSelectors} from './../../'
import {RolesPageTitle} from "../../components/roles/RolesPageTitle";
import {toastify} from "../../../../../../libs/toastify";
import {formatMessage} from "../../../../../../../_metronic/i18n/Metronici18n";
import {useAppDispatch} from "../../../../../../infrastructure/hooks/reduxHooks";
import {destinationsActions} from "../../../destinations";

export const Roles = () => {

    const intl = useIntl()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const organisation = useSelector(organisationSelectors.getSelectedOrganisation()) as OrganisationModel
    const roles = useSelector(rolesSelectors.getRoles())

    const columns = [
        {
            header: "Id",
            accessor: "id",
        },
        {
            Header: intl.formatMessage({id: "ORGANISATION.ROLES.TABLE.COLUMN.NAME"}),
            accessor: "name",
            width: 'auto',
            Cell: ({cell}: any) => {
                const roleId = cell.row.original.id

                return (
                    <div className="cursor-pointer" onClick={() => navigate(format(urls.ORGANISATION_ROLE_USERS_URL, organisation.id, roleId))}>
                        <span className="bullet bullet-vertical h-25px float-start me-3"
                              style={{backgroundColor: cell.row.original.color}}></span>
                        <span className="mt-1 float-start">{cell.row.original.name}</span>
                    </div>
                )
            }
        },
        {
            Header: intl.formatMessage({id: "TABLE.COLUMN.ACTIONS"}),
            Cell: ({cell}: any) => {

                const roleId = cell.row.original.id

                return (
                    <ButtonWithDropdown
                        text={intl.formatMessage({id: 'TABLE.ACTIONS.BUTTON.VIEW'})}
                        linkTo={format(urls.ORGANISATION_ROLE_USERS_URL, organisation.id, roleId)}
                    >
                        <ButtonWithDropdownTitle text={intl.formatMessage({id: 'TABLE.ACTIONS.TITLE.ACTIONS'})}/>
                        <ButtonWithDropdownItem
                            text={intl.formatMessage({id: 'TABLE.ACTIONS.BUTTON.EDIT'})}
                            onClick={() => editRole(roleId)}
                        />
                        <ButtonWithDropdownItem
                            text={intl.formatMessage({id: 'ORGANISATION.ROLES.TABLE.ACTIONS.BUTTON.DELETE'})}
                            type='danger'
                            linkTo=""
                            onClick={async (e) => {
                                e.currentTarget.style.pointerEvents = "none"
                                const deletableResponse = await rolesActions.checkRoleDeletable(organisation.id, roleId)

                                if (deletableResponse.data) {
                                    const result = await swal.question(
                                        intl.formatMessage({id: 'SWEETALERT.MESSAGE.CONFIRM'}),
                                        intl.formatMessage({id: 'ORGANISATION.ROLES.TABLE.MESSAGE.DELETE_CONFIRM'})
                                    )
                                    if (result.isConfirmed) {
                                        await deleteRole(roleId)
                                    } else {
                                        e.currentTarget.style.pointerEvents = ""
                                    }
                                } else {
                                    const result = await swal.questionWithConfirm(
                                        intl.formatMessage({id: 'SWEETALERT.MESSAGE.CONFIRM'}),
                                        intl.formatMessage({id: 'ORGANISATION.ROLES.TABLE.MESSAGE.DELETE_CONFIRM_FORCE'})
                                    )
                                    if (result.isConfirmed) {
                                        await deleteRole(roleId)
                                    } else {
                                        e.currentTarget.style.pointerEvents = ""
                                    }
                                }

                            }}
                        />
                    </ButtonWithDropdown>
                )
            }
        }
    ]

    const toolbar = () => {
        return (
            <>
                <button type="button" className="btn btn-sm btn-light-primary" onClick={newRole}>
                    <span className="svg-icon svg-icon-3">
												<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                     viewBox="0 0 24 24" fill="none">
													<rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5"
                                                          fill="currentColor"></rect>
													<rect x="10.8891" y="17.8033" width="12" height="2" rx="1"
                                                          transform="rotate(-90 10.8891 17.8033)"
                                                          fill="currentColor"></rect>
													<rect x="6.01041" y="10.9247" width="12" height="2" rx="1"
                                                          fill="currentColor"></rect>
												</svg>
											</span>
                    {intl.formatMessage({id: 'ORGANISATION.ROLES.TABLE.TOOLBAR.BUTTON.NEW_ROLE'})}
                </button>
            </>
        )
    }

    const getRoles = (dataRequest: DataRequest | undefined = undefined) => {
        dispatch(rolesActions.getRoles({organisationId: organisation.id, dataRequest}))
    }

    const newRole = async () => {
        dispatch(rolesActions.showOrganisationRoleModal({state: true, refreshTableWhenModalClosed: true}))
    }

    const editRole = async (roleId: string) => {
        dispatch(rolesActions.showOrganisationRoleModal({state: true, roleId: roleId, refreshTableWhenModalClosed: true}))
    }

    const deleteRole = async (roleId: string) => {
        const response = await rolesActions.deleteRole(organisation.id, roleId)
        if (response.isSucceed) {
            dispatch(rolesActions.refreshUserRolesTable())
            toastify.success(intl.formatMessage({id: 'ORGANISATION.ROLES.TABLE.MESSAGE.ROLE_DELETED'}))
        }
    }

    // tab tekrar acildiginda yeniden loading animasyonunu gostermek icin
    useEffect(() => {
            return () => {
                dispatch((rolesReducerActions.setRoles(null)))
            }
        }
        , [])

    return (
        <>
            <RolesPageTitle/>
            <Card>
                <Card.Body>
                    <Table
                        columns={columns}
                        dataContext={roles}
                        events={{
                            getData: getRoles,
                            goToPage: getRoles,
                            refreshState: useSelector(rolesSelectors.getRolesTableRefreshState())
                        }}
                        toolbar={toolbar()}
                    />
                </Card.Body>
            </Card>
        </>
    )
}