import React, {ReactNode} from "react";
import {AccordionItem} from "@szhsin/react-accordion";
import clsx from "clsx";

export const GuideAccordionItem = ({title, children}: { title: string, children: ReactNode }) => {
    return (
        <AccordionItem header={({state}) => {
            return (
                <div className="card bg-light mb-3">
                    <div className="accordion-header m-3 d-flex">
                        <h3 className="fs-5 text-gray-800 fw-bold mb-0 ms-1">
                                                    <span
                                                        className={clsx("fw-bold", state.isEnter ? "" : "text-gray-600")}>{title}</span>
                        </h3>
                    </div>
                </div>
            )
        }
        }>
            <div className="p-1 fs-5 mb-3">
                <div className="card card-bordered p-5">
                    {children}
                </div>
            </div>
        </AccordionItem>
    )
}