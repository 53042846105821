import React from 'react'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router'
import clsx from 'clsx'
import {checkIsActive, KTSVG} from '../../../helpers'

type MenuItemProps = {
    to: string
    title: string
    icon?: string
    fontIcon?: string
    hasArrow?: boolean
    hasBullet?: boolean
    type?: 'link' | 'anchor_blank'
}

const MenuItem: React.FC<MenuItemProps> = ({
                                               to,
                                               title,
                                               icon,
                                               fontIcon,
                                               hasArrow = false,
                                               hasBullet = false,
                                               type = 'link'
                                           }) => {
    const {pathname} = useLocation()

    return (
        <div className='menu-item me-lg-1'>
            {
                type == 'link' ?
                    <Link
                        className={clsx('menu-link py-3', {
                            active: checkIsActive(pathname, to),
                        })}
                        to={to}
                    >
                        <MenuItemTemplate
                            title={title}
                            icon={icon}
                            fontIcon={fontIcon}
                            hasArrow={hasArrow}
                            hasBullet={hasBullet}
                        />
                    </Link>
                    :
                    <a href={to} target="_blank"
                       className={clsx('menu-link py-3', {
                           active: checkIsActive(pathname, to),
                       })}
                    >
                        <MenuItemTemplate
                            title={title}
                            icon={icon}
                            fontIcon={fontIcon}
                            hasArrow={hasArrow}
                            hasBullet={hasBullet}
                        />
                    </a>
            }
        </div>
    )
}

type MenuItemTemplateProps = {
    title: string
    icon?: string
    fontIcon?: string
    hasArrow?: boolean
    hasBullet?: boolean
}

const MenuItemTemplate: React.FC<MenuItemTemplateProps> = ({
                                                               title,
                                                               icon,
                                                               fontIcon,
                                                               hasArrow = false,
                                                               hasBullet = false,
                                                           }) => {
    return (
        <>
            {hasBullet && (
                <span className='menu-bullet'>
            <span className='bullet bullet-dot'></span>
          </span>
            )}

            {icon && (
                <span className='menu-icon'>
            <KTSVG path={icon} className='svg-icon-2'/>
          </span>
            )}

            {fontIcon && (
                <span className='menu-icon'>
            <i className={clsx('bi fs-3', fontIcon)}></i>
          </span>
            )}

            <span className='menu-title'>{title}</span>

            {hasArrow && <span className='menu-arrow'></span>}
        </>
    )
}

export {MenuItem}
