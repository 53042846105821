import axios, {AxiosResponse} from "axios";
import {DataRequest, DataResponse} from "../../../../components/tables";
import {CreateCmsExtensionRuleModel, RuleModel, RulesModel, UpdateAgentRuleModel, UpdateCmsExtensionRuleModel, CreateAgentRuleModel} from "./models";
import {RolesModel} from "../roles/models";
import {DestinationsModel, DestinationTypeEnum} from "../destinations/models";
import {CreateSoftwarePackageRuleModel} from "./models/CreateSoftwarePackageRuleModel";
import {UpdateSoftwarePackageRuleModel} from "./models/UpdateSoftwarePackageRuleModel";

const getRules = async ({
                            organisationId,
                            destinationId,
                            type = undefined,
                            dataRequest = undefined
                        }: { organisationId: string, destinationId?: string, type?: DestinationTypeEnum | undefined, dataRequest: DataRequest | undefined }) => {

    const params = {
        searchText: dataRequest?.searchText,
        page: dataRequest?.page ?? 1,
        pageSize: dataRequest?.pageSize ?? 10
    } as any

    if (destinationId) {
        params[`customFilter[0].name`] = "destinationId"
        params[`customFilter[0].value`] = destinationId
    }

    if (type) {
        params[`customFilter[0].name`] = "type"
        params[`customFilter[0].value`] = type
    }

    return await axios.get<DataResponse<RulesModel>, AxiosResponse<DataResponse<RulesModel>>>(`/organisations/${organisationId}/rules`, {
        params
    })
}

const createAgentRule = async (model: CreateAgentRuleModel) => await axios.post(`/organisations/${model.organisationId}/rules/agent`, {
    name: model.name,
    roleIds: model.roleIds,
    destinationIds: model.destinationIds,
    ports: model.ports,
    isActive: true // ui destegi yok, eklenecek.
})

const createCmsExtensionRule = async (model: CreateCmsExtensionRuleModel) => await axios.post(`/organisations/${model.organisationId}/rules/cms-extension`, {
    name: model.name,
    roleIds: model.roleIds,
    destinationIds: model.destinationIds,
    isActive: true // ui destegi yok, eklenecek.
})

const createSoftwarePackageRule = async (model: CreateSoftwarePackageRuleModel) => await axios.post(`/organisations/${model.organisationId}/rules/software-package`, {
    name: model.name,
    roleIds: model.roleIds,
    destinationIds: model.destinationIds,
    isActive: true // ui destegi yok, eklenecek.
})

const updateAgentRule = async (model: UpdateAgentRuleModel) => await axios.patch(`/organisations/${model.organisationId}/rules/agent/${model.ruleId}`, {
    name: model.name,
    roleIds: model.roleIds,
    destinationIds: model.destinationIds,
    ports: model.ports,
    isActive: true // ui destegi yok, eklenecek.
})

const updateCmsExtensionRule = async (model: UpdateCmsExtensionRuleModel) => await axios.patch(`/organisations/${model.organisationId}/rules/cms-extension/${model.ruleId}`, {
    name: model.name,
    roleIds: model.roleIds,
    destinationIds: model.destinationIds,
    isActive: true // ui destegi yok, eklenecek.
})

const updateSoftwarePackageRule = async (model: UpdateSoftwarePackageRuleModel) => await axios.patch(`/organisations/${model.organisationId}/rules/software-package/${model.ruleId}`, {
    name: model.name,
    roleIds: model.roleIds,
    destinationIds: model.destinationIds,
    isActive: true // ui destegi yok, eklenecek.
})

const getRule = async ({
                           organisationId,
                           ruleId
                       }: {
    organisationId: string,
    ruleId: string
}) => await axios.get<RuleModel, AxiosResponse<RuleModel>>(`/organisations/${organisationId}/rules/${ruleId}`)

const getRoles = async ({organisationId}: {
    organisationId: string
}) => axios.get<DataResponse<RolesModel>, AxiosResponse<DataResponse<RolesModel>>>(`/organisations/${organisationId}/roles`, {
    params: {
        page: 1,
        pageSize: 500
    }
})

const getDestinations = async ({organisationId}: {
    organisationId: string
}) => await axios.get<DataResponse<DestinationsModel>, AxiosResponse<DataResponse<DestinationsModel>>>(`/organisations/${organisationId}/destinations`, {
    params: {
        page: 1,
        pageSize: 500
    }
})

const checkRuleDeletable = async ({
                                      organisationId,
                                      ruleId
                                  }: {
    organisationId: string,
    ruleId: string
}) => await axios.get<boolean, AxiosResponse<boolean>>(`/organisations/${organisationId}/rules/${ruleId}/check-deletable`)

const deleteRule = async ({
                              organisationId,
                              ruleId
                          }: { organisationId: string, ruleId: string }) => await axios.delete(`/organisations/${organisationId}/rules/${ruleId}`)

const activateRule = async ({
                                organisationId,
                                ruleId,
                                state
                            }: {
    organisationId: string,
    ruleId: string,
    state: boolean
}) => await axios.patch(`/organisations/${organisationId}/rules/${ruleId}/activate`, {state: state})

export const ruleApis = {
    getRules,
    createAgentRule,
    createCmsExtensionRule,
    createSoftwarePackageRule,
    updateAgentRule,
    updateCmsExtensionRule,
    updateSoftwarePackageRule,
    getRule,
    getRoles,
    getDestinations,
    checkRuleDeletable,
    deleteRule,
    activateRule
}