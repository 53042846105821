import Select from "react-select";
import React, {FC, ReactNode} from "react";
import {useIntl} from "react-intl";
import {FormatOptionLabelMeta} from "react-select/dist/declarations/src/Select";
import {FormGroup} from "./FormGroup";
import {FieldInputProps} from "formik";
import {FormReactSelect} from "./FormReactSelect";

interface Props {
    fieldProps: FieldInputProps<any>,
    showLabel?: boolean,
    labelResourceName: string,
    link?: PropsLink,
    informationMessage?: string,
    validationCondition?: string | false | undefined,
    validationMessage?: string,
    options: any,
    placeHolderResourceName?: string,
    isMulti?: boolean,
    maxSelectionLength?: number
    formatOptionLabel?: (data: any, formatOptionLabelMeta: FormatOptionLabelMeta<any>) => ReactNode,
    setFieldValue: any
}

interface PropsLink {
    textResourceName: string
    to: string,
}

export const FormGroupReactSelect: FC<Props> = ({
                                                    fieldProps,
                                                    link,
                                                    showLabel = true,
                                                    labelResourceName,
                                                    informationMessage,
                                                    validationCondition,
                                                    validationMessage,
                                                    options,
                                                    placeHolderResourceName,
                                                    isMulti = false,
                                                    maxSelectionLength,
                                                    formatOptionLabel,
                                                    setFieldValue
                                                }) => {

    const intl = useIntl()

    return (
        <FormGroup
            link={link}
            showLabel={showLabel}
            labelResourceName={labelResourceName}
            informationMessage={informationMessage}
            validationCondition={validationCondition}
            validationMessage={validationMessage}
        >
            <FormReactSelect
                fieldProps={fieldProps}
                options={options}
                setFieldValue={setFieldValue}
                formatOptionLabel={formatOptionLabel}
                isMulti={isMulti}
                maxSelectionLength={maxSelectionLength}
                placeHolderResourceName={placeHolderResourceName}
            />
        </FormGroup>

    )
}