import {Dispatch} from "redux";
import {organisationApis, organisationReducerActions} from "./";
import axios, {AxiosResponse} from "axios";
import {ActionResponse} from "../../infrastructure/models/actionResponse";
import {OrganisationModel} from "./models/OrganisationModel";
import {toastify} from "../../libs/toastify";
import {MyInvitationsModelInvitation} from "./models/MyInvitationsModel";
import {InviteResponse} from "../../infrastructure/enums/inviteResponse";
import {formatMessage} from "../../../_metronic/i18n/Metronici18n";
import {createAsyncThunk} from "@reduxjs/toolkit";
import {authActions} from "../auth";

// organisation

const showCreateOrganisationModal = (state: boolean = true) => async (dispatch: Dispatch) => {
    dispatch(organisationReducerActions.setShowCreateOrganisationModal(state));
}

// const setSelectedTab = (selectedTab: string) => async (dispatch: Dispatch) => {
//     dispatch(organisationReducerActions.setSelectedTab(selectedTab));
// }

const createOrganisation = createAsyncThunk(
    'organisation/createOrganisation',
    async ({name}: { name: string }, {dispatch, signal}) => {
        try {
            // todo burada internet yavasligi test edilecek organizasyon olusmadan yonlendirme yapilirsa ne olur?
            const response = await organisationApis.createOrganisation({name})
            dispatch(getOrganisations())
            return {
                isSucceed: true,
                data: response.data
            } as ActionResponse<string>
        } catch (e: any) {
            return {
                isSucceed: false,
                message: e.response?.data,
                errors: e.response?.data?.errors
            } as ActionResponse<string>
        }
    }
)

const updateOrganisationLogo = createAsyncThunk(
    'organisation/updateOrganisationLogo',
    async ({organisationId, logo}: { organisationId: string, logo: File }, {dispatch, signal}) => {
        try {
            const response = await organisationApis.updateOrganisationLogo({
                organisationId,
                logo
            })

            dispatch(organisationReducerActions.setSelectedOrganisationLogo(response.data));
            dispatch(organisationActions.getOrganisations())

            return {
                isSucceed: true
            }

        } catch (e: any) {
            return {
                isSucceed: false,
                message: e.response?.data,
                errors: e.response?.data?.errors
            }
        }
    }
)

const getOrganisations = createAsyncThunk(
    'organisation/getOrganisations',

    async (arg, {dispatch}) => {
        const response = await organisationApis.getOrganisations();
        dispatch(organisationReducerActions.setOrganisations(response.data))
    }
)

const deleteOrganisation = createAsyncThunk(
    'organisation/deleteOrganisation',

    async ({organisationId}: { organisationId: string }, {dispatch}) => {

        try {
            await organisationApis.deleteOrganisation({organisationId})
            dispatch(getOrganisations())
            return {
                isSucceed: true
            }
        } catch (e: any) {
            return {
                isSucceed: false,
                message: e.response?.data,
                errors: e.response?.data?.errors
            }
        }
    }
)

const showMyInvitationsModal = createAsyncThunk(
    'organisation/showMyInvitationsModal',
    async ({
               state = true,
               requestedFromExternalUrl = false
           }: { state: boolean, requestedFromExternalUrl: boolean }, {dispatch}) => {
        dispatch(organisationReducerActions.setMyInvitationsModalState({state, requestedFromExternalUrl}));
    })

const getMyInvitations = createAsyncThunk(
    'organisation/getMyInvitations',
    async (arg, {dispatch}) => {
        const response = await organisationApis.getOrganisationInvites()
        dispatch(organisationReducerActions.setMyInvitations(response.data))
    }
)

const responseMyInvitation = createAsyncThunk(
    'organisation/responseMyInvitation',
    async ({inviteId, response}: { inviteId: string, response: InviteResponse }, {dispatch}) => {
        await organisationApis.responseOrganisationInvites({inviteId, response})

        dispatch(getMyInvitations())
        dispatch(authActions.refreshUserAndAccessToken())
        dispatch(organisationActions.getOrganisations())

        switch (response) {
            case InviteResponse.Accept:
                toastify.success(formatMessage({id: 'ORGANISATIONS.MODAL.MY_INVITATIONS.MESSAGE.JOINED_THE_ORGANISATION'}))
                break;
            case InviteResponse.Reject:
                toastify.warning(formatMessage({id: 'ORGANISATIONS.MODAL.MY_INVITATIONS.MESSAGE.INVITE_REJECTED'}))
                break;
            case InviteResponse.BlockUser:
                toastify.warning(formatMessage({id: 'ORGANISATIONS.MODAL.MY_INVITATIONS.MESSAGE.USER_BLOCKED'}))
                break;
            case InviteResponse.BlockOrganisation:
                toastify.warning(formatMessage({id: 'ORGANISATIONS.MODAL.MY_INVITATIONS.MESSAGE.ORGANISATION_BLOCKED'}))
                break;
        }
    }
)


const updateOrganisationInfo = createAsyncThunk(
    'organisation/updateOrganisationInfo',
    async ({organisationId}: { organisationId: string }, {dispatch}) => {
        const response = await organisationApis.getOrganisation({organisationId})

        dispatch(organisationReducerActions.setOrganisationInfo({
            userCount: response.data.userCount,
            destinationCount: response.data.destinationCount
        }))
    }
)

const getOrganisation = createAsyncThunk(
    'organisation/getOrganisation',
    async (organisationId: string, {dispatch, signal}) => {
        const response = await organisationApis.getOrganisation({organisationId})
        if (signal.aborted) return;
        dispatch(organisationReducerActions.setSelectedOrganisation(response.data))
    }
)


export const 
    organisationActions =
    {
        createOrganisation,
        // setSelectedTab,
        getOrganisation,
        updateOrganisationLogo,
        showCreateOrganisationModal,
        getOrganisations,
        deleteOrganisation,
        showMyInvitationsModal,
        getMyInvitations,
        responseMyInvitation,
        updateOrganisationInfo
    }