import {cssTransition, toast} from 'react-toastify'
import {ToastOptions} from 'react-toastify/dist/types'
import {formatMessage} from '../../_metronic/i18n/Metronici18n'

const bounce = cssTransition({
    enter: 'animate__animated animate__pulse',
    exit: 'animate__animated animate__fadeOut',
})

const success = (message: string, options?: ToastOptions) => {
    let defaultOptions: ToastOptions = {
        position: 'top-right',
        theme: 'colored',
        transition: bounce,
        hideProgressBar: true,
        className: 'toastr-default',
        pauseOnHover: true,
        closeOnClick: true,
        style: {backgroundColor: '#50CD89'},
        bodyClassName: 'fw-bolder',
    }

    if (options) {
        defaultOptions = {...defaultOptions, ...options}
    }

    toast.success(message, defaultOptions)
}

const error = (message?: string, options?: ToastOptions) => {
    let defaultOptions: ToastOptions = {
        position: 'top-right',
        theme: 'colored',
        transition: bounce,
        hideProgressBar: true,
        className: 'toastr-default',
        pauseOnHover: true,
        closeOnClick: true,
        style: {backgroundColor: '#F1416C'},
        bodyClassName: 'fw-bolder',
    }

    if (options) {
        defaultOptions = {...defaultOptions, ...options}
    }

    if (message) {
        toast.error(message, defaultOptions)
    } else {
        defaultOptions.toastId = 'ERROR.ERROR_OCCURRED'
        toast.error(formatMessage({id: 'ERROR.ERROR_OCCURRED'}), defaultOptions)
    }
}

const warning = (message: string, options?: ToastOptions) => {
    let defaultOptions: ToastOptions = {
        position: 'top-right',
        theme: 'colored',
        transition: bounce,
        hideProgressBar: true,
        className: 'toastr-default',
        pauseOnHover: true,
        closeOnClick: true,
        style: {backgroundColor: '#FFC700'},
        bodyClassName: 'fw-bolder',
    }

    if (options) {
        defaultOptions = {...defaultOptions, ...options}
    }

    toast.warning(message, defaultOptions)
}

const info = (message: string, options?: ToastOptions) => {
    let defaultOptions: ToastOptions = {
        position: 'top-right',
        theme: 'colored',
        transition: bounce,
        hideProgressBar: true,
        className: 'toastr-default',
        pauseOnHover: true,
        closeOnClick: true,
        style: {backgroundColor: '#7239EA'},
        bodyClassName: 'fw-bolder',
    }

    if (options) {
        defaultOptions = {...defaultOptions, ...options}
    }

    toast.info(message, defaultOptions)
}

export const toastify = {
    success,
    error,
    warning,
    info,
}
