import {PageLink, PageTitle} from "../../../../_metronic/layout/core";
import {useIntl} from "react-intl";
import React from "react";
import {useSelector} from "react-redux";
import {organisationSelectors} from "../";

export const OrganisationPageTitle = () => {

    const intl = useIntl()
    const organisation = useSelector(organisationSelectors.getSelectedOrganisation())

    const breadcrumbs: Array<PageLink> = [
        {
            title: intl.formatMessage({id: "ORGANISATIONS.PAGE_TITLE"}),
            path: '', // urls.ORGANISATIONS_URL,
            isSeparator: false,
            isActive: false,
        },
        {
            title: '',
            path: '',
            isSeparator: true,
            isActive: false,
        }
    ]

    return (
        <PageTitle breadcrumbs={breadcrumbs}>{organisation.name}</PageTitle>
    )
}