import axios, {AxiosResponse} from "axios";
import {SearchResultModel, UsersModel} from "./models";
import {DataRequest, DataResponse} from "../../../../components/tables";

const searchUsersForInvite = async ({organisationId, emails}: { organisationId: string, emails: string[] }) =>
    await axios.post<SearchResultModel[], AxiosResponse<SearchResultModel[]>>(`/organisations/${organisationId}/users/invite/search`, emails)

const sendUserInvites = async ({organisationId, emails}: { organisationId: string, emails: SearchResultModel[] }) =>
    await axios.post<SearchResultModel[], AxiosResponse<SearchResultModel[]>>(`/organisations/${organisationId}/users/invite`, emails)

const getOrganisationUsers = async ({
                                        organisationId,
                                        dataRequest
                                    }: { organisationId: string, dataRequest: DataRequest | undefined }) => {
    let params = {
        searchText: dataRequest?.searchText,
        page: dataRequest?.page ?? 1,
        pageSize: dataRequest?.pageSize ?? 10
    } as any

    dataRequest?.customFilter?.map((filter, index) => {
        params[`customFilter[${index}].name`] = filter.name
        params[`customFilter[${index}].value`] = filter.value
    })

    return await axios.get<DataResponse<UsersModel>, AxiosResponse<DataResponse<UsersModel>>>(`/organisations/${organisationId}/users`, {
        params
    })
}

const removeOrganisationUser = async ({
                                          organisationId,
                                          organisationUserId
                                      }: { organisationId: string, organisationUserId: string }) =>
    await axios.delete(`/organisations/${organisationId}/users/${organisationUserId}`)


const updateUserType = async ({
                                  organisationId,
                                  organisationUserId,
                                  userType
                              }: { organisationId: string, organisationUserId: string, userType: string }) =>
    await axios.patch(`/organisations/${organisationId}/users/${organisationUserId}/user-type`, {
        userType: userType
    })


export const usersApis = {
    searchUsersForInvite,
    sendUserInvites,
    getOrganisationUsers,
    removeOrganisationUser,
    updateUserType
}