import {Link, useLocation} from "react-router-dom";
import clsx from "clsx";
import {FC} from "react";

type Props = {
    tabs: NavigationItem[]
}

interface NavigationItem {
    text: string
    url: string,
    svg: any,
    hasSubPages: boolean
}

export const Navigation: FC<Props> = ({tabs}) => {
    return (
        <ul className="nav nav-pills nav-pills-custom flex-column border-transparent fs-5 fw-bold">
            {
                tabs.map(tab => {
                    return (
                        <NavigationItem key={tab.text} text={tab.text} svg={tab.svg} url={tab.url} hasSubPages={tab.hasSubPages} />
                    )
                })
            }
        </ul>
    )
}

const NavigationItem: FC<NavigationItem> = ({text, url, svg, hasSubPages}) => {

    const location = useLocation()

    return (
        <li className="nav-item mt-5">
            <Link to={url}
                  className={clsx('nav-link text-muted ms-0 py-0 me-10 ps-9 border-0',
                      ((!hasSubPages && location.pathname === url) || (hasSubPages && location.pathname.startsWith(url))) && 'text-active-primary active')}>
                    {/*<span className="svg-icon svg-icon-3 svg-icon-muted me-3">*/}
                    {/*    {svg}*/}
                    {/*</span>*/}
                    {text}
                    <span className="bullet-custom position-absolute start-0 top-0 w-3px h-100 bg-primary rounded-end" />
            </Link>
        </li>
    )
}