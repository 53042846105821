import {RootState} from "../../../../../setup";

const getRoleModalState = () => ({roles: {editRolesModal}}: RootState) => editRolesModal.show
const getRoleModalRoleId = () => ({roles: {editRolesModal}}: RootState) => editRolesModal.roleId
const getRoles = () => ({roles: {table}}: RootState) => table.data
const getRolesTableRefreshState = () => ({roles: {table}}: RootState) => table.refreshTable
const getOrganisationRoleDetailUsers = () => ({roles: {detail}}: RootState) => detail.usersTable.data
const getOrganisationRoleDetailUsersTableRefreshState = () => ({roles: {detail}}: RootState) => detail.usersTable.refreshTable
const getOrganisationRoleDetailInfo = () => ({roles: {detail}}: RootState) => detail.info
const getEditRolesModal = () => ({roles: {editRolesModal}}: RootState) => editRolesModal
const getEditUserRolesModalOrganisationUserId = () => ({roles: {editUserRoles}}: RootState) => editUserRoles.organisationUserId
const getEditUserRolesModalState = () => ({roles: {editUserRoles}}: RootState) => editUserRoles.showModal
const getEditUserRolesModalRoles = () => ({roles: {editUserRoles}}: RootState) => editUserRoles.roles

export const rolesSelectors =
    {
        getRoleModalState,
        getRoleModalRoleId,
        getRoles,
        getRolesTableRefreshState,
        getOrganisationRoleDetailUsers,
        getOrganisationRoleDetailUsersTableRefreshState,
        getOrganisationRoleDetailInfo,
        getEditRolesModal,
        getEditUserRolesModalOrganisationUserId,
        getEditUserRolesModalState,
        getEditUserRolesModalRoles
    }