export const AUTH_LOGIN_PATH = "login"
export const AUTH_LOGIN_URL = "/auth/login"

export const ROOT_URL = "/"

export const WELCOME_URL = "/welcome"

export const ACCOUNT_PROFILE = "/account/profile"
export const ACCOUNT_CHANGE_PASSWORD = "/account/change-password"

export const ORGANISATIONS_URL = "/organisations"
export const ORGANISATION_DETAIL_URL = "/organisation/{0}" // {0}: organisationId


export const ORGANISATION_DASHBOARD_URL = "/organisation/{0}/dashboard" // {0}: organisationId
export const ORGANISATION_RULES_URL = "/organisation/{0}/rules" // {0}: organisationId
export const ORGANISATION_ROLES_URL = "/organisation/{0}/roles" // {0}: organisationId
export const ORGANISATION_ROLE_USERS_URL = "/organisation/{0}/roles/{1}" // {0}: organisationId, {1}: organisationRoleId

export const USERS_URL = "/organisation/{0}/users" // {0}: organisationId

export const ORGANISATION_DESTINATIONS_URL = "/organisation/{0}/destinations/" // {0}: organisationId,
export const ORGANISATION_DESTINATION_URL = "/organisation/{0}/destinations/{1}" // {0}: organisationId, {1}: destinationId
export const ORGANISATION_DESTINATION_AGENTS_URL = "/organisation/{0}/agents" // {0}: organisationId
export const ORGANISATION_DESTINATION_AGENTS_RULES_URL = "/organisation/{0}/agents/rules" // {0}: organisationId
export const ORGANISATION_DESTINATION_AGENT_DETAIL_URL = "/organisation/{0}/agents/{1}" // {0}: organisationId, {1}: destinationId
export const ORGANISATION_DESTINATION_CMS_URL = "/organisation/{0}/cms" // {0}: organisationId
export const ORGANISATION_DESTINATION_CMS_DETAIL_URL = "/organisation/{0}/cms/{1}" // {0}: organisationId, {1}: destinationId
export const ORGANISATION_DESTINATION_SOFTWARE_PACKAGES_URL = "/organisation/{0}/software-packages" // {0}: organisationId
export const ORGANISATION_DESTINATION_SOFTWARE_PACKAGE_DETAIL_URL = "/organisation/{0}/software-packages/{1}" // {0}: organisationId, {1}: destinationId

export const ERROR_401_URL = "/auth/login"
export const ERROR_403_URL = "/error/403"
export const ERROR_404_URL = "/error/404"
export const ERROR_500_URL = "/error/500"