import {combineReducers} from 'redux'
import {authReducer} from "../../app/pages/auth/authSlice";
import {devicesReducer} from "../../app/pages/devices/devicesSlice";
import {organisationReducer} from "../../app/pages/organisation/";
import {usersReducer} from "../../app/pages/organisation/subpages/users/";
import {rolesReducer} from "../../app/pages/organisation/subpages/roles";
import {rulesReducer} from "../../app/pages/organisation/subpages/rules/";
import {destinationsReducer} from "../../app/pages/organisation/subpages/destinations";
import {subscriptionReducer} from "../../app/pages/organisation/subpages/subscription";

export const rootReducer = combineReducers({
    auth: authReducer,
    devices: devicesReducer,
    organisation: organisationReducer,
    users: usersReducer,
    roles: rolesReducer,
    rules: rulesReducer,
    destinations: destinationsReducer,
    subscription: subscriptionReducer
})

// export type RootState = ReturnType<typeof store.getState>
export type RootState = ReturnType<typeof rootReducer>
