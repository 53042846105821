import axios, {AxiosResponse} from "axios";
import {DataRequest, DataResponse} from "../../../../components/tables";
import {
    AgentModel, AgentScriptModel,
    CreateAgentModel,
    CreateAgentModelResponse,
    DestinationsModel,
    UpdateDestinationModel
} from "./models";
import {DestinationSettingsModel} from "./models/DestinationSettingsModel";
import {CreateCmsExtensionModel, CreateCmsExtensionModelResponse} from "./models/CreateCmsExtensionModel";
import {CreateSoftwarePackageModel, CreateSoftwarePackageModelResponse} from "./models/CreateSoftwarePackageModel";

const getAgents = async ({
                                   organisationId,
                                   dataRequest = undefined
                               }: { organisationId: string, dataRequest: DataRequest | undefined }) =>
    await axios.get<DataResponse<DestinationsModel>, AxiosResponse<DataResponse<DestinationsModel>>>
    (`/organisations/${organisationId}/destinations/agents`, {
        params: {
            searchText: dataRequest?.searchText,
            page: dataRequest?.page ?? 1,
            pageSize: dataRequest?.pageSize ?? 10
        }
    });

const getCms = async ({
                                   organisationId,
                                   dataRequest = undefined
                               }: { organisationId: string, dataRequest: DataRequest | undefined }) =>
    await axios.get<DataResponse<DestinationsModel>, AxiosResponse<DataResponse<DestinationsModel>>>
    (`/organisations/${organisationId}/destinations/cms`, {
        params: {
            searchText: dataRequest?.searchText,
            page: dataRequest?.page ?? 1,
            pageSize: dataRequest?.pageSize ?? 10
        }
    });


const getSoftwarePackages = async ({
                                   organisationId,
                                   dataRequest = undefined
                               }: { organisationId: string, dataRequest: DataRequest | undefined }) =>
    await axios.get<DataResponse<DestinationsModel>, AxiosResponse<DataResponse<DestinationsModel>>>
    (`/organisations/${organisationId}/destinations/software-packages`, {
        params: {
            searchText: dataRequest?.searchText,
            page: dataRequest?.page ?? 1,
            pageSize: dataRequest?.pageSize ?? 10
        }
    });


const createAgent = async ({model}: {
    model: CreateAgentModel
}) => await axios.post<CreateAgentModelResponse, AxiosResponse<CreateAgentModelResponse>>(`/organisations/${model.organisationId}/destinations/agents`, {
    organisationId: model.organisationId,
    name: model.name,
    type: model.type,
    ipAddress: model.ipAddress ?? null
})

const createCmsExtension = async ({model}: {
    model: CreateCmsExtensionModel
}) => await axios.post<CreateCmsExtensionModelResponse, AxiosResponse<CreateCmsExtensionModelResponse>>(`/organisations/${model.organisationId}/destinations/cms-extensions`, {
    organisationId: model.organisationId,
    name: model.name,
    userIds: model.userIds,
    emails: model.emails
})

const createSoftwarePackage = async ({model}: {
    model: CreateSoftwarePackageModel
}) => await axios.post<CreateSoftwarePackageModelResponse, AxiosResponse<CreateSoftwarePackageModelResponse>>(`/organisations/${model.organisationId}/destinations/software-packages`, {
    organisationId: model.organisationId,
    name: model.name
})

const getDestination = async ({organisationId, destinationId}: { organisationId: string, destinationId: string }) =>
    await axios.get<AgentModel, AxiosResponse<AgentModel>>(`/organisations/${organisationId}/destinations/${destinationId}`)

const updateDestination = async ({model}: { model: UpdateDestinationModel }) =>
    await axios.patch(`/organisations/${model.organisationId}/destinations/${model.destinationId}`, {
        name: model.name,
        ipAddress: model.ipAddress ?? null
    });

const checkOnline = async ({organisationId, destinationId}: { organisationId: string, destinationId: string }) =>
    await axios.get<boolean, AxiosResponse<boolean>>(`/organisations/${organisationId}/destinations/${destinationId}/check-online`)

const checkDestinationName = async({organisationId, name}:{organisationId: string, name:string}) =>
    await axios.get<boolean, AxiosResponse<boolean>>(`/organisations/${organisationId}/destinations/check-name?name=${name}`)

const checkDestinationDeletable = async ({
                                             organisationId,
                                             destinationId
                                         }: { organisationId: string, destinationId: string }) =>
    await axios.get<boolean, AxiosResponse<boolean>>(`/organisations/${organisationId}/destinations/${destinationId}/check-deletable`)

const deleteDestination = async ({organisationId, destinationId}: { organisationId: string, destinationId: string }) =>
    await axios.delete(`/organisations/${organisationId}/destinations/${destinationId}`)

const getAgentScript = async ({organisationId, agentId}: {
    organisationId: string,
    agentId: string
}) => await axios.get<AgentScriptModel, AxiosResponse<AgentScriptModel>>(`/organisations/${organisationId}/destinations/agents/${agentId}/script`)

const getCmsExtensionToken = async ({organisationId, cmsExtensionId}: {
    organisationId: string,
    cmsExtensionId: string
}) => await axios.get<String, AxiosResponse<String>>(`/organisations/${organisationId}/destinations/cms-extensions/${cmsExtensionId}/token`)

const getSoftwarePackageToken = async ({organisationId, softwarePackageId}: {
    organisationId: string,
    softwarePackageId: string
}) => await axios.get<String, AxiosResponse<String>>(`/organisations/${organisationId}/destinations/software-packages/${softwarePackageId}/token`)

const getSettings = async ({organisationId, destinationId}: {
    organisationId: string,
    destinationId: string
}) => await axios.get<DestinationSettingsModel, AxiosResponse<DestinationSettingsModel>>(`/organisations/${organisationId}/destinations/${destinationId}/settings`)

const updateSettingsFailoverRdp = async ({organisationId, destinationId, enable}: { organisationId: string, destinationId: string, enable: boolean }) =>
    await axios.patch(`/organisations/${organisationId}/destinations/${destinationId}/settings/failover-rdp`, {
        enable
    });

const updateSettingsFailoverSsh = async ({organisationId, destinationId, enable}: { organisationId: string, destinationId: string, enable: boolean }) =>
    await axios.patch(`/organisations/${organisationId}/destinations/${destinationId}/settings/failover-ssh`, {
        enable
    });

const updateSettingsDocker = async ({organisationId, destinationId, enable}: { organisationId: string, destinationId: string, enable: boolean }) =>
    await axios.patch(`/organisations/${organisationId}/destinations/${destinationId}/settings/docker`, {
        enable
    });

const updateSettingsCloudflare = async ({organisationId, destinationId, enable}: { organisationId: string, destinationId: string, enable: boolean }) =>
    await axios.patch(`/organisations/${organisationId}/destinations/${destinationId}/settings/cloudflare`, {
        enable
    });

const updateSettingsGitHubActions = async ({organisationId, destinationId, enable}: { organisationId: string, destinationId: string, enable: boolean }) =>
    await axios.patch(`/organisations/${organisationId}/destinations/${destinationId}/settings/github-actions`, {
        enable
    });

export const destinationsApis = {
    getAgents,
    getCms,
    getSoftwarePackages,
    createAgent,
    createCmsExtension,
    createSoftwarePackage,
    getDestination,
    updateDestination,
    checkOnline,
    checkDestinationName,
    checkDestinationDeletable,
    deleteDestination,
    getAgentScript,
    getCmsExtensionToken,
    getSoftwarePackageToken,
    getSettings,
    updateSettingsFailoverRdp,
    updateSettingsFailoverSsh,
    updateSettingsDocker,
    updateSettingsCloudflare,
    updateSettingsGitHubActions
}