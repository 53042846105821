import {FormModal} from "../../../../../../components/forms/FormModal";
import {useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {organisationSelectors} from "../../../../";
import {FormInputGroup} from "../../../../../../components/forms/FormInputGroup";
import {FormActionButtons} from "../../../../../../components/forms/FormActionButtons";
import {Form, Formik, useFormik} from "formik";
import {ActionResponse} from "../../../../../../infrastructure/models/actionResponse";
import * as Yup from "yup";
import React, {useEffect, useState} from "react";
import {Color,} from "react-color";
import {ColorPicker} from "./ColorPicker";
import {toastify} from "../../../../../../libs/toastify";
import * as urls from "../../../../../../infrastructure/variables/urls";
import {useNavigate} from "react-router-dom";
import {rolesActions, rolesSelectors} from "../../../../subpages/roles/";
import {addValidationErrors} from "../../../../../../infrastructure/utils/formikUtils";
import {FormLoader} from "../../../../../../components/loaders";
import {destinationsActions, destinationsSelectors} from "../../../destinations";
import {useAppDispatch} from "../../../../../../infrastructure/hooks/reduxHooks";

export const RoleModal = () => {
    const intl = useIntl()
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const modalState = useSelector(rolesSelectors.getRoleModalState())
    const [loading, setLoading] = useState(false)
    const [showContentLoader, setShowContentLoader] = useState(false)
    const [color, setColor] = useState<Color>();
    const organisationId = useSelector(organisationSelectors.getSelectedOrganisationId()) as string
    const roleId = useSelector(rolesSelectors.getRoleModalRoleId())
    const rolesModal = useSelector(rolesSelectors.getEditRolesModal())

    const [formValues, setFormValues] = useState({
        name: '',
        color: '',
    })

    const closeModal = () => {

        if (rolesModal.refreshTableWhenModalClosed) {
            dispatch(rolesActions.refreshUserRolesTable())
        }

        dispatch(rolesActions.showOrganisationRoleModal({state: false}))
    }

    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .min(3, intl.formatMessage({id: 'VALIDATION.MIN_LENGTH'}, {length: 3}))
            .max(75, intl.formatMessage({id: 'VALIDATION.MAX_LENGTH'}, {length: 75}))
            .required(intl.formatMessage({id: 'VALIDATION.REQUIRED'}, {name: intl.formatMessage({id: 'ORGANISATION.MODAL.USER_ROLE.INPUT.NAME'})})),
    })

    const handleChange = (color: any) => {
        // formik.setErrors({})
        setColor(color.hex);
    };

    const getOrganisationRole = async (roleId: string) => {
        const response = await rolesActions.getRole(organisationId, roleId)
        if (response.isSucceed) {
            setFormValues({
                name: response.data.name,
                color: response.data.colorCode
            })
            setColor(response.data.colorCode)
            setShowContentLoader(false)

        } else {
            navigate(urls.ERROR_404_URL)
        }
    }

    useEffect(() => {
        if (roleId) {
            setShowContentLoader(true)
            getOrganisationRole(roleId as string)
        }
    }, [roleId])

    return (
        <FormModal
            modalState={modalState as boolean}
            closeModal={closeModal}
            title={intl.formatMessage({
                id: !roleId
                    ? "ORGANISATION.MODAL.USER_ROLE.TITLE.ADD_ROLE"
                    : "ORGANISATION.MODAL.USER_ROLE.TITLE.EDIT_ROLE"
            })}
            subTitle={intl.formatMessage({id: "ORGANISATION.MODAL.USER_ROLE.SUBTITLE"})}
        >
            <>
                {showContentLoader && <FormLoader/>}

                {
                    !showContentLoader &&
                    (

                        <Formik
                            initialValues={formValues}
                            validationSchema={validationSchema}
                            enableReinitialize={true}
                            onSubmit={(values, {setSubmitting, setFieldError}) => {
                                setLoading(true);

                                setTimeout(async () => {
                                    if (!roleId) {
                                        const response: ActionResponse = await rolesActions.createRole({
                                            organisationId: organisationId,
                                            name: values.name,
                                            colorCode: color as string
                                        })

                                        if (response.isSucceed) {
                                            toastify.success(intl.formatMessage({id: "ORGANISATION.MODAL.USER_ROLE.MESSAGE.ROLE_CREATED"}))
                                            closeModal()
                                        } else {
                                            addValidationErrors(setFieldError, response.errors)
                                            setLoading(false)
                                            setSubmitting(false)

                                            switch (response.message) {
                                                case "RoleNameAlreadyExist":
                                                    toastify.error(intl.formatMessage({id: "ORGANISATION.MODAL.USER_ROLE.MESSAGE.ROLE_NAME_IS_ALREADY_EXIST"}))
                                                    break
                                            }
                                        }
                                    } else {
                                        const response: ActionResponse = await rolesActions.updateRole(
                                            {
                                                organisationId,
                                                roleId: roleId,
                                                name: values.name,
                                                colorCode: color as string
                                            })
                                        if (response.isSucceed) {
                                            toastify.success(intl.formatMessage({id: "ORGANISATION.MODAL.USER_ROLE.MESSAGE.ROLE_UPDATED"}))
                                            closeModal()
                                        } else {
                                            addValidationErrors(setFieldError, response.errors)
                                            setLoading(false)
                                            setSubmitting(false)

                                            switch (response.message) {
                                                case "RoleNameAlreadyExist":
                                                    toastify.error(intl.formatMessage({id: "ORGANISATION.MODAL.USER_ROLE.MESSAGE.ROLE_NAME_IS_ALREADY_EXIST"}))
                                                    break
                                                case "RecordNotFound":
                                                    toastify.error(intl.formatMessage({id: "ERROR.RECORD_NOT_FOUND"}))
                                                    break
                                            }
                                        }
                                    }


                                }, 500)
                            }
                            }
                        >
                            {(formik) => (
                                <Form className="form">
                                    <FormInputGroup
                                        labelResourceName={"ORGANISATION.MODAL.USER_ROLE.INPUT.NAME"}
                                        fieldProps={formik.getFieldProps('name')}
                                        validationCondition={(formik.touched.name && formik.errors.name)}
                                        validationMessage={formik.errors.name}
                                        focus={true}
                                    />

                                    <FormInputGroup
                                        labelResourceName={"ORGANISATION.MODAL.USER_ROLE.INPUT.COLOR"}
                                        fieldProps={formik.getFieldProps('color')}
                                        validationCondition={(formik.touched.color && formik.errors.color)}
                                        validationMessage={formik.errors.color}>
                                        <ColorPicker color={color} setColor={setColor} onChange={handleChange}/>
                                    </FormInputGroup>

                                    <FormActionButtons
                                        formik={formik}
                                        loading={loading}
                                        closeModal={closeModal}
                                        submitButtonResourceName={!roleId ? "FORM.CREATE" : "FORM.UPDATE"}
                                    />
                                </Form>
                            )}
                        </Formik>

                    )
                }

            </>
        </FormModal>
    )
}