import {Link, useLocation} from "react-router-dom";
import clsx from "clsx";
import {FC} from "react";
import {useIntl} from "react-intl";

type Props = {
    tabs?: NavigationItem[]
    back?: Back
}

export interface NavigationItem {
    show: boolean,
    text: string
    url: string,
    hasSubPages: boolean
}

interface Back {
    url: string,
    text?: string
}

export const Navigation: FC<Props> = ({tabs, back}) => {

    const intl = useIntl()

    return (
        <ul className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-6 fw-bolder mb-4">
            {
                tabs?.filter(x => x.show).map(tab => {
                    return (
                        <NavigationItem key={tab.text} show={tab.show} text={tab.text} url={tab.url} hasSubPages={tab.hasSubPages}/>
                    )
                })
            }
            {back?.url &&
                <li className="nav-item ms-auto">
                    <Link to={back.url}
                          className="btn btn-sm btn-light ms-auto">
                        <span className="me-2">
                            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M48.5 224H40c-13.3 0-24-10.7-24-24V72c0-9.7 5.8-18.5 14.8-22.2s19.3-1.7 26.2 5.2L98.6 96.6c87.6-86.5 228.7-86.2 315.8 1c87.5 87.5 87.5 229.3 0 316.8s-229.3 87.5-316.8 0c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0c62.5 62.5 163.8 62.5 226.3 0s62.5-163.8 0-226.3c-62.2-62.2-162.7-62.5-225.3-1L185 183c6.9 6.9 8.9 17.2 5.2 26.2s-12.5 14.8-22.2 14.8H48.5z"/></svg>
                        </span>
                        {intl.formatMessage({id: back.text ?? "NAVIGATION.BACK_TO_THE_LIST"})}
                    </Link>
                </li>}
        </ul>
    )
}

const NavigationItem: FC<NavigationItem> = ({text, url, hasSubPages}) => {

    const location = useLocation()

    return (
        <li className="nav-item">
            <Link to={url}
                  className={clsx('nav-link text-active-primary pb-4',
                      ((!hasSubPages && location.pathname === url) || (hasSubPages && location.pathname.indexOf(url) > 1)) && 'active')}>
                {text}
            </Link>
        </li>
    )
}