// React Imports
import {useState} from 'react'

// Infrasturcture Imports
import {FormActionButtons, FormInputGroup} from '../../../components/forms'
import {ActionResponse} from '../../../infrastructure/models/actionResponse'
import {addValidationErrors} from '../../../infrastructure/utils/formikUtils'
import {lowerCaseFirstLetter} from '../../../infrastructure/utils/stringUtils'
import {PageTitle} from '../../../../_metronic/layout/core'

// Package Imports
import clsx from 'clsx'
import {Form, Formik} from 'formik'
import {useIntl} from 'react-intl'
import * as Yup from 'yup'
import {accountActions} from '../'

// Util and Lib Imports
import {translator} from '../../../utils'
import {toastify} from '../../../libs/toastify'
import {formatMessage} from '../../../../_metronic/i18n/Metronici18n'

export const ChangePasswordPage = () => {
  const intl = useIntl()
  const [loading, setLoading] = useState(false)

  const initialValues = {
    oldPassword: '',
    newPassword: '',
    newPasswordConfirm: '',
  }

  const validationSchema = Yup.object().shape({
    oldPassword: Yup.string().required(
      translator('VALIDATION.REQUIRED', {
        name: translator('ACCOUNT.RESET_PASSWORD.INPUT.OLD_PASSWORD'),
      })
    ),
    newPassword: Yup.string()
      .min(8, translator('VALIDATION.MIN_LENGTH', {length: 10}))
      .max(25, translator('VALIDATION.MAX_LENGTH', {length: 25}))
      .required(
        translator('VALIDATION.REQUIRED', {
          name: translator('ACCOUNT.RESET_PASSWORD.INPUT.NEW_PASSWORD'),
        })
      ),
    newPasswordConfirm: Yup.string()
      .required(
        translator('VALIDATION.CONFIRMATION', {
          name: translator('ACCOUNT.RESET_PASSWORD.INPUT.NEW_PASSWORD'),
        })
      )
      .when('newPassword', {
        is: (val: string) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref('newPassword')],
          translator('VALIDATION.CONFIRMATION_NOT_MATCH', {
            name: translator('ACCOUNT.RESET_PASSWORD.INPUT.NEW_PASSWORD'),
            confirmName: translator('ACCOUNT.RESET_PASSWORD.INPUT.NEW_PASSWORD_CONFIRM'),
          })
        ),
      }),
  })

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.CHANGE_PASSWORD'})}</PageTitle>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async ({oldPassword, newPassword}, {setSubmitting, setFieldError, resetForm}) => {
          setLoading(true)
          setTimeout(async () => {
            const response: ActionResponse = await accountActions.resetPassword({
              oldPassword,
              newPassword,
            })

            if (response.isSucceed) {
              toastify.success(formatMessage({id: 'ACCOUNT.RESET_PASSWORD.MESSAGE.SUCCESS'}))
              resetForm()
            } else {
              addValidationErrors(setFieldError, response.errors)
              setSubmitting(false)

              switch (response.message) {
                case 'OldPasswordIsNotCorrect':
                  setFieldError(
                    lowerCaseFirstLetter('oldPassword'),
                    intl.formatMessage({
                      id: 'ACCOUNT.RESET_PASSWORD.VALIDATION.OLD_PASSWORD_IS_NOT_CORRECT',
                    })
                  )
                  toastify.error(intl.formatMessage({id: 'ERROR.CHECK_THE_FORM_FIELDS'}))
                  break
              }
            }

            setLoading(false)
          }, 500)
        }}
      >
        {(formik) => (
          <Form className='form w-100'>
            <div className='text-center mb-10'>
              <h1 className='text-dark mb-3'>
                {intl.formatMessage({id: 'ACCOUNT.RESET_PASSWORD.TITLE'})}
              </h1>
            </div>

            {formik.status && (
              <div
                className={clsx(
                  'alert mb-5',
                  formik.status.isSucceed ? 'alert-success' : 'alert-danger'
                )}
              >
                {formik.status.message}
              </div>
            )}

            <FormInputGroup
              labelResourceName={'ACCOUNT.RESET_PASSWORD.INPUT.OLD_PASSWORD'}
              inputType={'password'}
              fieldProps={formik.getFieldProps('oldPassword')}
              validationCondition={formik.touched.oldPassword && formik.errors.oldPassword}
              validationMessage={formik.errors.oldPassword}
            />

            <FormInputGroup
              labelResourceName={'ACCOUNT.RESET_PASSWORD.INPUT.NEW_PASSWORD'}
              inputType={'password'}
              fieldProps={formik.getFieldProps('newPassword')}
              validationCondition={formik.touched.newPassword && formik.errors.newPassword}
              validationMessage={formik.errors.newPassword}
            />

            <FormInputGroup
              labelResourceName={'ACCOUNT.RESET_PASSWORD.INPUT.NEW_PASSWORD_CONFIRM'}
              inputType={'password'}
              fieldProps={formik.getFieldProps('newPasswordConfirm')}
              validationCondition={
                formik.touched.newPasswordConfirm && formik.errors.newPasswordConfirm
              }
              validationMessage={formik.errors.newPasswordConfirm}
            />

            <FormActionButtons
              formik={formik}
              loading={loading}
              submitButtonResourceName={'ACCOUNT.RESET_PASSWORD.BUTTON.SUBMIT'}
              showCancelButton={false}
              fullWidthButton={false}
              buttonSize={'btn-lg'}
            />
          </Form>
        )}
      </Formik>
    </>
  )
}
