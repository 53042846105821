import {FormCancelButton, FormModal, FormSubmitButton} from "../../../../../../components/forms";
import {useIntl} from "react-intl";
import {useSelector} from "react-redux";
import {usersSelectors} from "../../usersSelectors";
import {usersActions} from "../../usersActions";
import {useAppDispatch} from "../../../../../../infrastructure/hooks/reduxHooks";
import React, {useEffect, useState} from "react";
import {organisationSelectors} from "../../../../organisationSelectors";
import {UserType} from "../../../../../../infrastructure/enums/userType";
import clsx from "clsx";
import {UserState} from "../../../../../../infrastructure/enums/userState";
import {ImageFromBase64} from "../../../../../../components/images";
import {useLang} from "../../../../../../../_metronic/i18n/Metronici18n";
import {toastify} from "../../../../../../libs/toastify";

export const ChangeUserTypeModal = () => {
    const intl = useIntl();
    const dispatch = useAppDispatch()
    const selectedLang = useLang()
    const selectedUser = useSelector(usersSelectors.getChangeUserTypeModalUser())
    const modalState = useSelector(usersSelectors.getChangeUserTypeModalState())
    const [showContentLoader, setShowContentLoader] = useState(false)
    const [userTypeValue, setUserTypeValue] = useState("")
    const [loading, setLoading] = useState(false)
    const organisationId = useSelector(organisationSelectors.getSelectedOrganisationId())

    const closeModal = () => {
        dispatch(usersActions.showChangeUserTypeModal({
            state: false
        }))
    }

    useEffect(() => {
        setShowContentLoader(true)
        setUserTypeValue(selectedUser.organisationUserType);

        return () => {
            dispatch(usersActions.refreshOrganisationUsersTable())
        }
    }, [])

    const updateUserType = async () => {
        setLoading(true)
        const response = await usersActions.updateUserType({
            organisationId: organisationId,
            organisationUserId: selectedUser.organisationUserId,
            userType: userTypeValue
        });

        if (response.isSucceed) {
            setLoading(false)
            closeModal()
            toastify.success(intl.formatMessage({id: 'ORGANISATION.MODAL.EDIT_USER_ROLE.MESSAGE.UPDATED'}))
        } else {
            if (response.message == "ThereIsNoOtherAdminInTheOrganisation") {
                toastify.error(intl.formatMessage({id: "ORGANISATION.MODAL.USER_TYPE.MESSAGE.THERE_IS_NO_OTHER_ADMIN_IN_THE_ORGANISATION"}))
            } else {
                toastify.error()
            }
            setLoading(false)
        }
    }

    const changeUserType = async (value: string) => {
        setUserTypeValue(value);
    }

    return <>
        <FormModal
            modalState={modalState}
            closeModal={closeModal}
            title={intl.formatMessage({id: 'ORGANISATION.MODAL.USER_TYPE.TITLE'})}
            dialogClass="mw-425px"
        >
            <div className="form">
                <div className="d-flex align-items-center">
                    <div className="me-5 position-relative">
                        <div className="symbol symbol-50px">
                            {
                                !selectedUser.photo &&
                                <span
                                    className={clsx("symbol-label fs-2 fw-bold", selectedUser.userState != UserState.Invited ? "bg-light-primary text-primary" : "bg-light text-gray-400")}>
                                          {selectedUser?.name.length > 1 ? selectedUser.name[0].toLocaleUpperCase(selectedLang) : selectedUser.email[0].toLocaleUpperCase(selectedLang)}
                                    </span>
                            }
                            {
                                selectedUser.photo &&
                                <ImageFromBase64 base64String={selectedUser.photo}/>
                            }
                        </div>
                    </div>
                    <div className="d-flex flex-column justify-content-center">
                        <span
                            className="text-gray-800 fw-bolder fs-5">
                            {selectedUser.name}
                        </span>
                        <div className="fw-bold fs-6 text-gray-400">{selectedUser.email}</div>
                    </div>
                </div>
                {/*<div className="ms-2">*/}
                {/*    <span className="badge badge-light-primary me-auto">{selectedUser.email}</span>*/}
                {/*    {selectedUser.name &&*/}
                {/*        <span className="badge badge-light-primary me-auto">({selectedUser.name})</span>*/}
                {/*    }*/}
                {/*</div>*/}
                <div className="mt-10">
                    <select className="form-select form-select-solid w-100"
                            data-control="select2"
                            data-hide-search="true"
                            value={userTypeValue}
                            onChange={(e) => changeUserType(e.currentTarget.value)}
                    >
                        <option
                            value={UserType.User}>{intl.formatMessage({id: 'ORGANISATION.MODAL.INVITE_USERS.SEARCH_RESULT.USER_TYPE.USER'})}</option>
                        {/*<option*/}
                        {/*    value={UserType.Moderator}>{intl.formatMessage({id: 'ORGANISATION.MODAL.INVITE_USERS.SEARCH_RESULT.USER_TYPE.MODERATOR'})}</option>*/}
                        <option
                            value={UserType.Admin}>{intl.formatMessage({id: 'ORGANISATION.MODAL.INVITE_USERS.SEARCH_RESULT.USER_TYPE.ADMIN'})}</option>
                    </select>
                </div>
                <div className="d-flex flex-center mt-15">
                    <FormCancelButton onClick={closeModal}/>
                    <FormSubmitButton
                        loading={loading}
                        type="button"
                        onClick={updateUserType}
                        buttonResourceName={"FORM.SAVE"}
                    />
                </div>
            </div>
        </FormModal>
    </>
}