import {Dispatch} from "redux";
import {destinationsReducerActions} from "../destinations";
import {DataRequest, DataResponse} from "../../../../components/tables";
import axios, {AxiosResponse} from "axios";
import {toastify} from "../../../../libs/toastify";
import {ActionResponse} from "../../../../infrastructure/models/actionResponse";
import {
    CreateAgentModel,
    CreateAgentModelResponse,
    AgentModel,
    UpdateDestinationModel, AgentScriptModel
} from "./models";
import {destinationsApis} from "./destinationApis";
import {createAsyncThunk} from "@reduxjs/toolkit";
import {organisationActions} from "../../organisationActions";
import {formatMessage} from "../../../../../_metronic/i18n/Metronici18n";
import {DestinationSettingsModel} from "./models/DestinationSettingsModel";
import {CreateCmsExtensionModel, CreateCmsExtensionModelResponse} from "./models/CreateCmsExtensionModel";
import {CreateSoftwarePackageModel, CreateSoftwarePackageModelResponse} from "./models/CreateSoftwarePackageModel";
import {usersApis} from "../users/usersApis";

const showCreateAgentModal = createAsyncThunk(
    'destinations/showCreateAgentModal',
    async ({state = true, setSelectedTabAsAgents = true}: { state?: boolean, setSelectedTabAsAgents?: boolean }, {dispatch}) => {
        dispatch(destinationsReducerActions.setShowCreateAgentModal({state}))
        // if (setSelectedTabAsAgents) {
        //     dispatch(organisationActions.setSelectedTab('agents'))
        // }
    })

const showCreateCmsModal = createAsyncThunk(
    'destinations/showCmsModal',
    async ({state = true, setSelectedTabAsCms = true}: { state?: boolean, setSelectedTabAsCms?: boolean }, {dispatch}) => {
        dispatch(destinationsReducerActions.setShowCreateCmsModal({state}))
        // if (setSelectedTabAsCms) {
        //     dispatch(organisationActions.setSelectedTab('cms'));
        // }
    })

const showCreateSoftwarePackageModal = createAsyncThunk(
    'destinations/showSoftwarePackageModal',
    async ({state = true, setSelectedTabAsSoftwarePackages = true}: { state?: boolean, setSelectedTabAsSoftwarePackages?: boolean }, {dispatch}) => {
        dispatch(destinationsReducerActions.setShowSoftwarePackageModal({state}))
        // if (setSelectedTabAsSoftwarePackages) {
        //     dispatch(organisationActions.setSelectedTab('software-packages'));
        // }
    })

const showEditDestinationModal = createAsyncThunk(
    'destinations/showEditDestinationModal',
    async ({state = true, destinationId, refreshTableWhenModalClosed = false}: {
        state?: boolean,
        destinationId?: string,
        refreshTableWhenModalClosed?: boolean
    }, {dispatch}) => {
        dispatch(destinationsReducerActions.setShowEditDestinationModal({state, destinationId, refreshTableWhenModalClosed}));
    }
)

const getAgents = createAsyncThunk(
    'destinations/getAgents',
    async ({
               organisationId,
               dataRequest = undefined
           }: { organisationId: string, dataRequest?: DataRequest | undefined }, {dispatch}) => {
        const response = await destinationsApis.getAgents({organisationId, dataRequest})
        dispatch(destinationsReducerActions.setAgents(response.data))
    }
)

const getCms = createAsyncThunk(
    'destinations/getCms',
    async ({
               organisationId,
               dataRequest = undefined
           }: { organisationId: string, dataRequest?: DataRequest | undefined }, {dispatch}) => {
        const response = await destinationsApis.getCms({organisationId, dataRequest})
        dispatch(destinationsReducerActions.setCms(response.data))
    }
)

const getSoftwarePackages = createAsyncThunk(
    'destinations/getSoftwarePackages',
    async ({
               organisationId,
               dataRequest = undefined
           }: { organisationId: string, dataRequest?: DataRequest | undefined }, {dispatch}) => {
        const response = await destinationsApis.getSoftwarePackages({organisationId, dataRequest})
        dispatch(destinationsReducerActions.setSofwarePackages(response.data))
    }
)

const refreshAgentsTable = createAsyncThunk(
    'destinations/refreshAgentsTable',
    async (_, {dispatch}) => {
        dispatch(destinationsReducerActions.setAgentsRefreshTable())
    }
)
const refreshCmsTable = createAsyncThunk(
    'destinations/refreshCmsTable',
    async (_, {dispatch}) => {
        dispatch(destinationsReducerActions.setCmsRefreshTable())
    }
)
const refreshSoftwarePackagesTable = createAsyncThunk(
    'destinations/refreshSoftwarePackagesTable',
    async (_, {dispatch}) => {
        dispatch(destinationsReducerActions.setSofWarePackagesRefreshTable())
    }
)

const createAgent = createAsyncThunk(
    'destinations/createAgent',
    async (model: CreateAgentModel, {dispatch}) => {
        try {
            const response = await destinationsApis.createAgent({model})

            dispatch(organisationActions.updateOrganisationInfo({organisationId: model.organisationId}));

            return {
                isSucceed: true,
                data: response.data
            } as ActionResponse<CreateAgentModelResponse>
        } catch (e: any) {
            return {
                isSucceed: false,
                errors: e.response?.data?.errors,
                message: e.response.data
            } as ActionResponse<CreateAgentModelResponse>
        }
    }
)

const createCmsExtension = createAsyncThunk(
    'destinations/createCmsExtension',
    async (model: CreateCmsExtensionModel, {dispatch}) => {
        try {
            const response = await destinationsApis.createCmsExtension({model})
            dispatch(organisationActions.updateOrganisationInfo({organisationId: model.organisationId}));

            return {
                isSucceed: true,
                data: response.data
            } as ActionResponse<CreateCmsExtensionModelResponse>
        } catch (e: any) {
            return {
                isSucceed: false,
                errors: e.response?.data?.errors,
                message: e.response.data
            } as ActionResponse<CreateCmsExtensionModelResponse>
        }
    }
)

const createSoftwarePackage = createAsyncThunk(
    'destinations/createSoftwarePackage',
    async (model: CreateSoftwarePackageModel, {dispatch}) => {
        try {
            const response = await destinationsApis.createSoftwarePackage({model})
            dispatch(organisationActions.updateOrganisationInfo({organisationId: model.organisationId}));

            return {
                isSucceed: true,
                data: response.data
            } as ActionResponse<CreateSoftwarePackageModelResponse>
        } catch (e: any) {
            return {
                isSucceed: false,
                errors: e.response?.data?.errors,
                message: e.response.data
            } as ActionResponse<CreateSoftwarePackageModelResponse>
        }
    }
)

const getDestination = async (organisationId: string, destinationId: string) => {
    try {
        const response = await axios.get<AgentModel, AxiosResponse<AgentModel>>(`/organisations/${organisationId}/destinations/${destinationId}`);
        return {
            isSucceed: true,
            data: response.data
        } as ActionResponse<AgentModel>
    } catch (e: any) {
        return {
            isSucceed: false,
            errors: e.response?.data?.errors,
            message: e.response.data
        } as ActionResponse<AgentModel>
    }
}

const updateDestination = async (model: UpdateDestinationModel) => {
    try {
        await axios.patch(`/organisations/${model.organisationId}/destinations/${model.destinationId}`, {
            name: model.name,
            ipAddress: model.ipAddress ?? null
        });
        return {
            isSucceed: true
        } as ActionResponse
    } catch (e: any) {
        return {
            isSucceed: false,
            errors: e.response?.data?.errors,
            message: e.response.data
        } as ActionResponse
    }
}

const checkOnline = async (organisationId: string, destinationId: string) => {
    try {
        const response = await axios.get<boolean, AxiosResponse<boolean>>(`/organisations/${organisationId}/destinations/${destinationId}/check-online`);
        return {
            isSucceed: true,
            data: response.data
        } as ActionResponse<boolean>
    } catch (e: any) {
        return {
            isSucceed: false,
            errors: e.response?.data?.errors,
            message: e.response.data
        } as ActionResponse<boolean>
    }
}

const checkDestinationName = async ({organisationId, name}: { organisationId: string, name: string }) => {
    try {
        const response = await destinationsApis.checkDestinationName({organisationId, name})
        return {
            isSucceed: true,
            data: response.data
        } as ActionResponse<boolean>
    } catch (e: any) {
        return {
            isSucceed: false,
            errors: e.response?.data?.errors,
            message: e.response.data
        } as ActionResponse<boolean>
    }
}

const checkDestinationDeletable = async (organisationId: string, destinationId: string) => {
    try {
        const response = await axios.get<boolean, AxiosResponse<boolean>>(`/organisations/${organisationId}/destinations/${destinationId}/check-deletable`);
        return {
            isSucceed: true,
            data: response.data
        } as ActionResponse<boolean>
    } catch {
        return {
            isSucceed: false,
        } as ActionResponse<boolean>
    }
}

const deleteAgent = createAsyncThunk(
    'destinations/deleteAgent',
    async ({organisationId, destinationId}: { organisationId: string, destinationId: string }, {dispatch}) => {
        await destinationsApis.deleteDestination({organisationId, destinationId})
        dispatch(organisationActions.updateOrganisationInfo({organisationId}))
        dispatch(destinationsActions.refreshAgentsTable())
        toastify.success(formatMessage({id: 'ORGANISATION.DESTINATIONS.TABLE.MESSAGE.DESTINATION_DELETED'}))
    }
)

const deleteCms = createAsyncThunk(
    'destinations/deleteCms',
    async ({organisationId, destinationId}: { organisationId: string, destinationId: string }, {dispatch}) => {
        await destinationsApis.deleteDestination({organisationId, destinationId})
        dispatch(organisationActions.updateOrganisationInfo({organisationId}))
        dispatch(destinationsActions.refreshCmsTable())
        toastify.success(formatMessage({id: 'ORGANISATION.DESTINATIONS.TABLE.MESSAGE.DESTINATION_DELETED'}))
    }
)

const deleteSoftwarePackage = createAsyncThunk(
    'destinations/deleteSoftwarePackage',
    async ({organisationId, destinationId}: { organisationId: string, destinationId: string }, {dispatch}) => {
        await destinationsApis.deleteDestination({organisationId, destinationId})
        dispatch(organisationActions.updateOrganisationInfo({organisationId}))
        dispatch(destinationsActions.refreshSoftwarePackagesTable())
        toastify.success(formatMessage({id: 'ORGANISATION.DESTINATIONS.TABLE.MESSAGE.DESTINATION_DELETED'}))
    }
)

const getDestinationInfo = (organisationId: string, destinationId: string) => async (dispatch: Dispatch) => {
    const response = await getDestination(organisationId, destinationId);
    if (response.isSucceed) {
        dispatch(destinationsReducerActions.setDestinationDetailInfo(response.data))
    }
}

const getAgentScript = async ({organisationId, agentId}: { organisationId: string, agentId: string }) => {
    try {
        const response = await destinationsApis.getAgentScript({organisationId, agentId})
        return {
            isSucceed: true,
            data: response.data
        } as ActionResponse<AgentScriptModel>
    } catch (e: any) {
        return {
            isSucceed: false,
            errors: e.response?.data?.errors,
            message: e.response.data
        } as ActionResponse<AgentScriptModel>
    }
}

const getCmsExtensionToken = async ({organisationId, cmsExtensionId}: { organisationId: string, cmsExtensionId: string }) => {
    try {
        const response = await destinationsApis.getCmsExtensionToken({organisationId, cmsExtensionId})
        return {
            isSucceed: true,
            data: response.data
        } as ActionResponse<string>
    } catch (e: any) {
        return {
            isSucceed: false,
            errors: e.response?.data?.errors,
            message: e.response.data
        } as ActionResponse<string>
    }
}

const getSoftwarePackageToken = async ({organisationId, softwarePackageId}: { organisationId: string, softwarePackageId: string }) => {
    try {
        const response = await destinationsApis.getSoftwarePackageToken({organisationId, softwarePackageId})
        return {
            isSucceed: true,
            data: response.data
        } as ActionResponse<string>
    } catch (e: any) {
        return {
            isSucceed: false,
            errors: e.response?.data?.errors,
            message: e.response.data
        } as ActionResponse<string>
    }
}

const getSettings = async ({
                               organisationId,
                               destinationId
                           }: { organisationId: string, destinationId: string, }) => {

    try {
        const response = await destinationsApis.getSettings({organisationId, destinationId})
        return {
            isSucceed: true,
            data: response.data
        } as ActionResponse<DestinationSettingsModel>
    } catch (e: any) {
        return {
            isSucceed: false,
            errors: e.response?.data?.errors,
            message: e.response.data
        } as ActionResponse<DestinationSettingsModel>
    }
}


const updateSettingsFailoverRdp = async ({organisationId, destinationId, enable}: { organisationId: string, destinationId: string, enable: boolean }) => {
    try {
        await destinationsApis.updateSettingsFailoverRdp({organisationId, destinationId, enable})
        return {
            isSucceed: true
        } as ActionResponse
    } catch (e: any) {
        return {
            isSucceed: false,
            errors: e.response?.data?.errors,
            message: e.response.data
        } as ActionResponse
    }
}

const updateSettingsFailoverSsh = async ({organisationId, destinationId, enable}: { organisationId: string, destinationId: string, enable: boolean }) => {
    try {
        await destinationsApis.updateSettingsFailoverSsh({organisationId, destinationId, enable})
        return {
            isSucceed: true
        } as ActionResponse
    } catch (e: any) {
        return {
            isSucceed: false,
            errors: e.response?.data?.errors,
            message: e.response.data
        } as ActionResponse
    }
}

const updateSettingsDocker = async ({organisationId, destinationId, enable}: { organisationId: string, destinationId: string, enable: boolean }) => {
    try {
        await destinationsApis.updateSettingsDocker({organisationId, destinationId, enable})
        return {
            isSucceed: true
        } as ActionResponse
    } catch (e: any) {
        return {
            isSucceed: false,
            errors: e.response?.data?.errors,
            message: e.response.data
        } as ActionResponse
    }
}

const updateSettingsCloudflare = async ({organisationId, destinationId, enable}: { organisationId: string, destinationId: string, enable: boolean }) => {
    try {
        await destinationsApis.updateSettingsCloudflare({organisationId, destinationId, enable})
        return {
            isSucceed: true
        } as ActionResponse
    } catch (e: any) {
        return {
            isSucceed: false,
            errors: e.response?.data?.errors,
            message: e.response.data
        } as ActionResponse
    }
}

const updateSettingsGitHubActions = async ({organisationId, destinationId, enable}: { organisationId: string, destinationId: string, enable: boolean }) => {
    try {
        await destinationsApis.updateSettingsGitHubActions({organisationId, destinationId, enable})
        return {
            isSucceed: true
        } as ActionResponse
    } catch (e: any) {
        return {
            isSucceed: false,
            errors: e.response?.data?.errors,
            message: e.response.data
        } as ActionResponse
    }
}

const getOrganisationUsersForCreatingDestination = async ({organisationId, currentUserEmail}: { organisationId: string, currentUserEmail: string }) => {
    const response = await usersApis.getOrganisationUsers({organisationId: organisationId, dataRequest: {page: 1, pageSize: 100} as DataRequest})
    return response.data.data.filter(x => x.email !== currentUserEmail)
}

export const destinationsActions =
    {
        showCreateAgentModal,
        showCreateCmsModal,
        showCreateSoftwarePackageModal,
        showEditDestinationModal,
        getAgents,
        getCms,
        getSoftwarePackages,
        refreshAgentsTable,
        refreshCmsTable,
        refreshSoftwarePackagesTable,
        createAgent,
        createCmsExtension,
        createSoftwarePackage,
        getDestination,
        updateDestination,
        checkOnline,
        checkDestinationDeletable,
        checkDestinationName,
        deleteAgent,
        deleteCms,
        deleteSoftwarePackage,
        getDestinationInfo,
        getAgentScript,
        getCmsExtensionToken,
        getSoftwarePackageToken,
        getSettings,
        updateSettingsFailoverRdp,
        updateSettingsFailoverSsh,
        updateSettingsDocker,
        updateSettingsCloudflare,
        updateSettingsGitHubActions,
        getOrganisationUsersForCreatingDestination
    }