import {Link, useLocation} from 'react-router-dom'
import {FormActionButtons, FormInputGroup} from '../../../../components/forms'
import * as Yup from 'yup'
import {Form, Formik} from 'formik'
import {authActions} from '../../authActions'
import {useState} from 'react'
import {useAppDispatch} from '../../../../infrastructure/hooks/reduxHooks'
import {useIntl} from 'react-intl'
import {addValidationErrors} from '../../../../infrastructure/utils/formikUtils'
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3'
import {toastify} from '../../../../libs/toastify'
import {RouterState} from '../../../../infrastructure/state/routerState'
import {v4 as uuidv4} from 'uuid'

export const Login = () => {
  const [loading, setLoading] = useState(false)
  const dispatch = useAppDispatch()
  const intl = useIntl()
  const location = useLocation()
  const reCaptcha = useGoogleReCaptcha()

  const initialValues = {
    email: '',
    password: '',
  }

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(intl.formatMessage({id: 'VALIDATION.EMAIL_FORMAT'}))
      .required(
        intl.formatMessage(
          {id: 'VALIDATION.REQUIRED'},
          {name: intl.formatMessage({id: 'AUTH.LOGIN.INPUT.EMAIL'})}
        )
      )
      .min(10, intl.formatMessage({id: 'VALIDATION.MIN_LENGTH'}, {length: 10}))
      .max(100, intl.formatMessage({id: 'VALIDATION.MAX_LENGTH'}, {length: 100})),
    password: Yup.string()
      .min(8, intl.formatMessage({id: 'VALIDATION.MIN_LENGTH'}, {length: 8}))
      .max(30, intl.formatMessage({id: 'VALIDATION.MAX_LENGTH'}, {length: 30}))
      .required(
        intl.formatMessage(
          {id: 'VALIDATION.REQUIRED'},
          {name: intl.formatMessage({id: 'AUTH.LOGIN.INPUT.PASSWORD'})}
        )
      ),
  })

  // @ts-ignore
  // @ts-ignore
  // @ts-ignore
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, {setStatus, setSubmitting, setFieldError}) => {
          if (!reCaptcha.executeRecaptcha) {
            toastify.error(intl.formatMessage({id: 'AUTH.LOGIN.MESSAGE.RE_CAPTCHA_NOT_FOUND'}))
            return
          }

          const reCAPTCHAToken = await reCaptcha.executeRecaptcha()

          setLoading(true)

          setTimeout(async () => {
            const response = await dispatch(
              authActions.login({
                email: values.email,
                password: values.password,
                reCAPTCHAToken: reCAPTCHAToken,
              })
            ).unwrap()

            if (!response.isSucceed) {
              addValidationErrors(setFieldError, response.errors)
              setLoading(false)
              setSubmitting(false)

              switch (response.message) {
                case 'EmailOrPasswordIncorrect':
                  setStatus(
                    intl.formatMessage({id: 'AUTH.LOGIN.MESSAGE.EMAIL_OR_PASSWORD_IS_INCORRECT'})
                  )
                  break
                default:
                  setStatus(intl.formatMessage({id: 'ERROR.ERROR_OCCURRED'}))
              }
            } else {
              const verificationCode = uuidv4()
              dispatch(authActions.setVerificationCodeAction({verificationCode}))
            }
          }, 500)
        }}
      >
        {(formik) => (
          <Form className='form w-100'>
            {/* begin::Heading */}
            <div className='text-center mb-10'>
              <h1 className='text-dark mb-3'>{intl.formatMessage({id: 'AUTH.LOGIN.TITLE'})}</h1>
              <div className='text-gray-400 fw-bold fs-4'>
                {intl.formatMessage({id: 'AUTH.LOGIN.NEW_HERE'})}{' '}
                <Link to='/auth/registration' className='link-primary fw-bolder'>
                  {/*state={(location as any)?.state?.returnUrl ? {returnUrl: (location as any)?.state?.returnUrl} : {}}>*/}
                  {intl.formatMessage({id: 'AUTH.LOGIN.CREATE_ACCOUNT'})}
                </Link>
              </div>
            </div>
            {/* begin::Heading */}

            {formik.status && (
              <div className='mb-lg-15 alert alert-danger'>
                <div className='alert-text font-weight-bold'>{formik.status}</div>
              </div>
            )}

            {/* begin::Form group */}
            <FormInputGroup
              labelResourceName={'AUTH.LOGIN.INPUT.EMAIL'}
              fieldProps={formik.getFieldProps('email')}
              validationCondition={formik.touched.email && formik.errors.email}
              validationMessage={formik.errors.email}
            />
            {/* end::Form group */}

            {/* begin::Form group */}
            <FormInputGroup
              labelResourceName={'AUTH.LOGIN.INPUT.PASSWORD'}
              link={{
                textResourceName: 'AUTH.LOGIN.INPUT.LINK.FORGOT_PASSWORD',
                to: '/auth/forgot-password',
              }}
              inputType={'password'}
              fieldProps={formik.getFieldProps('password')}
              validationCondition={formik.touched.password && formik.errors.password}
              validationMessage={formik.errors.password}
            />
            {/* end::Form group */}

            {/* begin::Action */}
            <FormActionButtons
              formik={formik}
              disableSubmitButton={!formik.isValid || (!formik.dirty && formik.isValid)}
              loading={loading}
              submitButtonResourceName={'AUTH.LOGIN.BUTTON.LOGIN'}
              showCancelButton={false}
              fullWidthButton={true}
              buttonSize={'btn-lg'}
            />
            {/* end::Action */}
          </Form>
        )}
      </Formik>
    </>
  )
}
