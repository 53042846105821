import {useIntl} from "react-intl";
import {PageTitle} from "../../../../_metronic/layout/core";
import React, {useEffect} from "react";
import {Device} from "./device/Device";
import {useDispatch, useSelector} from "react-redux";
import {devicesActions, devicesSelectors} from "../";
import clsx from "clsx";

export const DevicesPage = () => {

    const dispatch = useDispatch()
    const intl = useIntl()

    const devices = useSelector(devicesSelectors.getDevices())
    const selectedDeviceId = useSelector(devicesSelectors.getSelectedDeviceId())

    useEffect(() => {
        (async () => {
            dispatch(devicesActions.getDevices())
        })()
    }, [])

    return (
        <>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DEVICES'})}</PageTitle>

            <div className="row justify-content-center">

                <div className="col-lg-8 col-md-12">
                    <div className="row">
                        {devices?.map((device) => {
                            return (
                                <div key={device.userDeviceId} style={{transition: "all 1s linear"}}
                                     className={clsx("col-12", !selectedDeviceId || selectedDeviceId == device.userDeviceId ? "" : "")}>
                                    <Device
                                        deviceId={device.userDeviceId}
                                        name={device.name}
                                        deviceType={device.deviceType}
                                        operatingSystem={device.operatingSystem}
                                        browser={device.browser}
                                        lastSeen={device.lastCommunication}
                                    />
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </>
    )
}