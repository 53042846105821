import ContentLoader from "react-content-loader"
import {useIntl} from "react-intl";

export const BlocksLoader = (props: any) => {

    const intl = useIntl()

    return (
        <div className="text-center">
            <ContentLoader
                speed={2}
                width={900}
                height={450}
                viewBox="0 0 900 450"
                backgroundColor="#d9d9d9"
                foregroundColor="#ededed"
                {...props}
            >
                <rect x="0" y="6" rx="4" ry="4" width="100%" height="75" />
                <rect x="0" y="105" rx="4" ry="4" width="100%" height="75" />
                <rect x="0" y="200" rx="4" ry="4" width="100%" height="75" />
                <rect x="0" y="300" rx="4" ry="4" width="100%" height="75" />
            </ContentLoader>
        </div>
    )
}