import React, {FC} from "react";
import {useIntl} from "react-intl";

interface MessageProps {
    propertyResourceName: string,
    errorMessage?: string
}

export const FormValidationMessage: FC<MessageProps> = ({propertyResourceName, errorMessage}) => {

    const intl = useIntl();
    
    if (errorMessage === undefined) return null;

    let message = errorMessage.toString();

    const replaceText = "{PropertyName}";

    do {
        message = message.replace(replaceText, intl.formatMessage({id: propertyResourceName}));
    }
    while (message.includes(replaceText))

    do {
        message = message.replace(".,", ".<br />");
    }
    while (message.includes(".,"))

    return <span dangerouslySetInnerHTML={{__html: message}}/>;
}


interface Props {
    condition: string | false | undefined,
    propertyResourceName: string,
    errorMessage?: string
}

export const FormValidation: FC<Props> = ({condition, propertyResourceName, errorMessage}) => {

    if (condition && errorMessage) {
        return (
            <div className='mt-2 text-danger'>
                <FormValidationMessage
                    propertyResourceName={propertyResourceName}
                    errorMessage={errorMessage}
                />
            </div>
        )
    } else {
        return (
            <></>
        )
    }
}