import React from "react";
import {UserSymbol} from "./UserSymbol";
import {useIntl} from "react-intl";

interface UserSymbolProps {
    name?: string,
    email: string,
    photo?: string,
    unclaimedUser: boolean
}

export const UserCard = ({name, email, photo, unclaimedUser}: UserSymbolProps) => {

    const intl = useIntl()

    return (
        <div className="d-flex align-items-center">
            <UserSymbol
                text={(name && name.length > 1) ? name : email}
                photo={photo}
            />
            <div className="d-flex flex-column justify-content-center">
                <span className="text-gray-800 fw-bold">{name}</span>
                <div className="fw-bold fs-6 text-gray-400">{email}</div>
                {
                    unclaimedUser &&
                    <span className="text-gray-400 fs-8">{intl.formatMessage({id: "ORGANISATION.USERS.TABLE.MESSAGE.PENDING_REGISTER"})}</span>
                }
            </div>
        </div>
    )
}