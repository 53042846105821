import {DateTime} from "luxon";
import store from "../../../setup/redux/Store";
import {getSelectedLang} from "../../../_metronic/i18n/Metronici18n";


export const formatDate = (dateString: string) => {

    let date = DateTime.fromISO(dateString, {zone: 'UTC'})

    const user = store.getState().auth.user;

    if (user?.timeZone) {
        date = date.setZone(user.timeZone)
    }

    date = date.setLocale(getSelectedLang())

    if (user?.dateFormat) {
        return date.toFormat(user.dateFormat)
    } else {
        return date.toLocaleString(DateTime.DATE_SHORT)
    }
}

export const formatDateTime = (dateString: string) => {

    let date = DateTime.fromISO(dateString, {zone: 'UTC'})

    const user = store.getState().auth.user;

    if (user?.timeZone) {
        date = date.setZone(user.timeZone)
    }

    date = date.setLocale(getSelectedLang())


    if (user?.dateFormat && user?.timeFormat) {
        return date.toFormat(`${user.dateFormat} ${user.timeFormat}`)
    } else if (user?.dateFormat) {
        return date.toFormat(`${user.dateFormat} ${DateTime.TIME_24_SIMPLE}`)
    } else if (user?.timeFormat) {
        return date.toFormat(`${DateTime.DATE_SHORT} ${user.timeFormat}`)
    } else {
        return date.toLocaleString(DateTime.DATETIME_SHORT)
    }
}

export const formatRelativeFromISO = (dateString: string) => {

    let date = DateTime.fromISO(dateString, {zone: 'UTC'})

    const user = store.getState().auth.user;

    if (user?.timeZone) {
        date = date.setZone(user.timeZone)
    }

    date = date.setLocale(getSelectedLang())

    return date.toRelative()
}

export const checkDateExpiredFromISO = (dateString: string, expireDateString: string) => {

    const date = DateTime.fromISO(dateString, {zone: 'UTC'})
    let expireDate = DateTime.fromISO(expireDateString, {zone: 'UTC'})

    return date < expireDate
}