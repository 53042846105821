import React, {FC, useEffect, useMemo, useState} from "react";
import {Column, useGlobalFilter, usePagination, useTable} from "react-table";
import clsx from "clsx";
import {TableFooter} from "./TableFooter";
import {TableHeader} from "./TableHeader";
import {TableLoader} from "../loaders/TableLoader";

export interface TableContext {
    columns: Column<any>[],
    hiddenColumns?: string[]
    dataContext: TableDataContext | null,
    pagination?: boolean,
    hideIdColumn?: boolean,
    events?: EventProps,
    toolbar?: any,
    customFilter?: {
        content: JSX.Element
        getCustomFilter: any,
        resetCustomFilter: any,
    }
}

export interface TableDataContext {
    data: any,
    itemsCount: number,
    pageCount: number,
    currentPage: number
}

export interface EventProps {
    getData?: any
    goToPage?: any,
    refreshState?: boolean
}

export interface DataRequest {
    page: number,
    pageSize: number,
    searchText?: string
    customFilter?: DataRequestFilter[]
}

export interface DataRequestFilter {
    name: string,
    value: string
}

export interface DataResponse<T = any> {
    data: T[],
    itemsCount: number,
    pageCount: number,
    currentPage: number
}

export const Table: FC<TableContext> = ({
                                            columns,
                                            dataContext,
                                            hideIdColumn = true,
                                            hiddenColumns = [],
                                            pagination = true,
                                            events,
                                            customFilter,
                                            toolbar,
                                        }) => {

    const [isLoading, setIsLoading] = useState(true)
    const [customFilterData, setCustomFilterData] = useState(undefined)

    const memoizedColumns = useMemo<Column<any>[]>(() => columns, [columns])
    const memoizedData = useMemo<any>(() => dataContext?.data ?? [], [dataContext?.data])

    const tableConfig = {
        columns: memoizedColumns,
        data: memoizedData,
        manuelPagination: true,
        manualSortBy: true,
        manualFilters: true,
        manualGlobalFilter: true,
        initialState: {
            pageIndex: 0,
            manuelPagination: true,
            hiddenColumns: [...hiddenColumns, hideIdColumn && "id"]
        } as any
    } as any

    const tableInstance: any = useTable(
        tableConfig,
        useGlobalFilter,
        usePagination
    )

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        state,
        setGlobalFilter,
    } = tableInstance

    useEffect(() => {
        events?.goToPage({searchText: state.globalFilter, customFilter: customFilterData, page: 1, pageSize: 10})
    }, [state.globalFilter, customFilterData])

    useEffect(() => {
        if (events?.refreshState) {
            const currentPage = state.page
            const totalPages = page.length

            if (currentPage > page.length) {
                state.page = 1
            }

            events?.goToPage({searchText: state.globalFilter, customFilter: customFilterData, page: state.page, pageSize: 10})
        }
    }, [events?.refreshState])

    useEffect(() => {
        setIsLoading(false)
    }, [dataContext?.data])

    const goToPage = (pageNumber: number) => {

        state.page = pageNumber ?? 1

        events?.goToPage({
            searchText: state.globalFilter,
            customFilter: customFilterData,
            page: pageNumber,
            pageSize: 10
        })
    }

    useEffect(() => {
        return () => {
            setIsLoading(true)
        }
    }, [])

    // Render the UI for your table
    return (
        <>
            <TableHeader
                {...(customFilter ? {
                    customFilter: {
                        content: customFilter.content,
                        getCustomFilter: customFilter.getCustomFilter,
                        resetCustomFilter: customFilter.resetCustomFilter,
                        setCustomFilterData: setCustomFilterData,
                        setIsLoading: setIsLoading
                    }
                } : {})}
                toolbar={toolbar}
                globalFilter={state.globalFilter}
                setGlobalFilter={setGlobalFilter}
                setIsLoading={setIsLoading}
                setCustomFilterData={setCustomFilterData}
            />

            <div className={(!dataContext?.data || isLoading) ? "d-none" : ""}>
                <div className="table-responsive min-h-350px">
                    <table {...getTableProps()}
                           className={clsx("table table-row-bordered table-row-dashed gy-4 align-middle fw-bolder")}>
                        <thead className="fs-7 text-gray-400 text-uppercase">
                        {headerGroups?.map((headerGroup: any) => (
                            <tr {...headerGroup.getHeaderGroupProps()} className="fw-bolder">
                                {headerGroup?.headers?.map((column: any) => (
                                    <th {...column.getHeaderProps([
                                        {className: column.className, width: column.width},
                                    ])}>
                                        {column.render('Header')}
                                        <div>{column.canFilter ? column.render('Filter') : null}</div>
                                    </th>
                                ))}
                            </tr>
                        ))}
                        </thead>
                        <tbody className="fs-6" {...getTableBodyProps()}>
                        {page?.map((row: any, i: any) => {
                            prepareRow(row)
                            return (
                                <tr {...row.getRowProps()}>
                                    {row?.cells?.map((cell: any) => {
                                        return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                    })}
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                </div>
                <TableFooter
                    pagination={pagination}
                    pageCount={dataContext?.pageCount ?? 0}
                    currentPage={dataContext?.currentPage ?? 1}
                    itemsCount={dataContext?.itemsCount ?? 0}
                    goToPage={goToPage}
                    setIsLoading={setIsLoading}
                />
            </div>
            {(!dataContext || isLoading) && <TableLoader/>}
        </>
    )
}