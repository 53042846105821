import {RootState} from "../../../../../setup";

const getUpgradeModalState = () => ({subscription: {upgradeModal}}: RootState) => upgradeModal.show
const getFreemiumModalState = () => ({subscription: {freemiumModal}}: RootState) => freemiumModal.show
const getCurrentPlan = () => ({subscription: {upgradeModal}}: RootState) => upgradeModal.currentPlan
const getPlans = () => ({subscription: {upgradeModal}}: RootState) => upgradeModal.packages

const getSubscriptionInfo = () => ({subscription: {info}}: RootState) => info
const getBillingInfo = () => ({subscription: {billingInfo}}: RootState) => billingInfo

export const subscriptionSelectors =
    {
        getUpgradeModalState,
        getFreemiumModalState,
        getCurrentPlan,
        getPlans,
        getSubscriptionInfo,
        getBillingInfo
    }