import {Pagination} from "./Pagination";
import React, {useEffect} from "react";
import {useIntl} from "react-intl";

export interface TableFooterContext {
    pagination: boolean,
    itemsCount: number,
    pageCount: number,
    currentPage: number,
    goToPage: any,
    setIsLoading: any
}

export const TableFooter = ({pagination, itemsCount, pageCount, currentPage, goToPage, setIsLoading}: TableFooterContext) => {
    const intl = useIntl()
    
    return (
        <>
            {intl.formatMessage({id: "TABLE.TOTAL_RECORDS"})} {itemsCount ? itemsCount : 0}
            {
                pagination &&
                <div className="float-end">

                    <Pagination
                        pageCount={pageCount}
                        currentPage={currentPage}
                        goToPage={goToPage}
                        setIsLoading={setIsLoading}
                    />
                </div>
            }
        </>
    )
}