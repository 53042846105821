import {FC, Suspense, useEffect} from 'react'
import {Route, Routes, Navigate, useLocation} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardPage} from '../pages/dashboard/DashboardPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WelcomePage} from "../pages/welcome/WelcomePage";
import {
    RedirectToWelcomePageIfThereIsNoOrganisation
} from "../infrastructure/RedirectToWelcomePageIfThereIsNoOrganisation";
import {DevicesPage} from "../pages/devices/components";
import {OrganisationPage, MyInvitationsModal} from "../pages/organisation/components/";
import {useDispatch} from "react-redux";
import {organisationActions} from "../pages/organisation";
import { AccountPage } from '../pages/account/components'

const PrivateRoutes = () => {
    
    const location = useLocation();
    const routerState = location.state as any
    const background = routerState?.background
    
    return (
        <>
            <Routes location={background || location}>
            {/*<Routes>  */}
                <Route element={<MasterLayout/>}>
                    {/* Pages */}
                    <Route path='welcome' element={<WelcomePage/>}/>
                    <Route element={<RedirectToWelcomePageIfThereIsNoOrganisation/>}>
                        <Route path='dashboard' element={<DashboardPage/>}/>
                        <Route path='/organisation/:id/*' element={<OrganisationPage/>}/>
                    </Route>
                    <Route path="/organisation-invites" element={<ModalOrganisationInvitesRoute />}/>
                    <Route path='devices' element={<DevicesPage/>}/>
                    <Route path="/account/*" element={<AccountPage />}/>
                    {/* Page Not Found */}
                    <Route path='*' element={<Navigate to='/error/404'/>}/>
                </Route>
            </Routes>
        </>
    )
}

const ModalOrganisationInvitesRoute = () => {
    
    const dispatch = useDispatch()
    
    useEffect(() => {
        // window.history.pushState({ isPopup: true }, 'Some Title');
        dispatch(organisationActions.showMyInvitationsModal({state: true, requestedFromExternalUrl: true}))
    }, [])
    
    return (
        <>
            <DashboardPage/>
            <MyInvitationsModal />
        </>
    )
}

const SuspensedView: FC = ({children}) => {
    const baseColor = getCSSVariableValue('--bs-primary')
    TopBarProgress.config({
        barColors: {
            '0': baseColor,
        },
        barThickness: 1,
        shadowBlur: 5,
    })
    return <Suspense fallback={<TopBarProgress/>}>{children}</Suspense>
}

export {PrivateRoutes}
