import {Card} from "react-bootstrap";
import {DestinationCard, DestinationsPageTitle} from "../destinations";
import {useIntl} from "react-intl";
import {useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {destinationsActions, destinationsReducerActions, destinationsSelectors} from "../../";
import {DataRequest, Table} from "../../../../../../components/tables";
import {organisationSelectors} from "../../../../";
import {ButtonWithDropdown, ButtonWithDropdownItem, ButtonWithDropdownTitle} from "../../../../../../components/buttons";
import {swal} from "../../../../../../libs/sweetAlert";
import {format} from "react-string-format";
import * as urls from "../../../../../../infrastructure/variables/urls";
import {useAppDispatch} from "../../../../../../infrastructure/hooks/reduxHooks";
import {CreateAgentModal} from "../agent";
import {Link, Route, Routes, useLocation, useNavigate} from "react-router-dom";
import clsx from "clsx";
import {Rules} from "../../../rules/components/rules/Rules";
import {DestinationTypeEnum} from "../../models";
import {EditDestinationModal} from "../editDestinationModal/EditDestinationModal";


export const Agents = () => {

    const intl = useIntl()
    const navigate = useNavigate()
    const dispatch = useAppDispatch();
    const location = useLocation()
    const organisationId = useSelector(organisationSelectors.getSelectedOrganisationId())
    const agents = useSelector(destinationsSelectors.getAgents());
    const showCreateAgentModalState = useSelector(destinationsSelectors.getCreateAgentModalState())
    const agentsTableRefreshState = useSelector(destinationsSelectors.getAgentsTableRefreshState())
    const showEditDestinationModalState = useSelector(destinationsSelectors.getEditDestinationModalState())



    const createAgent = () => {
        dispatch(destinationsActions.showCreateAgentModal({}))
    }

    const editDestination = (agentId: string) => {
        dispatch(destinationsActions.showEditDestinationModal({
            state: true,
            destinationId: agentId,
            refreshTableWhenModalClosed: true
        }))
    }

    const getAgents = (dataRequest: DataRequest | undefined = undefined) => {
        dispatch(destinationsActions.getAgents({organisationId, dataRequest}))
    }

    const deleteAgent = async (destinationId: string) => {
        dispatch(destinationsActions.deleteAgent({organisationId, destinationId}))
    }

    const columns = [
        {
            Header: "Id",
            accessor: "id",
        },
        {
            Header: intl.formatMessage({id: "ORGANISATION.DESTINATIONS.TABLE.COLUMN.NAME"}),
            accessor: "name",
            width: 'auto',
            Cell: ({cell}: any) => {

                const destinationId = cell.row.original.id
                const name = cell.row.original.name
                const type = cell.row.original.destinationTypeDetail

                return (
                    <DestinationCard
                        name={name}
                        type={type}
                        useBackgroundForIcons={true}
                        showCursorPointer={true}
                        onClick={() => navigate(format(urls.ORGANISATION_DESTINATION_AGENT_DETAIL_URL, organisationId, destinationId))}
                    />
                )
            }
        },
        {
            Header: intl.formatMessage({id: "ORGANISATION.DESTINATIONS.TABLE.COLUMN.STATUS"}),
            accessor: "isOnline",
            width: 'auto',
            Cell: ({cell}: any) => {

                const isOnline = cell.row.original.isOnline

                {
                    return (isOnline
                            ? <span className="badge badge-light-success">Online</span>
                            : <span className="badge badge-light-danger">Offline</span>
                    )
                }
            }
        },
        {
            Header: intl.formatMessage({id: "TABLE.COLUMN.ACTIONS"}),
            Cell: ({cell}: any) => {

                const destinationId = cell.row.original.id

                return (
                    <ButtonWithDropdown
                        text={intl.formatMessage({id: 'TABLE.ACTIONS.BUTTON.VIEW'})}
                        linkTo={format(urls.ORGANISATION_DESTINATION_AGENT_DETAIL_URL, organisationId, destinationId)}
                    >
                        <ButtonWithDropdownTitle text={intl.formatMessage({id: 'TABLE.ACTIONS.TITLE.ACTIONS'})}/>
                        <ButtonWithDropdownItem
                            text={intl.formatMessage({id: 'TABLE.ACTIONS.BUTTON.EDIT'})}
                            onClick={() => editDestination(destinationId)}
                        />
                        <ButtonWithDropdownItem
                            text={intl.formatMessage({id: 'ORGANISATION.DESTINATIONS.TABLE.ACTIONS.BUTTON.DELETE'})}
                            type='danger'
                            linkTo=""
                            onClick={async (e) => {
                                e.currentTarget.style.pointerEvents = "none"
                                const deletableResponse = await destinationsActions.checkDestinationDeletable(organisationId, destinationId)

                                if (deletableResponse.data) {
                                    const result = await swal.question(
                                        intl.formatMessage({id: 'SWEETALERT.MESSAGE.CONFIRM'}),
                                        intl.formatMessage({id: 'ORGANISATION.DESTINATIONS.TABLE.MESSAGE.DELETE_CONFIRM'})
                                    )
                                    if (result.isConfirmed) {
                                        await deleteAgent(destinationId)
                                    } else {
                                        e.currentTarget.style.pointerEvents = ""
                                    }
                                } else {
                                    const result = await swal.questionWithConfirm(
                                        intl.formatMessage({id: 'SWEETALERT.MESSAGE.CONFIRM'}),
                                        intl.formatMessage({id: 'ORGANISATION.DESTINATIONS.TABLE.MESSAGE.DELETE_CONFIRM_FORCE'})
                                    )
                                    if (result.isConfirmed) {
                                        await deleteAgent(destinationId)
                                    } else {
                                        e.currentTarget.style.pointerEvents = ""
                                    }
                                }

                            }}
                        />
                    </ButtonWithDropdown>
                )
            }
        }
    ]

    const toolbar = () => {
        return (
            <>
                <button type="button" className="btn btn-sm btn-light-primary" onClick={createAgent}>
                    <span className="svg-icon svg-icon-3">
						<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                             viewBox="0 0 24 24" fill="none">
													<rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5"
                                                          fill="currentColor"></rect>
													<rect x="10.8891" y="17.8033" width="12" height="2" rx="1"
                                                          transform="rotate(-90 10.8891 17.8033)"
                                                          fill="currentColor"></rect>
													<rect x="6.01041" y="10.9247" width="12" height="2" rx="1"
                                                          fill="currentColor"></rect>
												</svg>
											</span>
                    {intl.formatMessage({id: 'ORGANISATION.DESTINATIONS.TABLE.TOOLBAR.BUTTON.NEW_DESTINATION.AGENT'})}
                </button>
            </>
        )
    }

    useEffect(() => {
        return () => {
            dispatch(destinationsReducerActions.setAgents(null))
        }
    }, [])

    return (
        <>
            <DestinationsPageTitle/>

            <Card>
                <Card.Header>
                    <div className="card-toolbar">
                        <ul className="nav nav-stretch fs-5 fw-semibold nav-line-tabs border-transparent"
                            role="tablist">

                            <li className="nav-item" role="presentation">

                                <Link to={format(urls.ORGANISATION_DESTINATION_AGENTS_URL, organisationId)}
                                      className={clsx("nav-link text-active-gray-800 me-4 fw-bolder cursor-pointer", location.pathname.endsWith("agents") ? "active" : "")}>
                                    {intl.formatMessage({id: 'ORGANISATION.DESTINATIONS.AGENTS.TAB.AGENTS'})}
                                </Link>
                            </li>

                            <li className="nav-item" role="presentation">
                                <Link to={format(urls.ORGANISATION_DESTINATION_AGENTS_RULES_URL, organisationId)}
                                      className={clsx("nav-link text-active-gray-800 me-4 fw-bolder cursor-pointer", location.pathname.endsWith("rules") ? "active" : "")}>
                                    {intl.formatMessage({id: 'ORGANISATION.DESTINATIONS.AGENTS.TAB.RULES'})}
                                </Link>
                            </li>


                        </ul>
                    </div>
                </Card.Header>
                <Card.Body>
                    {location.pathname.includes("rules") && <Rules type={DestinationTypeEnum.Agent}/>}
                    {!location.pathname.includes("rules") &&
                        <Table
                            columns={columns}
                            dataContext={agents}
                            events={{
                                getData: getAgents,
                                goToPage: getAgents,
                                refreshState: agentsTableRefreshState
                            }}
                            toolbar={toolbar()}
                        />}
                </Card.Body>

            </Card>

            {showCreateAgentModalState && <CreateAgentModal/>}
            {showEditDestinationModalState && <EditDestinationModal/>}
        </>
    )
}