import React from "react";
import {useIntl} from "react-intl";
import {Button, Card} from "react-bootstrap";
import {Table, UserCard, UserSymbol} from "../../../../../../components/tables";
import {useDispatch, useSelector} from "react-redux";
import {organisationSelectors} from "../../../../";
import {DataRequest} from "../../../../../../components/tables/Table";
import {useParams} from "react-router-dom";
import {rolesActions, rolesSelectors} from "../../";
import {swal} from "../../../../../../libs/sweetAlert";
import {toastify} from "../../../../../../libs/toastify";

export const RoleUsers = () => {

    const intl = useIntl()
    const dispatch = useDispatch()

    const organisationId = useSelector(organisationSelectors.getSelectedOrganisationId())
    const users = useSelector(rolesSelectors.getOrganisationRoleDetailUsers()) as any
    const {roleId} = useParams()

    const removeFromTheRole = async (organisationUserId: string) => {
        const response = await rolesActions.removeUserFromTheRole(organisationId, organisationUserId, roleId as string)
        if (response.isSucceed) {
            getRoleUsers();
        }
    }

    const columns = [
        {
            header: "Id",
            accessor: "id",
        },
        {
            Header: intl.formatMessage({id: "ORGANISATION.USERS.TABLE.COLUMN.USER"}),
            accessor: "name",
            width: 'auto',
            Cell: ({cell}: any) => {

                const item = cell.row.original

                return (
                    <UserCard
                        name={item.name}
                        photo={item.photo}
                        email={item.email}
                        unclaimedUser={item.unclaimedUser}
                    />
                )
            }
        },
        {
            Header: intl.formatMessage({id: "TABLE.COLUMN.ACTIONS"}),
            accessor: (x: any) => x.unclaimedUser.toString(),
            Cell: ({cell}: any) => {

                const organisationUserId = cell.row.original.id

                return (
                    <>
                        <Button
                            type="button"
                            size="sm"
                            variant="danger"
                            onClick={async (e) => {
                                e.currentTarget.style.pointerEvents = "none"

                                const result = await swal.question(
                                    intl.formatMessage({id: 'SWEETALERT.MESSAGE.CONFIRM'}),
                                    intl.formatMessage({id: 'ORGANISATION.ROLE_DETAIL.MESSAGE.REMOVE_FROM_THE_ROLE_CONFIRM'})
                                )
                                if (result.isConfirmed) {
                                    await removeFromTheRole(organisationUserId)
                                } else {
                                    (e.target as HTMLButtonElement).style.pointerEvents = ""
                                }
                            }}
                        >
                            {intl.formatMessage({id: "ORGANISATION.ROLE_DETAIL.TABLE.ACTIONS.BUTTON.REMOVE_FROM_THE_ROLE"})}
                        </Button>
                    </>
                )
            }
        }
    ]

    const getRoleUsers = (dataRequest: DataRequest | undefined = undefined) => {
        dispatch(rolesActions.getRoleUsers({organisationId, roleId: roleId!, dataRequest}))
    }

    return (

        <Card>
            <Card.Body>
                <Table
                    columns={columns}
                    dataContext={users}
                    events={{
                        getData: getRoleUsers,
                        goToPage: getRoleUsers,
                        refreshState: useSelector(rolesSelectors.getOrganisationRoleDetailUsersTableRefreshState())
                    }}
                />

            </Card.Body>
        </Card>
    )
}