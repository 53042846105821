import React from "react";
import {useLang} from "../../../_metronic/i18n/Metronici18n";
import {ImageFromBase64} from "../images";

interface UserSymbolProps {
    text: string,
    photo?: string
}

export const UserSymbol = ({text, photo}: UserSymbolProps) => {

    const selectedLang = useLang()

    return (
        <div className="me-5 position-relative">
            <div className="symbol symbol-35px symbol-circle">
                {
                    !photo &&
                    <span className="symbol-label bg-light-primary text-primary fw-bold">
                          {text[0].toLocaleUpperCase(selectedLang)}
                    </span>
                }
                {
                    photo &&
                    <ImageFromBase64 base64String={photo}/>
                }
            </div>
            {/*<div*/}
            {/*    className="bg-success position-absolute border border-4 border-white h-15px w-15px rounded-circle translate-middle start-100 top-100 ms-n1 mt-n1">*/}
            {/*</div>*/}
        </div>
    )
}