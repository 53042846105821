import clsx from "clsx";
import {ScriptCodeCard} from "../../../../../../../components/cards";
import React, {useEffect, useState} from "react";
import {useIntl} from "react-intl";
import {destinationsActions} from "../../../destinationsActions";


const servicesCode = "builder.Services.AddProtectRemote();";

const servicesCodeInProgram = "builder.Services.AddProtectRemote(options =><br />" +
    "{<br />" +
    "&emsp;&emsp;// your token<br />" +
    "&emsp;&emsp;options.Token = \"TOKEN_WILL_BE_HERE\";<br />" +
    "&emsp;&emsp;// You should enable this for MVC and Razor Pages Projects. false for WebAPI.<br />" +
    "&emsp;&emsp;options.RenderHtmlPage = true;<br />" +
    "&emsp;&emsp;// Time period to request Protect Remote services, default is 15<br />" +
    "&emsp;&emsp;options.RequestIntervalInSeconds = 15;<br />" +
    "&emsp;&emsp;// Debug Mode.<br />" +
    "&emsp;&emsp;options.DebugIpAddress = \"222.222.222.222\";<br />" +
    "&emsp;&emsp;// Manually assigned ip value when debug mode is enabled.<br />" +
    "&emsp;&emsp;options.Debug = true;<br />" +
    "});"

const servicesCodeInAppSettings = "\"ProtectRemote\": {<br />" +
    "&emsp;&emsp;\"token\": \"TOKEN_WILL_BE_HERE\",<br />" +
    "&emsp;&emsp;\"renderHtmlPage\": \"true\";<br />" +
    "&emsp;&emsp;\"requestIntervalInSeconds\": \"15\",<br />" +
    "&emsp;&emsp;\"debugmode\": \"true\"<br />" +
    "&emsp;&emsp;\"debugIpAddress\": \"222.222.222.222\",<br />" +
    "}"

const middlewareCode = "app.UseProtectRemote();"
const actionFilterCode = "[PRAccess]<br />" +
    "public IActionResult Index()<br />" +
    "{<br />" +
    "&emsp;&emsp;Return View();<br />" +
    "}"


export const DotNetIntegration = ({organisationId, softwarePackageId}: { organisationId: string, softwarePackageId: string }) => {

    const intl = useIntl()

    const [selectedInstallType, setSelectedInstallType] = useState("nuget")
    const [selectedOptionsType, setSelectedOptionsType] = useState("program")
    const [selectedUsageType, setSelectedUsageType] = useState("middleware")
    const [token, setToken] = useState(intl.formatMessage({id: "ORGANISATIONS.DESTINATION.TOKEN.LOADING"}))

    useEffect(() => {
        (async () => {
            if (!softwarePackageId) return
            const response = await destinationsActions.getSoftwarePackageToken({organisationId, softwarePackageId})
            if (response.isSucceed) {
                setToken(response.data)
            }
        })()

    }, [softwarePackageId])


    return (
        <>
            <div className="fw-bolder fs-6 mb-1">
                {intl.formatMessage({id: "ORGANISATION.DESTINATION.MESSAGE.STEP1"})}
            </div>

            <div>
                {intl.formatMessage({id: "ORGANISATION.MODAL.DESTINATION.CREATE_SOFTWARE_PACKAGE.MESSAGE.DOTNET.DOWNLOAD"})}
            </div>

            <div className="mt-2">
                <ul className="nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-5 fs-6">
                    <li className="nav-item fw-bold">
                        <a className={clsx("nav-link cursor-pointer", selectedInstallType == "nuget" ? "active" : "")}
                           onClick={() => setSelectedInstallType("nuget")}>Nuget Package Manager</a>
                    </li>
                    <li className="nav-item fw-bold">
                        <a className={clsx("nav-link cursor-pointer", selectedInstallType == "dotnetcli" ? "active" : "")}
                           onClick={() => setSelectedInstallType("dotnetcli")}>.NET CLI</a>
                    </li>
                </ul>

                <div className="tab-content">
                    <div className={clsx("tab-pane fade ", selectedInstallType == "nuget" ? "show active" : "")}>
                        <ScriptCodeCard text={"Install-Package ProtectRemote"}/>
                    </div>
                    <div className={clsx("tab-pane fade ", selectedInstallType == "dotnetcli" ? "show active" : "")}>
                        <ScriptCodeCard text={"dotnet add package ProtectRemote"}/>
                    </div>
                </div>
            </div>

            <div className="fw-bolder fs-6 mt-6 mb-1">
                {intl.formatMessage({id: "ORGANISATION.DESTINATION.MESSAGE.STEP2"})}
            </div>

            <div>
                {intl.formatMessage({id: "ORGANISATION.MODAL.DESTINATION.CREATE_SOFTWARE_PACKAGE.MESSAGE.TOKEN"})}
            </div>

            <div className="my-6">
                <ScriptCodeCard text={token}/>
            </div>

            <div className="fw-bolder fs-6 mt-6 mb-1">
                {intl.formatMessage({id: "ORGANISATION.DESTINATION.MESSAGE.STEP3"})}
            </div>

            <div>
                {intl.formatMessage({id: "ORGANISATION.MODAL.DESTINATION.CREATE_SOFTWARE_PACKAGE.MESSAGE.DOTNET.BUILDER"})}
            </div>

            <div className="my-6">
                <ScriptCodeCard text={servicesCode}/>
            </div>

            <div className="fw-bolder fs-6 mt-6 mb-1">
                {intl.formatMessage({id: "ORGANISATION.DESTINATION.MESSAGE.STEP4"})}
            </div>

            <div>
                {intl.formatMessage({id: "ORGANISATION.MODAL.DESTINATION.CREATE_SOFTWARE_PACKAGE.MESSAGE.DOTNET.BUILDER2"},
                    {
                        addProtectRemote: <strong>AddProtectRemote();</strong>,
                        token: <strong>Token</strong>,
                        renderHtmlPage: <strong>RenderHtmlPage</strong>,
                        true: <strong>true</strong>
                    }
                )}
            </div>

            <div className="mt-2">
                <ul className="nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-5 fs-6">
                    <li className="nav-item fw-bold">
                        <a className={clsx("nav-link cursor-pointer", selectedOptionsType == "program" ? "active" : "")}
                           onClick={() => setSelectedOptionsType("program")}>Program.cs</a>
                    </li>
                    <li className="nav-item fw-bold">
                        <a className={clsx("nav-link cursor-pointer", selectedOptionsType == "appsettings" ? "active" : "")}
                           onClick={() => setSelectedOptionsType("appsettings")}>AppSettings.json</a>
                    </li>
                </ul>

                <div className="tab-content">
                    <div className={clsx("tab-pane fade ", selectedOptionsType == "program" ? "show active" : "")}>
                        <ScriptCodeCard text={servicesCodeInProgram}/>
                    </div>
                    <div className={clsx("tab-pane fade ", selectedOptionsType == "appsettings" ? "show active" : "")}>
                        <ScriptCodeCard text={servicesCodeInAppSettings}/>
                    </div>
                </div>
            </div>

            <div className="fw-bolder fs-6 mt-6 mb-1">
                {intl.formatMessage({id: "ORGANISATION.DESTINATION.MESSAGE.STEP5"})}
            </div>

            <div>
                {intl.formatMessage({id: "ORGANISATION.MODAL.DESTINATION.CREATE_SOFTWARE_PACKAGE.MESSAGE.DOTNET.USAGE"}, {
                    useProtectRemote: <strong>app.UseProtectRemote();</strong>,
                    prAccess: <strong>[PRAccess]</strong>
                })}
            </div>

            <div className="mt-2">
                <ul className="nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-5 fs-6">
                    <li className="nav-item fw-bold">
                        <a className={clsx("nav-link cursor-pointer", selectedUsageType == "middleware" ? "active" : "")}
                           onClick={() => setSelectedUsageType("middleware")}>Middleware</a>
                    </li>
                    <li className="nav-item fw-bold">
                        <a className={clsx("nav-link cursor-pointer", selectedUsageType == "actionfilter" ? "active" : "")}
                           onClick={() => setSelectedUsageType("actionfilter")}>Action Filter</a>
                    </li>
                </ul>

                <div className="tab-content">
                    <div className={clsx("tab-pane fade ", selectedUsageType == "middleware" ? "show active" : "")}>
                        <ScriptCodeCard text={middlewareCode}/>
                    </div>
                    <div className={clsx("tab-pane fade ", selectedUsageType == "actionfilter" ? "show active" : "")}>
                        <ScriptCodeCard text={actionFilterCode}/>
                    </div>
                </div>
            </div>
        </>
    )
}