/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import {FC, useEffect} from 'react'
import {Routes, Route, BrowserRouter, Navigate, useLocation} from 'react-router-dom'
import {shallowEqual, useSelector} from 'react-redux'
import {PrivateRoutes} from './PrivateRoutes'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {AuthPage} from '../pages/auth/components'
import {RootState} from '../../setup'
import {App} from '../App'
import {LogoutPage} from "../pages/auth/subpages";
import {authReducerActions} from "../pages/auth";
import {useAppDispatch} from "../infrastructure/hooks/reduxHooks";

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const {PUBLIC_URL} = process.env

const AppRoutes: FC = () => {
    
    const dispatch = useAppDispatch()
    
    const isAuthorized = useSelector<RootState>(({auth}) => auth.verifiedDevice, shallowEqual)

    const returnUrl = window.location.pathname + window.location.search

    useEffect(() => {
        if((returnUrl && returnUrl != "/auth/")){
            dispatch(authReducerActions.setReturnURl(returnUrl))
        }
    }, [])
    
    return (
        <BrowserRouter basename={PUBLIC_URL}>
            <Routes>
                <Route element={<App/>}>
                    <Route path='error/*' element={<ErrorsPage/>}/>
                    <Route path='logout' element={<LogoutPage/>}/>
                    {isAuthorized ? (
                        <>
                            <Route path='auth/*' element={<AuthPage/>}/>
                            <Route path='/*' element={<PrivateRoutes/>}/>
                            <Route index element={<Navigate to='/dashboard'/>}/>
                        </>
                    ) : (
                        <>
                            <Route path='auth/*' element={<AuthPage/>}/>
                            <Route path='*' element={<Navigate to='/auth' />} />
                            {/*<Route path='*' element={<Navigate to='/auth' state={(returnUrl && returnUrl != "/auth/") ? {returnUrl: returnUrl} : {}}/>}/>*/}
                        </>
                    )}
                </Route>
            </Routes>
        </BrowserRouter>
    )
}

export {AppRoutes}
