import {OverlayTrigger, Tooltip} from "react-bootstrap";
import React from "react";
import {DestinationTypeDetailEnum} from "../../models";
import clsx from "clsx";
import {ConditionalLink} from "../../../../../../components/router";

interface DestinationIcons {
    type: DestinationTypeDetailEnum,
    fileName: string
}

const icons = [
    {
        type: DestinationTypeDetailEnum.WindowsAgent,
        fileName: "windows.svg",
        tooltip: "Microsoft Windows",
        backgroundClass: "bg-light-primary"
    },
    {
        type: DestinationTypeDetailEnum.LinuxDebAgent,
        fileName: "linux.svg",
        tooltip: "Linux (Debian Based)",
        backgroundClass: "bg-light-warning"
    },
    {
        type: DestinationTypeDetailEnum.LinuxRpmAgent,
        fileName: "linux.svg",
        tooltip: "Linux (Redhat Based)",
        backgroundClass: "bg-light-warning"
    },
    {
        type: DestinationTypeDetailEnum.macOSAgent,
        fileName: "macos.svg",
        tooltip: "Apple macOS",
        backgroundClass: "bg-light-secondary"
    },
    {
        type: DestinationTypeDetailEnum.Wordpress,
        fileName: "wordpress.png",
        tooltip: "Wordpress",
        backgroundClass: "bg-light-secondary"
    },
    {
        type: DestinationTypeDetailEnum.DotNet,
        fileName: "aspnetcore.svg",
        tooltip: ".NET",
        backgroundClass: "bg-light-secondary"
    }
]

export const DestinationCard = ({name, type, useBackgroundForIcons = false, showCursorPointer = false, onClick = undefined}:
                                    {
                                        name: string,
                                        type: DestinationTypeDetailEnum,
                                        useBackgroundForIcons?: boolean,
                                        showCursorPointer?: boolean,
                                        onClick?: React.MouseEventHandler<HTMLSpanElement>
                                    }) => {

    const icon = icons.filter(x => x.type == type)[0]

    return (
        <>
            <div
                className={clsx("d-flex align-items-center", showCursorPointer ? "cursor-pointer" : "")}
                {...(onClick ? {onClick: onClick} : {})}
            >
                <OverlayTrigger
                    placement={"left"}
                    overlay={
                        <Tooltip>
                            {icon.tooltip}
                        </Tooltip>
                    }
                >
                <span className={clsx("symbol symbol-30px", useBackgroundForIcons ? "me-4" : "me-2")}>
                    <span className={clsx("symbol-label", useBackgroundForIcons ? icon.backgroundClass : "bg-transparent")}>
                        <span className="svg-icon svg-icon-5 svg-icon-primary">
                            <img src={`/assets/integrations/${icon.fileName}`} width={20} height={20}/>
                        </span>
                    </span>
                </span>
                </OverlayTrigger>
                <span>
                {name}
            </span>
            </div>
        </>
    )
}