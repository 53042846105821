import {rulesActions, rulesSelectors} from "../../../rules/";
import {useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {organisationSelectors} from "../../../../";
import {v4 as uuidv4} from 'uuid';
import {FormActionButtons, FormInputGroup, FormModal,} from "../../../../../../components/forms";
import {FormLoader} from "../../../../../../components/loaders";
import * as Yup from "yup";
import {Form, Formik} from "formik";
import {ActionResponse} from "../../../../../../infrastructure/models/actionResponse";
import {toastify} from "../../../../../../libs/toastify";
import {CreateAgentRuleModelPort, PortTypeEnum, UpdateAgentRuleModelPort} from "../../models";
import {addValidationErrors} from "../../../../../../infrastructure/utils/formikUtils";
import {OptionsOrGroups, StylesConfig} from 'react-select'
import {FormGroupReactSelect} from "../../../../../../components/forms/FormGroupReactSelect";
import {rolesActions} from "../../../roles/";
import {useNavigate} from "react-router-dom";
import {format} from "react-string-format";
import * as urls from "../../../../../../infrastructure/variables/urls";
import {destinationsActions} from "../../../destinations/";
import {DestinationTypeDetailEnum, DestinationTypeEnum} from "../../../destinations/models";
import {PortRow} from "./PortRow";
import {DestinationCard} from "../../../destinations/components/destinations/DestinationCard";

interface RoleSelectOption {
    value: string,
    label: string,
    colorCode: string
}

interface DestinationSelectOption {
    value: string,
    label: string,
    type: DestinationTypeDetailEnum
}

export interface RulePortRow {
    rowId: string,
    portId: string | "",
    portType: PortTypeEnum | "",
    portRange: boolean,
    port: number,
    portStart: number,
    portEnd?: number,
    description?: string
}

export const RuleModal = ({destinationId}: { destinationId?: string }) => {

    const intl = useIntl()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const modalState = useSelector(rulesSelectors.getRuleModalState())
    const [loading, setLoading] = useState(false)
    const [showContentLoader, setShowContentLoader] = useState(false)
    const [roles, setRoles] = useState<OptionsOrGroups<RoleSelectOption, any>>();
    const [destinations, setDestinations] = useState<OptionsOrGroups<DestinationSelectOption, any>>();
    const [showAddRolesContent, setShowAddRolesContent] = useState(false)
    const [showAddDestinationsContent, setShowAddDestinationsContent] = useState(false)
    const organisationId = useSelector(organisationSelectors.getSelectedOrganisationId())
    const ruleId = useSelector(rulesSelectors.getRuleModalRuleId())
    const modalType = useSelector(rulesSelectors.getRuleModalType())
    const ruleModal = useSelector(rulesSelectors.getRuleModal())
    const tableRefreshState = useSelector(rulesSelectors.getRulesTableRefreshState())
    const [showPortsError, setShowPortsError] = useState(false)

    const [formValues, setFormValues] = useState({
        name: '',
        ports: [{
            rowId: uuidv4(),
            portType: '',
            portRange: false

        }] as RulePortRow[],
        roles: [] as string[],
        destinations: [] as string[]
    })

    const closeModal = () => {
        if (ruleModal) {
            dispatch(rulesActions.refreshRulesTable())
        }
        
        dispatch(rulesActions.showRuleModal({state: false, type: null}))
    }

    const getRule = async (ruleId: string) => {
        const response = await rulesActions.getRule(organisationId, ruleId)
        if (response.isSucceed) {
            const rule = response.data;
            setFormValues({
                name: rule.name,
                ports: rule.ports?.length == 0 ?
                    [{
                        rowId: uuidv4(),
                        portType: '',
                        portRange: false

                    }] as RulePortRow[]
                    : rule.ports?.map(x => {
                    return {
                        rowId: uuidv4(),
                        portId: x.portId,
                        portType: x.portType,
                        portRange: x.portEnd ? true : false,
                        port: !x.portEnd ? x.portStart as number : undefined,
                        portStart: x.portEnd ? x.portStart as number : undefined,
                        portEnd: x.portEnd as number,
                        description: x.description
                    } as RulePortRow
                }) ?? [],
                roles: rule.roles?.map(x => x.roleId) ?? [],
                destinations: rule.destinations?.map(x => x.destinationId) ?? []
            })
            setShowContentLoader(false)
        }
    }

    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .min(3, intl.formatMessage({id: 'VALIDATION.MIN_LENGTH'}, {length: 3}))
            .max(50, intl.formatMessage({id: 'VALIDATION.MAX_LENGTH'}, {length: 30}))
            .required(intl.formatMessage({id: 'VALIDATION.REQUIRED'}, {name: intl.formatMessage({id: 'ORGANISATION.MODAL.RULE.INPUT.NAME'})})),
        roles: Yup.array()
            .min(1, intl.formatMessage({id: 'VALIDATION.REQUIRED'}, {name: intl.formatMessage({id: 'ORGANISATION.MODAL.RULE.INPUT.ROLES'})})),
        destinations: Yup.array()
            .min(1, intl.formatMessage({id: 'VALIDATION.REQUIRED'}, {name: intl.formatMessage({id: 'ORGANISATION.MODAL.RULE.INPUT.DESTINATIONS'})}))
    })

    useEffect(() => {
        if (ruleId) {
            setShowContentLoader(true)
            getRule(ruleId as string)
        }
    }, [ruleId])

    useEffect(() => {
        (async () => {
            setShowContentLoader(true)
            const roles = await rulesActions.getRoles(organisationId);
            const newRoles = roles?.data.data.map(x => {
                return {
                    value: x.id,
                    label: x.name,
                    colorCode: x.color
                }
            })

            setRoles(newRoles)

            const destinations = await rulesActions.getDestinations(organisationId);
            const newDestinations = destinations?.data.data.filter(x => x.destinationType == modalType!).map(x => {
                return {
                    value: x.id,
                    label: x.name,
                    type: x.destinationTypeDetail
                }
            })

            setDestinations(newDestinations)

            if (!newRoles?.length) {
                setShowAddRolesContent(true)
            } else if (!newDestinations?.length) {
                setShowAddDestinationsContent(true)
            }
            setShowContentLoader(false)
        })();

        return () => {
            setRoles(undefined)
            setDestinations(undefined)
        }
    }, [])


    const customStyles: StylesConfig = {
        control: (provided: Record<string, unknown>, state: any) => ({
            ...provided,
            height: 52,
            border: state.isFocused ? "1px solid red" : "1px solid red",
            boxShadow: state.isFocused ? "0px 0px 6px red" : "none",
            "&:hover": {
                border: "1px solid red",
                boxShadow: "none"
            }
        })
    };

    const formatOptionLabel = ({value, label, customAbbreviation}: any) => (
        <div style={{display: "flex"}}>
            <div>{label}</div>
            <div style={{marginLeft: "10px", color: "#ccc"}}>
                {customAbbreviation}
            </div>
        </div>
    );

    return (
        <FormModal
            modalState={modalState as boolean}
            closeModal={closeModal}
            dialogClass="mw-950px"
            title={intl.formatMessage({
                id: !ruleId
                    ? "ORGANISATION.MODAL.RULE.TITLE.ADD_RULE"
                    : "ORGANISATION.MODAL.RULE.TITLE.EDIT_RULE"
            })}
            subTitle={intl.formatMessage({id: "ORGANISATION.MODAL.RULE.SUBTITLE"})}
        >
            <>

                {showContentLoader && <FormLoader/>}
                {
                    !showContentLoader &&
                    (
                        <>
                            {
                                showAddRolesContent &&
                                <>
                                    <div className="text-center mb-4">
                                        <p className="text-gray-800 fs-5 fw-bold mb-13">
                                            {intl.formatMessage({id: 'ORGANISATION.MODAL.RULE.MESSAGE.ROLE_NOT_FOUND'}, {
                                                br: <br/>
                                            })}
                                            <br/><br/><a
                                            href="#">{intl.formatMessage({id: 'ORGANISATION.MODAL.RULE.MESSAGE.MORE_INFO'})}</a>
                                        </p>
                                        <button type="button" className="fs-6 px-8 py-4 btn btn-primary"
                                                onClick={() => {
                                                    navigate(format(urls.ORGANISATION_ROLES_URL, organisationId));
                                                    dispatch(rulesActions.showRuleModal({state: false, type: null}))
                                                    dispatch(rolesActions.showOrganisationRoleModal({refreshTableWhenModalClosed:true}))
                                                }}
                                        >
                                            {intl.formatMessage({id: 'ORGANISATION.MODAL.RULE.BUTTON.CREATE_ROLE'})}
                                        </button>
                                    </div>
                                </>
                            }
                            {showAddDestinationsContent &&
                                <>
                                    <div className="text-center mb-4">
                                        <p className="text-gray-800 fs-5 fw-bold mb-13">
                                            {intl.formatMessage({id: 'ORGANISATION.MODAL.RULE.MESSAGE.DESTINATION_NOT_FOUND'}, {
                                                br: <br/>
                                            })}
                                            <br/><br/><a
                                            href="#">{intl.formatMessage({id: 'ORGANISATION.MODAL.RULE.MESSAGE.MORE_INFO'})}</a>
                                        </p>
                                        <button type="button" className="fs-6 px-8 py-4 btn btn-primary"
                                                onClick={() => {
                                                    navigate(format(urls.ORGANISATION_DESTINATIONS_URL, organisationId));
                                                    dispatch(rulesActions.showRuleModal({state: false, type: null}))
                                                    dispatch(destinationsActions.showCreateAgentModal({}))
                                                }}
                                        >
                                            {intl.formatMessage({id: 'ORGANISATION.MODAL.RULE.BUTTON.CREATE_DESTINATION'})}
                                        </button>
                                    </div>
                                </>
                            }
                            {
                                (!showAddRolesContent && !showAddDestinationsContent) &&
                                <>
                                    <Formik
                                        initialValues={formValues}
                                        validationSchema={validationSchema}
                                        validateOnBlur={true}
                                        validateOnChange={true}
                                        enableReinitialize={true}
                                        onSubmit={async (values, {
                                            setSubmitting,
                                            setFieldError,
                                        }) => {

                                            if (modalType == DestinationTypeEnum.Agent && values.ports.some(item => !item.portType)) {
                                                toastify.error(intl.formatMessage({id: "ERROR.CHECK_THE_FORM_FIELDS"}))
                                                setShowPortsError(true)
                                                return;
                                            }

                                            setLoading(true);

                                            setTimeout(async () => {
                                                if (!ruleId) {
                                                    let response: ActionResponse

                                                    if (modalType == DestinationTypeEnum.Agent) {
                                                        response = await rulesActions.createAgentRule({
                                                            organisationId: organisationId,
                                                            name: values.name,
                                                            roleIds: values.roles,
                                                            destinationIds: values.destinations,
                                                            ports: values.ports.map(x => {
                                                                return {
                                                                    portType: x.portType,
                                                                    portStart: x.portRange ? x.portStart : x.port,
                                                                    portEnd: x.portRange ? x.portEnd : undefined,
                                                                    description: x.description
                                                                } as CreateAgentRuleModelPort
                                                            })
                                                        })
                                                    } else if (modalType == DestinationTypeEnum.CmsExtension) {
                                                        response = await rulesActions.createCmsExtensionRule({
                                                            organisationId: organisationId,
                                                            name: values.name,
                                                            roleIds: values.roles,
                                                            destinationIds: values.destinations
                                                        })
                                                    } else if (modalType == DestinationTypeEnum.SoftwarePackage) {
                                                        response = await rulesActions.createSoftwarePackageRule({
                                                            organisationId: organisationId,
                                                            name: values.name,
                                                            roleIds: values.roles,
                                                            destinationIds: values.destinations
                                                        })
                                                    } else {
                                                        toastify.error()
                                                        return
                                                    }

                                                    if (response.isSucceed) {
                                                        toastify.success(intl.formatMessage({id: "ORGANISATION.MODAL.RULE.MESSAGE.RULE_CREATED"}))
                                                        closeModal()
                                                    } else {
                                                        addValidationErrors(setFieldError, response.errors)
                                                        setLoading(false)
                                                        setSubmitting(false)

                                                        switch (response.message) {
                                                            case "RuleNameAlreadyExist":
                                                                toastify.error(intl.formatMessage({id: "ORGANISATION.MODAL.RULE.MESSAGE.RULE_NAME_IS_ALREADY_EXIST"}))
                                                                break
                                                            case "RuleAlreadyExistForCmsExtension":
                                                                toastify.error(intl.formatMessage({id: "ORGANISATION.MODAL.RULE.MESSAGE.RULE_ALREADY_EXIST_FOR_CMS_EXTENSION"}))
                                                                break
                                                            case "RuleAlreadyExistForSoftwarePackage":
                                                                toastify.error(intl.formatMessage({id: "ORGANISATION.MODAL.RULE.MESSAGE.RULE_ALREADY_EXIST_FOR_SOFTWARE_PACKAGE"}))
                                                                break
                                                        }
                                                    }
                                                } else {
                                                    let response: ActionResponse

                                                    if (modalType == DestinationTypeEnum.Agent) {
                                                        response = await rulesActions.updateAgentRule({
                                                            ruleId,
                                                            organisationId: organisationId,
                                                            name: values.name,
                                                            roleIds: values.roles,
                                                            destinationIds: values.destinations,
                                                            ports: values.ports.map(x => {
                                                                return {
                                                                    portType: x.portType,
                                                                    portStart: x.portRange ? x.portStart : x.port,
                                                                    portEnd: x.portRange ? x.portEnd : undefined,
                                                                    description: x.description
                                                                } as UpdateAgentRuleModelPort
                                                            })
                                                        })
                                                    } else if (modalType == DestinationTypeEnum.CmsExtension) {
                                                        response = await rulesActions.updateCmsExtensionRule({
                                                            ruleId,
                                                            organisationId: organisationId,
                                                            name: values.name,
                                                            roleIds: values.roles,
                                                            destinationIds: values.destinations
                                                        })
                                                    } else if (modalType == DestinationTypeEnum.SoftwarePackage) {
                                                        response = await rulesActions.updateSoftwarePackageRule({
                                                            ruleId,
                                                            organisationId: organisationId,
                                                            name: values.name,
                                                            roleIds: values.roles,
                                                            destinationIds: values.destinations
                                                        })
                                                    } else {
                                                        toastify.error()
                                                        return
                                                    }

                                                    if (response.isSucceed) {
                                                        toastify.success(intl.formatMessage({id: "ORGANISATION.MODAL.RULE.MESSAGE.RULE_UPDATED"}))
                                                        closeModal()
                                                    } else {
                                                        setLoading(false)
                                                        setSubmitting(false)

                                                        switch (response.message) {
                                                            case "RuleNameAlreadyExist":
                                                                toastify.error(intl.formatMessage({id: "ORGANISATION.MODAL.RULE.MESSAGE.RULE_NAME_IS_ALREADY_EXIST"}))
                                                                break
                                                            case "RecordNotFound":
                                                                toastify.error(intl.formatMessage({id: "ERROR.RECORD_NOT_FOUND"}))
                                                                break
                                                        }
                                                    }
                                                }
                                            }, 500)
                                        }
                                        }
                                    >
                                        {(formik) => (
                                            <Form className="form"
                                            >
                                                <FormInputGroup
                                                    labelResourceName={"ORGANISATION.MODAL.RULE.INPUT.NAME"}
                                                    fieldProps={formik.getFieldProps('name')}
                                                    validationCondition={(formik.errors.name)}
                                                    validationMessage={formik.errors.name}
                                                    focus={true}
                                                />

                                                <div className="d-flex flex-column">

                                                    <FormGroupReactSelect
                                                        labelResourceName={"ORGANISATION.MODAL.RULE.INPUT.DESTINATIONS"}
                                                        fieldProps={formik.getFieldProps('destinations')}
                                                        validationCondition={(formik.touched.destinations && formik.errors.destinations as string)}
                                                        validationMessage={formik.errors.destinations as string}
                                                        isMulti={true}
                                                        {...((modalType == DestinationTypeEnum.CmsExtension || modalType == DestinationTypeEnum.SoftwarePackage) ? {
                                                            maxSelectionLength: 1
                                                        } : {})}
                                                        formatOptionLabel={(data: DestinationSelectOption, formatOptionLabelMeta) => (
                                                            <>
                                                                <div style={{height: '26px'}}>
                                                                    <DestinationCard name={data.label} type={data.type}/>
                                                                </div>
                                                            </>
                                                        )}
                                                        options={destinations}
                                                        setFieldValue={formik.setFieldValue}
                                                    />

                                                </div>

                                                <div className="d-flex flex-column">

                                                    <FormGroupReactSelect
                                                        labelResourceName={"ORGANISATION.MODAL.RULE.INPUT.ROLES"}
                                                        fieldProps={formik.getFieldProps('roles')}
                                                        validationCondition={(formik.touched.roles && formik.errors.roles as string)}
                                                        validationMessage={formik.errors.roles as string}
                                                        isMulti={true}
                                                        formatOptionLabel={(data: RoleSelectOption, formatOptionLabelMeta) => (
                                                            <>
                                                                <div style={{height: '26px'}}>
                                    <span className="bullet bullet-vertical h-25px float-start me-3"
                                          style={{backgroundColor: data.colorCode}}></span>
                                                                    <span
                                                                        className="mt-1 float-start">{data.label}</span>
                                                                </div>
                                                            </>
                                                        )}
                                                        options={roles}
                                                        setFieldValue={formik.setFieldValue}
                                                    />

                                                </div>

                                                {
                                                    modalType == DestinationTypeEnum.Agent &&
                                                    <div className="mb-8">

                                                        <table className="table">
                                                            <thead className="border-bottom border-bottom-dashed">
                                                            <tr>
                                                                <th style={{width: 140}}>{intl.formatMessage({id: "ORGANISATION.MODAL.RULE.PORTS.LABEL.PORT_TYPE"})}</th>
                                                                <th>{intl.formatMessage({id: "ORGANISATION.MODAL.RULE.PORTS.LABEL.PORT_OR_PORT_RANGE"})}</th>
                                                                <th>{intl.formatMessage({id: "ORGANISATION.MODAL.RULE.PORTS.LABEL.PORT_INFORMATION"})}</th>
                                                                <th>{intl.formatMessage({id: "ORGANISATION.MODAL.RULE.PORTS.LABEL.DESCRIPTION"})}</th>
                                                                <th></th>
                                                            </tr>
                                                            </thead>
                                                            <tbody onClick={() => setShowPortsError(false)}>
                                                            {
                                                                formik.values.ports.map((item, index) => {

                                                                    return (
                                                                        <PortRow key={item.rowId} formik={formik}
                                                                                 item={item} index={index}/>
                                                                    )
                                                                })
                                                            }
                                                            {
                                                                showPortsError &&
                                                                <tr>
                                                                    <td colSpan={5}>
                                                                    <span className="text-danger">
                                                                        {intl.formatMessage({id: "ORGANISATION.MODAL.RULE.ERROR.PORTS"})}
                                                                    </span>
                                                                    </td>
                                                                </tr>
                                                            }
                                                            </tbody>
                                                            <tfoot>
                                                            <tr>
                                                                <td>
                                                                    <button type="button" className="btn btn-sm btn-success"
                                                                            onClick={async () => {

                                                                                let newIndex = formik.values.ports.length
                                                                                const newRow = {
                                                                                    rowId: uuidv4(),
                                                                                    portType: '',
                                                                                    portRange: false,
                                                                                } as RulePortRow
                                                                                const newPorts = [...formik.values.ports, newRow]
                                                                                await formik.setFieldValue('ports', newPorts)
                                                                                formik.setFieldError(`ports[${newIndex}].portType`, '')
                                                                                formik.setFieldError(`ports[${newIndex}].port`, '')
                                                                            }}
                                                                    >
                                                                        <span className="me-2">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512" fill="#ffffff"><path
                                                                                d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"/></svg>
                                                                        </span>
                                                                        {intl.formatMessage({id: "ORGANISATION.MODAL.RULE.INPUT.NEW_ROW"})}
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                            </tfoot>
                                                        </table>
                                                    </div>
                                                }


                                                <FormActionButtons
                                                    formik={formik}
                                                    loading={loading}
                                                    closeModal={closeModal}
                                                    submitButtonResourceName={!ruleId ? "FORM.CREATE" : "FORM.UPDATE"}
                                                />
                                                <div>
                                                    {/*<ShowJson title="Formik Values" object={formik.values}></ShowJson>*/}
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                    {/*<ShowJson title="FormValues State" object={formValues.ports}/>*/}
                                </>
                            }
                        </>
                    )
                }

            </>
        </FormModal>
    )

}