import React, {FC, MouseEventHandler, PropsWithChildren, useState} from "react";
import {Modal} from "react-bootstrap";
import {KTSVG} from "../../../_metronic/helpers";
import {FormikFormProps} from "formik";
import clsx from "clsx";

interface Props {
    children: JSX.Element,
    formik?: any,
    modalState: boolean,
    closeModal: any, // MouseEventHandler<HTMLButtonElement>,
    title: string,
    subTitle?: string,
    dialogClass?: string
}

export const FormModal: FC<Props> = ({children, formik, modalState, closeModal, title, subTitle, dialogClass}) => {

    const [showModal, setShowModal] = useState(true)

    const closeModalAndResetForm = (closeModal: any, formik?: any,) => {
        setShowModal(false)
        setTimeout(() => {
            closeModal()
        }, 250)
        formik && formik.resetForm()
    }

    return (
        <Modal show={showModal} onHide={closeModal} dialogClassName={dialogClass ?? "mw-650px"} backdrop="static">
            <Modal.Body>
                {/*<begin::Heading*/}
                <div className="mb-13 text-center">
                    {/*begin::Title*/}
                    <h1 className="mb-3 mt-5">
                        {title}
                    </h1>
                    {/*end::Title*/}
                    {
                        subTitle && <div className="text-muted fw-bold fs-5">{subTitle}</div>
                    }
                    <button z-index={10} className="btn btn-icon btn-sm btn-transparent ms-2 btn-active-color-dark"
                            style={{
                                float: 'right',
                                position: 'absolute',
                                right: '20px',
                                top: '20px'
                            }} onClick={() => closeModalAndResetForm(closeModal, formik)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                  transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                            <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)"
                                  fill="currentColor"></rect>
                        </svg>
                    </button>
                </div>
                {/*end::Heading*/}
                {children}
            </Modal.Body>
        </Modal>
    )
}