import {RootState} from "../../../setup";

const getCreateOrganisationModalState = () => ({organisation: {showCreateOrganisationModal}}: RootState) => showCreateOrganisationModal
const getSelectedOrganisation = () => ({organisation: {selectedOrganisation}}: RootState) => selectedOrganisation
const getSelectedOrganisationId = () => ({organisation: {selectedOrganisation}}: RootState) => selectedOrganisation.id
const getSelectedOrganisationLogo = () => ({organisation: {selectedOrganisation}}: RootState) => selectedOrganisation.logo
const getSelectedOrganisationFeatures = () => ({organisation: {selectedOrganisation}}: RootState) => selectedOrganisation.features
const getOrganisations = () => ({organisation: {organisations}}: RootState) => organisations
const getMyInvitationsModalState = () => ({organisation: {myInvitations}}: RootState) => myInvitations.showModal
const getMyInvitationsModalRequestedFromExternalUrl = () => ({organisation: {myInvitations}}: RootState) => myInvitations.requestedFromExternalUrl
const getMyInvitations = () => ({organisation: {myInvitations}}: RootState) => myInvitations.invitations

// const getSelectedTab = () => ({organisation: {selectedTab}}: RootState) => selectedTab

export const organisationSelectors =
    {
        getCreateOrganisationModalState,
        getSelectedOrganisation,
        getSelectedOrganisationId,
        getSelectedOrganisationLogo,
        getSelectedOrganisationFeatures,
        getOrganisations,
        getMyInvitationsModalState,
        getMyInvitationsModalRequestedFromExternalUrl,
        getMyInvitations,
        // getSelectedTab
    }