import CopyToClipboard from "react-copy-to-clipboard";
import {toastify} from "../../../libs/toastify";
import React, {useState} from "react";
import {useIntl} from "react-intl";
import clsx from "clsx";

export const ScriptCodeCard = ({text, className}: { text: string, className?: string }) => {

    const intl = useIntl()

    const [showCopy, setShowCopy] = useState(false)

    return (
        <div className={clsx("bg-black text-gray-400 p-7 rounded position-relative text-break", className)}
             onMouseEnter={() => setShowCopy(true)}
             onMouseLeave={() => setShowCopy(false)}
        >
            {showCopy &&
                <CopyToClipboard text={text}
                                 onCopy={() => toastify.success(intl.formatMessage({id: 'SCRIPT_CODE_BLOCK.MESSAGE.COPIED'}))}>
                    <button type="button" className="position-absolute btn btn-sm btn-active-dark"
                            style={{
                                backgroundColor: '#313236',
                                color: '#a4a4a4',
                                right: '10px',
                                top: '10px'
                            }}>
                        {intl.formatMessage({id: "SCRIPT_CODE_BLOCK.MESSAGE.COPY"})}
                    </button>
                </CopyToClipboard>
            }
            {<span dangerouslySetInnerHTML={{__html: text}}></span>}
        </div>
    )
}