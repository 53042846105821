import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {organisationSelectors} from "../../../../";
import {useParams} from "react-router-dom";
import {destinationsReducerActions, destinationsActions, destinationsSelectors} from "../../";
import {useIntl} from "react-intl";
import {useAppDispatch} from "../../../../../../infrastructure/hooks/reduxHooks";

export const DestinationInfo = () => {

    const dispatch = useAppDispatch()
    const intl = useIntl()
    const {destinationId} = useParams()

    const organisationId = useSelector(organisationSelectors.getSelectedOrganisationId())
    const detailInfo = useSelector(destinationsSelectors.getDestinationDetailInfo())
    const destinationModal = useSelector(destinationsSelectors.getEditDestinationModal())
    const getInfo = async () => {
        dispatch(destinationsActions.getDestinationInfo(organisationId, destinationId as string))
    }

    const editDestination = async () => {
        dispatch(destinationsActions.showEditDestinationModal({state: true, destinationId,  }))
    }

    useEffect(() => {
        if (!destinationModal.show) {
            getInfo()
        }
    }, [destinationModal.show])

    useEffect(() => {
        getInfo()

        return () => {
            dispatch(destinationsReducerActions.setDestinationDetailInfo(null))
        }
    }, [])

    return (
        <div className="card card-flush">
            <div className="card-header">
                <div className="card-title">
                    {/*<span className="bullet bullet-vertical h-25px float-start me-3"*/}
                    {/*      style={{backgroundColor: detailInfo.colorCode}}></span>*/}
                    <h2 className="mb-0 text-break">{detailInfo.name}</h2>
                </div>
            </div>
            <div className="card-body pt-0">
            {/*    <div className="d-flex flex-column text-gray-600">*/}
            {/*        <div className="d-flex align-items-center py-2">*/}
            {/*            <span className="bullet bg-primary me-3"></span>*/}
            {/*            {detailInfo.totalUsers} {intl.formatMessage({id: "ORGANISATION.ROLE_DETAIL.LABEL.USERS_COUNT"})}*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/*<div className="card-footer pt-0">*/}
            {/*    <button type="button"*/}
            {/*            className="btn btn-light btn-active-primary"*/}
            {/*            onClick={() => editRole()}*/}
            {/*    >*/}
            {/*        {intl.formatMessage({id: "ORGANISATION.ROLE_DETAIL.BUTTON.EDIT_ROLE"})}*/}
            {/*    </button>*/}
            </div>
            <div className="card-footer pt-0">
                <button type="button"
                        className="btn btn-light btn-active-primary"
                        onClick={() => editDestination()}
                >
                    {intl.formatMessage({id: "ORGANISATIONS.DESTINATION_DETAIL.BUTTON.EDIT_DESTINATION"})}
                </button>
            </div>
        </div>
    )
}