import React, {FC} from "react";
import {BrowserEnum, DeviceTypeEnum, OperatingSystemEnum} from "../../models";

interface Props {
    deviceType: DeviceTypeEnum,
    operatingSystem: OperatingSystemEnum,
    browser?: BrowserEnum
}

export const DeviceSymbol: FC<Props> = ({deviceType, operatingSystem, browser}) => {
    let imagePath

    switch (deviceType) {
        case DeviceTypeEnum.Web:
            switch (browser) {
                case BrowserEnum.GoogleChrome:
                    imagePath = "/media/browsers/google-chrome.png"
                    break
                case BrowserEnum.MicrosoftEdge:
                    imagePath = "/media/browsers/microsoft-edge.png"
                    break
                case BrowserEnum.MozillaFirefox:
                    imagePath = "/media/browsers/mozilla-firefox.png"
                    break
                case BrowserEnum.Opera:
                    imagePath = "/media/browsers/opera.png"
                    break
                case BrowserEnum.Safari:
                    imagePath = "/media/browsers/apple-safari.png"
                    break
                case BrowserEnum.Brave:
                    imagePath = "/media/browsers/brave.png"
                    break
                case BrowserEnum.InternetExplorer:
                    imagePath = "/media/browsers/microsoft-internet-explorer.png"
                    break
                default:
                    imagePath = "/media/browsers/other.png"
                    break
            }
            break;
        case DeviceTypeEnum.ChromeExtension:
            imagePath = "/media/browsers/google-chrome.png"
            break
        case DeviceTypeEnum.Desktop:
            switch (operatingSystem) {
                case OperatingSystemEnum.Windows:
                    imagePath = "/media/operating-systems/microsoft-windows.png"
                    break;
                case OperatingSystemEnum.MacOS:
                    imagePath = "/media/operating-systems/apple-macos.png"
                    break
                case OperatingSystemEnum.Linux:
                    imagePath = "/media/operating-systems/linux.png"
                    break
                case OperatingSystemEnum.ChromeOS:
                    imagePath = "/media/operating-systems/google-chromeos.png"
                    break
            }
            break
        case DeviceTypeEnum.Mobile:
            switch (operatingSystem) {
                case OperatingSystemEnum.IOS:
                    imagePath = "/media/operating-systems/apple-ios.png"
                    break;
                case OperatingSystemEnum.IPadOS:
                    imagePath = "/media/operating-systems/apple-ipados.png"
                    break
                case OperatingSystemEnum.Android:
                    imagePath = "/media/operating-systems/android.png"
                    break
            }
    }


    return (
        <>
            <span className="symbol-label me-5">
                <img src={imagePath} alt=""/>
            </span>
        </>
    )
}