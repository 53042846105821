import React, {FC, useState} from "react";
import clsx from "clsx";
import {Button, Dropdown} from "react-bootstrap";
import {Link} from "react-router-dom";
import {ButtonVariant} from "react-bootstrap/types";

type Props = {
    text: string
    linkTo?: string
    active?: boolean;
    variant?: ButtonVariant;
    size?: 'sm' | 'lg';
    onClick?: React.MouseEventHandler<HTMLButtonElement>
}

export const ButtonWithDropdown: FC<Props> = ({text, linkTo, children, active, variant = 'light', size, onClick}) => {

    return (
        <>
            <div className="btn-group btn-group-sm">

                {linkTo ?
                    <Link
                        to={linkTo}
                        className="btn btn-light d-flex"
                    >
                        <div className="align-self-center">{text}</div>
                    </Link>
                    :
                    <Button type="button"
                            variant={variant}
                            {...(onClick ? {onClick: onClick} : {})}
                    >
                        {text}
                    </Button>
                }


                <Dropdown>
                    <Dropdown.Toggle active={active} variant={variant} size={size} style={{
                        borderRadius: "0 .475rem .475rem 0",
                        paddingLeft: "0.8rem",
                        paddingRight: "0.3rem",
                        width: 30,
                        height: '100%'
                    }}>
                        <svg xmlns="http://www.w3.org/2000/svg" height="0.7em" viewBox="0 0 512 512"><path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"/></svg>
                        {/*<i className="fas fa-chevron-down fs-8 ms-1"></i>*/}
                    </Dropdown.Toggle>

                    <Dropdown.Menu
                        className="menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-200px py-3">
                        {children}
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </>
    )
}

type ButtonWithDropdownTitleProps = {
    text: string
}

export const ButtonWithDropdownTitle = ({text}: ButtonWithDropdownTitleProps) => {
    return (
        <div className="menu-item px-3">
            <div
                className="menu-content text-muted pb-2 px-3 fs-7 text-uppercase">
                {text}
            </div>
        </div>
    )
}

type ButtonWithDropdownItemProps = {
    text: string,
    type?: 'default' | 'primary' | 'success' | 'info' | 'warning' | 'danger',
    linkTo?: string,
    onClick?: React.MouseEventHandler<HTMLAnchorElement>
}

export const ButtonWithDropdownItem = ({text, type = 'default', linkTo, onClick}: ButtonWithDropdownItemProps) => {
    return (
        <div className="menu-item px-3">
            <Link
                className={
                    clsx("menu-link px-3",
                        type == 'primary' && "text-primary bg-hover-light-primary",
                        type == 'success' && "text-success bg-hover-light-success",
                        type == 'info' && "text-info bg-hover-light-info",
                        type == 'warning' && "text-warning bg-hover-light-warning",
                        type == 'danger' && "text-danger bg-hover-light-danger"
                    )
                }
                {...(linkTo ? {to: linkTo} : {to: ''})}
                {...(onClick ? {onClick: onClick} : {})}
            >
                {text}
            </Link>
        </div>
    )
}