import {useEffect, useRef} from 'react'
import {
    MenuComponent,
    DrawerComponent,
    ScrollComponent,
    ScrollTopComponent,
    StickyComponent,
    ToggleComponent,
    SwapperComponent,
} from '../assets/ts/components'

import {useLayout} from './core'
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {organisationActions, organisationSelectors} from "../../app/pages/organisation/";
import {CreateOrganisationModal} from "../../app/pages/organisation/components/modals/CreateOrganisationModal";
import {RootState} from "../../setup";
import {MyInvitationsModal} from "../../app/pages/organisation/components/modals/MyInvitations/MyInvitationsModal";
import {subscriptionSelectors} from "../../app/pages/organisation/subpages/subscription/subscriptionSelectors";
import {UpgradeModal} from "../../app/pages/organisation/subpages/subscription/components/upgradeModal/UpgradeModal";
import {FreemiumModal} from "../../app/pages/organisation/subpages/subscription/components/freemiumModal";

export function MasterInit() {
    const dispatch = useDispatch()
    const {config} = useLayout()
    const isFirstRun = useRef(true)
    const isAuthorized = useSelector<RootState>(({auth}) => auth.verifiedDevice, shallowEqual)
    const createOrganisationModalState = useSelector(organisationSelectors.getCreateOrganisationModalState())
    const myInvitationsModalState = useSelector(organisationSelectors.getMyInvitationsModalState())
    const subscriptionUpgradeModalState = useSelector(subscriptionSelectors.getUpgradeModalState())
    const subscriptionFreemiumModalState = useSelector(subscriptionSelectors.getFreemiumModalState())

    const pluginsInitialization = () => {
        isFirstRun.current = false
        setTimeout(() => {
            ToggleComponent.bootstrap()
            ScrollTopComponent.bootstrap()
            DrawerComponent.bootstrap()
            StickyComponent.bootstrap()
            MenuComponent.bootstrap()
            ScrollComponent.bootstrap()
            SwapperComponent.bootstrap()
        }, 500)
    }

    useEffect(() => {
        if (isFirstRun.current) {
            isFirstRun.current = false
            pluginsInitialization()
        }
    }, [config])

    useEffect(() => {
        (async () => {
            if (isAuthorized) {
                dispatch(organisationActions.getOrganisations())
            }
        })()
    }, [isAuthorized])

    return <>
        {createOrganisationModalState && <CreateOrganisationModal/>}
        {myInvitationsModalState && <MyInvitationsModal/>}
        {subscriptionUpgradeModalState && <UpgradeModal/>}
        {subscriptionFreemiumModalState && <FreemiumModal/>}
    </>
}
