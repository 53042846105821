import {DataRequest, Table} from "../../../../../../components/tables";
import {RuleModal} from "../ruleModal/RuleModal";
import React, {Fragment, useEffect} from "react";
import {ButtonWithDropdown, ButtonWithDropdownItem, ButtonWithDropdownTitle} from "../../../../../../components/buttons";
import {rulesActions} from "../../rulesActions";
import {swal} from "../../../../../../libs/sweetAlert";
import {toastify} from "../../../../../../libs/toastify";
import {rulesReducerActions} from "../../rulesSlice";
import {useIntl} from "react-intl";
import {useSelector} from "react-redux";
import {organisationSelectors} from "../../../../organisationSelectors";
import {OrganisationModel} from "../../../../models";
import {rulesSelectors} from "../../rulesSelectors";
import {PortTypeEnum, RuleModel} from "../../models";
import {SelectDropdown, SelectDropdownItem} from "../../../../../../components/select";
import {DestinationTypeEnum} from "../../../destinations/models";
import clsx from "clsx";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {useAppDispatch} from "../../../../../../infrastructure/hooks/reduxHooks";

export const Rules = ({destinationId, type = undefined}: { type?: DestinationTypeEnum, destinationId?: string }) => {

    const intl = useIntl()
    const dispatch = useAppDispatch()
    const organisation = useSelector(organisationSelectors.getSelectedOrganisation()) as OrganisationModel
    const rules = useSelector(rulesSelectors.getRules())
    const showRuleModal = useSelector(rulesSelectors.getRuleModalState())

    const columns = [
        {
            Header: "Id",
            accessor: "id",
        },
        {
            accessor: "isActive",
            width: 10,
            Cell: ({cell}: any) => {
                const item = cell.row.original as RuleModel

                return (
                    // <>
                    //     <span className="float-start">{item.name}</span>{!item.isActive && <small className="badge badge-danger float-start ms-1">{intl.formatMessage({id: "ORGANISATION.RULES.TABLE.STATE.INACTIVE"})}</small>}
                    // </>
                    <span className="d-flex align-items-center">
                        <OverlayTrigger
                            placement={"left"}
                            overlay={
                                <Tooltip>
                                    {item.isActive ? intl.formatMessage({id: "ORGANISATION.RULES.TABLE.STATE.ACTIVE"}) : intl.formatMessage({id: "ORGANISATION.RULES.TABLE.STATE.INACTIVE"})}
                                </Tooltip>
                            }
                        >
                    <span
                        className={clsx("bullet", item.isActive ? "bg-success" : "bg-danger")} style={{height: 11, width: 11}}
                    ></span>
                </OverlayTrigger>
                    </span>
                )
            }
        },
        {
            Header: intl.formatMessage({id: "ORGANISATION.RULES.TABLE.COLUMN.NAME"}),
            accessor: "name",
            width: 'auto',
            Cell: ({cell}: any) => {

                const ruleId = cell.row.original.id
                const ruleName = cell.row.original.name
                const ruleDestinationType: DestinationTypeEnum = cell.row.original.ruleDestinationType

                return (
                    <span className="cursor-pointer" onClick={() => editRule(ruleId, ruleDestinationType)}>{ruleName}</span>
                )
            }
        },
        {
            Header: intl.formatMessage({id: "ORGANISATION.RULES.TABLE.COLUMN.PORTS"}),
            width: 'auto',
            Cell: ({cell}: any) => {

                const item = cell.row.original as RuleModel

                return (
                    <>
                        {item.ports?.map(x => {
                            return (
                                <Fragment key={x.portId}>
                                    {
                                        x.portType == PortTypeEnum.TCP &&
                                        <span
                                            className="badge badge-lg badge-outline badge-light-primary border-dashed border-primary opacity-75 me-2">
                                            {PortTypeEnum.TCP} {x.portStart}{x.portEnd && ` - ${x.portEnd}`}
                                         </span>
                                    }
                                    {
                                        x.portType == PortTypeEnum.UDP &&
                                        <span
                                            className="badge badge-lg badge-outline badge-light-danger border-dashed border-danger opacity-75 me-2">
                                            {PortTypeEnum.UDP} {x.portStart}{x.portEnd && ` - ${x.portEnd}`}
                                         </span>
                                    }
                                    {
                                        x.portType == PortTypeEnum.Both &&
                                        <span
                                            className="badge badge-lg badge-outline badge-light-success border-dashed border-success opacity-75 me-2">
                                            {PortTypeEnum.TCP} & {PortTypeEnum.UDP} {x.portStart}{x.portEnd && ` - ${x.portEnd}`}
                                         </span>
                                    }
                                </Fragment>
                            )

                        })}
                    </>
                )


                // return (
                //     <span className="text-muted">
                //         {(!item.portEnd || item.portStart == item.portEnd) && item.portStart}
                //         {(item.portEnd && item.portStart != item.portEnd) && `${item.portStart} - ${item.portEnd}`}
                //     </span>
                // )
            }
        },
        {
            Header: intl.formatMessage({id: "TABLE.COLUMN.ACTIONS"}),
            Cell: ({cell}: any) => {

                const ruleId = cell.row.original.id
                const ruleDestinationType: DestinationTypeEnum = cell.row.original.ruleDestinationType
                const isActive = cell.row.original.isActive

                return (
                    <ButtonWithDropdown
                        text={intl.formatMessage({id: 'TABLE.ACTIONS.BUTTON.EDIT'})}
                        onClick={() => editRule(ruleId, ruleDestinationType)}
                    >
                        <ButtonWithDropdownTitle text={intl.formatMessage({id: 'TABLE.ACTIONS.TITLE.ACTIONS'})}/>
                        <ButtonWithDropdownItem
                            text={isActive
                                ? intl.formatMessage({id: "ORGANISATION.RULES.TABLE.ACTIONS.BUTTON.DEACTIVATE"})
                                : intl.formatMessage({id: "ORGANISATION.RULES.TABLE.ACTIONS.BUTTON.ACTIVATE"})}
                            onClick={async (e) => {
                                e.currentTarget.style.pointerEvents = "none"

                                const result = await swal.question(
                                    intl.formatMessage({id: 'SWEETALERT.MESSAGE.CONFIRM'})
                                )

                                if (result.isConfirmed) {
                                    await activateRule(organisation.id, ruleId, isActive ? false : true)
                                } else {
                                    e.currentTarget.style.pointerEvents = ""
                                }
                            }}
                        />
                        <ButtonWithDropdownItem
                            text={intl.formatMessage({id: 'ORGANISATION.RULES.TABLE.ACTIONS.BUTTON.DELETE'})}
                            type='danger'
                            linkTo=""
                            onClick={async (e) => {
                                e.currentTarget.style.pointerEvents = "none"
                                const deletableResponse = await rulesActions.checkRuleDeletable(organisation.id, ruleId)

                                if (deletableResponse.data) {
                                    const result = await swal.question(
                                        intl.formatMessage({id: 'SWEETALERT.MESSAGE.CONFIRM'}),
                                        intl.formatMessage({id: 'ORGANISATION.RULES.TABLE.MESSAGE.DELETE_CONFIRM'})
                                    )
                                    if (result.isConfirmed) {
                                        await deleteRule(ruleId)
                                    } else {
                                        e.currentTarget.style.pointerEvents = ""
                                    }
                                } else {
                                    const result = await swal.questionWithConfirm(
                                        intl.formatMessage({id: 'SWEETALERT.MESSAGE.CONFIRM'}),
                                        intl.formatMessage({id: 'ORGANISATION.RULES.TABLE.MESSAGE.DELETE_CONFIRM_FORCE'})
                                    )
                                    if (result.isConfirmed) {
                                        await deleteRule(ruleId)
                                    } else {
                                        e.currentTarget.style.pointerEvents = ""
                                    }
                                }

                            }}
                        />
                    </ButtonWithDropdown>
                )
            }
        }
    ]

    const toolbar = () => {
        return (
            <>
                {!type &&
                    <SelectDropdown
                        textResource="ORGANISATION.RULES.TABLE.TOOLBAR.BUTTON.NEW_RULE"
                    >
                        <SelectDropdownItem textResource="ORGANISATION.RULES.TABLE.TOOLBAR.BUTTON.NEW_RULE.SERVER" style={{minWidth: 160}}
                                            onClick={() => newRule(DestinationTypeEnum.Agent)}/>
                        <SelectDropdownItem textResource="ORGANISATION.RULES.TABLE.TOOLBAR.BUTTON.NEW_RULE.CMS" style={{minWidth: 160}}
                                            onClick={() => newRule(DestinationTypeEnum.CmsExtension)}/>
                        <SelectDropdownItem textResource="ORGANISATION.RULES.TABLE.TOOLBAR.BUTTON.NEW_RULE.SOFTWARE_PACKAGE" style={{minWidth: 160}}
                                            onClick={() => newRule(DestinationTypeEnum.SoftwarePackage)}/>
                    </SelectDropdown>
                }

                {type &&
                    <button type="button" className="btn btn-sm btn-light-primary" onClick={() => newRule(type)}>
                    <span className="svg-icon svg-icon-3">
						<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                             viewBox="0 0 24 24" fill="none">
													<rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5"
                                                          fill="currentColor"></rect>
													<rect x="10.8891" y="17.8033" width="12" height="2" rx="1"
                                                          transform="rotate(-90 10.8891 17.8033)"
                                                          fill="currentColor"></rect>
													<rect x="6.01041" y="10.9247" width="12" height="2" rx="1"
                                                          fill="currentColor"></rect>
												</svg>
											</span>
                        {intl.formatMessage({id: 'ORGANISATION.RULES.TABLE.TOOLBAR.BUTTON.NEW_RULE'})}
                    </button>
                }
            </>
        )
    }

    const getRules = (dataRequest: DataRequest | undefined = undefined) => {
        dispatch(rulesActions.getRules({organisationId: organisation.id, destinationId, type, dataRequest}))
    }

    const newRule = async (type: DestinationTypeEnum) => {
        dispatch(rulesActions.showRuleModal({state: true, type: type, refreshTableWhenModalClosed: true}))
    }

    const editRule = async (ruleId: string, type: DestinationTypeEnum) => {
        dispatch(rulesActions.showRuleModal({state: true, type: type, ruleId, refreshTableWhenModalClosed: true}))
    }

    const deleteRule = async (roleId: string) => {
        const response = await rulesActions.deleteRule(organisation.id, roleId)

        if (response.isSucceed) {
            dispatch(rulesActions.refreshRulesTable())
            toastify.success(intl.formatMessage({id: 'ORGANISATION.RULES.TABLE.MESSAGE.RULE_DELETED'}))
        } else {
            toastify.error()
        }


    }

    const activateRule = async (organisationId: string, ruleId: string, state: boolean) => {

        await rulesActions.activateRule({
            organisationId,
            ruleId,
            state
        })

        if (state) {
            toastify.success(intl.formatMessage({id: "ORGANISATION.RULES.TABLE.MESSAGE.RULE_ACTIVATED"}))
        } else {
            toastify.success(intl.formatMessage({id: "ORGANISATION.RULES.TABLE.MESSAGE.RULE_DEACTIVATED"}))
        }

        dispatch(rulesActions.refreshRulesTable())
    }

    useEffect(() => {
        return () => {
            dispatch(rulesReducerActions.setRules(null))
            dispatch(rulesActions.showRuleModal({state: false, type: null}))
        }
    }, [])

    return (
        <>
            <Table
                columns={columns}
                dataContext={rules}
                events={{
                    getData: getRules,
                    goToPage: getRules,
                    refreshState: useSelector(rulesSelectors.getRulesTableRefreshState())
                }}
                toolbar={toolbar()}
            />

            {showRuleModal && <RuleModal destinationId={destinationId}/>}
        </>
    )
}