import {TableSearch} from "./TableSearch";
import React, {FC, useEffect} from "react";
import {CustomFilterProps, TableFilter} from "./TableFilter";

interface Props {
    customFilter?: CustomFilterProps
    toolbar?: any,
    globalFilter: any,
    setGlobalFilter: any
    setCustomFilterData: any,
    setIsLoading: any
}

export const TableHeader: FC<Props> = ({customFilter, toolbar, globalFilter, setGlobalFilter, setCustomFilterData, setIsLoading}) => {

    return (
        <>
            <div className="card-header border-0 px-0">
                <div className="card-title">
                    <TableSearch
                        globalFilter={globalFilter}
                        setGlobalFilter={setGlobalFilter}
                        setIsLoading={setIsLoading}
                    />
                    {customFilter &&
                        <TableFilter
                            content={customFilter?.content}
                            getCustomFilter={customFilter?.getCustomFilter}
                            resetCustomFilter={customFilter?.resetCustomFilter}
                            setCustomFilterData={setCustomFilterData}
                            setIsLoading={setIsLoading}
                        />
                    }
                </div>
                {
                    toolbar &&
                    <div className="card-toolbar">
                        {toolbar}
                    </div>
                }

            </div>
        </>
    )
}