import {useDispatch, useSelector} from "react-redux";
import {InviteState, SearchResultModel} from "../../models/InviteUsersModel";
import clsx from "clsx";
import {useLang} from "../../../../../../../_metronic/i18n/Metronici18n";
import {useIntl} from "react-intl";
import {usersReducerActions, usersSelectors} from "../../../../subpages/users/";
import {Fragment} from "react";
import { ImageFromBase64 } from "../../../../../../components/images";
import {UserType} from "../../../../../../infrastructure/enums/userType";

export const SearchResult = () => {
    const searchResult = useSelector(usersSelectors.getInviteUsersSearchResult())
    const dispatch = useDispatch()
    const selectedLang = useLang()
    const intl = useIntl()

    const changeUserRole = async (email: string, value: string) => {
        dispatch(usersReducerActions.setUserRoleForInvite({email, userType: value}))
    }


    const template = (searchResult: any) => {
        return searchResult.map((user: SearchResultModel, index: number) => {

            const hasUser = user.inviteState === InviteState.HasUser ||
                user.inviteState === InviteState.HasUserInTheOrganisation ||
                user.inviteState === InviteState.HasUserInTheOrganisationAsInvite;

            return (
                <Fragment key={user.email}>
                    <div className="rounded d-flex flex-stack bg-active-lighten p-4">
                        <div className="d-flex align-items-center">
                            <div className="symbol symbol-35px symbol-circle">
                                {user.photo
                                    ? <ImageFromBase64 base64String={user.photo} />
                                    : <span
                                        className={clsx("symbol-label fw-bold", hasUser ? "bg-light-primary text-primary" : "bg-light-danger text-danger")}>
                                        {
                                            hasUser
                                                ? user.name.charAt(0).toLocaleUpperCase(selectedLang) //.toLocaleUpperCase(getCurrentCulture())
                                                : user.email.charAt(0).toLocaleUpperCase(selectedLang) //.toLocaleUpperCase(getCurrentCulture())
                                        }    
                                        </span>}
                            </div>
                            <div className="ms-5">
                                <a href="#" className="fs-5 fw-bolder text-gray-900 text-hover-primary mb-2">
                                    {user.name}
                                </a>
                                <div className={clsx("fw-bold", hasUser && "text-muted")}>{user.email}</div>
                                {
                                    (user.inviteState === InviteState.HasNoUser || user.inviteState === InviteState.HasUnclaimedUser) &&
                                    <div className="text-muted">
                                        {intl.formatMessage({id: 'ORGANISATION.MODAL.INVITE_USERS.SEARCH_RESULT.MESSAGE.INVITATION_WILL_SEND'})}
                                    </div>
                                }
                                {
                                    user.inviteState === InviteState.HasUnclaimedUserAsInvite &&
                                    <div className="text-danger">
                                        {intl.formatMessage({id: 'ORGANISATION.MODAL.INVITE_USERS.SEARCH_RESULT.MESSAGE.ALREADY_INVITED_AS_UNCLAIMED_USER'})}
                                    </div>
                                }
                                {
                                    user.inviteState === InviteState.HasUserInTheOrganisationAsInvite &&
                                    <div className="text-danger">
                                        {intl.formatMessage({id: 'ORGANISATION.MODAL.INVITE_USERS.SEARCH_RESULT.MESSAGE.ALREADY_INVITED_AS_USER'})}
                                    </div>
                                }
                                {
                                    user.inviteState === InviteState.HasUserInTheOrganisationAsRejectedInvite &&
                                    <div className="fw-bold text-danger">
                                        {intl.formatMessage({id: 'ORGANISATION.MODAL.INVITE_USERS.SEARCH_RESULT.MESSAGE.REJECTED'})}
                                    </div>
                                }
                                {
                                    user.inviteState === InviteState.HasUserInTheOrganisation &&
                                    <div className="fw-bold text-primary">
                                        {intl.formatMessage({id: 'ORGANISATION.MODAL.INVITE_USERS.SEARCH_RESULT.MESSAGE.HAS_USER_IN_THE_ORGANISATION'})}
                                    </div>
                                }
                                {
                                    user.inviteState === InviteState.OrganisationBlocked &&
                                    <div className="fw-bold text-danger">
                                        {intl.formatMessage({id: 'ORGANISATION.MODAL.INVITE_USERS.SEARCH_RESULT.MESSAGE.BLOCKED'})}
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="ms-2">
                            <select className="form-select form-select-solid form-select-sm w-150px"
                                    disabled={user.inviteState === InviteState.HasUserInTheOrganisation}
                                    data-control="select2"
                                    data-hide-search="true"
                                    value={user.userType}
                                    onChange={(e) => changeUserRole(user.email, e.currentTarget.value)}
                                    style={user.inviteState === InviteState.HasUserInTheOrganisation ? {backgroundImage: "none"} : {}}
                            >
                                <option
                                    value={UserType.User}>{intl.formatMessage({id: 'ORGANISATION.MODAL.INVITE_USERS.SEARCH_RESULT.USER_TYPE.USER'})}</option>
                                {/*<option*/}
                                {/*    value={UserType.Moderator}>{intl.formatMessage({id: 'ORGANISATION.MODAL.INVITE_USERS.SEARCH_RESULT.USER_TYPE.MODERATOR'})}</option>*/}
                                <option
                                    value={UserType.Admin}>{intl.formatMessage({id: 'ORGANISATION.MODAL.INVITE_USERS.SEARCH_RESULT.USER_TYPE.ADMIN'})}</option>
                            </select>
                        </div>
                    </div>
                    {
                        index + 1 != searchResult.length &&
                        <div className="border-bottom border-gray-300 border-bottom-dashed"></div>
                    }
                </Fragment>
            )
        })
    }


    return (
        <div className="mh-375px min-h-70px scroll-y me-n7 pe-7">
            {searchResult?.length > 0 && template(searchResult)}
        </div>
    )
}