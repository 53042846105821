import {Link} from "react-router-dom";
import {toAbsoluteUrl} from "../../../../_metronic/helpers";

const Logo = () => {
    return (
        <Link to='/' className='my-5 my-md-15 text-center'>
            <img alt='Logo' src={toAbsoluteUrl('/assets/protectremote-logo.svg')} className='h-35px' style={{maxWidth: "95%"}}/>
        </Link>
    )
}

export default Logo

