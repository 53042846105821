import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ChangeUserTypeModelUser, SearchResultModel, UsersModel, UsersStateModel} from "./models";
import {DataResponse} from "../../../../components/tables";

const initial: UsersStateModel = {
    table: {
        data: null,
        refreshTable: false
    },
    inviteUsers: {
        showInviteUsersModal: false,
        searchResult: []
    },
    changeUserType: {
        showChangeUserTypeModal: false,
        user: null
    }
}

const {actions, reducer} = createSlice({
    name: 'organisationUsers',
    initialState: initial,
    reducers: {
        setShowInviteUsersModal: (state, action: PayloadAction<boolean>) => {
            state.inviteUsers = {...state.inviteUsers, showInviteUsersModal: action.payload}
        },
        setChangeUserTypeModal: (state, action: PayloadAction<{ state: boolean, user: ChangeUserTypeModelUser | null }>) => {
            state.changeUserType = {
                ...state.changeUserType,
                showChangeUserTypeModal: action.payload.state,
                user: action.payload.user
            }
        },
        setSearchResultForInvite: (state, action: PayloadAction<SearchResultModel[]>) => {
            state.inviteUsers = {...state.inviteUsers, searchResult: action.payload}
        },
        setUserRoleForInvite: (state, action: PayloadAction<{ email: string, userType: string }>) => {
            const newSearchResult = state.inviteUsers.searchResult.map((user: SearchResultModel) => {
                if (user.email === action.payload.email) {
                    return {
                        ...user,
                        userType: action.payload.userType
                    }
                }

                return user
            });

            state.inviteUsers = {...state.inviteUsers, searchResult: newSearchResult}
        },
        setUsers: (state, action: PayloadAction<DataResponse<UsersModel> | null>) => {
            state.table = {
                data: action.payload ?? initial.table.data,
                refreshTable: false
            }
        },
        setUsersRefreshTable: (state) => {
            state.table.refreshTable = true
        }
    }
})

export const usersReducer = reducer
export const usersReducerActions = actions