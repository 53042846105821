import {Button} from "react-bootstrap";
import {useIntl} from "react-intl";
import {useDispatch} from "react-redux";
import {organisationActions} from "../organisation";

export const WelcomePage = () => {
    const intl = useIntl()
    const dispatch = useDispatch()

    return (
        <>
            {/*begin::Card*/}
            <div className="card">
                {/*begin::Card body*/}
                <div className="card-body p-0">
                    {/*begin::Heading*/}
                    <div className="card-px text-center py-15 my-10">
                        <img src="/assets/protectremote-icon.svg" width="50" className="mb-7"/>
                        {/*begin::Title*/}
                        <h2 className="fs-2x fw-bolder mb-10">{intl.formatMessage({id: 'WELCOME.TITLE'})}</h2>
                        {/*end::Title*/}
                        {/*begin::Description*/}
                        <p className="text-gray-400 fs-5 fw-bold mb-13">
                            {intl.formatMessage({id: 'WELCOME.MESSAGE'}, {br: <br/>})}
                        </p>
                        {/*end::Description*/}
                        {/*begin::Action*/}
                        <Button variant="primary" className="fs-6 px-8 py-4"
                                onClick={() => dispatch(organisationActions.showCreateOrganisationModal())}>
                            {intl.formatMessage({id: 'WELCOME.CREATE_ORGANISATION'})}
                        </Button>
                        {/*end::Action*/}
                        {/*begin::Action*/}
                        <Button variant="outline-primary"
                                className="btn-outline btn-outline-dashed btn-active-light-primary fs-6 px-8 py-4 ms-3"
                                onClick={() => dispatch(organisationActions.showMyInvitationsModal({
                                    state: true,
                                    requestedFromExternalUrl: false
                                }))}>
                            {intl.formatMessage({id: 'WELCOME.MY_INVATIONS'})}
                        </Button>
                        {/*end::Action*/}
                    </div>
                    {/*end::Heading*/}
                    {/*begin::Illustration*/}
                    <img src="/assets/media/svg/illustrations/networks.svg" alt="" className="mx-auto w-100 mh-300px"/>
                    {/*end::Illustration*/}
                </div>
                {/*end::Card body*/}
            </div>
            {/*end::Card*/}
        </>
    )
}