import {SubscriptionStateModel, SubscriptionStateModelBillingInfo, SubscriptionStateModelInfo} from "./models/SubscriptionStateModel";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {PackagesModel} from "./models/PackagesModel";

const initial: SubscriptionStateModel = {
    upgradeModal: {
        show: false,
        packages: []
    },
    freemiumModal: {
        show: false
    },
    info: {
        packageName: '',
        packageKey: '',
        currentPeriodStart: '',
        currentPeriodEnd: '',
        priceMonthly: '',
        priceYearly: '',
        cancelAtPeriodEnd: false
    },
    billingInfo: {
        name: '',
        email: ''
    }
}

const {actions, reducer} = createSlice({
    name: 'subscription',
    initialState: initial,
    reducers: {
        setShowUpgradeModal: (state, action: PayloadAction<{ state?: boolean, currentPlan?: 'freemium' | 'starter' | 'growth' | 'scale' }>) => {
            state.upgradeModal.show = action.payload.state ?? true
            state.upgradeModal.currentPlan = action.payload.currentPlan ?? initial.upgradeModal.currentPlan
        },
        setShowFreemiumModal: (state, action: PayloadAction<{ state?: boolean }>) => {
            state.freemiumModal.show = action.payload.state ?? true
        },
        setUpgradeModalPackages: (state, action: PayloadAction<PackagesModel[]>) => {
            state.upgradeModal.packages = action.payload ?? initial.upgradeModal.packages
        },
        setSubscriptionInfo: (state, action: PayloadAction<SubscriptionStateModelInfo>) => {
            state.info = action.payload ?? initial.info
        },
        setBillingInfo: (state, action: PayloadAction<SubscriptionStateModelBillingInfo>) => {
            state.billingInfo = action.payload ?? initial.info
        }
    }
})

export const subscriptionReducer = reducer
export const subscriptionReducerActions = actions