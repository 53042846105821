import React, {useEffect, useState} from "react";
import {useIntl} from "react-intl";
import {useSelector} from "react-redux";
import {OrganisationModel} from "../../../models/OrganisationModel";
import {Card} from "react-bootstrap";
import {DataRequest, Table} from "../../../../../components/tables/Table";
import {
    ButtonWithDropdown,
    ButtonWithDropdownItem,
    ButtonWithDropdownTitle
} from "../../../../../components/buttons/ButtonWithDropdown";
import {useLang} from "../../../../../../_metronic/i18n/Metronici18n";
import {TableFilterRow} from "../../../../../components/tables/TableFilter";
import {usersActions, usersReducerActions, usersSelectors} from '../'
import {rolesActions} from "../../roles/";
import {UsersPageTitle} from "./UsersPageTitle";
import {UserState} from "../../../../../infrastructure/enums/userState";
import {UserType} from "../../../../../infrastructure/enums/userType";
import clsx from "clsx";
import {DateTime} from "luxon";
import {
    checkDateExpiredFromISO,
    formatRelativeFromISO
} from "../../../../../infrastructure/utils/dateUtils";
import {UsersModel} from "../models";
import {swal} from "../../../../../libs/sweetAlert";
import {toastify} from "../../../../../libs/toastify";
import {organisationSelectors, organisationActions} from "../../../";
import {ImageFromBase64} from "../../../../../components/images";
import {useAppDispatch} from "../../../../../infrastructure/hooks/reduxHooks";

const CustomFilterContent = ({state, setState, userType, setUserType}: any) => {

    const intl = useIntl()

    return (
        <>
            <TableFilterRow
                labelText={intl.formatMessage({id: 'ORGANISATION.USERS.TABLE.FILTER.STATE'})}
                content={
                    <select
                        className="form-select form-select-sm form-select-solid fw-bolder"
                        onChange={(e) => {
                            const value = e.currentTarget?.selectedIndex == 0
                                ? ''
                                : e.currentTarget.value as | UserState.Invited | UserState.Accepted
                            setState(value)
                        }}
                        value={state}
                    >
                        <option
                            value=''>{intl.formatMessage({id: 'ORGANISATION.USERS.TABLE.FILTER.STATE.ALL'})}</option>
                        <option
                            value={`${UserState.OrganisationCreator.toString()}, ${UserState.Accepted.toString()}`}>{intl.formatMessage({id: 'ORGANISATION.USERS.TABLE.FILTER.STATE.ACCEPTED'})}</option>
                        <option
                            value={UserState.Invited}>{intl.formatMessage({id: 'ORGANISATION.USERS.TABLE.FILTER.STATE.INVITED'})}</option>
                    </select>
                }
            />
            <TableFilterRow
                labelText={intl.formatMessage({id: 'ORGANISATION.USERS.TABLE.FILTER.USER_TYPE'})}
                content={
                    <select
                        className="form-select form-select-sm form-select-solid fw-bolder"
                        onChange={(e) => {
                            const value = e.currentTarget?.selectedIndex == 0
                                ? ''
                                : e.currentTarget.value as UserType.Admin | UserType.Moderator | UserType.User | ''
                            setUserType(value)
                        }}
                        value={userType}
                    >
                        <option
                            value=''>{intl.formatMessage({id: 'TABLE_FILTER.FILTER_ALL'})}</option>
                        <option
                            value={UserType.Admin.toString()}>{intl.formatMessage({id: 'ENUMS.USERTYPE.ADMIN'})}</option>
                        {/*<option*/}
                        {/*    value={UserType.Moderator.toString()}>{intl.formatMessage({id: 'ENUMS.USERTYPE.MODERATOR'})}</option>*/}
                        <option
                            value={UserType.User.toString()}>{intl.formatMessage({id: 'ENUMS.USERTYPE.USER'})}</option>
                    </select>
                }
            />
        </>
    )
}

export const UsersPage = () => {

    const intl = useIntl()
    const dispatch = useAppDispatch()
    const selectedLang = useLang()
    const organisation = useSelector(organisationSelectors.getSelectedOrganisation()) as OrganisationModel
    const organisationUsers = useSelector(usersSelectors.getUsers())
    const [stateCustomFilter, setStateCustomFilter] = useState<UserState.Accepted | UserState.Invited | ''>('')
    const [userTypeCustomFilter, setUserTypeCustomFilter] = useState<UserType.Admin | UserType.Moderator | UserType.User | ''>('')
    const [searchTextCustomFilter, setSearchTextCustomFilter] = useState('')

    const editUsersRole = (organisationUserId: string) => {
        dispatch(rolesActions.showEditUsersRoleModal({
            state: true,
            organisationUserId: organisationUserId
        }))
    }

    const columns = [
        {
            header: "Id",
            accessor: "id",
        },
        {
            Header: intl.formatMessage({id: "ORGANISATION.USERS.TABLE.COLUMN.USER"}),
            accessor: "name",
            width: 'auto',
            Cell: ({cell}: any) => {

                const item = cell.row.original as UsersModel

                return (
                    <div className="d-flex align-items-center cursor-pointer"
                         onClick={() => editUsersRole(item.id)}
                    >
                        <div className="me-5 position-relative">
                            <div className="symbol symbol-35px symbol-circle">
                                {
                                    !item.photo &&
                                    <span
                                        className={clsx("symbol-label fw-bold", item.state != UserState.Invited ? "bg-light-primary text-primary" : "bg-light text-gray-400")}>
                                          {item.name.length > 1 ? item.name[0].toLocaleUpperCase(selectedLang) : item.email[0].toLocaleUpperCase(selectedLang)}
                                    </span>
                                }
                                {
                                    item.photo &&
                                    <ImageFromBase64 base64String={item.photo}/>
                                }
                            </div>
                            {/*{*/}
                            {/*    (item.state != UserState.Invited && item.lastOnlineDate) &&*/}
                            {/*    <div*/}
                            {/*        className={clsx("position-absolute border border-4 border-white h-15px w-15px rounded-circle translate-middle start-100 top-100 ms-n1 mt-n1",*/}
                            {/*            checkDateExpiredFromISO(DateTime.fromISO(item.lastOnlineDate), DateTime.utc().plus({minutes: -15})) ? "bg-danger" : "bg-success")*/}
                            {/*        }>*/}
                            {/*    </div>*/}
                            {/*}*/}
                        </div>
                        <div className="d-flex flex-column justify-content-center">
                            {
                                <span
                                    className={item.state != UserState.Invited ? "text-gray-800" : "text-gray-400"}>
                                    {item.name && <span className="me-1">{item.name}</span>}
                                    {
                                        cell.row.original.userType == UserType.Admin &&
                                        <span>
                                            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512" fill="#F1416C"><path
                                                d="M309 106c11.4-7 19-19.7 19-34c0-22.1-17.9-40-40-40s-40 17.9-40 40c0 14.4 7.6 27 19 34L209.7 220.6c-9.1 18.2-32.7 23.4-48.6 10.7L72 160c5-6.7 8-15 8-24c0-22.1-17.9-40-40-40S0 113.9 0 136s17.9 40 40 40c.2 0 .5 0 .7 0L86.4 427.4c5.5 30.4 32 52.6 63 52.6H426.6c30.9 0 57.4-22.1 63-52.6L535.3 176c.2 0 .5 0 .7 0c22.1 0 40-17.9 40-40s-17.9-40-40-40s-40 17.9-40 40c0 9 3 17.3 8 24l-89.1 71.3c-15.9 12.7-39.5 7.5-48.6-10.7L309 106z"/></svg>
                                        </span>
                                    }
                                    {
                                        cell.row.original.userType == UserType.Moderator &&
                                        <span>
                                            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512" fill="#ffc107"><path
                                                d="M309 106c11.4-7 19-19.7 19-34c0-22.1-17.9-40-40-40s-40 17.9-40 40c0 14.4 7.6 27 19 34L209.7 220.6c-9.1 18.2-32.7 23.4-48.6 10.7L72 160c5-6.7 8-15 8-24c0-22.1-17.9-40-40-40S0 113.9 0 136s17.9 40 40 40c.2 0 .5 0 .7 0L86.4 427.4c5.5 30.4 32 52.6 63 52.6H426.6c30.9 0 57.4-22.1 63-52.6L535.3 176c.2 0 .5 0 .7 0c22.1 0 40-17.9 40-40s-17.9-40-40-40s-40 17.9-40 40c0 9 3 17.3 8 24l-89.1 71.3c-15.9 12.7-39.5 7.5-48.6-10.7L309 106z"/></svg>
                                        </span>
                                    }

                                </span>
                            }
                            <div className="fw-bold fs-6 text-gray-400">{item.email}</div>
                            {
                                (!item.unclaimedUser && item.state == UserState.Invited) &&
                                <span
                                    className="text-gray-400 fs-8">{intl.formatMessage({id: 'ORGANISATION.USERS.TABLE.MESSAGE.PENDING_INVITE'})}</span>
                            }
                            {
                                (item.unclaimedUser && item.state == UserState.Invited) &&
                                <span
                                    className="text-gray-400 fs-8">{intl.formatMessage({id: 'ORGANISATION.USERS.TABLE.MESSAGE.PENDING_REGISTER'})}</span>
                            }
                        </div>
                    </div>
                )
            }
        },
        {
            Header: intl.formatMessage({id: "ORGANISATION.USERS.TABLE.COLUMN.ROLE"}),
            accessor: "role",
            width: "auto",
            Cell: ({cell}: any) => {

                const item = cell.row.original as UsersModel
                const roles = cell.row.original.roles

                return (
                    <>
                        <div className="d-flex flex-wrap">
                            {
                                roles?.length > 3
                                    ?
                                    <>
                                        {
                                            roles.slice(0, 3).map((role: any) => {
                                                return (
                                                    <span key={role.name} className="badge mx-1 my-1"
                                                          style={{backgroundColor: role.colorCode}}>{role.name}</span>
                                                )
                                            })
                                        }
                                        <span
                                            key="asd"
                                            className="badge badge-square badge-secondary mx-1 my-1 cursor-pointer fw-bolder"
                                            onClick={() => dispatch(rolesActions.showEditUsersRoleModal({
                                                state: true,
                                                organisationUserId: item.id
                                            }))}
                                        >+{roles.length - 3}</span>
                                    </>
                                    :
                                    roles.map((role: any) => {
                                        return (
                                            <span key={role.name} className="badge mx-1 my-1"
                                                  style={{backgroundColor: role.colorCode}}>{role.name}</span>
                                        )
                                    })
                            }
                        </div>
                    </>
                )
            }
        },
        {
            Header: intl.formatMessage({id: "ORGANISATION.USERS.TABLE.COLUMN.LAST_ONLINE_DATE"}),
            accessor: (row: any) => {
                return (
                    <span className="fw-bold text-muted">
                        {row.lastOnlineDate && formatRelativeFromISO(row.lastOnlineDate)}
                    </span>
                )
            },
            width: "auto"
        },
        {
            Header: intl.formatMessage({id: "TABLE.COLUMN.ACTIONS"}),
            width: 180,
            Cell: ({cell}: any) => {

                const item = cell.row.original as UsersModel

                return (
                    <ButtonWithDropdown
                        text={intl.formatMessage({id: 'ORGANISATION.USERS.TABLE.ACTIONS.BUTTON.EDIT_ROLES'})}
                        onClick={() => editUsersRole(item.id)}
                    >
                        <ButtonWithDropdownTitle text={intl.formatMessage({id: 'TABLE.ACTIONS.TITLE.ACTIONS'})}/>
                        <ButtonWithDropdownItem
                            type="default"
                            text={intl.formatMessage({id: 'ORGANISATION.USERS.TABLE.ACTIONS.BUTTON.CHANGE_USER_TYPE'})}
                            onClick={() => dispatch(usersActions.showChangeUserTypeModal({
                                state: true,
                                user: {
                                    userState: item.state,
                                    name: item.name,
                                    email: item.email,
                                    photo: item.photo,
                                    organisationUserId: item.id,
                                    organisationUserType: item.userType,
                                }
                            }))}
                        />
                        <ButtonWithDropdownItem
                            type="danger"
                            text={intl.formatMessage({id: 'ORGANISATION.USERS.TABLE.ACTIONS.BUTTON.REMOVE_USER'})}
                            onClick={async (e) => {
                                e.currentTarget.style.pointerEvents = "none"

                                const result = await swal.question(
                                    intl.formatMessage({id: 'SWEETALERT.MESSAGE.CONFIRM'}),
                                    intl.formatMessage({id: 'ORGANISATION.USERS.TABLE.MESSAGE.REMOVE_USER_CONFIRM'})
                                )
                                if (result.isConfirmed) {
                                    await removeOrganisationUser(item.id)
                                } else {
                                    e.currentTarget.style.pointerEvents = ""
                                }

                            }}
                        />
                    </ButtonWithDropdown>
                )
            }
        }
    ]

    const getOrganisationUsers = async (dataRequest: DataRequest | undefined = undefined) => {
        dispatch(usersActions.getOrganisationUsers({organisationId: organisation.id, dataRequest}))
    }

    const removeOrganisationUser = async (organisationUserId: string) => {
        const response = await dispatch(usersActions.removeOrganisationUser({
            organisationId: organisation.id,
            organisationUserId
        })).unwrap()
        if (response.isSucceed) {
            dispatch(usersActions.refreshOrganisationUsersTable())
            toastify.success(intl.formatMessage({id: 'ORGANISATION.USERS.TABLE.MESSAGE.USER_REMOVED'}))
        } else {
            if (response.message == "ThereIsNoOtherAdminInTheOrganisation") {
                toastify.error(intl.formatMessage({id: "ORGANISATION.USERS.TABLE.MESSAGE.THERE_IS_NO_OTHER_ADMIN_IN_THE_ORGANISATION"}))
            } else {
                toastify.error()
            }
        }
    }

    const getCustomFilter = () => {

        let filters = [];
        if (stateCustomFilter != '') {
            filters.push({name: "state", value: stateCustomFilter})
        }
        if (userTypeCustomFilter != '') {
            filters.push({name: "userType", value: userTypeCustomFilter})
        }
        return filters
    }

    const resetCustomFilter = () => {
        setStateCustomFilter('')
        setUserTypeCustomFilter('')
        setSearchTextCustomFilter('')
    }

    // tab tekrar acildiginda yeniden loading animasyonunu gostermek icin
    useEffect(() => {
            return () => {
                dispatch(usersReducerActions.setUsers(null))
                dispatch(rolesActions.showEditUsersRoleModal({state: false}))
            }
        }
        , [])

    return (
        <>
            <UsersPageTitle/>
            <h1>{searchTextCustomFilter}</h1>
            <Card>
                <Card.Body>
                    <Table
                        columns={columns}
                        dataContext={organisationUsers}
                        events={{
                            getData: getOrganisationUsers,
                            goToPage: getOrganisationUsers,
                            refreshState: useSelector(usersSelectors.getUsersTableRefreshState())
                        }}
                        customFilter={{
                            content: <CustomFilterContent
                                state={stateCustomFilter}
                                setState={setStateCustomFilter}
                                userType={userTypeCustomFilter}
                                setUserType={setUserTypeCustomFilter}
                            />,
                            getCustomFilter,
                            resetCustomFilter
                        }}
                    />
                </Card.Body>
                <Card.Footer>
                    <span className="me-4">
                        <span className="me-2 float-start">
                            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512" fill="#F1416C"><path
                                d="M309 106c11.4-7 19-19.7 19-34c0-22.1-17.9-40-40-40s-40 17.9-40 40c0 14.4 7.6 27 19 34L209.7 220.6c-9.1 18.2-32.7 23.4-48.6 10.7L72 160c5-6.7 8-15 8-24c0-22.1-17.9-40-40-40S0 113.9 0 136s17.9 40 40 40c.2 0 .5 0 .7 0L86.4 427.4c5.5 30.4 32 52.6 63 52.6H426.6c30.9 0 57.4-22.1 63-52.6L535.3 176c.2 0 .5 0 .7 0c22.1 0 40-17.9 40-40s-17.9-40-40-40s-40 17.9-40 40c0 9 3 17.3 8 24l-89.1 71.3c-15.9 12.7-39.5 7.5-48.6-10.7L309 106z"/></svg>
                        </span>
                        <span className="float-start" style={{paddingTop: 2}}>
                            {intl.formatMessage({id: 'ENUMS.USERTYPE.ADMIN'})}
                        </span>
                    </span>
                    {/*<span>*/}
                    {/*    <span className="me-2 ms-3 float-start">*/}
                    {/*        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512" fill="#ffc107"><path*/}
                    {/*            d="M309 106c11.4-7 19-19.7 19-34c0-22.1-17.9-40-40-40s-40 17.9-40 40c0 14.4 7.6 27 19 34L209.7 220.6c-9.1 18.2-32.7 23.4-48.6 10.7L72 160c5-6.7 8-15 8-24c0-22.1-17.9-40-40-40S0 113.9 0 136s17.9 40 40 40c.2 0 .5 0 .7 0L86.4 427.4c5.5 30.4 32 52.6 63 52.6H426.6c30.9 0 57.4-22.1 63-52.6L535.3 176c.2 0 .5 0 .7 0c22.1 0 40-17.9 40-40s-17.9-40-40-40s-40 17.9-40 40c0 9 3 17.3 8 24l-89.1 71.3c-15.9 12.7-39.5 7.5-48.6-10.7L309 106z"/></svg>*/}
                    {/*    </span>*/}
                    {/*    <span className="float-start" style={{paddingTop: 2}}>*/}
                    {/*        {intl.formatMessage({id: 'ENUMS.USERTYPE.MODERATOR'})}*/}
                    {/*    </span>*/}
                    {/*</span>*/}
                </Card.Footer>
            </Card>
        </>
    )
}