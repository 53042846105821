import {Modal} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {useIntl} from "react-intl";
import {useSelector} from "react-redux";
import {organisationActions, organisationReducerActions, organisationSelectors} from "../../../";
import {ButtonWithDropdown, ButtonWithDropdownItem, ButtonWithDropdownTitle} from "../../../../../components/buttons";
import {InviteResponse} from "../../../../../infrastructure/enums/inviteResponse";
import {swal} from "../../../../../libs/sweetAlert";
import {useNavigate} from "react-router-dom";
import {useAppDispatch} from "../../../../../infrastructure/hooks/reduxHooks";
import {FormLoader, TableLoader} from "../../../../../components/loaders";


export const MyInvitationsModal = () => {

    const intl = useIntl()
    const dispatch = useAppDispatch()
    const navigate = useNavigate();


    const modalState = useSelector(organisationSelectors.getMyInvitationsModalState());
    const requestedFromExternalUrl = useSelector(organisationSelectors.getMyInvitationsModalRequestedFromExternalUrl())
    const invites = useSelector(organisationSelectors.getMyInvitations())

    const [pageLoading, setPageLoading] = useState(true)

    const closeModal = () => {
        if (requestedFromExternalUrl) {
            navigate("/")
        }
        dispatch(organisationActions.showMyInvitationsModal({state: false, requestedFromExternalUrl: false}))
    }

    useEffect(() => {

        (async () => {
            await dispatch(organisationActions.getMyInvitations()).unwrap()
            setPageLoading(false)
        })()
        
        return () => {
            dispatch(organisationReducerActions.setMyInvitations(null))
        }
    }, [])

    return (
        <>
            <Modal show={modalState} dialogClassName="mw-650px" backdrop="static" onHide={closeModal}>
                <Modal.Body>
                    {/*<begin::Heading*/}
                    <div className="mb-13 text-center">
                        {/*begin::Title*/}
                        <h1 className="mb-3 mt-5">
                            {intl.formatMessage({id: 'ORGANISATIONS.MODAL.MY_INVITATIONS.TITLE'})}
                        </h1>
                        {/*end::Title*/}
                        <button z-index={10} className="btn btn-icon btn-sm btn-transparent ms-2 btn-active-color-dark"
                                style={{
                                    float: 'right',
                                    position: 'absolute',
                                    right: '20px',
                                    top: '20px'
                                }} onClick={() => closeModal()}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                 fill="none">
                                <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                      transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                                <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)"
                                      fill="currentColor"></rect>
                            </svg>
                        </button>
                    </div>
                    {/*end::Heading*/}


                    {pageLoading && <FormLoader/>}
                    
                    {
                        (!pageLoading && (!invites || invites.length == 0)) &&
                        <div className="fs-3 text-center text-gray-600 my-10">
                            {intl.formatMessage({id: "ORGANISATIONS.MODAL.MY_INVITATIONS.NO_PENDING_INVITATIONS"})}
                        </div>
                    }

                    {
                        (!pageLoading && invites) &&
                        <table className="table table-row-bordered table-row-dashed gy-4 align-middle fw-bolder">
                            <tbody className="fs-6">
                            {
                                invites?.map((invite) => {
                                    return (
                                        <tr key={invite.inviteId}>
                                            <td>{invite.organisationName}</td>
                                            <td className="text-end">
                                                <button type="button"
                                                        className="btn btn-sm btn-success me-2"
                                                        onClick={() => {
                                                            dispatch(organisationActions.responseMyInvitation({
                                                                inviteId: invite.inviteId,
                                                                response: InviteResponse.Accept
                                                            }))
                                                        }}
                                                >
                                                    {intl.formatMessage({id: "ORGANISATIONS.MODAL.MY_INVITATIONS.BUTTON.ACCEPT"})}
                                                </button>
                                                <ButtonWithDropdown
                                                    text={intl.formatMessage({id: "ORGANISATIONS.MODAL.MY_INVITATIONS.BUTTON.REJECT"})}
                                                    onClick={async (e) => {
                                                        e.currentTarget.style.pointerEvents = "none"

                                                        const result = await swal.question(
                                                            intl.formatMessage({id: 'SWEETALERT.MESSAGE.CONFIRM'}),
                                                        )
                                                        if (result.isConfirmed) {
                                                            dispatch(organisationActions.responseMyInvitation({
                                                                inviteId: invite.inviteId,
                                                                response: InviteResponse.Reject
                                                            }))
                                                        } else {
                                                            e.currentTarget.style.pointerEvents = ""
                                                        }
                                                    }}
                                                    size={"sm"}
                                                    variant={"danger"}
                                                >
                                                    <ButtonWithDropdownTitle
                                                        text={intl.formatMessage({id: 'TABLE.ACTIONS.TITLE.ACTIONS'})}
                                                    />
                                                    <ButtonWithDropdownItem
                                                        text={intl.formatMessage({id: "ORGANISATIONS.MODAL.MY_INVITATIONS.BUTTON.BLOCK_ORGANISATION"})}
                                                        type='danger'
                                                        onClick={async (e) => {
                                                            e.currentTarget.style.pointerEvents = "none"

                                                            const result = await swal.question(
                                                                intl.formatMessage({id: 'SWEETALERT.MESSAGE.CONFIRM'}),
                                                            )
                                                            if (result.isConfirmed) {
                                                                dispatch(organisationActions.responseMyInvitation({
                                                                    inviteId: invite.inviteId,
                                                                    response: InviteResponse.BlockOrganisation
                                                                }))
                                                            } else {
                                                                e.currentTarget.style.pointerEvents = ""
                                                            }
                                                        }}
                                                    />
                                                    <ButtonWithDropdownItem
                                                        text={intl.formatMessage({id: "ORGANISATIONS.MODAL.MY_INVITATIONS.BUTTON.BLOCK_USER"})}
                                                        type='danger'
                                                        onClick={async (e) => {
                                                            e.currentTarget.style.pointerEvents = "none"

                                                            const result = await swal.question(
                                                                intl.formatMessage({id: 'SWEETALERT.MESSAGE.CONFIRM'})
                                                            )
                                                            if (result.isConfirmed) {
                                                                dispatch(organisationActions.responseMyInvitation({
                                                                    inviteId: invite.inviteId,
                                                                    response: InviteResponse.BlockUser
                                                                }))
                                                            } else {
                                                                e.currentTarget.style.pointerEvents = ""
                                                            }
                                                        }}
                                                    />
                                                </ButtonWithDropdown>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                            </tbody>
                        </table>

                    }
                    <div className="text-inverse-light bg-light p-3 rounded-2 fst-italic">
                        * {intl.formatMessage({id: "ORGANISATIONS.MODAL.MY_INVITATIONS.INFO_ABOUT_UNBLOCKING"})}
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}