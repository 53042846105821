import axios, {AxiosResponse} from "axios";
import {DeviceModel} from "./models";

const getDevices = async () => await axios.get(`/user-device/`);

const getDevice = async ({userDeviceId}: { userDeviceId: string }) => await axios.get<DeviceModel, AxiosResponse<DeviceModel>>(`/user-device/${userDeviceId}`)

const removeDevice = async ({userDeviceId}: { userDeviceId: string }) => await axios.delete(`/user-device/${userDeviceId}`)

export const devicesApis = {
    getDevices,
    getDevice,
    removeDevice
}