import React, {ChangeEventHandler, FC, KeyboardEventHandler} from "react";
import {FieldInputProps} from "formik";
import {useIntl} from "react-intl";
import {UserState} from "../../infrastructure/enums/userState";

interface Props {
    items: FormSelectItem[],
    fieldProps?: FieldInputProps<any> | undefined
    placeholderResourceName?: string,
    onChange?: ChangeEventHandler<HTMLSelectElement>
    disabled?: boolean,
    defaultValue?: string | number
    name?: string
}

export interface FormSelectItem {
    value: string,
    text?: string
}


export const FormSelect: FC<Props> = ({
                                          items,
                                          fieldProps,
                                          placeholderResourceName,
                                          onChange,
                                          disabled = false,
                                          defaultValue,
                                          name
                                      }) => {

    const intl = useIntl()

    return (
        <select

            className="form-select form-select-solid fw-bolder"
            {...(placeholderResourceName ? {placeholder: intl.formatMessage({id: placeholderResourceName})} : {})}
            {...(fieldProps ? {...fieldProps} : {})}
            {...(onChange ? {onChange: onChange} : {})}
            disabled={disabled}
            {...(defaultValue ? {defaultValue: defaultValue} : {})}
            {...(name ? {name: name} : {})}
        >
            {
                <>
                    <option value=''>{intl.formatMessage({id: "FORM.SELECT"})}</option>
                    {
                        items.map((item: FormSelectItem, index: number) => {
                            return (
                                <option key={`${index}_${item.value}`}
                                        value={item.value}>{item.text ?? item.value}</option>
                            )
                        })
                    })
                </>
            }
        </select>
    )
}