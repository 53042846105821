import {Dropdown, DropdownButton} from "react-bootstrap"
import React, {CSSProperties, FC, useState} from "react";
import {useIntl} from "react-intl";
import {Link} from "react-router-dom";
import clsx from "clsx";


interface Props {
    textResource: string,
    className?: string
}

export const SelectDropdown: FC<Props> = ({children, textResource, className}) => {

    const intl = useIntl()

    const [show, setShow] = useState(false);

    return (
        <>
            <Dropdown
                show={show}
                onMouseEnter={() => setShow(true)}
                onMouseLeave={() => setShow(false)}
                autoClose="inside"
                onClick={(e) => setShow(true)}
            >
                <button type="button" className="btn btn-sm btn-light-primary">
                <span className="svg-icon svg-icon-3">
            								<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                 viewBox="0 0 24 24" fill="none">
            									<rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5"
                                                      fill="currentColor"></rect>
            									<rect x="10.8891" y="17.8033" width="12" height="2" rx="1"
                                                      transform="rotate(-90 10.8891 17.8033)"
                                                      fill="currentColor"></rect>
            									<rect x="6.01041" y="10.9247" width="12" height="2" rx="1"
                                                      fill="currentColor"></rect>
            								</svg>
            							</span>
                    {intl.formatMessage({id: textResource})}
                </button>

           
                    <Dropdown.Menu>
                        {children}
                    </Dropdown.Menu>
            </Dropdown>

            {/*<Dropdown>*/}
            {/*    <Dropdown.Toggle active={true}*/}
            {/*    >*/}
            {/*            <span className="svg-icon svg-icon-3">*/}
            {/*        								<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"*/}
            {/*                                             viewBox="0 0 24 24" fill="none">*/}
            {/*        									<rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5"*/}
            {/*                                                  fill="currentColor"></rect>*/}
            {/*        									<rect x="10.8891" y="17.8033" width="12" height="2" rx="1"*/}
            {/*                                                  transform="rotate(-90 10.8891 17.8033)"*/}
            {/*                                                  fill="currentColor"></rect>*/}
            {/*        									<rect x="6.01041" y="10.9247" width="12" height="2" rx="1"*/}
            {/*                                                  fill="currentColor"></rect>*/}
            {/*        								</svg>*/}
            {/*        							</span>*/}
            {/*        {intl.formatMessage({id: textResource})}*/}
            {/*    </Dropdown.Toggle>*/}
            {/*    <Dropdown.Menu style={{"width": "100%"}}>*/}
            {/*        {children}*/}
            {/*    </Dropdown.Menu>*/}
            {/*</Dropdown>*/}
        </>
    )
}

interface ItemProps {
    textResource: string
    onClick?: React.MouseEventHandler<HTMLButtonElement>
    style?: CSSProperties
}

export const SelectDropdownItem: FC<ItemProps> = ({textResource, onClick, style}) => {

    const intl = useIntl()

    return (
        <div className="menu-item px-3">
            <span
                className={
                    clsx("menu-link px-3", "text-dark bg-hover-light-primary")}
                {...(onClick ? {onClick: onClick} : {})}
                {...(style ? {style: style} : {})}
            >
                {intl.formatMessage({id: textResource})}
            </span>
        </div>
    )
}