import React from "react";
import {useIntl} from "react-intl";

interface Props {
    titleResource: string
    children: string | JSX.Element | JSX.Element[]
}


export const SettingsGroup = ({titleResource, children}: Props) => {

    const intl = useIntl()

    return (
        <div className="card card-flush mb-6 mb-xl-9 mt-6">
            <div className="card-header border-bottom min-h-50px">
                <div className="card-title m-0">
                    <h4 className="fw-bold m-0">
                        {intl.formatMessage({id: titleResource})}
                    </h4>
                </div>
            </div>
            <div className="card-body">
                {children}
            </div>
        </div>
    )
}