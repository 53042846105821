import {Suspense, useEffect} from 'react'
import {Outlet} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'
import {AuthInit} from './pages/auth/components'
import {GoogleReCaptchaProvider} from "react-google-recaptcha-v3";
import {ToastContainer} from "react-toastify";
import * as consts from "./infrastructure/variables/consts";
import { v4 as uuidv4 } from 'uuid';

const App = () => {

    useEffect(() => {

        const value = localStorage.getItem(consts.PROTECT_REMOTE_DEVICE_KEY)
        if (!value) {
            localStorage.setItem(consts.PROTECT_REMOTE_DEVICE_KEY, uuidv4())
        }
    }, [])
    
    const reCaptchaKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY ?? "";
    
    return (
        <Suspense fallback={<LayoutSplashScreen/>}>
            <GoogleReCaptchaProvider reCaptchaKey={reCaptchaKey}>
                <I18nProvider>
                    <LayoutProvider>
                        <AuthInit>
                            <Outlet/>
                            <ToastContainer newestOnTop/>
                            <MasterInit/>
                        </AuthInit>
                    </LayoutProvider>
                </I18nProvider>
            </GoogleReCaptchaProvider>
        </Suspense>
    )
}

export {App}
