import React from "react";
import {Card} from "react-bootstrap";
import {RulesPageTitle} from "./";
import {Rules} from "./rules/Rules";

export const RulesPage = () => {
    return (
        <>
            <RulesPageTitle/>
            <Card>
                <Card.Body>
                    <Rules />
                </Card.Body>
            </Card>
        </>
    )
}