import Swal, {SweetAlertOptions} from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {formatMessage} from "../../_metronic/i18n/Metronici18n";

const defaultOptions: SweetAlertOptions = {
    heightAuto: false
}

const SweetAlertInstance = withReactContent(Swal).mixin({...defaultOptions});

const success = (title: string, text: string | '' = '') => {

    return SweetAlertInstance.fire({
        title: title,
        text: text,
        icon: "success",
        confirmButtonText: formatMessage({id: 'SWEETALERT.CONFIRM_BUTTON_TEXT'}),
        cancelButtonText: formatMessage({id: 'SWEETALERT.CANCEL_BUTTON_TEXT'}),
        heightAuto: false,
        allowOutsideClick: false
    })
}


const error = (title: string, text: string | '' = '') => {
    return SweetAlertInstance.fire({
        title: title,
        text: text,
        icon: "error",
        confirmButtonText: formatMessage({id: 'SWEETALERT.CONFIRM_BUTTON_TEXT'}),
        heightAuto: false,
        allowOutsideClick: false
    })
}

const warning = (title: string, text: string | '' = '') => {
    return SweetAlertInstance.fire({
        title: title,
        text: text,
        icon: "warning",
        confirmButtonText: formatMessage({id: 'SWEETALERT.CONFIRM_BUTTON_TEXT'}),
        heightAuto: false,
        allowOutsideClick: false
    })
}

const info = (title: string, text: string | '' = '') => {
    return SweetAlertInstance.fire({
        title: title,
        text: text,
        icon: "info",
        heightAuto: false,
        allowOutsideClick: false
    })
}

const question = (title: string, text: string | '' = '') => {
    return SweetAlertInstance.fire({
        title: title,
        text: text,
        icon: "question",
        heightAuto: false,
        confirmButtonText: formatMessage({id: 'SWEETALERT.CONFIRM_BUTTON_TEXT'}),
        cancelButtonText: formatMessage({id: 'SWEETALERT.CANCEL_BUTTON_TEXT'}),
        showCancelButton: true,
        showConfirmButton: true,
        allowOutsideClick: false
    })
}

const questionWithConfirm = (title: string, text: string | '' = '') => {
    return SweetAlertInstance.fire({
        title: title,
        text: text,
        icon: "question",
        confirmButtonColor: "#009EF7",
        heightAuto: false,
        confirmButtonText: formatMessage({id: 'SWEETALERT.CONFIRM_BUTTON_TEXT'}),
        cancelButtonText: formatMessage({id: 'SWEETALERT.CANCEL_BUTTON_TEXT'}),
        showCancelButton: true,
        showConfirmButton: true,
        input: "checkbox",
        customClass: "form-check form-check-custom form-check-primary form-check-solid",
        inputPlaceholder: formatMessage({id: 'SWEETALERT.MESSAGE.CHECK_CONFIRM'}),
        inputAttributes: {"class": "form-control form-control-solid form-check-input me-3"},
        allowOutsideClick: false,
        inputValidator: (value): any => {
            if (!value) {
                return formatMessage({id: 'SWEETALERT.MESSAGE.CHECK_CONFIRM_VALIDATOR'})
            }
        }
    })
}

export const swal = {
    success,
    error,
    warning,
    info,
    question,
    questionWithConfirm
}

export default SweetAlertInstance