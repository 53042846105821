import {Dispatch, FC, SetStateAction, useEffect} from "react"
import {CirclePicker, Color, ColorChangeHandler} from "react-color"

type Props = {
    color: Color | undefined,
    setColor: Dispatch<SetStateAction<Color | undefined>>,
    onChange: ColorChangeHandler
}

const colors = [
    "#f44336",
    "#e91e63",
    "#9c27b0",
    "#673ab7",
    "#3f51b5",
    "#2196f3",
    "#03a9f4",
    "#00bcd4",
    "#009688",
    "#4caf50",
    "#8bc34a",
    "#ffeb3b",
    "#ffc107",
    "#ff9800",
    "#ff5722",
    "#795548",
    "#607d8b"
]

export const ColorPicker: FC<Props> = ({color, setColor, onChange}) => {

   useEffect(() => {
       if(!color){
           const randomColor = colors[Math.floor(Math.random()*colors.length)]
           setColor(randomColor)
       }
   }, [])
    
    return (
        <>
            <CirclePicker
                className={"mt-1 w-100"}
                colors={colors}
                color={color}
                onChange={onChange}
            />
        </>
    )
}