import React, {useState} from "react";
import {useIntl} from "react-intl";
import {v4 as uuidv4} from "uuid";
import clsx from "clsx";

interface Props {
    type: "checkbox"
    imageFile: string,
    titleResource: string,
    descriptionResource: string
    onChange: React.ChangeEventHandler<HTMLInputElement>
    defaultChecked: boolean | undefined
    preventClick: boolean
}

export const SettingsGroupItem = ({type, imageFile, titleResource, descriptionResource, onChange, defaultChecked, preventClick}: Props) => {

    const intl = useIntl()
    const uniqueId = uuidv4()

    const [checked, setChecked] = useState(defaultChecked)


    return (
        <>
            {type == "checkbox" &&
                <div className={clsx("d-flex flex-stack", preventClick ? "pointer-events-none" : "")}>
                    <div className="d-flex">
                        <div className="d-flex flex-column w-45px flex-center">
                            <img src={`/media/destination-settings/${imageFile}`} className="w-45px" alt=""/>
                        </div>
                        <div className="d-flex flex-column ps-6">
                            <span className="fs-5 text-dark fw-bold">{intl.formatMessage({id: titleResource})}</span>
                            <div className="fs-6 fw-semibold text-gray-400">{intl.formatMessage({id: descriptionResource})}</div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-end ms-3">
                        <div className="form-check form-check-solid form-check-custom form-switch">
                            <input className="form-check-input w-45px h-30px" type="checkbox" id={`settingsChecked_${uniqueId}`}
                                   onChange={onChange}
                                   defaultChecked={checked}
                            />
                            <label className="form-check-label" htmlFor={`settingsChecked_${uniqueId}`}></label>
                        </div>
                    </div>
                </div>}
        </>
    )
}