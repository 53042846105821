import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {DevicesModel, DevicesStateModel} from "./models/";

const initial: DevicesStateModel = {
    devices: null,
    selectedDeviceId: ''
}

const {actions, reducer} = createSlice({
    name: 'devices',
    initialState: initial,
    reducers: {
        setDevices: (state, action: PayloadAction<DevicesModel[] | null>) => {
            state.devices = action.payload ?? initial.devices
        },
        setSelectedDeviceId: (state, action: PayloadAction<string | null>) => {
            state.selectedDeviceId = action.payload ?? initial.selectedDeviceId
        }
    }
})

export const devicesReducer = reducer
export const devicesReducerActions = actions