import {useDispatch, useSelector} from "react-redux";
import {destinationsActions, destinationsSelectors} from "../../";
import {organisationSelectors} from "../../../../";
import React, {useEffect, useState} from "react";
import {FormActionButtons, FormGroup, FormInputGroup, FormModal} from "../../../../../../components/forms";
import {useIntl} from "react-intl";
import {Form, Formik} from "formik";
import {DestinationTypeEnum} from "../../models";
import {toastify} from "../../../../../../libs/toastify";
import {FormLoader} from "../../../../../../components/loaders";
import * as Yup from "yup";
import ipRegex from "ip-regex";

export const EditDestinationModal = () => {

    const intl = useIntl()
    const dispatch = useDispatch()

    const organisationId = useSelector(organisationSelectors.getSelectedOrganisationId())
    const destinationId = useSelector(destinationsSelectors.getEditDestinationModalDestinationId())
    const modalState = useSelector(destinationsSelectors.getEditDestinationModalState())
    const [showContentLoader, setShowContentLoader] = useState(true)
    const destinationModal = useSelector(destinationsSelectors.getEditDestinationModal())



    const [destinationType, setDestinationType] = useState<DestinationTypeEnum>()


    interface FormModel {
        agentId: string,
        name: string,
        hasStaticIp: boolean,
        ipAddress: string
    }

    const [formValues, setFormValues] = useState<FormModel>({
        agentId: '',
        name: '',
        hasStaticIp: false,
        ipAddress: ''
    })

    const closeModal = () => {
        if (destinationModal.refreshTableWhenModalClosed){
            
            if (destinationType == DestinationTypeEnum.Agent) {
                dispatch(destinationsActions.refreshAgentsTable())
            }
            else if (destinationType == DestinationTypeEnum.CmsExtension) {
                dispatch(destinationsActions.refreshCmsTable())
            }
            else if (destinationType == DestinationTypeEnum.SoftwarePackage) {
                dispatch(destinationsActions.refreshSoftwarePackagesTable())
            }
            
        }
        
        dispatch(destinationsActions.showEditDestinationModal({state: false}))
    }

    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .min(3, intl.formatMessage({id: 'VALIDATION.MIN_LENGTH'}, {length: 3}))
            .max(50, intl.formatMessage({id: 'VALIDATION.MAX_LENGTH'}, {length: 30}))
            .required(intl.formatMessage({id: 'VALIDATION.REQUIRED'}, {name: intl.formatMessage({id: 'ORGANISATION.MODAL.DESTINATION.EDIT_AGENT.INPUT.NAME'})})),
        hasStaticIp: Yup.string().required(intl.formatMessage({id: 'VALIDATION.SELECT'})),
        ipAddress: Yup.string()
            .when(["hasStaticIp"], {
                is: (hasStaticIp: any) => hasStaticIp == "true",
                then: Yup.string().required(intl.formatMessage({id: 'VALIDATION.IP_ADDRESS'}))
                    .test('hasStaticIp', 'Error message', (value, context) => {
                        const isValidIp = ipRegex({exact: true}).test(value ?? '')
                        return isValidIp
                    })
            })
    })

    const getDestination = async () => {
        const response = await destinationsActions.getDestination(organisationId, destinationId)
        if (response.isSucceed) {
            setFormValues(response.data)
            setDestinationType(response.data.destinationType)
            setShowContentLoader(false)
        }
    }

    useEffect(() => {
        (async () => {
            await getDestination()
        })()
    }, [])

    return (
        <FormModal
            modalState={modalState as boolean}
            closeModal={closeModal}
            title={intl.formatMessage({id: "ORGANISATION.MODAL.DESTINATION.EDIT_AGENT.TITLE"})}
            subTitle={intl.formatMessage({id: "ORGANISATION.MODAL.DESTINATION.EDIT_AGENT.SUBTITLE"})}
        >
            <>
                {showContentLoader && <FormLoader/>}

                {
                    !showContentLoader &&
                    (
                        <Formik
                            initialValues={formValues}
                            validationSchema={validationSchema}
                            onSubmit={async (values, {setSubmitting}) => {
                                setTimeout(async () => {

                                    const response = await destinationsActions.updateDestination({
                                        organisationId: organisationId,
                                        destinationId: destinationId,
                                        name: values.name,
                                        ipAddress: values.ipAddress
                                    });

                                    setSubmitting(false)

                                    if (response.isSucceed) {
                                        closeModal()
                                        toastify.success(intl.formatMessage({id: "ORGANISATION.MODAL.DESTINATION.EDIT_AGENT.MESSAGE.UPDATED"}))
                                    }
                                }, 500);
                            }}
                        >
                            {(formik) => (
                                <Form className="form">
                                    <FormInputGroup
                                        labelResourceName={"ORGANISATION.MODAL.DESTINATION.EDIT_AGENT.INPUT.NAME"}
                                        fieldProps={formik.getFieldProps('name')}
                                    />

                                    {
                                        destinationType == DestinationTypeEnum.Agent &&
                                        <>
                                            <FormGroup
                                                labelResourceName={"ORGANISATION.MODAL.DESTINATION.EDIT_AGENT.INPUT.IPADDRESS"}
                                            >
                                                <label className="form-check form-check-custom form-check-solid mb-5 pt-3">
                                                    <input className="form-check-input"
                                                           type="radio"
                                                           {...formik.getFieldProps('hasStaticIp')}
                                                           value="true"
                                                           checked={formik.values.hasStaticIp}
                                                           onChange={() => formik.setFieldValue('hasStaticIp', true)}
                                                    />
                                                    <span className="form-check-label">
                                    {intl.formatMessage({id: 'ORGANISATION.MODAL.DESTINATION.EDIT_AGENT.RADIO.MY_SERVER_HAS_STATIC_IP'})}
                                </span>
                                                </label>

                                                <label className="form-check form-check-custom form-check-solid">
                                                    <input className="form-check-input"
                                                           type="radio"
                                                           {...formik.getFieldProps('hasStaticIp')}
                                                           value="false"
                                                           checked={!formik.values.hasStaticIp}
                                                           onChange={() => {
                                                               formik.setFieldValue('ipAddress', '')
                                                               formik.setFieldValue('hasStaticIp', false)
                                                           }}
                                                    />
                                                    <span className="form-check-label">
                                    {intl.formatMessage({id: 'ORGANISATION.MODAL.DESTINATION.EDIT_AGENT.RADIO.MY_SERVER_DOESNT_HAVE_STATIC_IP'})}
                                </span>
                                                </label>
                                            </FormGroup>

                                            {formik.values.hasStaticIp == true &&
                                                <FormInputGroup
                                                    showLabel={false}
                                                    labelResourceName={""}
                                                    focus={formik.values.hasStaticIp == true}
                                                    fieldProps={formik.getFieldProps('ipAddress')}
                                                    placeholderResourceName={"ORGANISATION.MODAL.DESTINATION.EDIT_AGENT.INPUT.IPADDRESS.PLACEHOLDER"}
                                                />
                                            }
                                        </>
                                    }

                                    <FormActionButtons
                                        formik={formik}
                                        loading={formik.isSubmitting}
                                        closeModal={closeModal}
                                        submitButtonResourceName={"FORM.UPDATE"}
                                    />
                                </Form>
                            )}
                        </Formik>
                    )
                }
            </>
        </FormModal>
    )
}