import {authReducerActions} from './'
import {LoginModel} from './models/LoginModel'
import {ActionResponse} from '../../infrastructure/models/actionResponse'
import {Languages} from '../../../_metronic/i18n/Metronici18n'
import {createAsyncThunk} from '@reduxjs/toolkit'
import {authApis} from './authApis'
import * as device from 'react-device-detect'
import {devicesActions} from '../devices'
import {organisationActions, organisationReducerActions} from '../organisation'

const login = createAsyncThunk(
  'auth/login',
  async (
    {email, password, reCAPTCHAToken}: {email: string; password: string; reCAPTCHAToken: string},
    {dispatch, signal}
  ) => {
    try {
      const response = await authApis.login(email, password, reCAPTCHAToken)
      dispatch(
        authReducerActions.setLoggedUser({
          accessToken: response.data.accessToken,
          user: response.data.user,
          verifiedDevice: false,
          returnUrl: '',
        })
      )

      return {
        isSucceed: true,
      } as ActionResponse<LoginModel>
    } catch (e: any) {
      return {
        isSucceed: false,
        errors: e.response?.data?.errors,
        message: e.response?.data,
      } as ActionResponse<LoginModel>
    }
  }
)

const logout = createAsyncThunk(
  'auth/logout',
  async ({forgetDevice = false}: {forgetDevice?: boolean}, {dispatch}) => {
    if (forgetDevice) {
      // await olmayacak, yoksa apideki yada tokendaki bir hata durumunda logout olamaz.
      authApis.forgetDevice()
    }
    dispatch(authReducerActions.logout())
    dispatch(organisationReducerActions.setOrganisations(null))
    dispatch(organisationReducerActions.setSelectedOrganisation(null))
  }
)

const register = createAsyncThunk(
  'auth/register',
  async (
    {email, password, reCAPTCHAToken}: {email: string; password: string; reCAPTCHAToken: string},
    {dispatch, signal}
  ) => {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

    try {
      const response = await authApis.register(email, password, timeZone, reCAPTCHAToken)

      dispatch(
        authReducerActions.setLoggedUser({
          accessToken: response.data.accessToken,
          user: response.data.user,
          verifiedDevice: false,
        })
      )

      return {
        isSucceed: true,
      } as ActionResponse
    } catch (e: any) {
      return {
        isSucceed: false,
        errors: e.response?.data?.errors,
        message: e.response.data,
      } as ActionResponse
    }
  }
)

const forgotPassword = async ({email, reCAPTCHAToken}: {email: string; reCAPTCHAToken: string}) => {
  try {
    await authApis.forgotPassword({
      email,
      reCAPTCHAToken,
    })
    return {
      isSucceed: true,
    } as ActionResponse
  } catch (e: any) {
    return {
      isSucceed: false,
      message: e.response.data,
    } as ActionResponse
  }
}

const resetPassword = async ({
  email,
  password,
  token,
  reCAPTCHAToken,
}: {
  email: string
  password: string
  token: string
  reCAPTCHAToken: string
}) => {
  try {
    await authApis.resetPassword({
      email,
      password,
      token,
      reCAPTCHAToken,
    })
    return {
      isSucceed: true,
    } as ActionResponse
  } catch (e: any) {
    return {
      isSucceed: false,
    } as ActionResponse
  }
}

const resetPasswordTokenVerify = async (email: string, token: string) => {
  try {
    await authApis.resetPasswordTokenVerify({email, token})
    return {
      isSucceed: true,
    } as ActionResponse
  } catch (e: any) {
    return {
      isSucceed: false,
    } as ActionResponse
  }
}

const getUserAndAccessToken = async () => {
  try {
    const response = await authApis.getUserAndAccessToken()
    return {
      isSucceed: true,
      data: response.data,
    } as ActionResponse
  } catch (e: any) {
    return {
      isSucceed: false,
    } as ActionResponse
  }
}

const refreshUserAndAccessToken = createAsyncThunk(
  'auth/refreshUserAndAccessToken',
  async (arg, {dispatch}) => {
    try {
      const response = await authApis.getUserAndAccessToken()
      dispatch(
        authReducerActions.setLoggedUser({
          accessToken: response.data.accessToken,
          user: response.data.user,
          verifiedDevice: true,
        })
      )
      return {
        isSucceed: true,
      }
    } catch {
      return {
        isSucceed: false,
      }
    }
  }
)

const setLanguage = createAsyncThunk(
  'auth/setLanguage',
  async ({language}: {language: Languages | null}, {dispatch}) => {
    dispatch(authReducerActions.setLanguage(language))
  }
)

const registerDevice = createAsyncThunk('auth/registerDevice', async (arg, {dispatch}) => {
  try {
    const response = await authApis.registerDevice({
      name: `${device.browserName} (${device.osName})`,
      deviceType: 'Web',
      deviceInfo: {
        deviceType: 'Web',
        vendor: device.mobileVendor,
        model: device.mobileModel,
        osName: device.osName,
        osVersion: device.osVersion,
        browserName: device.browserName,
        browserVersion: device.fullBrowserVersion,
        browserEngine: device.engineName,
        browserEngineVersion: device.engineVersion,
      },
    })
    dispatch(
      authReducerActions.setLoggedUser({
        accessToken: response.data.accessToken,
        user: response.data.user,
        verifiedDevice: false,
      })
    )
    return {
      isSucceed: true,
    }
  } catch {
    return {
      isSucceed: false,
    }
  }
})

const verifyDevice = createAsyncThunk(
  'auth/verifyDevice',
  async ({verificationCode}: {verificationCode: string}, {dispatch}) => {
    try {
      const response = await authApis.verifyDevice({
        verificationCode,
      })

      dispatch(
        authReducerActions.setLoggedUser({
          accessToken: response.data.accessToken,
          user: response.data.user,
          verifiedDevice: true,
        })
      )

      dispatch(authReducerActions.setRefreshToken(response.data.refreshToken ?? ''))

      return {
        isSucceed: true,
      }
    } catch (e: any) {
      return {
        isSucceed: false,
        errors: e.response?.data?.errors,
        message: e.response.data,
      }
    }
  }
)

const resendVerificationCode = async () => {
  try {
    await authApis.resendVerificationCode()

    return {
      isSucceed: true,
    }
  } catch (e: any) {
    return {
      isSucceed: false,
      errors: e.response?.data?.errors,
      message: e.response.data,
    }
  }
}

const setVerificationCodeAction = createAsyncThunk(
  'auth/setVerificationCodeAction',
  (
    {verificationCode, logout = false}: {verificationCode: string | null; logout?: boolean},
    {dispatch}
  ) => {
    dispatch(authReducerActions.setVerificationCode(verificationCode))

    if (!verificationCode && logout) {
      dispatch(authReducerActions.logout())
    }
  }
)

export const authActions = {
  login,
  logout,
  register,
  forgotPassword,
  resetPassword,
  resetPasswordTokenVerify,
  getUserAndAccessToken,
  setLanguage,
  refreshUserAndAccessToken,
  registerDevice,
  verifyDevice,
  resendVerificationCode,
  setVerificationCodeAction,
}
