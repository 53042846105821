import React, {FC, MouseEventHandler} from "react";
import clsx from "clsx";
import {FormCancelButton} from "./FormCancelButton";
import {FormSubmitButton} from "./FormSubmitButton";


interface Props {
    formik: any,
    loading: boolean,
    closeModal?: MouseEventHandler<HTMLButtonElement>,
    cancelLinkTo?: string,
    cancelLinkState?: any,
    submitButtonResourceName?: string,
    cancelButtonResourceName?: string
    showCancelButton?: boolean
    fullWidthButton?: boolean,
    buttonSize?: "default" | "btn-xs" | "btn-sm" | "btn-lg"
    position?: "start" | "center" | "end",
    showNextStepIcon?: boolean,
    submitButtonType?: "submit" | "button",
    cancelButtonType?: "reset" | "button",
    cancelButtonOnClick?: React.MouseEventHandler<HTMLButtonElement>
    disableSubmitButton?: boolean
}

export const FormActionButtons: FC<Props> = ({
                                                 formik,
                                                 loading,
                                                 closeModal,
                                                 cancelLinkTo,
                                                 cancelLinkState,
                                                 submitButtonResourceName,
                                                 cancelButtonResourceName,
                                                 showCancelButton = true,
                                                 fullWidthButton = false,
                                                 buttonSize = "default",
                                                 position = "center",
                                                 showNextStepIcon = false,
                                                 submitButtonType = "submit",
                                                 cancelButtonType = "reset",
                                                 cancelButtonOnClick = undefined,
                                                 disableSubmitButton = false
                                             }) => {

    const closeModalAndResetForm = (formik: any, closeModal: any) => {
        closeModal && closeModal();
        formik.resetForm();
    }

    return (
        <div className={clsx(`text-${position}`, fullWidthButton && "d-flex flex-column-reverse")}>
            {showCancelButton &&
                <FormCancelButton
                    type={cancelButtonType}
                    onClick={cancelButtonOnClick ? cancelButtonOnClick as React.MouseEventHandler<HTMLButtonElement> : () => closeModalAndResetForm(formik, closeModal)}
                    buttonResourceName={cancelButtonResourceName}
                    linkTo={cancelLinkTo}
                    LinkState={cancelLinkState}
                    buttonSize={buttonSize}
                    fullWidthButton={fullWidthButton}
                />
            }
            <FormSubmitButton
                type={submitButtonType}
                disabled={formik.isSubmitting || !formik.isValid || disableSubmitButton}
                loading={loading}
                fullWidthButton={fullWidthButton}
                buttonSize={buttonSize}
                buttonResourceName={submitButtonResourceName}
                showNextStepIcon={showNextStepIcon}
            />
        </div>
    )
}