import {DeviceTypeEnum, OperatingSystemEnum} from "../../pages/devices/models";
import {formatMessage} from "../../../_metronic/i18n/Metronici18n";

export const formatOperatingSystem = (value: OperatingSystemEnum) => {
    switch (value) {
        case OperatingSystemEnum.Windows:
            return "Windows"
        case OperatingSystemEnum.Linux:
            return "Linux"
        case OperatingSystemEnum.MacOS:
            return "macOS"
        case OperatingSystemEnum.ChromeOS:
            return "Chrome OS"
        case OperatingSystemEnum.IOS:
            return "iOS"
        case OperatingSystemEnum.IPadOS:
            return "iPadOS"
        case OperatingSystemEnum.Android:
            return "Android"
        default:
            return "-"
    }
}

export const formatDeviceType = (value: DeviceTypeEnum) => {
    switch (value) {
        case DeviceTypeEnum.Desktop:
            return formatMessage({id:'ENUMS.DEVICETYPE.DESKTOP'})
        case DeviceTypeEnum.Web:
            return formatMessage({id:'ENUMS.DEVICETYPE.WEB'})
        case DeviceTypeEnum.Mobile:
            return formatMessage({id:'ENUMS.DEVICETYPE.MOBILE'})
        case DeviceTypeEnum.ChromeExtension:
            return formatMessage({id:'ENUMS.DEVICETYPE.CHROMEEXTENSION'})
        default:
            return "-"
    }
}