import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RoleDetailModel, RolesModel, RoleUsersModel, EditUserRolesModel, RolesStateModel} from "./models";
import {DataResponse} from "../../../../components/tables";
import {bool} from "yup";

const initial: RolesStateModel = {
    editRolesModal: {
        show: false,
        roleId: '',
        refreshTableWhenModalClosed: false
    },
    table: {
        data: null,
        refreshTable: false
    },
    detail: {
        info: {
            name: '',
            colorCode: '',
            totalUsers: 0
        },
        usersTable: {
            data: null,
            refreshTable: false
        },
    },
    editUserRoles: {
        showModal: false,
        organisationUserId: '',
        roles: null
    },
}

const {actions, reducer} = createSlice({
    name: 'roles',
    initialState: initial,
    reducers: {
        setShowRoleModal: (state, action: PayloadAction<{ state: boolean, roleId: string | null, refreshTableWhenModalClosed?: boolean }>) => {
            state.editRolesModal = {
                show: action.payload.state,
                roleId: action.payload.roleId ?? initial.editRolesModal.roleId,
                refreshTableWhenModalClosed: action.payload.refreshTableWhenModalClosed ?? initial.editRolesModal.refreshTableWhenModalClosed
            }
        },
        setRoles: (state, action: PayloadAction<DataResponse<RolesModel> | null>) => {
            state.table = {
                data: action.payload ?? initial.table.data,
                refreshTable: false
            }
        },
        setRolesRefreshTable: (state) => {
            state.table.refreshTable = true
        },
        setRoleDetailUsers: (state, action: PayloadAction<DataResponse<RoleUsersModel>>) => {
            state.detail.usersTable = {
                data: action.payload ?? initial.detail.usersTable.data,
                refreshTable: false
            }
        },
        setRoleDetailUsersRefreshTable: (state) => {
            state.detail.usersTable.refreshTable = true
        },
        setRoleDetailInfo: (state, action: PayloadAction<RoleDetailModel>) => {
            state.detail = {...state.detail, info: action.payload}
        },
        setShowEditUserRolesModal: (state, action: PayloadAction<{ showModal: boolean, organisationUserId: string | null }>) => {
            state.editUserRoles = {
                ...state.editUserRoles,
                showModal: action.payload.showModal,
                organisationUserId: action.payload.organisationUserId ?? initial.editUserRoles.organisationUserId
            }
        },
        setEditUsersRolesModalUserRoles: (state, action: PayloadAction<EditUserRolesModel[] | null>) => {
            state.editUserRoles = {...state.editUserRoles, roles: action.payload ?? initial.editUserRoles.roles}
        },
    }
})

export const rolesReducer = reducer
export const rolesReducerActions = actions