/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import React, {FC, useEffect, useState} from 'react'
import {KTSVG} from '../../../helpers'
import {useLayout} from '../../core'
import {DefaultTitle} from '../header/page-title/DefaultTitle'
import {Ping} from "../ping/Ping";
import {useWindowWidth} from "@react-hook/window-size";

const Toolbar1: FC = () => {
    const {classes} = useLayout()

    const windowWidth = useWindowWidth()
    const [myIpv4Address, setMyIpv4Address] = useState('')
    const [myIpv6Address, setMyIpv6Address] = useState('')

    return (
        <>
            <div className='toolbar' id='kt_toolbar'>
                {/* begin::Container */}
                <div
                    id='kt_toolbar_container'
                    className={clsx(classes.toolbarContainer.join(' '), 'container-xxl d-flex flex-stack')}
                >
                    <DefaultTitle/>
                    {
                        <Ping
                            setMyIpv4Address={setMyIpv4Address}
                            setMyIpv6Address={setMyIpv6Address}
                            myIpv4Address={myIpv4Address}
                            myIpv6Address={myIpv6Address}
                        />
                    }

                </div>
                {/* end::Container */}
            </div>
            {
                windowWidth <= 600 &&
                <div className="bg-white border border-top-1 py-4">
                    <div className="container-fluid text-end">
                        {myIpv4Address && <div>IPv4: {myIpv4Address}</div>}
                        {myIpv6Address && <div>IPv6: {myIpv6Address}</div>}
                    </div>
                </div>
            }
        </>
    )
}

export {Toolbar1}
