import {DestinationTypeDetailEnum} from "../../../models";
import {ScriptCodeCard} from "../../../../../../../components/cards";
import React, {useEffect, useState} from "react";
import {useIntl} from "react-intl";
import {useInterval} from "usehooks-ts";
import {destinationsActions} from "../../../destinationsActions";


interface StateModel {
    isLoading: boolean,
    agentType: DestinationTypeDetailEnum | null,
    script: string
}

export const AgentIntegration = (
    {organisationId, agentId, setAgentStatus = null}
        : { organisationId: string, agentId: string, setAgentStatus?: any }
) => {

    const intl = useIntl()

    const [model, setModel] = useState<StateModel>({isLoading: true, agentType: null, script: ''})
    const [isAgentOnline, setIsAgentOnline] = useState<boolean | null>(null)


    useEffect(() => {
        (async () => {
            if (!agentId) return

            const response = await destinationsActions.getAgentScript({organisationId, agentId: agentId})
            if (response.isSucceed) {
                setModel({
                    isLoading: false,
                    agentType: response.data.agentType,
                    script: response.data.script
                })
            }
        })()
    }, [agentId])


    useInterval(
        async () => {

            const response = await destinationsActions.checkOnline(organisationId, agentId)

            if (response.isSucceed) {
                setIsAgentOnline(response.data)
                if (setAgentStatus) {
                    setAgentStatus(true)
                }
            }
        }, agentId ? 5000 : null)

    return (
        <>
            {model.isLoading && <ScriptCodeCard className="mb-6" text={intl.formatMessage({id: "ORGANISATIONS.DESTINATION.SCRIPT.LOADING"})}/>}
            {
                !model.isLoading &&
                <>
                    <div className="mb-5">
                        {model.agentType == DestinationTypeDetailEnum.WindowsAgent &&
                            intl.formatMessage({id: 'ORGANISATION.MODAL.DESTINATION.CREATE_AGENT.MESSAGE.RUN_SCRIPT_ON_WINDOWS'})
                        }
                        {(model.agentType == DestinationTypeDetailEnum.LinuxDebAgent || model.agentType == DestinationTypeDetailEnum.LinuxRpmAgent) &&
                            intl.formatMessage({id: 'ORGANISATION.MODAL.DESTINATION.CREATE_AGENT.MESSAGE.RUN_SCRIPT_ON_LINUX'})
                        }
                        {model.agentType == DestinationTypeDetailEnum.macOSAgent &&
                            intl.formatMessage({id: 'ORGANISATION.MODAL.DESTINATION.CREATE_AGENT.MESSAGE.RUN_SCRIPT_ON_MACOS'})
                        }
                    </div>

                    <ScriptCodeCard text={model.script} className="mh-300px scroll-y"/>

                    <div className="mb-5 mt-5">
                        {intl.formatMessage({id: 'ORGANISATION.MODAL.DESTINATION.CREATE_AGENT.MESSAGE.STATE'})}
                    </div>

                    <div
                        className="mb-5 mt-7 mb-10 text-center fs-4 border border-2 border-dashed rounded py-5">
                        {isAgentOnline == null &&
                            <span
                                className="badge badge-lg badge-light">
                            {intl.formatMessage({id: 'LOADING'})}
                        </span>
                        }
                        {isAgentOnline == true &&
                            <span
                                className="badge badge-lg badge-light-success">
                            {intl.formatMessage({id: 'ORGANISATION.MODAL.DESTINATION.CREATE_AGENT.MESSAGE.STATE_ONLINE'})}
                        </span>
                        }
                        {isAgentOnline == false &&
                            <span
                                className="badge badge-lg badge-light-danger">
                                {intl.formatMessage({id: 'ORGANISATION.MODAL.DESTINATION.CREATE_AGENT.MESSAGE.STATE_OFFLINE'})}
                            </span>
                        }
                    </div>
                </>
            }
        </>
    )
}