import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {UserModel} from './models/UserModel'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {Languages} from '../../../_metronic/i18n/Metronici18n'
import {init} from '@sentry/react'

export interface AuthState {
  user?: UserModel
  accessToken?: string
  refreshToken?: string
  verifiedDevice: boolean
  returnUrl?: string
  verificationCode?: string | null
}

const initial: AuthState = {
  user: undefined,
  accessToken: '',
  refreshToken: '',
  verifiedDevice: false,
  returnUrl: '',
  verificationCode: null,
}

const {actions, reducer} = createSlice({
  name: 'auth',
  initialState: initial,
  reducers: {
    setLoggedUser: (state, action: PayloadAction<AuthState>) => {
      state.accessToken = action.payload.accessToken
      state.user = action.payload.user
      state.verifiedDevice = action.payload.verifiedDevice
    },
    setAccessToken: (state, action: PayloadAction<string>) => {
      state.accessToken = action.payload
    },
    setRefreshToken: (state, action: PayloadAction<string>) => {
      state.refreshToken = action.payload
    },
    setReturnURl: (state, action: PayloadAction<string>) => {
      state.returnUrl = action.payload
    },
    logout: (state) => {
      state.accessToken = initial.accessToken
      state.refreshToken = initial.refreshToken
      state.user = initial.user
      state.verifiedDevice = initial.verifiedDevice
    },
    setLanguage: (state, action: PayloadAction<Languages | null>) => {
      if (state.user) {
        state.user.language = action.payload ?? undefined
      }
    },
    setVerificationCode: (state, {payload}: PayloadAction<string | null>) => {
      state.verificationCode = payload
    },
  },
})

export const authReducer = persistReducer(
  {
    storage,
    key: 'auth',
    whitelist: ['user', 'accessToken', 'refreshToken', 'verifiedDevice'],
  },
  reducer
)
export const authReducerActions = actions
