/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {Navigate, Outlet, Route, Routes, useLocation} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import Logo from "../../../pages/auth/components/Logo";
import {ResetPasswordPage, ForgotPasswordPage, RegistrationPage, LoginPage} from "../subpages";
import {Languages} from "./Languages";
import {shallowEqual, useSelector} from "react-redux";
import {RootState} from "../../../../setup";
import {authSelectors} from "../authSelectors";

const AuthLayout = () => {
    useEffect(() => {
        document.body.classList.add('bg-white')
        return () => {
            document.body.classList.remove('bg-white')
        }
    }, [])

    return (
        <div
            className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
            style={{
                backgroundImage: `url(${toAbsoluteUrl('/assets/auth-background.png')})`,
            }}
        >
            {/* begin::Content */}
            <div className='d-flex flex-center flex-column flex-column-fluid p-5 p-md-10 pb-lg-20'>
                {/* begin::Logo */}
                <Logo/>
                {/* end::Logo */}
                {/* begin::Wrapper */}
                <div className='w-md-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto'>
                    <Outlet/>
                </div>
                {/* end::Wrapper */}
                <div className="mt-10">
                    <Languages/>
                </div>
            </div>
            {/* end::Content */}
            {/* begin::Footer */}
            <div className='d-flex flex-center flex-column-auto p-10'>
                <div className='d-flex align-items-center fw-bold text-muted fs-6'>
                    © Protect Remote
                </div>
            </div>
            {/* end::Footer */}
        </div>
    )
}

const AuthPage = () => {

    const isAuthorized = useSelector<RootState>(({auth}) => auth.verifiedDevice, shallowEqual)
    const returnUrl = useSelector(authSelectors.getReturnUrl())

    return (
        <>
            <Routes>
                <Route element={<AuthLayout/>}>
                    {isAuthorized ? (
                            <>
                                {
                                    // @ts-ignore
                                    (returnUrl && !returnUrl.toLowerCase().startsWith("/auth")) ?
                                        // @ts-ignore
                                        <Route path='*' element={<Navigate to={returnUrl}/>}/>
                                        :
                                        <Route path='*' element={<Navigate to='/dashboard'/>}/>
                                }
                            </>
                        ) :
                        (
                            <>
                                <Route path='login' element={<LoginPage/>}/>
                                <Route path='registration' element={<RegistrationPage/>}/>
                                <Route path='forgot-password' element={<ForgotPasswordPage/>}/>
                                <Route path='reset-password' element={<ResetPasswordPage/>}/>
                                <Route index element={<LoginPage/>}/>
                            </>
                        )}
                </Route>
            </Routes>
        </>
    )

}

export {AuthPage}
