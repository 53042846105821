import React, {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Form, Formik} from 'formik'
import {useIntl} from "react-intl";
import {FormInputGroup} from "../../../components/forms/FormInputGroup";
import {FormActionButtons} from "../../../components/forms/FormActionButtons";
import {authActions} from "../";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";
import {toastify} from "../../../libs/toastify";

const initialValues = {
    email: '',
}

export const ForgotPasswordPage = () => {
    const intl = useIntl()
    const [loading, setLoading] = useState(false)
    const reCaptcha = useGoogleReCaptcha()

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .email(intl.formatMessage({id: 'VALIDATION.EMAIL_FORMAT'}))
            .min(10, intl.formatMessage({id: 'VALIDATION.MIN_LENGTH'}, {length: 10}))
            .max(100, intl.formatMessage({id: 'VALIDATION.MAX_LENGTH'}, {length: 100}))
            .required(intl.formatMessage({id: 'VALIDATION.REQUIRED'}, {name: intl.formatMessage({id: 'AUTH.FORGOT_PASSWORD.INPUT.EMAIL'})})),
    })

    return (
        <>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={async (values, {setStatus, setSubmitting}) => {
                    setLoading(true)
                    setTimeout(async () => {

                        if (!reCaptcha.executeRecaptcha) {
                            toastify.error(intl.formatMessage({id: "AUTH.LOGIN.MESSAGE.RE_CAPTCHA_NOT_FOUND"}))
                            return
                        }

                        const reCAPTCHAToken = await reCaptcha.executeRecaptcha()
                        
                        const response = await authActions.forgotPassword({
                            email: values.email,
                            reCAPTCHAToken: reCAPTCHAToken
                        })
                        if (response.isSucceed) {
                            setStatus({
                                isSucceed: true,
                                message: intl.formatMessage({id: "AUTH.FORGOT_PASSWORD.MESSAGE.SENT"})
                            })
                        } else {
                            switch (response.message) {
                                case "NotRegistered":
                                    setStatus({
                                        isSucceed: false,
                                        message: intl.formatMessage({id: "AUTH.FORGOT_PASSWORD.MESSAGE.NOT_REGISTERED"})
                                    })
                                    break
                                default:
                                    setStatus({
                                        isSucceed: false,
                                        message: intl.formatMessage({id: "ERROR.ERROR_OCCURRED"})
                                    })
                            }
                            setSubmitting(false)
                        }
                        setLoading(false)
                    }, 500)
                }}
            >
                {(formik) => (
                    <Form className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'>
                        <div className='text-center mb-10'>
                            {/* begin::Title */}
                            <h1 className='text-dark mb-3'>{intl.formatMessage({id: 'AUTH.FORGOT_PASSWORD.TITLE'})}</h1>
                            {/* end::Title */}

                            {/* begin::Link */}
                            <div
                                className='text-gray-400 fw-bold fs-4'>{intl.formatMessage({id: 'AUTH.FORGOT_PASSWORD.SUBTITLE'})}</div>
                            {/* end::Link */}
                        </div>

                        {/* begin::Title */}
                        {formik.status && (
                            <div
                                className={clsx('alert mb-5', formik.status.isSucceed ? 'alert-success' : 'alert-danger')}>
                                {formik.status.message}
                            </div>
                        )}
                        {/* end::Title */}

                        {/* begin::Form group */}
                        <FormInputGroup
                            labelResourceName={"AUTH.FORGOT_PASSWORD.INPUT.EMAIL"}
                            fieldProps={formik.getFieldProps('email')}
                            validationCondition={(formik.touched.email && formik.errors.email)}
                            validationMessage={formik.errors.email}
                        />
                        {/* end::Form group */}

                        {/* begin::Action */}
                        <FormActionButtons
                            formik={formik}
                            loading={loading}
                            submitButtonResourceName={"FORM.SUBMIT"}
                            showCancelButton={true}
                            cancelLinkTo={"/auth/login"}
                            cancelButtonResourceName={"FORM.GO_BACK"}
                            fullWidthButton={false}
                            buttonSize={"btn-lg"}
                        />
                        {/* end::Action */}
                    </Form>
                )}
            </Formik>
        </>
    )
}
