import {useSelector} from "react-redux";
import React, {useState} from "react";
import {organisationSelectors} from "../../organisation/";
import {useIntl} from "react-intl";
import {ImageFromBase64} from "../../../components/images";
import {toastify} from "../../../libs/toastify";
import {accountActions} from "../";
import {authSelectors, authActions} from "../../auth/";
import {useAppDispatch} from "../../../infrastructure/hooks/reduxHooks";

export const UserPhoto = () => {

    const dispatch = useAppDispatch();
    const intl = useIntl()
    const organisation = useSelector(organisationSelectors.getSelectedOrganisation())
    const [selectedImage, setSelectedImage] = useState<string | null>(null);
    const fileInput = React.createRef<HTMLInputElement>();
    const user = useSelector(authSelectors.getUser())
    const [loading, setLoading] = useState(false)

    const allowedExtensions = ["jpg", "jpeg", "png", "svg"]
    const allowedMimeTypes = ["image/jpeg", "image/png", "image/svg+xml"]

    const imageSelected = (e: any) => {
        if (e.target?.files?.length === 0) {
            setSelectedImage(null)
            e.target.value = null
            return
        }

        const uploadedFile = e.target.files[0]

        const fileExtension = uploadedFile.name.split('.').pop()
        const fileMimeType = uploadedFile.type
        const fileSize = uploadedFile.size

        if (!allowedExtensions.includes(fileExtension) || !allowedMimeTypes.includes(fileMimeType)) {
            toastify.error(intl.formatMessage({id: "ERROR.INVALID_IMAGE_FILE_TYPE"}))
            setSelectedImage(null)
            e.target.value = null
            return
        }

        if (fileSize > 256000) {
            toastify.error(intl.formatMessage({id: "ERROR.IMAGE_FILE_SIZE"}, {"size": "256 KB"}))
            setSelectedImage(null)
            e.target.value = null
            return
        }

        setSelectedImage(URL.createObjectURL(e.target.files[0]))
    }

    const resetImage = () => {
        setSelectedImage(null)
        if (fileInput.current) {
            fileInput.current.value = "";
        }
    }

    const updatePhoto = async () => {
        if (fileInput?.current?.files?.length != 1) {
            return toastify.error()
        }
        
        const file = fileInput.current.files[0];

        setLoading(true);

        const response = await accountActions.updateProfilePhoto(file)
        if (response.isSucceed) {
            await dispatch(authActions.refreshUserAndAccessToken()).unwrap();
            setLoading(false);
            resetImage();
        } else {
            setLoading(false);
        }
    }

    return (
        <>
            {
                <div
                    className="d-flex flex-center flex-shrink-0 bg-light rounded w-100px h-100px w-lg-150px h-lg-150px mx-auto mb-4 position-relative">
                    {
                        (user.photo && !selectedImage) &&
                        <>
                            <ImageFromBase64 base64String={user.photo} className="w-100 p-4"/>
                            <span
                                className="btn btn-sm btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow position-absolute"
                                style={{"right": "-18px", "top": "-10px"}}
                                onClick={() => fileInput.current?.click()}>
                            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512" fill="#7E8299">
                                    <path
                                        d="M362.7 19.3L314.3 67.7 444.3 197.7l48.4-48.4c25-25 25-65.5 0-90.5L453.3 19.3c-25-25-65.5-25-90.5 0zm-71 71L58.6 323.5c-10.4 10.4-18 23.3-22.2 37.4L1 481.2C-1.5 489.7 .8 498.8 7 505s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L421.7 220.3 291.7 90.3z"/>
                                </svg>
                        </span>
                        </>
                    }
                    {
                        selectedImage &&
                        <img className="w-100 p-4"
                             src={selectedImage}
                             alt="User Photo"/>
                    }
                    {
                        (selectedImage && !loading) &&
                        <>
                        <span
                            className="btn btn-sm btn-icon btn-circle btn-white btn-active-light-success btn-hover-text-primary btn-shadow position-absolute"
                            style={{"bottom": "-10px", "marginLeft": "-40px"}}
                            onClick={() => updatePhoto()}>
                            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512" fill="green"><path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>
                        </span>
                            <span
                                className="btn btn-sm btn-icon btn-circle btn-white btn-active-light-danger btn-hover-text-primary btn-shadow position-absolute"
                                style={{"bottom": "-10px", "marginRight": "-40px"}}
                                onClick={() => resetImage()}>
                            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 352 512" fill="red"><path d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"/></svg>
                    </span>
                        </>
                    }
                    {
                        (!user.photo && !selectedImage) &&
                        <div className={"p-5 text-center cursor-pointer"}
                             onClick={(e) => {
                                 fileInput.current?.click();
                             }}>
                            {intl.formatMessage({id: 'ACCOUNT.PROFILE.MESSAGE.ADD_PHOTO'})}
                        </div>
                    }
                    <input type="file" className="d-none" ref={fileInput} onChange={imageSelected}/>

                </div>
            }
        </>
    )
}