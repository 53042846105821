import {createAsyncThunk} from "@reduxjs/toolkit";
import {subscriptionApis} from "./subscriptionApis";
import {subscriptionReducerActions} from "./subscriptionSlice";
import {ActionResponse} from "../../../../infrastructure/models/actionResponse";
import {PaymentModel} from "./models/PaymentModel";
import {SubscriptionStateModelInfo} from "./models/SubscriptionStateModel";

const showUpgradeModal = createAsyncThunk(
    'subscription/showUpgradeModal',
    async ({state = true, currentPlan}: { state?: boolean, currentPlan?: 'freemium' | 'starter' | 'growth' | 'scale' }, {dispatch}) => {
        dispatch(subscriptionReducerActions.setShowUpgradeModal({state, currentPlan}))
    })

const showFreemiumModal = createAsyncThunk(
    'subscription/showFreemiumModal',
    async ({state = true}: { state?: boolean }, {dispatch}) => {
        dispatch(subscriptionReducerActions.setShowFreemiumModal({state}))
    })

const getPackages = createAsyncThunk(
    'subscription/getPackages',
    async ({abc}: { abc: string }, {dispatch}) => {
        const response = await subscriptionApis.getPackages()
        dispatch(subscriptionReducerActions.setUpgradeModalPackages(response.data))
    })

const buyPackage = async ({organisationId, packageId, priceLookupKey}: { organisationId: string, packageId: string, priceLookupKey: string }) => {

    try {
        const response = await subscriptionApis.buyPackage({organisationId, packageId, priceLookupKey})

        return {
            data: response.data,
            isSucceed: true
        } as ActionResponse<PaymentModel>
    } catch (e: any) {
        return {
            isSucceed: false,
            errors: e.response?.data?.errors,
            message: e.response.data
        } as ActionResponse<PaymentModel>
    }
}

const successPayment = async ({organisationId, session}: { organisationId: string, session: string }) => {

    try {
        const response = await subscriptionApis.successPayment({organisationId, session})

        return {
            data: response.data,
            isSucceed: true
        } as ActionResponse
    } catch (e: any) {
        return {
            isSucceed: false,
            errors: e.response?.data?.errors,
            message: e.response.data
        } as ActionResponse
    }
}

const cancelSubscription = async ({organisationId}: { organisationId: string }) => {
    try {
        const response = await subscriptionApis.cancelSubscription({organisationId})

        return {
            data: response.data,
            isSucceed: true
        } as ActionResponse
    } catch (e: any) {
        return {
            isSucceed: false,
            errors: e.response?.data?.errors,
            message: e.response.data
        } as ActionResponse
    }
}

const reactivateSubscription = async ({organisationId}: { organisationId: string }) => {
    try {
        const response = await subscriptionApis.reactivateSubscription({organisationId})

        return {
            data: response.data,
            isSucceed: true
        } as ActionResponse
    } catch (e: any) {
        return {
            isSucceed: false,
            errors: e.response?.data?.errors,
            message: e.response.data
        } as ActionResponse
    }
}

const getSubscriptionInfo = createAsyncThunk(
    'subscription/getSubscriptionInfo',

    async ({organisationId}: { organisationId: string }, {dispatch}) => {
        const response = await subscriptionApis.getSubscriptionInfo({organisationId})
        dispatch(subscriptionReducerActions.setSubscriptionInfo(response.data))
    })

const getBillingInfo = createAsyncThunk(
    'subscription/getBillingInfo',

    async ({organisationId}: { organisationId: string }, {dispatch}) => {
        const response = await subscriptionApis.getBillingInfo({organisationId})
        dispatch(subscriptionReducerActions.setBillingInfo(response.data))
    })

const editBillingInfo = async ({organisationId}: { organisationId: string }) => {
    try {
        const response = await subscriptionApis.editBillingInfo({organisationId})

        return {
            data: response.data,
            isSucceed: true
        } as ActionResponse
    } catch (e: any) {
        return {
            isSucceed: false,
            errors: e.response?.data?.errors,
            message: e.response.data
        } as ActionResponse
    }
}

export const subscriptionActions = {
    showUpgradeModal,
    showFreemiumModal,
    getPackages,
    buyPackage,
    successPayment,
    cancelSubscription,
    reactivateSubscription,
    getSubscriptionInfo,
    getBillingInfo,
    editBillingInfo
}