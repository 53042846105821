import clsx from "clsx";
import React, {FC, MouseEventHandler} from "react";
import {useIntl} from "react-intl";
import {Link} from "react-router-dom";

interface Props {
    type?: "reset" | "button"
    onClick: React.MouseEventHandler<HTMLButtonElement>,
    linkTo?: string,
    LinkState?: any,
    buttonResourceName?: string
    fullWidthButton?: boolean,
    buttonSize?: "default" | "btn-xs" | "btn-sm" | "btn-lg"
}

export const FormCancelButton: FC<Props> = ({
                                                type = "reset",
                                                onClick,
                                                linkTo,
                                                LinkState,
                                                buttonResourceName,
                                                fullWidthButton = false,
                                                buttonSize = "default",
                                            }) => {

    return (
        linkTo ?
            (
                <Link to={linkTo} state={LinkState ? LinkState : {}}>
                    <CancelButtonTemplate
                        type={type}
                        onClick={onClick}
                        linkTo={linkTo}
                        buttonResourceName={buttonResourceName}
                        fullWidthButton={fullWidthButton}
                        buttonSize={buttonSize}
                    />
                </Link>
            )
            :
            <CancelButtonTemplate
                type={type}
                onClick={onClick}
                linkTo={linkTo}
                buttonResourceName={buttonResourceName}
                fullWidthButton={fullWidthButton}
                buttonSize={buttonSize}
            />
    )
}

const CancelButtonTemplate: FC<Props> = ({
                                             type = "reset",
                                             onClick,
                                             buttonResourceName,
                                             fullWidthButton = false,
                                             buttonSize = "default",
                                         }) => {

    const intl = useIntl()

    return (
        <button type={type}
                onClick={onClick}
                className={clsx("btn btn-white me-3", fullWidthButton && "w-100 mt-3")}>
            {buttonResourceName && intl.formatMessage({id: buttonResourceName})}
            {buttonResourceName === undefined && intl.formatMessage({id: 'FORM.CANCEL'})}
        </button>
    )
}