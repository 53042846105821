import {RootState} from "../../../../../setup";

const getRuleModal = () => ({rules: {ruleModal}}: RootState) => ruleModal
const getRuleModalState = () => ({rules: {ruleModal}}: RootState) => ruleModal.show
const getRuleModalRuleId = () => ({rules: {ruleModal}}: RootState) => ruleModal.ruleId
const getRuleModalType = () => ({rules: {ruleModal}}: RootState) => ruleModal.type
const getRuleModalDestinationId = () => ({rules: {ruleModal}}: RootState) => ruleModal.destinationId
const getRules = () => ({rules: {table}}: RootState) => table.data
const getRulesTableRefreshState = () => ({rules: {table}}: RootState) => table.refreshTable

export const rulesSelectors =
    {
        getRuleModal,
        getRuleModalState,
        getRuleModalRuleId,
        getRuleModalType,
        getRuleModalDestinationId,
        getRules,
        getRulesTableRefreshState
    }