import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {DataResponse} from "../../../../components/tables";
import {RulesModel, RulesStateModel} from "./models";
import {DestinationTypeEnum} from "../destinations/models";

const initial: RulesStateModel = {
    ruleModal: {
        show: false,
        type: null,
        ruleId: '',
        destinationId: '',
        refreshTableWhenModalClosed: false
    },
    table: {
        data: null,
        refreshTable: false
    }
}

const {actions, reducer} = createSlice({
    name: 'rules',
    initialState: initial,
    reducers: {
        setShowRuleModal: (state, action: PayloadAction<{ state: boolean, type: DestinationTypeEnum | null, ruleId: string | null, refreshTableWhenModalClosed?: boolean }>) => {
            state.ruleModal = {
                show: action.payload.state,
                type: action.payload.type ?? initial.ruleModal.type,
                ruleId: action.payload.ruleId ?? initial.ruleModal.ruleId,
                refreshTableWhenModalClosed: action.payload.refreshTableWhenModalClosed ?? initial.ruleModal.refreshTableWhenModalClosed
            }
        },
        setRules: (state, action: PayloadAction<DataResponse<RulesModel> | null>) => {
            state.table = {
                data: action.payload ?? initial.table.data,
                refreshTable: false
            }
        },
        setRulesRefreshTable: (state) => {
            state.table.refreshTable = true
        }
    }
})

export const rulesReducer = reducer
export const rulesReducerActions = actions