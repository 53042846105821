import {DestinationTypeDetailEnum} from "../../../models";
import {FieldInputProps} from "formik";
import {FormGroup} from "../../../../../../../components/forms";

export interface SelectDestinationDetailTypeModel {
    formik: any,
    items: SelectDestinationDetailTypeModelItem[],
    fieldProps: string
}

interface SelectDestinationDetailTypeModelItem {
    iconHtml: JSX.Element,
    title: string,
    description: string,
    value: DestinationTypeDetailEnum,
}

export const SelectDestinationDetailType = ({formik, items, fieldProps}: SelectDestinationDetailTypeModel) => {
    return (
        <>
            <div className="fv-row mb-8">
                <FormGroup labelResourceName={"ORGANISATION.MODAL.DESTINATION.CREATE_AGENT.INPUT.TYPE"}>
                    <div className="fv-row fv-plugins-icon-container mt-2">
                        {
                            items.map((item, index) => {
                                return <SelectDestinationDetailTypeItem key={item.value + "_" + index} item={item} fieldProps={formik.getFieldProps(fieldProps)}
                                />
                            })
                        }
                    </div>
                </FormGroup>
            </div>
        </>
    )
}

const SelectDestinationDetailTypeItem = ({
                                             item,
                                             fieldProps
                                         }: { item: SelectDestinationDetailTypeModelItem, fieldProps: FieldInputProps<any> }) => {
    return (
        <label className="d-flex flex-stack mb-5 cursor-pointer">
                            <span className="d-flex align-items-center me-2">
                                {item.iconHtml}
                                <span className="d-flex flex-column">
																<span
                                                                    className="fw-bold fs-6">{item.title}</span>
																<span
                                                                    className="fs-7 text-muted">{item.description}</span>
															</span>
								</span>
            <span className="form-check form-check-custom form-check-solid">
                <input className="form-check-input" type="radio" {...fieldProps} value={item.value} />
			</span>
        </label>
    )
}