import {RootState} from "../../../../../setup";
import {ChangeUserTypeModelUser} from "./models";

// users
const getUsers = () => ({users: {table}}: RootState) => table.data
const getUsersTableRefreshState = () => ({users: {table}}: RootState) => table.refreshTable

// inviteUsers
const getInviteUsersModalState = () => ({users: {inviteUsers}}: RootState) => inviteUsers.showInviteUsersModal
const getInviteUsersSearchResult = () => ({users: {inviteUsers}}: RootState) => inviteUsers.searchResult

// changeUserType
const getChangeUserTypeModalState = () => ({users: {changeUserType}}: RootState) => changeUserType.showChangeUserTypeModal
const getChangeUserTypeModalUser = () => ({users: {changeUserType}}: RootState) => changeUserType.user as ChangeUserTypeModelUser

export const usersSelectors = {
    getUsers,
    getUsersTableRefreshState,
    getInviteUsersModalState,
    getInviteUsersSearchResult,
    getChangeUserTypeModalState,
    getChangeUserTypeModalUser
}