import {useSelector} from "react-redux";
import {organisationReducerActions, organisationSelectors} from "../../";
import {Logo} from "./Logo";
import {organisationActions} from "../../";
import {useIntl} from "react-intl";
import {Link, useNavigate} from "react-router-dom";
import {Navigation} from "./Navigation";
import {usersActions} from "../../subpages/users/";
import {swal} from "../../../../libs/sweetAlert";
import * as urls from "../../../../infrastructure/variables/urls";
import {formatDate} from "../../../../infrastructure/utils/dateUtils";
import {useAppDispatch} from "../../../../infrastructure/hooks/reduxHooks";
import {Dropdown} from "react-bootstrap";
import {format} from "react-string-format";
import {authActions} from "../../../auth";
import {toastify} from "../../../../libs/toastify";
import * as device from 'react-device-detect'

export const Header = () => {
    const dispatch = useAppDispatch()
    const intl = useIntl()
    const navigate = useNavigate()
    const organisation = useSelector(organisationSelectors.getSelectedOrganisation());

    return (
        <>
            {
                organisation &&
                <>
                    {/*begin::Navbar*/}
                    <div className="card mb-6 mb-xl-9">
                        <div className="card-body pt-9 pb-0">
                            {/*begin::Details*/}
                            <div className="d-flex flex-wrap flex-sm-nowrap mb-3">
                                {/*begin::Logo*/}
                                {
                                    !device.isMobile &&
                                    <Logo/>
                                }
                                
                                {/*end::Logo*/}
                                {/*begin::Wrapper*/}
                                <div className="flex-grow-1">
                                    {/*begin::Head*/}
                                    <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                                        {/*begin::Details*/}
                                        <div className="d-flex flex-column">
                                            {/*begin::Status*/}
                                            <div className="d-flex align-items-center mb-2">
                                                <Link to={format(urls.ORGANISATION_DETAIL_URL, organisation.id)}
                                                      className="text-gray-800 fs-2 fw-bolder me-3">
                                                    {organisation.name}
                                                </Link>
                                            </div>
                                            {/*end::Status*/}
                                            {/*begin::Description*/}
                                            {/* <div className="d-flex flex-wrap fw-bold mb-4 fs-5 text-gray-400">#ProtectRemote ile daha güvende
                                            </div> */}
                                            {/*end::Description*/}
                                        </div>
                                        {/*end::Details*/}
                                        {/*begin::Actions*/}
                                        <div className="d-flex">

                                            <button
                                                className="btn btn-sm btn-bg-light btn-active-color-primary me-3"
                                                onClick={() => dispatch(usersActions.showInviteUsersModal({}))}>
                                                {intl.formatMessage({id: 'ORGANISATION.HEADER.BUTTON.INVITE_USER'})}
                                            </button>
                                            {/*begin::Menu*/}
                                            <div className="me-0">
                                                <Dropdown>
                                                    <Dropdown.Toggle variant={"light"}
                                                                     className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary">
                                                        <span className="ms-5">
                                                            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M8 256a56 56 0 1 1 112 0A56 56 0 1 1 8 256zm160 0a56 56 0 1 1 112 0 56 56 0 1 1 -112 0zm216-56a56 56 0 1 1 0 112 56 56 0 1 1 0-112z"/></svg>
                                                        </span>
                                                        {/*<i className="bi bi-three-dots fs-3 ms-5"/>*/}
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu
                                                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-200px py-3">
                                                        {/*begin::Heading*/}
                                                        <div className="menu-item px-3">
                                                            <div
                                                                className="menu-content text-muted pb-2 px-3 fs-7 text-uppercase">
                                                                {intl.formatMessage({id: 'ORGANISATION.HEADER.ACTIONS.TITLE.ACTIONS'})}
                                                            </div>
                                                        </div>
                                                        {/*end::Heading*/}
                                                        {/*begin::Menu item*/}
                                                        <div className="menu-item px-3">
                                                            <a
                                                                className="menu-link px-3 text-danger bg-hover-light-danger"
                                                                onClick={async () => {
                                                                    const result = await swal.questionWithConfirm(
                                                                        intl.formatMessage({id: 'SWEETALERT.MESSAGE.CONFIRM'}),
                                                                        intl.formatMessage({id: 'ORGANISATION.HEADER.ACTIONS.MESSAGE.DELETE_ORGANISATION'})
                                                                    )
                                                                    if (result.isConfirmed) {
                                                                        const response = await dispatch(organisationActions.deleteOrganisation({organisationId: organisation.id})).unwrap()

                                                                        if (!response.isSucceed) {

                                                                            if (response.message == "ThisOrganisationHasSubscription") {
                                                                                swal.warning("", intl.formatMessage({id: "ORGANISATION.HEADER.ACTIONS.MESSAGE.DELETE_ORGANISATION_HAS_SUBSCRIPTION"}))
                                                                            } else {
                                                                                toastify.error()
                                                                            }
                                                                            return
                                                                        }

                                                                        await authActions.refreshUserAndAccessToken();
                                                                        dispatch(organisationReducerActions.setSelectedOrganisation(null))
                                                                        setTimeout(() => {
                                                                            navigate(urls.ROOT_URL);
                                                                        }, 500)
                                                                    }
                                                                }
                                                                }
                                                            >
                                                                {intl.formatMessage({id: 'ORGANISATION.HEADER.ACTIONS.BUTTON.DELETE_ORGANISATION'})}
                                                            </a>
                                                        </div>
                                                        {/*end::Menu item*/}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                            {/*end::Menu*/}
                                        </div>
                                        {/*end::Actions*/}
                                    </div>
                                    {/*end::Head*/}
                                    {/*begin::Info*/}
                                    <div className="d-flex flex-wrap justify-content-start">
                                        {/*begin::Stats*/}
                                        <div className="d-flex flex-wrap">
                                            {/*begin::Stat*/}
                                            <div
                                                className="border border-gray-300 border-dashed rounded min-w-120px py-2 px-3 me-3 mb-3">
                                                {/*begin::Number*/}
                                                <div className="d-flex align-items-center">
                                                    <div
                                                        className="fs-6 fw-bolder">{organisation.createdDate && formatDate(organisation.createdDate)}</div>

                                                </div>
                                                {/*end::Number*/}
                                                {/*begin::Label*/}
                                                <div
                                                    className="fw-bold fs-7 text-gray-400">{intl.formatMessage({id: 'ORGANISATION.HEADER.INFO.CREATED_DATE'})}</div>
                                                {/*end::Label*/}
                                            </div>
                                            {/*end::Stat*/}
                                            {/*begin::Stat*/}
                                            <div
                                                className="border border-gray-300 border-dashed rounded min-w-120px py-2 px-3 me-3 mb-3">
                                                {/*begin::Number*/}
                                                <div className="d-flex align-items-center">
                                                    <div className="fs-6 fw-bolder">{organisation.userCount}
                                                    </div>
                                                </div>
                                                {/*end::Number*/}
                                                {/*begin::Label*/}
                                                <div
                                                    className="fw-bold fs-7 text-gray-400">{intl.formatMessage({id: 'ORGANISATION.HEADER.INFO.USER_COUNT'})}</div>
                                                {/*end::Label*/}
                                            </div>
                                            {/*end::Stat*/}
                                            {/*begin::Stat*/}
                                            <div
                                                className="border border-gray-300 border-dashed rounded min-w-120px py-2 px-3 me-3 mb-3">
                                                {/*begin::Number*/}
                                                <div className="d-flex align-items-center">
                                                    <div className="fs-6 fw-bolder">{organisation.destinationCount}
                                                    </div>
                                                </div>
                                                {/*end::Number*/}
                                                {/*begin::Label*/}
                                                <div
                                                    className="fw-bold fs-7 text-gray-400">{intl.formatMessage({id: 'ORGANISATION.HEADER.INFO.DESTINATION_COUNT'})}</div>
                                                {/*end::Label*/}
                                            </div>
                                            {/*end::Stat*/}
                                        </div>
                                        {/*end::Stats*/}
                                    </div>
                                    {/*end::Info*/}
                                </div>
                                {/*end::Wrapper*/}
                            </div>
                            {/*end::Details*/}
                            <div className="separator"/>
                            {/*begin::Nav*/}
                            <Navigation
                                tabs={
                                    [
                                        {
                                            text: intl.formatMessage({id: 'ORGANISATION.HEADER.TABS.DASHBOARD'}),
                                            url: `/organisation/${organisation.id}/dashboard`,
                                            hasSubPages: true,
                                            selectedKey: 'dashboard',
                                        },
                                        // {
                                        //     text: intl.formatMessage({id: 'ORGANISATION.HEADER.TABS.RULES'}),
                                        //     url: `/organisation/${organisation.id}/rules`,
                                        //     hasSubPages: true
                                        // },
                                        {
                                            text: intl.formatMessage({id: 'ORGANISATION.HEADER.TABS.DESTINATIONS.AGENTS'}),
                                            url: `/organisation/${organisation.id}/agents`,
                                            hasSubPages: true,
                                            selectedKey: 'agents'
                                        },
                                        {
                                            text: intl.formatMessage({id: 'ORGANISATION.HEADER.TABS.DESTINATIONS.CMS'}),
                                            url: `/organisation/${organisation.id}/cms`,
                                            hasSubPages: true,
                                            selectedKey: 'cms'
                                        },
                                        {
                                            text: intl.formatMessage({id: 'ORGANISATION.HEADER.TABS.DESTINATIONS.SOFTWARE_PACKAGES'}),
                                            url: `/organisation/${organisation.id}/software-packages`,
                                            hasSubPages: true,
                                            selectedKey: 'software-packages'
                                        },
                                        {
                                            text: intl.formatMessage({id: 'ORGANISATION.HEADER.TABS.ROLES'}),
                                            url: `/organisation/${organisation.id}/roles`,
                                            hasSubPages: true,
                                            selectedKey: 'roles'
                                        },
                                        {
                                            text: intl.formatMessage({id: 'ORGANISATION.HEADER.TABS.USERS'}),
                                            url: `/organisation/${organisation.id}/users`,
                                            hasSubPages: true,
                                            selectedKey: 'users'
                                        },
                                        // {
                                        //     text: intl.formatMessage({id: 'ORGANISATION.HEADER.TABS.SUBSCRIPTION'}),
                                        //     url: `/organisation/${organisation.id}/subscription`,
                                        //     hasSubPages: true
                                        // }
                                    ]
                                }
                            />
                            {/*end::Nav*/}
                        </div>
                    </div>
                    {/*end::Navbar*/}
                </>
            }

        </>
    )
}