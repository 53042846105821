import {lowerCaseFirstLetter} from "./stringUtils";

export const addValidationErrorsToFormik = (formik: any, errors?: []) => {
    if (errors) {
        for (let error in errors) {
            let errorMessage = '';
            (errors[error] as []).map((message: string) => formik.setFieldError(lowerCaseFirstLetter(error), errorMessage += message + "<br />"))
            formik.setFieldError(lowerCaseFirstLetter(error), errorMessage)
        }
    }
}

export const addValidationErrors = (setFieldError: any, errors?: []) => {
    if (errors) {
        for (let error in errors) {
            let errorMessage = '';
            (errors[error] as []).map((message: string) => setFieldError(lowerCaseFirstLetter(error), errorMessage += message + "<br />"))
            setFieldError(lowerCaseFirstLetter(error), errorMessage)
        }
    }
}