import React, {FC, useState} from "react";
import styles from './Device.module.css'
import clsx from "clsx";
import {DeviceTypeEnum} from "../../models/enums/DeviceTypeEnum";
import {OperatingSystemEnum} from "../../models/enums/OperatingSystemEnum";
import {useSelector} from "react-redux";
import {devicesActions, devicesReducerActions, devicesSelectors} from "../../";
import {BrowserEnum, DeviceModel} from "../../models";
import {toastify} from "../../../../libs/toastify";
import {useIntl} from "react-intl";
import {formatRelativeFromISO} from "../../../../infrastructure/utils/dateUtils";
import {DateTime} from "luxon";
import {formatDeviceType, formatOperatingSystem} from "../../../../infrastructure/enums/enumTranslator";
import {swal} from "../../../../libs/sweetAlert";
import {DeviceSymbol} from "./DeviceSymbol";
import {useAppDispatch} from "../../../../infrastructure/hooks/reduxHooks";
import {ThreeDotsLoader} from "../../../../components/loaders";

interface DeviceProps {
    deviceId: string,
    name: string,
    lastSeen: string,
    deviceType: DeviceTypeEnum,
    operatingSystem: OperatingSystemEnum,
    browser?: BrowserEnum
}

export const Device: FC<DeviceProps> = ({deviceId, name, lastSeen, deviceType, operatingSystem, browser}) => {

    const dispatch = useAppDispatch()
    const intl = useIntl()
    const selectedDeviceId = useSelector(devicesSelectors.getSelectedDeviceId())
    const [deviceDetail, setDeviceDetail] = useState<DeviceModel | null>(null)
    const [loading, setLoading] = useState(false)


    const onClick = async (deviceId: string) => {

        setLoading(true)

        if (deviceDetail?.userDeviceId == selectedDeviceId) {
            dispatch(devicesReducerActions.setSelectedDeviceId(null))
            setDeviceDetail(null)
            setLoading(false)
            return;
        }

        dispatch(devicesReducerActions.setSelectedDeviceId(deviceId))
        const response = await devicesActions.getDevice(deviceId)
        if (response.isSucceed) {
            setDeviceDetail(response.data)
            setLoading(false)
        } else {
            setDeviceDetail(null)
        }
    }

    const removeDevice = async (userDeviceId: string) => {
        const response = await dispatch(devicesActions.removeDevice({userDeviceId})).unwrap()
        if (response.isSucceed) {
            toastify.success(intl.formatMessage({id: 'DEVICES.MESSAGE.DEVICE_REMOVED'}))
        }
    }

    return (
        <>
            <div className="mb-6">
                <div
                    onClick={() => onClick(deviceId)}
                    className={clsx("card shadow-sm cursor-pointer text-hover-primary text-active-primary", styles.device)}>
                    <div className="card-body">
                        <div className="d-flex flex-stack">

                            <DeviceSymbol deviceType={deviceType} operatingSystem={operatingSystem} browser={browser}/>

                            <div className="d-flex align-items-center flex-row-fluid flex-wrap">
                                <div className="flex-grow-1 me-2">
                                    <a
                                        className={clsx("text-gray-800 fs-6 fw-bold")}>{name}</a>
                                    <span
                                        className="text-muted fw-semibold d-block fs-7">{intl.formatMessage({id: 'DEVICES.LAST_SEEN'})} {formatRelativeFromISO(lastSeen)}</span>
                                </div>
                                <a href="#"
                                   className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary w-30px h-30px">
                                <span className="svg-icon svg-icon-2">
                                    <svg width="24" height="24" viewBox="0 0 24 24"
                                         fill="none" xmlns="http://www.w3.org/2000/svg"
                                         className={clsx(styles.svgIcon, selectedDeviceId == deviceId ? styles.clicked : "")}>
                                        <rect opacity="0.5" x="18" y="13" width="13"
                                              height="2" rx="1"
                                              transform="rotate(-180 18 13)"
                                              fill="currentColor"></rect>
                                        <path
                                            d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                                            fill="currentColor"></path>
                                    </svg>
                                </span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>


                {
                    loading ?
                        <>
                            <div className="card mt-3 mb-5 mb-xl-10">
                                <div className="card-body p-9">
                                    <div className="row mb-5">
                                        <ThreeDotsLoader/>
                                    </div>
                                </div>
                            </div>
                        </>
                        :
                        <>
                            {deviceDetail?.userDeviceId == selectedDeviceId &&
                                <>
                                    {
                                        <div className="card mt-3 mb-5 mb-xl-10">
                                            <div className="card-body p-9">
                                                <div className="row mb-5 pb-4 border-bottom-dashed border-gray-200">
                                                    <label
                                                        className="col-lg-4 fw-semibold text-muted">{intl.formatMessage({id: 'DEVICES.DETAIL.NAME'})}</label>
                                                    <div className="col-lg-8">
                                                            <span
                                                                className="fw-bold fs-6 text-gray-800">{deviceDetail.name}</span>
                                                    </div>
                                                </div>
                                                <div className="row mb-5 pb-4 border-bottom-dashed border-gray-200">
                                                    <label
                                                        className="col-lg-4 fw-semibold text-muted">{intl.formatMessage({id: 'DEVICES.DETAIL.DEVICE_TYPE'})}</label>
                                                    <div className="col-lg-8 fv-row">
                                    <span
                                        className="fw-semibold text-gray-800 fs-6">{formatDeviceType(deviceDetail.deviceType)}</span>
                                                    </div>
                                                </div>
                                                <div className="row mb-5 pb-4 border-bottom-dashed border-gray-200">
                                                    <label
                                                        className="col-lg-4 fw-semibold text-muted">{intl.formatMessage({id: 'DEVICES.DETAIL.OPERATING_SYSTEM'})}</label>
                                                    <div className="col-lg-8 fv-row">
                                    <span
                                        className="fw-semibold text-gray-800 fs-6">{formatOperatingSystem(deviceDetail.operatingSystem)}</span>
                                                    </div>
                                                </div>
                                                <div className="row mb-5 pb-4 border-bottom-dashed border-gray-200">
                                                    <label
                                                        className="col-lg-4 fw-semibold text-muted">{intl.formatMessage({id: 'DEVICES.DETAIL.VERIFIED'})}</label>
                                                    <div className="col-lg-8 fv-row">
                                    <span className="fw-semibold text-gray-800 fs-6">
                                        {deviceDetail.verified
                                            ? <span className="badge badge-light-success">
                                                {intl.formatMessage({id: 'DEVICES.DETAIL.MESSAGE.VERIFIED'})}
                                            </span>
                                            : <span className="badge badge-light-danger">
                                                {intl.formatMessage({id: 'DEVICES.DETAIL.MESSAGE.NOT_VERIFIED'})}
                                            </span>
                                        }
                                    </span>
                                                    </div>
                                                </div>
                                                <div className="row mb-5 pb-4 border-bottom-dashed border-gray-200">
                                                    <label
                                                        className="col-lg-4 fw-semibold text-muted">{intl.formatMessage({id: 'DEVICES.DETAIL.LAST_COMMUNICATION'})}</label>
                                                    <div className="col-lg-8 fv-row">
                                    <span
                                        className="fw-semibold text-gray-800 fs-6">{formatRelativeFromISO(deviceDetail.lastCommunication)}</span>
                                                    </div>
                                                </div>

                                                {deviceDetail.ipAddressV4 &&
                                                    <div className="row mb-7">
                                                        <label
                                                            className="col-lg-4 fw-semibold text-muted">{intl.formatMessage({id: 'DEVICES.DETAIL.IP_ADDRESS_V4'})}</label>
                                                        <div className="col-lg-8 fv-row">
                                                            <span
                                                                className="fw-semibold text-gray-800 fs-6">{deviceDetail.ipAddressV4}</span>
                                                        </div>
                                                    </div>
                                                }

                                                {deviceDetail.ipAddressV6 &&
                                                    <div className="row mb-7">
                                                        <label
                                                            className="col-lg-4 fw-semibold text-muted">{intl.formatMessage({id: 'DEVICES.DETAIL.IP_ADDRESS_V6'})}</label>
                                                        <div className="col-lg-8 fv-row">
                                                            <span
                                                                className="fw-semibold text-gray-800 fs-6">{deviceDetail.ipAddressV6}</span>
                                                        </div>
                                                    </div>
                                                }

                                                <div className="row">
                                                    <div className="col-12">
                                                        <button className="btn btn-sm btn-danger float-end"
                                                                onClick={async (e) => {
                                                                    e.currentTarget.style.pointerEvents = "none"
                                                                    const result = await swal.question(
                                                                        intl.formatMessage({id: 'SWEETALERT.MESSAGE.CONFIRM'}),
                                                                        intl.formatMessage({id: 'DEVICES.DETAIL.MESSAGE.REMOVE_CONFIRM'})
                                                                    )
                                                                    if (result.isConfirmed) {
                                                                        await removeDevice(deviceDetail?.userDeviceId)
                                                                    } else {
                                                                        // @ts-ignore
                                                                        e.target.style.pointerEvents = ""
                                                                    }
                                                                }}
                                                        >
                                                            {intl.formatMessage({id: 'DEVICES.DETAIL.BUTTON.FORGET_DEVICE'})}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </>
                            }
                        </>
                }
            </div>
        </>
    )
}