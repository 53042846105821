import {FormModal, FormSubmitButton} from "../../../../../../components/forms";
import {useIntl} from "react-intl";
import {useSelector} from "react-redux";
import {subscriptionSelectors} from "../../subscriptionSelectors";
import {useEffect} from "react";
import {subscriptionActions} from "../../subscriptionActions";
import {useAppDispatch} from "../../../../../../infrastructure/hooks/reduxHooks";

export const FreemiumModal = () => {

    const intl = useIntl()
    const dispatch = useAppDispatch()


    const modalState = useSelector(subscriptionSelectors.getFreemiumModalState())

    const closeModal = () => {
        dispatch(subscriptionActions.showFreemiumModal({state: false}))
    }

    useEffect(() => {
        return () => {
            closeModal()
        }
    }, [])

    return (
        <FormModal
            modalState={modalState}
            closeModal={closeModal}
            title={intl.formatMessage({id: 'ORGANISATIONS.MODAL.CREATE_ORGANISATION.TITLE'})}
        >
            <>
                <div className="fs-4 mb-10">
                    {intl.formatMessage({id: "ORGANISATION.MODAL.FREEMIUM.MESSAGE"})}
                </div>
                <div className="text-center">
                    <FormSubmitButton
                        loading={false}
                        buttonResourceName={"FORM.FINISH"}
                        onClick={closeModal}
                    />
                </div>
            </>
        </FormModal>
    )
}