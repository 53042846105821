import React, {useEffect, useState} from "react";
import {SettingsGroup} from "./SettingsGroup";
import {SettingsGroupItem} from "./SettingsGroupItem";
import {SettingsGroupSeparator} from "./SettingsGroupSeparator";
import {DestinationSettingsModel} from "../../../models/DestinationSettingsModel";
import {destinationsActions} from "../../../destinationsActions";
import {useSelector} from "react-redux";
import {organisationSelectors} from "../../../../../organisationSelectors";
import {useParams} from "react-router-dom";
import {BlocksLoader} from "../../../../../../../components/loaders";
import {toastify} from "../../../../../../../libs/toastify";
import {useIntl} from "react-intl";
import {destinationsSelectors} from "../../../destinationsSelectors";
import {DestinationTypeDetailEnum, DestinationTypeEnum} from "../../../models";

export const Settings = () => {

    const intl = useIntl()

    const organisationId = useSelector(organisationSelectors.getSelectedOrganisationId())
    const destination = useSelector(destinationsSelectors.getDestinationDetailInfo())
    const {destinationId} = useParams()

    const [settings, setSettings] = useState<DestinationSettingsModel | null>(null)
    const [isLoading, setIsLoading] = useState(true)
    const [isLocked, setIsLocked] = useState(false)

    useEffect(() => {
        (async () => {
            const response = await destinationsActions.getSettings({organisationId, destinationId: destinationId as string});
            if (response.isSucceed) {
                setSettings(response.data)
                setIsLoading(false)

            } else {
                setSettings(null)
                setIsLoading(true)
            }
        })()
    }, [])

    return (
        <>
            {
                !isLoading &&
                <>
                    {destination.destinationType == DestinationTypeEnum.Agent &&
                        <SettingsGroup
                            titleResource="ORGANISATIONS.DESTINATION_DETAIL.SETTINGS.SERVER_GROUP.TITLE"
                        >

                            <>
                                {destination.destinationType == DestinationTypeEnum.Agent &&
                                    <>
                                        {
                                            destination.destinationTypeDetail == DestinationTypeDetailEnum.WindowsAgent &&
                                            <SettingsGroupItem
                                                type="checkbox"
                                                imageFile="failover.png"
                                                titleResource="ORGANISATIONS.DESTINATION_DETAIL.SETTINGS.SERVER_GROUP.RDP_FAILOVER.TITLE"
                                                descriptionResource="ORGANISATIONS.DESTINATION_DETAIL.SETTINGS.SERVER_GROUP.RDP_FAILOVER.DESCRIPTION"
                                                defaultChecked={settings?.failoverRdp}
                                                preventClick={isLocked}
                                                onChange={async (e) => {
                                                    setIsLocked(true)
                                                    const response = await destinationsActions.updateSettingsFailoverRdp({
                                                        organisationId,
                                                        destinationId: destinationId as string,
                                                        enable: e.currentTarget.checked
                                                    })
                                                    if (response.isSucceed) {
                                                        toastify.success(intl.formatMessage({id: "ORGANISATIONS.DESTINATION_DETAIL.SETTINGS.SERVER_GROUP.RDP_FAILOVER.MESSAGE.SUCCESS"}))
                                                    }
                                                    setTimeout(() => {
                                                        setIsLocked(false)
                                                    }, 1000)
                                                }}
                                            />
                                        }
                                        {
                                            (destination.destinationTypeDetail == DestinationTypeDetailEnum.LinuxDebAgent || destination.destinationTypeDetail == DestinationTypeDetailEnum.LinuxRpmAgent) &&
                                            <>
                                                <SettingsGroupItem
                                                    type="checkbox"
                                                    imageFile="failover.png"
                                                    titleResource="ORGANISATIONS.DESTINATION_DETAIL.SETTINGS.SERVER_GROUP.SSH_FAILOVER.TITLE"
                                                    descriptionResource="ORGANISATIONS.DESTINATION_DETAIL.SETTINGS.SERVER_GROUP.SSH_FAILOVER.DESCRIPTION"
                                                    defaultChecked={settings?.failoverSsh}
                                                    preventClick={isLocked}
                                                    onChange={async (e) => {
                                                        setIsLocked(true)
                                                        const response = await destinationsActions.updateSettingsFailoverSsh({
                                                            organisationId,
                                                            destinationId: destinationId as string,
                                                            enable: e.currentTarget.checked
                                                        })
                                                        if (response.isSucceed) {
                                                            toastify.success(intl.formatMessage({id: "ORGANISATIONS.DESTINATION_DETAIL.SETTINGS.SERVER_GROUP.SSH_FAILOVER.MESSAGE.SUCCESS"}))
                                                        }
                                                        setTimeout(() => {
                                                            setIsLocked(false)
                                                        }, 1000)
                                                    }}
                                                />
                                                <SettingsGroupSeparator/>
                                                <SettingsGroupItem
                                                    type="checkbox"
                                                    imageFile="docker.png"
                                                    titleResource="ORGANISATIONS.DESTINATION_DETAIL.SETTINGS.SERVER_GROUP.DOCKER.TITLE"
                                                    descriptionResource="ORGANISATIONS.DESTINATION_DETAIL.SETTINGS.SERVER_GROUP.DOCKER.DESCRIPTION"
                                                    defaultChecked={settings?.docker}
                                                    preventClick={isLocked}
                                                    onChange={async (e) => {
                                                        setIsLocked(true)
                                                        const response = await destinationsActions.updateSettingsDocker({
                                                            organisationId,
                                                            destinationId: destinationId as string,
                                                            enable: e.currentTarget.checked
                                                        })
                                                        if (response.isSucceed) {
                                                            toastify.success(intl.formatMessage({id: "ORGANISATIONS.DESTINATION_DETAIL.SETTINGS.SERVER_GROUP.DOCKER.MESSAGE.SUCCESS"}))
                                                        }
                                                        setTimeout(() => {
                                                            setIsLocked(false)
                                                        }, 1000)
                                                    }}
                                                />
                                            </>
                                        }
                                    </>
                                }
                            </>
                        </SettingsGroup>
                    }

                    <SettingsGroup
                        titleResource="ORGANISATIONS.DESTINATION_DETAIL.SETTINGS.INTEGRATIONS_GROUP.TITLE"
                    >
                        <SettingsGroupItem
                            type="checkbox"
                            imageFile="cloudflare.svg"
                            titleResource="ORGANISATIONS.DESTINATION_DETAIL.SETTINGS.INTEGRATIONS_GROUP.CLOUDFLARE.TITLE"
                            descriptionResource={destination.destinationType == DestinationTypeEnum.Agent ? "ORGANISATIONS.DESTINATION_DETAIL.SETTINGS.INTEGRATIONS_GROUP.CLOUDFLARE.AGENT_DESCRIPTION" : "ORGANISATIONS.DESTINATION_DETAIL.SETTINGS.INTEGRATIONS_GROUP.CLOUDFLARE.CMS_DESCRIPTION"}
                            defaultChecked={settings?.cloudflareIsActive}
                            preventClick={isLocked}
                            onChange={async (e) => {
                                setIsLocked(true)
                                const response = await destinationsActions.updateSettingsCloudflare({
                                    organisationId,
                                    destinationId: destinationId as string,
                                    enable: e.currentTarget.checked
                                })
                                if (response.isSucceed) {
                                    toastify.success(intl.formatMessage({id: "ORGANISATIONS.DESTINATION_DETAIL.SETTINGS.INTEGRATIONS_GROUP.CLOUDFLARE.MESSAGE.SUCCESS"}))
                                }
                                setTimeout(() => {
                                    setIsLocked(false)
                                }, 1000)
                            }}
                        />
                        <>
                            {
                                destination.destinationType == DestinationTypeEnum.Agent &&
                                <>
                                    <SettingsGroupSeparator/>
                                    <SettingsGroupItem
                                        type="checkbox"
                                        imageFile="github.svg"
                                        titleResource="ORGANISATIONS.DESTINATION_DETAIL.SETTINGS.INTEGRATIONS_GROUP.GITHUB_ACTIONS.TITLE"
                                        descriptionResource="ORGANISATIONS.DESTINATION_DETAIL.SETTINGS.INTEGRATIONS_GROUP.GITHUB_ACTIONS.DESCRIPTION"
                                        defaultChecked={settings?.githubIsActive}
                                        preventClick={isLocked}
                                        onChange={async (e) => {
                                            setIsLocked(true)
                                            const response = await destinationsActions.updateSettingsGitHubActions({
                                                organisationId,
                                                destinationId: destinationId as string,
                                                enable: e.currentTarget.checked
                                            })
                                            if (response.isSucceed) {
                                                toastify.success(intl.formatMessage({id: "ORGANISATIONS.DESTINATION_DETAIL.SETTINGS.INTEGRATIONS_GROUP.GITHUB_ACTIONS.MESSAGE.SUCCESS"}))
                                            }
                                            setTimeout(() => {
                                                setIsLocked(false)
                                            }, 1000)
                                        }}
                                    />
                                </>
                            }
                        </>
                    </SettingsGroup>
                </>
            }
            {isLoading &&
                <div className="mt-10">
                    <BlocksLoader/>
                </div>
            }

        </>
    )
}