/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {shallowEqual, useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import * as urls from "../../../../app/infrastructure/variables/urls";
import {RootState} from '../../../../setup'
import {useIntl} from "react-intl";
import {Languages} from './Languages'
import {useDispatch} from 'react-redux'
import {toAbsoluteUrl} from '../../../helpers'
import {authActions, authSelectors} from "../../../../app/pages/auth";
import {ImageFromBase64} from "../../../../app/components/images";
import {useLang} from "../../../i18n/Metronici18n";
import {useAppDispatch} from "../../../../app/infrastructure/hooks/reduxHooks";

const HeaderUserMenu: FC = () => {
    const user = useSelector(authSelectors.getUser())
    const selectedLang = useLang()

    const dispatch = useAppDispatch()

    const logout = () => {
        dispatch(authActions.logout({forgetDevice: true}))
    }

    const intl = useIntl()

    return (
        <div
            className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-300px'
            data-kt-menu='true'
        >
            <div className='menu-item px-3'>
                <div className='menu-content d-flex align-items-center px-3'>
                    <div className='symbol symbol-50px me-5 '>
                        {
                            user.photo &&
                            <ImageFromBase64 base64String={user.photo}/>
                        }
                        {
                            !user.photo &&
                            <div className="symbol-label fs-2 fw-semibold text-primary">
                                {
                                    user.fullName
                                        ? user.fullName.charAt(0).toLocaleUpperCase(selectedLang)
                                        : user.email.charAt(0).toLocaleUpperCase(selectedLang)
                                }
                            </div>
                        }
                    </div>

                    <div className='d-flex flex-column'>
                        {user.fullName &&
                            <div className='fw-bolder d-flex align-items-center fs-5'>
                                {user.fullName}
                                {/*<span className='badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2'>Pro</span>*/}
                            </div>}
                        <span className='fw-bold text-muted fs-7 text-truncate w-200px'>
                        {user.email}
                            </span>
                    </div>
                </div>
            </div>

            <div className='separator my-2'></div>

            <div className='menu-item px-5'>
                <Link to={urls.ACCOUNT_PROFILE} className='menu-link px-5'>
                    {intl.formatMessage({id: 'MENU.MYPROFILE'})}
                </Link>
            </div>

            <div className='menu-item px-5'>
                <Link to={urls.ACCOUNT_CHANGE_PASSWORD} className='menu-link px-5'>
                    {intl.formatMessage({id: 'MENU.CHANGE_PASSWORD'})}
                </Link>
            </div>

            <div className='separator my-2'></div>

            <Languages/>

            <div className='menu-item px-5'>
                <a onClick={logout} className='menu-link px-5'>
                    {intl.formatMessage({id: 'AUTH.LOGIN.BUTTON.LOGOUT'})}
                </a>
            </div>
        </div>
    )
}

export {HeaderUserMenu}
