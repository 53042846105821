import {PageLink, PageTitle} from "../../../../../../_metronic/layout/core";
import React from "react";
import * as urls from "../../../../../infrastructure/variables/urls";
import {format} from "react-string-format";
import {useIntl} from "react-intl";
import {useSelector} from "react-redux";
import {organisationSelectors} from "../../../";
import {OrganisationModel} from "../../../models/OrganisationModel";

export const UsersPageTitle = () => {

    const intl = useIntl()
    const organisation = useSelector(organisationSelectors.getSelectedOrganisation()) as OrganisationModel
    
    
    const breadcrumbs: Array<PageLink> = [
        {
            title: intl.formatMessage({id: "ORGANISATIONS.PAGE_TITLE"}),
            path: '', // urls.ORGANISATIONS_URL,
            isSeparator: false,
            isActive: false,
        },
        {
            title: '',
            path: '',
            isSeparator: true,
            isActive: false,
        },
        {
            title: organisation.name as string,
            path: format(urls.ORGANISATION_DETAIL_URL, organisation.id),
            isSeparator: false,
            isActive: false,
        },
        {
            title: '',
            path: '',
            isSeparator: true,
            isActive: false,
        }
    ]
    
    return (
        <PageTitle breadcrumbs={breadcrumbs}>{intl.formatMessage({id: "ORGANISATION.USERS.PAGE_TITLE"})}</PageTitle>
    )
}