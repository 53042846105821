import * as urls from './../../app/infrastructure/variables/urls'
import {toastify} from "../../app/libs/toastify";
import {formatMessage, getSelectedLang} from "../../_metronic/i18n/Metronici18n";
import {swal} from "../../app/libs/sweetAlert";
import * as consts from "../../app/infrastructure/variables/consts";
import {authActions, authReducerActions} from "../../app/pages/auth";
import {authApis} from "../../app/pages/auth/authApis";


let isRefreshing = false;
let failedQueue: any[] = [];

const processQueue = (error: any, token: any = null) => {
    failedQueue.forEach(prom => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    })

    failedQueue = [];
}

export default function setupAxios(axios: any, store: any) {

    axios.defaults.headers.Accept = 'application/json'
    axios.defaults.baseURL = process.env.REACT_APP_API_URL
    axios.defaults.headers['Accept-Language'] = getSelectedLang()
    axios.defaults.headers['Device-Key'] = localStorage.getItem(consts.PROTECT_REMOTE_DEVICE_KEY)
    axios.interceptors.request.use(
        (config: any) => {
            const {
                auth: {accessToken},
            } = store.getState()

            if (accessToken) {
                config.headers.Authorization = `Bearer ${accessToken}`
            }

            return config
        },
        (err: any) => Promise.reject(err)
    )
    axios.interceptors.response.use((response: any) => {
            return response
        }, (error: any) => {

            if (!error.response) {
                // toastify.error(formatMessage({id: "ERROR.NETWORK_ERROR"}), {toastId: "NETWORK_ERROR"})
                throw new Error("NETWORK_ERROR")
            }

            // TODO: ileride burada paket yukseltme gerektiren islemler icin sunucudan farkli bir kod donmesi gerekli
            if (error.response.status == 400) {
                if (error.response?.data?.errors) {
                    toastify.error(formatMessage({id: "ERROR.CHECK_THE_FORM_FIELDS"}))
                }
                return Promise.reject(error);
            } else if (error.response.status == 401) {

                const originalRequest = error.config;

                if (!originalRequest._retry) {

                    if (isRefreshing) {
                        return new Promise(function (resolve, reject) {
                            failedQueue.push({resolve, reject})
                        }).then(token => {
                            originalRequest.headers['Authorization'] = 'Bearer ' + token;
                            return axios(originalRequest);
                        }).catch(err => {
                            return err
                        })
                    }

                    originalRequest._retry = true;
                    isRefreshing = true;

                    const {
                        auth: {accessToken, refreshToken},
                    } = store.getState()


                    return new Promise(function (resolve, reject) {

                        if (!accessToken || !refreshToken) {
                            store.dispatch(authActions.logout({forgetDevice: false})).unwrap()
                            return
                        }

                        authApis.refreshToken({accessToken, refreshToken}).then((response) => {
                            store.dispatch(authReducerActions.setAccessToken(response.data.accessToken as string))
                            store.dispatch(authReducerActions.setRefreshToken(response.data.refreshToken as string))
                            originalRequest.headers.Authorization = `Bearer ${response.data.accessToken}`;
                            processQueue(null, response.data.accessToken);
                            resolve(axios(originalRequest))
                        }).catch((err: any) => {
                            failedQueue = []
                            store.dispatch(authActions.logout({forgetDevice: false})).unwrap()
                            setTimeout(() => {
                                window.location.href = urls.ERROR_401_URL
                            }, 350)
                        }).then(async () => {
                            isRefreshing = false
                        })
                    })

                } else {
                    store.dispatch(authActions.logout({forgetDevice: false})).unwrap()
                    setTimeout(() => {
                        window.location.href = urls.ERROR_401_URL
                    }, 350)
                }


            } else if (error.response.status == 403) {
                if (error.response.data == consts.YOU_HAVE_NOT_AUTHORITY) {
                    swal.error('', formatMessage({id: "ERROR.YOU_ARE_NOT_AUTHORIZED"}))
                    error.response.data = consts.YOU_HAVE_NOT_AUTHORITY
                    return Promise.reject(error);
                }

                if (!window.location.href.includes("403")) {
                    window.location.href = urls.ERROR_403_URL
                }

            } else if (error.response.status == 404) {
                window.location.href = urls.ERROR_404_URL
            } else if (error.response.status == 500) {
                toastify.error(formatMessage({id: "ERROR.ERROR_OCCURRED"}), {toastId: "ERROR_OCCURRED"})
            } else {
                return Promise.reject(error);
            }
        }
    )
}
