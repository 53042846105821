import React, {
    ChangeEventHandler,
    FC,
    KeyboardEventHandler,
    MutableRefObject,
    RefObject,
    useEffect,
    useRef,
    useState
} from "react";
import {FieldInputProps} from "formik";
import clsx from "clsx";
import {useIntl} from "react-intl";

interface Props {
    fieldProps?: FieldInputProps<any> | undefined
    placeholderResourceName?: string,
    onKeyUp?: KeyboardEventHandler<HTMLInputElement> | undefined
    inputType: 'text' | 'password' | 'checkbox' | 'email' | 'number',
    inputSize?: 'sm' | 'lg',
    className?: string,
    readOnly?: boolean,
    focus?: boolean,
    defaultValue?: string,
    min?: number,
    max?: number,
    onChange?: ChangeEventHandler<HTMLInputElement>
    name?: string
}

export const FormInput: FC<Props> = ({
                                         fieldProps,
                                         placeholderResourceName,
                                         onKeyUp,
                                         inputType = "text",
                                         inputSize,
                                         className,
                                         readOnly = false,
                                         focus = false,
                                         defaultValue,
                                         min,
                                         max,
                                         onChange,
                                         name
                                     }) => {

    const intl = useIntl()
    const ref = useRef() as MutableRefObject<HTMLInputElement>;

    useEffect(() => {
        if (focus) {
            ref?.current && ref?.current?.focus()
        }
    }, []);

    return (
        <input
            type={inputType}
            className={clsx("form-control form-control-solid", inputSize == 'sm' && 'form-control-sm', inputSize == 'lg' && 'form-control-lg', className)}
            {...(placeholderResourceName ? {placeholder: intl.formatMessage({id: placeholderResourceName})} : {})}
            {...(onKeyUp ? {onKeyUp: onKeyUp} : {})}
            {...(fieldProps ? {...fieldProps} : {})}
            {...(defaultValue ? {defaultValue: defaultValue} : {})}
            {...(onChange ? {onChange: onChange} : {})}
            {...(min ? {min: min} : {})}
            {...(max ? {max: max} : {})}
            readOnly={readOnly}
            ref={ref}
            {...(name ? {name: name} : {})}
        />
    )
}