import {AuthState, authActions} from '../'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../setup'
import {Login, TwoStepVerification} from '../components'
import {useEffect, useState} from 'react'
import {useAppDispatch} from '../../../infrastructure/hooks/reduxHooks'

export const LoginPage = () => {
  const [isLoaded, setIsLoaded] = useState(false)

  const {accessToken, verificationCode} = useSelector<RootState>(({auth}) => auth) as AuthState

  useEffect(() => {
    setIsLoaded(true)
  }, [])

  return (
    <>
      {isLoaded && !verificationCode && <Login />}
      {isLoaded && verificationCode && accessToken && <TwoStepVerification />}
    </>
  )
}
