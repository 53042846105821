import clsx from "clsx";
import React, {FC} from "react";
import {useIntl} from "react-intl";

interface Props {
    type?: "submit" | "button"
    onClick?: React.MouseEventHandler<HTMLButtonElement>,
    loading: boolean,
    disabled?: boolean
    buttonResourceName?: string
    fullWidthButton?: boolean,
    buttonSize?: "default" | "btn-xs" | "btn-sm" | "btn-lg"
    showNextStepIcon?: boolean
}

export const FormSubmitButton: FC<Props> = ({
                                                type = "submit",
                                                onClick = undefined,
                                                loading,
                                                disabled = undefined,
                                                buttonResourceName,
                                                fullWidthButton = false,
                                                buttonSize = "default",
                                                showNextStepIcon = false
                                            }) => {

    const intl = useIntl()

    return (
        <button
            onClick={onClick}
            disabled={loading || disabled}
            className={clsx("btn btn-primary", fullWidthButton && "w-100", buttonSize !== "default" && buttonSize)}>
            {!loading && <span className='indicator-label'>
                    {buttonResourceName && intl.formatMessage({id: buttonResourceName})}
                {buttonResourceName === undefined && intl.formatMessage({id: 'FORM.SUBMIT'})}
                {showNextStepIcon &&
                    <span className="svg-icon svg-icon-3 ms-1 me-0">
												<svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
													<rect opacity="0.5" x="18" y="13" width="13" height="2" rx="1"
                                                          transform="rotate(-180 18 13)" fill="currentColor"></rect>
													<path
                                                        d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                                                        fill="currentColor"></path>
												</svg>
											</span>}
                </span>}
            {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                        {intl.formatMessage({id: 'FORM.PLEASE_WAIT'})}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
            )}
        </button>
    )
}