import ReactPaginate from "react-paginate";
import {FC} from "react";

type Props = {
    pageCount: number,
    currentPage: number,
    goToPage: any,
    setIsLoading: any
}

export const Pagination: FC<Props> = ({
                                          pageCount,
                                          currentPage,
                                          goToPage,
                                          setIsLoading
                                      }: any) => {

    return (
        <>
            <ReactPaginate
                nextLabel={<i className="next"></i>}
                onPageChange={(e) => {
                    setIsLoading(true)
                    goToPage(e.selected + 1)
                }
                }
                // todo sayfalama ile ilgili sorun varsa ilk burasi acilip test edilecek
                forcePage={currentPage - 1}
                pageRangeDisplayed={2}
                marginPagesDisplayed={2}
                pageCount={pageCount}
                previousLabel={<i className="previous"></i>}
                pageClassName="page-item pagination pagination-outline"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                containerClassName="pagination"
                activeClassName="active"
                renderOnZeroPageCount={undefined}
            />
        </>
    )
}