import React, {ReactNode} from "react";
import {Accordion} from "@szhsin/react-accordion";

export const GuideAccordion = ({title, children}: { title: string, children: ReactNode }) => {
    return (
        <>
            <div className="fs-5 fw-bolder text-gray-800 mb-6 mt-12">{title}</div>
            <Accordion className="dashboard-welcome">
                {children}
            </Accordion>
        </>
    )
}