import {RootState} from "./../../../setup";
import {UserModel} from "./models/UserModel";

const getUser = () => ({auth: {user}}: RootState) => user as UserModel
const getUserId = () => ({auth: {user}}: RootState) => user?.id as string
const getLanguage = () => ({auth: {user}}: RootState) => user?.language

const getReturnUrl = () => ({auth: {returnUrl}}: RootState) => returnUrl

export const authSelectors =
    {
        getUser,
        getUserId,
        getLanguage,
        getReturnUrl
    }