import {Link, useLocation} from "react-router-dom";
import clsx from "clsx";
import React, {FC} from "react";
import {useSelector} from "react-redux";
import {organisationSelectors} from "../../organisationSelectors";
import {UserSelectionCell} from "../../../../modules/apps/user-management/users-list/table/columns/UserSelectionCell";
import {useAppDispatch} from "../../../../infrastructure/hooks/reduxHooks";
import {organisationActions} from "../../organisationActions";

type Props = {
    tabs: NavigationItem[]
}

interface NavigationItem {
    text: string
    url: string,
    hasSubPages: boolean
    selectedKey: string,
    onClick?: React.MouseEventHandler<HTMLAnchorElement | undefined>
}

export const Navigation: FC<Props> = ({tabs}) => {
    return (
        <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder">
            {
                tabs.map(tab => {
                    return (
                        <NavigationItem
                            key={tab.text}
                            text={tab.text}
                            url={tab.url}
                            hasSubPages={tab.hasSubPages}
                            selectedKey={tab.selectedKey}
                            onClick={tab.onClick}
                        />
                    )
                })
            }
        </ul>
    )
}

const NavigationItem: FC<NavigationItem> = ({text, url, hasSubPages, selectedKey, onClick}) => {

    const location = useLocation()
    const dispatch = useAppDispatch()
    // const activeSelectedTab = useSelector(organisationSelectors.getSelectedTab())


    return (
        <li className="nav-item">
            <Link to={url}
                  className={clsx('nav-link text-active-primary py-5 me-6 text-nowrap',
                      ((!hasSubPages && location.pathname === url) || (hasSubPages && location.pathname.startsWith(url))) && 'active')}
            >
                {text}
            </Link>
        </li>
    )
}