import {FC, useEffect, useState} from 'react'
import {shallowEqual, useSelector} from 'react-redux'
import {LayoutSplashScreen} from '../../../../_metronic/layout/core'
import {RootState} from '../../../../setup'
import {authActions} from '../'
import jwtDecode from 'jwt-decode'
import {useAppDispatch} from '../../../infrastructure/hooks/reduxHooks'

export const AuthInit: FC = (props) => {
  const dispatch = useAppDispatch()
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  const accessToken = useSelector<RootState>(({auth}) => auth.accessToken, shallowEqual)

  // We should check accesstoken before rendering the application
  useEffect(() => {
    if (accessToken) {
      const token: any = jwtDecode(accessToken as string)
      // if ((token.exp as number) < new Date().getTime() / 1000) {
      //     dispatch(authActions.logout({forgetDevice: false}))
      // }
      setShowSplashScreen(false)
    } else {
      dispatch(authActions.logout({forgetDevice: false}))
      setShowSplashScreen(false)
    }
    // eslint-disable-next-line
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{props.children}</>
}
