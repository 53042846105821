import {useEffect} from 'react'
import {Navigate, Routes} from 'react-router-dom'
import {authActions} from "../";
import * as urls from "../../../infrastructure/variables/urls";
import {useAppDispatch} from "../../../infrastructure/hooks/reduxHooks";

export const LogoutPage = () => {
    const dispatch = useAppDispatch()
    useEffect(() => {
        dispatch(authActions.logout({forgetDevice: true}))
        document.location.reload()
    }, [dispatch])

    return (
        <Routes>
            <Navigate to={urls.AUTH_LOGIN_URL}/>
        </Routes>
    )
}
