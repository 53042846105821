import {FC} from 'react'
import {useLang} from './Metronici18n'
import {IntlProvider} from 'react-intl'
import '@formatjs/intl-relativetimeformat/polyfill'
import '@formatjs/intl-relativetimeformat/locale-data/en'
import '@formatjs/intl-relativetimeformat/locale-data/tr'

import enMessages from './messages/en-US.json'
import trMessages from './messages/tr-TR.json'
import deMessages from './messages/de-DE.json'

const allMessages = {
    "en-US": enMessages,
    "tr-TR": trMessages,
    "de-DE": deMessages
}

const I18nProvider: FC = ({children}) => {
    const locale = useLang()
    const messages = allMessages[locale]

    return (
        <IntlProvider locale={locale} messages={messages}>
            {children}
        </IntlProvider>
    )
}

export {
    I18nProvider, allMessages
}