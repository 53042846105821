import {Card} from "react-bootstrap";
import {DashboardPageTitle} from "./";
import {Guide_de, Guide_en, Guide_tr} from "./guide";
import {useIntl} from "react-intl";

export const DashboardPage = () => {

    const intl = useIntl()


    return (
        <>
            <DashboardPageTitle/>
            <Card>
                <Card.Body>
                    {(() => {
                        switch (intl.locale) {
                            case "en-US":
                                return <Guide_en />
                            case "de-DE":
                                return <Guide_de />
                            case "tr-TR":
                                return <Guide_tr />
                        }
                    })()}
                </Card.Body>
            </Card>
        </>
    )
}