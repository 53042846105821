import React, {useEffect, useState} from "react";
import {useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {organisationSelectors} from "../../../../";
import {rolesActions, rolesSelectors} from "../../";

export const RoleInfo = () => {

    const intl = useIntl()
    const dispatch = useDispatch()
    const {roleId} = useParams()
    const organisationId = useSelector(organisationSelectors.getSelectedOrganisationId()) as string
    const detailInfo = useSelector(rolesSelectors.getOrganisationRoleDetailInfo())
    const editRoleModalState = useSelector(rolesSelectors.getRoleModalState())
    const [loading, setIsLoading] = useState(true)

    useEffect(() => {
        if (!editRoleModalState) {
            getInfo()
        }
    }, [editRoleModalState])

    useEffect(() => {
        setIsLoading(false)
    }, [detailInfo])

    const getInfo = async () => {
        dispatch(rolesActions.getRoleDetailInfo({organisationId, roleId: roleId!}))
    }

    const editRole = async () => {
        dispatch(rolesActions.showOrganisationRoleModal({state: true, roleId}))
    }

    return (
        <>
            <div className="card card-flush">
                <div className="card-header">
                    <div className="card-title">
                        <span className="bullet bullet-vertical h-25px float-start me-3"
                              style={{backgroundColor: detailInfo.colorCode}}></span>
                        {/*<span className="mt-1 float-start">{cell.row.original.name}</span>*/}
                        <h2 className="mb-0 text-break">{detailInfo.name}</h2>
                    </div>
                </div>
                <div className="card-body pt-0">
                    <div className="d-flex flex-column text-gray-600">
                        <div className="d-flex align-items-center py-2">
                            <span className="bullet bg-primary me-3"></span>
                            {detailInfo.totalUsers} {intl.formatMessage({id: "ORGANISATION.ROLE_DETAIL.LABEL.USERS_COUNT"})}
                        </div>
                    </div>
                </div>
                <div className="card-footer pt-0">
                    <button type="button"
                            className="btn btn-light btn-active-primary"
                            onClick={() => editRole()}
                    >
                        {intl.formatMessage({id: "ORGANISATION.ROLE_DETAIL.BUTTON.EDIT_ROLE"})}
                    </button>
                </div>
            </div>
            {/*<div className="card card-flush mt-10">*/}
            {/*    <div className="card-body">*/}
            {/*        <div className="d-flex flex-column text-gray-600">*/}
            {/*            Bu alana daha sonra bu rolle alakalı firewall kuralları gibi bilgiler gelecek, ayrıca tıklanında*/}
            {/*            ilgili sayfalara yönlendirme yapılacak.*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </>
    )
}