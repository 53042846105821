import {ChangeUserTypeModel, ChangeUserTypeModelUser, SearchResultModel} from "./models/";
import {ActionResponse} from "../../../../infrastructure/models/actionResponse";
import {DataRequest} from "../../../../components/tables/Table";
import {usersReducerActions} from "./";
import {toastify} from "../../../../libs/toastify";
import {createAsyncThunk} from "@reduxjs/toolkit";
import {usersApis} from "./usersApis";
import {organisationActions} from "../../organisationActions";
import {roleApis} from "../roles/rolesApis";
import {ruleApis} from "../rules/rulesApis";
import {rulesReducerActions} from "../rules";

const showInviteUsersModal = createAsyncThunk(
    'users/showInviteUsersModal',
    async ({state = true}: { state?: boolean }, {dispatch}) => {
        dispatch(usersReducerActions.setShowInviteUsersModal(state));
    })

const showChangeUserTypeModal = createAsyncThunk(
    'users/showChangeUserTypeModal',
    async ({
               state = true,
               user = null
           }: { state?: boolean, user?: ChangeUserTypeModelUser | null }, {dispatch}) => {
        dispatch(usersReducerActions.setChangeUserTypeModal({
            state,
            user
        }));
    })

const searchUsersForInvite = createAsyncThunk(
    'users/searchUsersForInvite',
    async ({organisationId, emails, currentResult = null}: { organisationId: string, emails: string[], currentResult?: SearchResultModel[] | null }, {dispatch}) => {
        try {
            const response = await usersApis.searchUsersForInvite({organisationId, emails})

            if (!response.data) {
                dispatch(usersReducerActions.setSearchResultForInvite([]))
                return
            }

            const userList = response.data

            if (currentResult && currentResult?.length > 0) {
                for (const userIndex in userList) {
                    const user = userList[userIndex]
                    const currentUser = currentResult.filter(x => x.email == user.email)
                    if (currentUser?.length > 0) {
                        user.userType = currentUser[0].userType
                    }
                }
            }


            dispatch(usersReducerActions.setSearchResultForInvite(userList))
        } catch {
            dispatch(usersReducerActions.setSearchResultForInvite([]))
        }
    })

const sendUserInvites = createAsyncThunk(
    'users/sendUserInvites',
    async ({organisationId, emails}: { organisationId: string, emails: SearchResultModel[] }, {dispatch}) => {
        try {
            await usersApis.sendUserInvites({organisationId, emails})
            dispatch(organisationActions.updateOrganisationInfo({organisationId}))
            return {
                isSucceed: true,
            } as ActionResponse
        } catch (e: any) {
            return {
                isSucceed: false,
                errors: e.response?.data?.errors,
                message: e.response.data,
            } as ActionResponse
        }
    })


const updateUserType = async ({
                                  organisationId,
                                  organisationUserId,
                                  userType
                              }: { organisationId: string, organisationUserId: string, userType: string }) => {
    try {
        const response = await usersApis.updateUserType({organisationId, organisationUserId, userType})

        return {
            isSucceed: true,
        } as ActionResponse
    } catch (e: any) {
        return {
            isSucceed: false,
            errors: e.response?.data?.errors,
            message: e.response.data
        } as ActionResponse
    }
}

const getOrganisationUsers = createAsyncThunk(
    'users/getOrganisationUsers',
    async ({
               organisationId,
               dataRequest = undefined
           }: { organisationId: string, dataRequest?: DataRequest | undefined }, {dispatch}) => {
        const response = await usersApis.getOrganisationUsers({organisationId, dataRequest})
        dispatch(usersReducerActions.setUsers(response.data))
    })

const refreshOrganisationUsersTable = createAsyncThunk(
    'users/refreshOrganisationUsers',
    async (_, {dispatch}) => {
        dispatch(usersReducerActions.setUsersRefreshTable())
    })

const removeOrganisationUser = createAsyncThunk(
    'users/getOrganisationUsers',
    async ({
               organisationId,
               organisationUserId
           }: { organisationId: string, organisationUserId: string }, {dispatch}) => {
        try {
            await usersApis.removeOrganisationUser({organisationId, organisationUserId})
            dispatch(organisationActions.updateOrganisationInfo({organisationId}))
            return {
                isSucceed: true,
            } as ActionResponse
        } catch (e: any) {
            return {
                isSucceed: false,
                errors: e.response?.data?.errors,
                message: e.response.data
            } as ActionResponse
        }
    })

export const usersActions = {
    showInviteUsersModal,
    showChangeUserTypeModal,
    searchUsersForInvite,
    sendUserInvites,
    getOrganisationUsers,
    refreshOrganisationUsersTable,
    removeOrganisationUser,
    updateUserType
}