import {FormCancelButton, FormModal, FormSubmitButton} from "../../../../../../components/forms";
import {useIntl} from "react-intl";
import {useAppDispatch} from "../../../../../../infrastructure/hooks/reduxHooks";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {subscriptionSelectors} from "../../subscriptionSelectors";
import clsx from "clsx";
import {subscriptionActions} from "../../subscriptionActions";
import {PackagesModel} from "../../models/PackagesModel";
import axios from "axios";
import {organisationSelectors} from "../../../../organisationSelectors";
import {subscriptionReducerActions} from "../../subscriptionSlice";

export const UpgradeModal = () => {

    const intl = useIntl()
    const dispatch = useAppDispatch()
    const [loading, setLoading] = useState(false)
    const organisationId = useSelector(organisationSelectors.getSelectedOrganisationId())
    const modalState = useSelector(subscriptionSelectors.getUpgradeModalState())
    const currentPlan = useSelector(subscriptionSelectors.getCurrentPlan())
    const [selectedPeriod, setSelectedPeriod] = useState<"monthly" | "yearly">("yearly")
    const [selectedPlan, setSelectedPlan] = useState<PackagesModel | null>(null)

    const packages = useSelector(subscriptionSelectors.getPlans())

    const closeModal = () => {
        dispatch(subscriptionReducerActions.setShowUpgradeModal({state: false}))
    }

    interface PackageFeatures {
        packageKey: string,
        supportedFeatureCount: number,
        upgradeFeatureCount: number
    }

    const packageFeatures: PackageFeatures[] = [
        {
            packageKey: "freemium",
            supportedFeatureCount: 5,
            upgradeFeatureCount: 6
        },
        {
            packageKey: "starter",
            supportedFeatureCount: 7,
            upgradeFeatureCount: 4
        },
        {
            packageKey: "growth",
            supportedFeatureCount: 7,
            upgradeFeatureCount: 4
        },
        {
            packageKey: "scale",
            supportedFeatureCount: 7,
            upgradeFeatureCount: 0
        }
    ]

    useEffect(() => {
        dispatch(subscriptionActions.getPackages({abc: ""})).unwrap()
    }, [])

    useEffect(() => {

        if (!selectedPlan && !currentPlan) {
            setSelectedPlan(packages.filter(x => x.packageKey == "growth")[0])
        } else {
            switch (currentPlan) {
                case "freemium":
                    setSelectedPlan(packages.filter(x => x.packageKey == "starter")[0])
                    break
                case "starter":
                    setSelectedPlan(packages.filter(x => x.packageKey == "growth")[0])
                    break
                case "growth":
                    setSelectedPlan(packages.filter(x => x.packageKey == "scale")[0])
                    break
                default:
                    setSelectedPlan(null)
            }
        }


    }, [packages])

    return (
        <>
            <FormModal modalState={modalState} closeModal={closeModal} title={intl.formatMessage({id: "ORGANISATION.MODAL.PACKAGES.TITLE"})}
                       subTitle={intl.formatMessage({id: "ORGANISATION.MODAL.PACKAGES.SUBTITLE"})} dialogClass="mw-1000px">
                <>
                    <div className="d-flex flex-column">
                        {/*begin::Row*/}
                        <div className="row">
                            {/*begin::Col*/}
                            <div className="col-lg-6 mb-10 mb-lg-0">

                                <div className="d-flex align-items-end flex-column">
                                    {/*begin::Nav group*/}
                                    <div className="nav-group nav-group-outline mb-5" data-kt-buttons="true" data-kt-initialized={1}>
                                        <button
                                            className={clsx("btn btn-color-gray-400 btn-active btn-active-secondary px-6 py-3 me-2", selectedPeriod == "yearly" && "active")}
                                            onClick={() => {
                                                if (selectedPeriod != "yearly")
                                                    setSelectedPeriod("yearly")
                                            }}
                                        >
                                            {intl.formatMessage({id: "ORGANISATION.MODAL.PACKAGES.TABS.PERIOD.YEARLY"})}
                                        </button>
                                        <button
                                            className={clsx("btn btn-color-gray-400 btn-active btn-active-secondary px-6 py-3", selectedPeriod == "monthly" && "active")}
                                            onClick={() => {
                                                if (selectedPeriod != "monthly")
                                                    setSelectedPeriod("monthly")
                                            }}>
                                            {intl.formatMessage({id: "ORGANISATION.MODAL.PACKAGES.TABS.PERIOD.MONTHLY"})}
                                        </button>
                                    </div>
                                    {/*end::Nav group*/}
                                </div>

                                {/*begin::Tabs*/}
                                <div className="nav flex-column" role="tablist">
                                    {packages && packages?.map((plan, index) => {
                                        return (
                                            <div
                                                onClick={() => {
                                                    const selected = packages.filter(x => x.packageKey == plan.packageKey)[0]
                                                    setSelectedPlan(selected as any)
                                                }}
                                                className={clsx("nav-link btn btn-outline btn-outline-dashed btn-color-dark d-flex flex-stack text-start p-6",
                                                    (index !== packages.length - 1 && 'mb-6'),
                                                    selectedPlan?.packageKey == plan.packageKey && 'btn-active btn-active-primary active',
                                                    (currentPlan == 'freemium' && plan.packageKey == 'freemium') && 'opacity-50 pointer-events-none',
                                                    (currentPlan == 'starter' && (plan.packageKey == 'freemium' || plan.packageKey == 'starter')) && 'opacity-50 pointer-events-none',
                                                    (currentPlan == 'growth' && (plan.packageKey == 'freemium' || plan.packageKey == 'starter' || plan.packageKey == 'growth')) && 'opacity-50 pointer-events-none',
                                                )}
                                                key={plan.packageKey}
                                            >
                                                <div className='d-flex align-items-center me-2'>
                                                    <div className='form-check form-check-custom form-check-solid form-check-success me-6'>
                                                        <input
                                                            className='form-check-input pointer-events-none'
                                                            type='radio'
                                                            name='plan'
                                                            value={plan.packageKey}
                                                            checked={selectedPlan?.packageKey == plan.packageKey}
                                                        />
                                                    </div>

                                                    <div className='flex-grow-1'>
                                                        <h2 className={clsx('d-flex align-items-center fs-2 fw-bolder flex-wrap', selectedPlan?.packageKey == plan.packageKey && 'text-white')}>
                                                            {intl.formatMessage({id: `ORGANISATION.MODAL.PACKAGES.${plan.packageKey}.PACKAGE_NAME`})}

                                                            {
                                                                (index == 2) && (
                                                                    <span className='badge badge-light-success ms-2 fs-7'>
                                                                      {intl.formatMessage({id: "ORGANISATION.MODAL.PACKAGES.LABEL.MOST_POPULAR"})}
                                                                    </span>
                                                                )}
                                                        </h2>
                                                        <div className='fw-bold opacity-50'>
                                                            {intl.formatMessage({id: `ORGANISATION.MODAL.PACKAGES.${plan.packageKey}.SHORT_DESC`})}
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className='ms-5'>

                                                    {plan.free && intl.formatMessage({id: "ORGANISATION.MODAL.PACKAGES.PRICE.FOR_FREE"})}

                                                    {!plan.free &&
                                                        <>
                                                            {/*                {plan.custom && (*/}
                                                            {/*                    <button className='btn btn-sm btn-primary'>Contact Us</button>*/}
                                                            {/*                )}*/}
                                                            {/*                {!plan.custom && (*/}
                                                            <>
                                                                <span className='mb-2'>€</span>

                                                                <span className='fs-2x fw-bolder'>
                                                      {selectedPeriod == 'yearly' ? plan.priceYearly : plan.priceMonthly}
                                                    </span>

                                                                <span className='fs-7 opacity-50'>
                                                      /<span data-kt-element='period'>{intl.formatMessage({id: "ORGANISATION.MODAL.PACKAGES.PRICE.MONTHLY"})}</span>
                                                    </span>
                                                            </>
                                                            {/*)}*/}
                                                        </>

                                                    }
                                                </div>
                                            </div>
                                        )
                                    })}
                                    <div>
                                        <div className="fs-7 fw-semibold text-muted mt-5">
                                            * {intl.formatMessage({id: "ORGANISATION.MODAL.PACKAGES.MESSAGE.YEARLY_PRICE_NOTE"})}
                                        </div>
                                    </div>
                                </div>
                                {/*end::Tabs*/}
                            </div>
                            {/*end::Col*/}

                            {/*begin::Col*/}
                            <div className="col-lg-6">
                                {/*begin::Tab content*/}
                                <div className="tab-content rounded h-100 bg-light p-10" style={{minHeight: 600}}>
                                    {/*begin::Tab Pane*/}
                                    {selectedPlan &&
                                        <div className="tab-pane fade active show" role="tabpanel">
                                            {/*begin::Heading*/}
                                            <div className="pb-5">
                                                <h2 className="fw-bold text-dark">
                                                    {intl.formatMessage({id: `ORGANISATION.MODAL.PACKAGES.${selectedPlan?.packageKey}.FEATURES.TITLE`})}
                                                </h2>
                                                <div className="text-muted fw-semibold">
                                                    {intl.formatMessage({id: `ORGANISATION.MODAL.PACKAGES.${selectedPlan?.packageKey}.FEATURES.DESCRIPTION`})}
                                                </div>
                                            </div>
                                            {/*end::Heading*/}
                                            {/*begin::Body*/}
                                            <div>

                                                {
                                                    selectedPlan &&
                                                    [...Array(packageFeatures.filter(x => x.packageKey == selectedPlan?.packageKey)[0].supportedFeatureCount)].map((e, i: number) => {
                                                        return (
                                                            <div key={`packageFeatures_supported_${i}`} className="d-flex align-items-center mb-2">
                                                                <i className="ki-duotone ki-check-circle fs-1">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512" fill="green">
                                                                        <path
                                                                            d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"/>
                                                                    </svg>
                                                                </i>
                                                                <span className="fw-bold fs-5 text-gray-700 flex-grow-1 ms-3 mt-1">
                                                                    {intl.formatMessage({id: `ORGANISATION.MODAL.PACKAGES.${selectedPlan?.packageKey}.ITEMS.${i + 1}`})}
                                                                 </span>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                            {/*end::Body*/}
                                            {/*begin::Heading*/}
                                            {
                                                (selectedPlan && packageFeatures.filter(x => x.packageKey == selectedPlan.packageKey)[0].upgradeFeatureCount > 0) &&
                                                <div className="mt-8 pb-5">
                                                    <h2 className="fw-bold text-dark">
                                                        {intl.formatMessage({id: "ORGANISATION.MODAL.PACKAGES.FEATURES.YOU_WILL_LOSE"})}
                                                    </h2>
                                                    <div className="text-muted fw-semibold">
                                                        {intl.formatMessage({id: "ORGANISATION.MODAL.PACKAGES.FEATURES.YOU_WILL_LOSE_UPGRADE"})}
                                                    </div>
                                                </div>
                                            }
                                            {/*end::Heading*/}
                                            {/*begin::Body*/}
                                            <div>
                                                {
                                                    selectedPlan &&
                                                    [...Array(packageFeatures.filter(x => x.packageKey == selectedPlan.packageKey)[0].upgradeFeatureCount)].map((e, i: number) => {
                                                        return (
                                                            <div key={`packageFeatures_upgrade_${i}`} className="d-flex align-items-center mb-2">
                                                                <i className="ki-duotone ki-check-circle fs-1">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512" fill="gray">
                                                                        <path
                                                                            d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"/>
                                                                    </svg>
                                                                </i>
                                                                <span className="fw-bold fs-5 text-gray-700 flex-grow-1 ms-3 mt-1">
                                                                    {intl.formatMessage({id: `ORGANISATION.MODAL.PACKAGES.${selectedPlan.packageKey}.ITEMS_UPGRADE.${i + 1}`})}
                                                                 </span>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                            {/*end::Body*/}
                                        </div>}
                                    {/*end::Tab Pane*/}
                                </div>
                                {/*end::Tab content*/}
                            </div>
                            {/*end::Col*/}
                        </div>
                        {/*end::Row*/}
                    </div>

                    <div className="d-flex flex-center mt-15">
                        <FormCancelButton onClick={closeModal}/>
                        <FormSubmitButton
                            loading={loading}
                            buttonResourceName={"FORM.CONTINUE"}
                            onClick={async () => {

                                if (!selectedPlan) return

                                setLoading(true)

                                if (selectedPlan.packageKey == "freemium") {
                                    setTimeout(() => {
                                        closeModal()
                                    }, 750)
                                    return
                                }

                                const response = await subscriptionActions.buyPackage({
                                    organisationId: organisationId,
                                    packageId: selectedPlan.id,
                                    priceLookupKey: selectedPeriod == "yearly" ? selectedPlan.priceYearlyLookupKey : selectedPlan.priceMonthlyLookupKey
                                })


                                if (response.isSucceed) {
                                    if (response.data.sessionUrl) {
                                        window.location.href = response.data.sessionUrl
                                    } else {
                                        window.location.href = `/organisation/${organisationId}/subscription/success`
                                    }
                                } else {
                                    setLoading(false)
                                }
                            }}
                        />
                    </div>

                </>

            </FormModal>
        </>
    )
}