import {GuideAccordion, GuideAccordionItem} from "./components";
import {format} from "react-string-format";
import * as urls from "../../../../../../infrastructure/variables/urls";
import {destinationsActions} from "../../../destinations";
import {useNavigate} from "react-router-dom";
import {useAppDispatch} from "../../../../../../infrastructure/hooks/reduxHooks";
import {useSelector} from "react-redux";
import {organisationSelectors} from "../../../../organisationSelectors";

export const Guide_en = () => {

    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const organisationId = useSelector(organisationSelectors.getSelectedOrganisationId())

    return (
        <>
            <div>
                <h4 className="fs-3 text-gray-800 w-bolder mb-6">
                    Quick Install
                </h4>
            </div>

            <ul className="nav nav-pills nav-pills-custom mb-3 mt-10" role="tablist">

                <li className="nav-item mb-3 me-3 me-lg-6 quick-install" role="presentation"
                    onClick={() => {
                        navigate(format(urls.ORGANISATION_DESTINATION_AGENTS_URL, organisationId));
                        dispatch(destinationsActions.showCreateAgentModal({state: true}))
                    }}
                >
                    <span className="nav-link btn btn-outline btn-flex btn-color-muted btn-active-color-primary flex-column overflow-hidden w-200px h-90px pt-5 pb-2 
                        active" id="kt_stats_widget_16_tab_link_1" data-bs-toggle="pill" aria-selected="true" role="tab">
                        <div className="nav-icon mb-3">
                            <img src="/assets/integrations/windows.svg" width="30" height="30"/>
                            <img src="/assets/integrations/linux.svg" className="ms-14" width="30" height="30"/>
                        </div>
                        <div className="nav-text text-gray-800 fw-bold fs-6 lh-1 mt-1">
                            Windows / Linux Server
                        </div>
                        <span className="bullet-custom position-absolute bottom-0 w-100 h-4px bg-primary"></span>
                    </span>
                </li>

                <li className="nav-item mb-3 me-3 me-lg-6 quick-install" role="presentation"
                    onClick={() => {
                        navigate(format(urls.ORGANISATION_DESTINATION_CMS_URL, organisationId));
                        dispatch(destinationsActions.showCreateCmsModal({state: true}))
                    }}
                >
                    <span className="nav-link btn btn-outline btn-flex btn-color-muted btn-active-color-primary flex-column overflow-hidden w-200px h-90px pt-5 pb-2 
                        active" id="kt_stats_widget_16_tab_link_1" data-bs-toggle="pill" aria-selected="true" role="tab">
                        <div className="nav-icon mb-3">
                            <img src="/assets/integrations/wordpress.png" width="30" height="30"/>
                            <img src="/assets/integrations/joomla.png" className="ms-6" width="30" height="30"/>
                            <img src="/assets/integrations/drupal.png" className="ms-6" width="30" height="30"/>
                        </div>
                        <div className="nav-text text-gray-800 fw-bold fs-6 lh-1 mt-1">
                            CMS / Wordpress
                        </div>
                        <span className="bullet-custom position-absolute bottom-0 w-100 h-4px bg-primary"></span>
                    </span>
                </li>

                <li
                    onClick={() => {
                        navigate(format(urls.ORGANISATION_DESTINATION_SOFTWARE_PACKAGES_URL, organisationId));
                        dispatch(destinationsActions.showCreateSoftwarePackageModal({state: true}))
                    }}
                    className="nav-item mb-3 me-3 me-lg-6 quick-install" role="presentation">
                    <span className="nav-link btn btn-outline btn-flex btn-color-muted btn-active-color-primary flex-column overflow-hidden w-200px h-90px pt-5 pb-2 
                        active" id="kt_stats_widget_16_tab_link_1" data-bs-toggle="pill" aria-selected="true" role="tab">
                        <div className="nav-icon mb-3">
                            <img src="/assets/integrations/aspnetcore.svg" width="30" height="30"/>
                            <img src="/assets/integrations/php.svg" className="ms-6" width="30" height="30"/>
                            <img src="/assets/integrations/phython.svg" className="ms-6" width="30" height="30"/>
                        </div>
                        <div className="nav-text text-gray-800 fw-bold fs-6 lh-1 mt-1">
                            Software Integration
                        </div>
                        <span className="bullet-custom position-absolute bottom-0 w-100 h-4px bg-primary"></span>
                    </span>
                </li>

            </ul>

            <div>
                <h4 className="fs-3 text-gray-800 w-bolder mt-10">
                    Installation Guide
                </h4>
            </div>


            <GuideAccordion title="Do you know these?">
                <GuideAccordionItem title="How can I view failed login attempts on my server?">
                    With the following information, you can view failed login attempts and attacks on your server.
                    <br/>
                    <br/>
                    Debian/Ubuntu:
                    <div className="bg-black text-gray-400 p-7 rounded position-relative text-break">
                        <span>
                            grep "Failed password" /var/log/auth.log
                        </span>
                    </div>
                    <br/>
                    RadHat/Centos:
                    <div className="bg-black text-gray-400 p-7 rounded position-relative text-break">
                        <span>
                            egrep "Failed|Failure" /var/log/secure
                        </span>
                    </div>
                </GuideAccordionItem>
                <GuideAccordionItem title="What are the addresses that could create security vulnerabilities on your WordPress site?">
                    <ul>
                        <li>www.yoursite.com/wp-admin/*</li>
                        <li>www.yoursite.com/wp-login.php</li>
                        <li>www.yoursite.com/wp-json/wp/v2/users/1</li>
                    </ul>

                    You can block access to the above example links that may create vulnerabilities on your WordPress site by using the 'protect remote' feature against
                    attacks.
                </GuideAccordionItem>
            </GuideAccordion>
            <br/>
            <div>
                <h4 className="fs-3 text-gray-800 w-bolder mb-6">
                    Installation Guide
                </h4>
            </div>

            <GuideAccordion title="How to Integrate Server?">
                <GuideAccordionItem title="Step 1: Define a Server">
                    <>
                        <div className="fw-bolder mb-2">Windows Server:</div>
                        <ol className="ps-4">
                            <li className="my-3">First, hover over the "Targets" section, click on "New Target," and select "Server" to define a new target. After
                                assigning a name to your server, you can proceed by choosing the Windows option.
                            </li>
                            <li className="my-3">In the second step, if your server has a static IP address, you can enter it. If your server connects to the internet via
                                IPv6, enter the IPv6 address; otherwise, enter the default IPv4 address. This selection is optional, entering a static IP address will
                                provide extra security for accessing our services.
                            </li>
                            <li className="my-3">In the third step, you need to run PowerShell on your server as an administrator. Right-click on the PowerShell software
                                and select "Run as Administrator." After copying the script here, press enter to execute it.
                            </li>
                            <li className="my-3">When you run the script, the installation process will begin. For the installation process, a ZIP file will be
                                downloaded, the installation will be performed, and the Protect Remote Agent service will be activated.
                            </li>
                            <li className="my-3">To confirm the successful completion of the installation, when we go to the Protect Remote web interface, we will see
                                that the server, which was previously shown as "Offline," is now "Online." This process can take up to 30 seconds.
                            </li>
                            <li className="my-3">The necessary actions on the server side and the integration are completed. To close access to remote desktop, databases,
                                etc., team and rule definitions need to be made. You can refer to other items for these.
                            </li>
                        </ol>
                        <div className="fw-bolder mb-2">Linux Server:</div>
                        <ol className="ps-4">
                            <li className="my-3">First, hover over the "Targets" section, click on "New Target," and select "Server" to define a new target. After
                                assigning a name to your server, you should proceed with the Linux option, depending on the kernel of the Linux operating system you are
                                using, you can choose Debian Based or Redhat Based.
                            </li>
                            <li className="my-3">In the second step, if your server has a static IP address, you can enter it. If your server connects to the internet via
                                IPv6, enter the IPv6 address; otherwise, enter the default IPv4 address. This selection is optional, entering a static IP address will
                                provide extra security for accessing our services.
                            </li>
                            <li className="my-3">In the third step, you need to run the script that appears on your server's command line (bash). Before running it, make
                                sure that the connected user has root privileges. You can do this with the "sudo su" command to gain administrative privileges.
                            </li>
                            <li className="my-3">When you run the script, the installation process will begin. For the installation process, a ZIP file will be
                                downloaded, the installation will be performed, and the Protect Remote Agent service will be activated.
                            </li>
                            <li className="my-3">To confirm the successful completion of the installation, when we go to the Protect Remote web interface, we will see
                                that the server, which was previously shown as "Offline," is now "Online." This process can take up to 30 seconds.
                            </li>
                            <li className="my-3">The necessary actions on the server side and the integration are completed. To close access to remote desktop, databases,
                                etc., team and rule definitions need to be made. You can refer to other items for these.
                            </li>
                            <li className="my-3">If you publish your applications on your server via Docker, don't forget to enable "Docker" support in the settings of
                                the relevant target.
                            </li>
                        </ol>
                    </>
                </GuideAccordionItem>
                <GuideAccordionItem title="Step 2: Define a Team">
                    <>
                        Access permissions within your organization are granted through teams rather than individual users. Therefore, for a user to have access rights,
                        they must be a member of a team. When a user cannot access a required area, the first thing to check is whether the user is a member of the team.
                        <ol className="ps-4 mt-4">
                            <li className="my-3">First, click on the "New Team" button in the "Teams" section to define a new team.</li>
                            <li className="my-3">After assigning any name as the team name, you can also make a suitable color selection to make the team stand out.</li>
                            <li className="my-3">After creating the team, go to the "Users" tab and use the "Edit Teams" button next to the relevant user to add users to
                                this team.
                            </li>
                        </ol>
                    </>
                </GuideAccordionItem>
                <GuideAccordionItem title="Step 3: Define a Rule and Close the Ports">
                    <>
                        Rules define who can access which ports and which ports are closed to the outside. For example, you can define a rule to close SSH access to your
                        Linux server or RDP (Remote Desktop Connection) access to your Windows server.
                        <br/><br/>
                        Let's close the RDP for Windows and SSH for Linux.
                        <ol className="ps-4 mt-4">
                            <li className="my-3">First, hover over the "Rules" section and click on the "New Rule" button.</li>
                            <li className="my-3">Give the rule a name that indicates the rule you will apply, such as "RDP Access" or "SSH Access".</li>
                            <li className="my-3">From the "Targets" selection, you can choose on which servers this rule will be valid.</li>
                            <li className="my-3">From the "Teams" selection, you can choose which teams this rule will be valid for. Here, it is important to remember
                                that the users you want to authorize must be members of this team. Otherwise, users will not be able to access.
                            </li>
                            <li className="my-3">For example, since we are going to close SSH or RDP access, select TCP as the port type. For closing SSH, enter port 22,
                                and for RDP (Remote Desktop Connection), enter port 3389. Remember, these ports are default ports. If you have changed the ports of these
                                accesses on your server or your service provider provides services on a different port, you must enter these ports.
                            </li>
                            <li className="my-3">By clicking Create, we define the rule. When the rule is activated, only authorized users can access your server via
                                SSH/RDP. This process can take up to 30 seconds.
                            </li>
                            <li className="my-3">Authorized users must actively ping with the Protect Remote application through desktop, web browser, or mobile
                                application to be able to access.
                            </li>
                        </ol>
                    </>
                </GuideAccordionItem>
            </GuideAccordion>


            <GuideAccordion title="How to integrate Wordpress?">
                <GuideAccordionItem title="Step 1: Define Wordpress Site">
                    <>
                        <ol>
                            <li className="my-3">First, hover over the "New Destination" button in the "Destinations" section and click on "CMS / Wordpress" to define a
                                new destination. After assigning a name to your site, select "Wordpress" from the CMS Packages.
                            </li>
                            <li className="my-3">
                                In the second step, download the plugin you want to install on your Wordpress site and install it. After downloading the plugin to your
                                computer, go to the "Plugins" section of your Wordpress admin panel, click on the "Upload Plugin" button, and add the downloaded plugin.
                            </li>
                            <li className="my-3">
                                In the third step, paste the "Token" you obtained from the Protect Remote interface into the "Settings &gt; Protect Remote" page of your
                                Wordpress admin panel.
                            </li>
                            <li className="my-3">
                                Here, you will see options for "Login Page," "Admin Panel," and "Allow admin-ajax.php". <br/><br/>
                                Login Page: Closes unauthorized access to your Wordpress admin panel login page (wp-login.php). <br/>
                                Admin Panel: Closes unauthorized access to your entire Wordpress admin panel (wp-admin folder). <br/>
                                Allow admin-ajax.php: Some plugins make requests to the "admin-ajax.php" file under the "wp-admin" folder. This can pose security risks;
                                it is not recommended to open it by default. However, if your plugin uses it and you don't want to block access, you can open it. It is
                                recommended to keep this setting closed by default. <br/><br/>
                                wp-login.php: If you want to change the Wordpress login address, you can define a different address here. <br/><br/>
                                After making and saving these settings, the Wordpress integration will be completed.
                            </li>
                        </ol>
                    </>
                </GuideAccordionItem>
                <GuideAccordionItem title="Step 2: Team Creation">
                    <>
                        Access permissions within your organization are granted through teams rather than individual users. Therefore, for a user to have access rights,
                        they must be a member of a team. When a user cannot access a required area, the first thing to check is whether the user is a member of the team.
                        <ol className="ps-4 mt-4">
                            <li className="my-3">
                                When you create a CMS / Wordpress des, a team associated with that destination is automatically created by default. In the "Teams" tab,
                                you will see that a team named "Your Destination Team" has been created.
                            </li>
                            <li className="my-3">
                                All users who need access must be defined within this team. You can also create a new team and specify it separately in the rules tab if
                                you wish. (This will be explained in detail in the next section.)
                            </li>
                            <li className="my-3">
                                Go to the "Users" tab and use the "Edit Teams" button next to the relevant user to add users to this team. When a user cannot access the
                                admin interface of the relevant site, the first thing to check is whether the user is in the authorized team.
                            </li>
                        </ol>
                    </>
                </GuideAccordionItem>
                <GuideAccordionItem title="Step 3: Rule Definition">
                    <>
                        Rules define who can access which sites.
                        <ol className="ps-4 mt-4">
                            <li className="my-3">
                                When you create a CMS / Wordpress destination, a rule associated with that destination is automatically created by default. In the "Rules"
                                tab, you will see that a rule named "Your Destination Rule" has been created.
                            </li>
                            <li>
                                When you view the relevant rule, you will see the "Destinations" and "Teams" settings. Here, you will see that the created destination is
                                matched with the relevant team.
                            </li>
                            <li className="my-3">
                                From the "Destinations" selection, you can choose which Wordpress sites this rule will apply to.
                            </li>
                            <li className="my-3">
                                From the "Teams" selection, you can choose which teams this rule will apply to. Here, it is important to remember that the users you want
                                to authorize must be members of this team. Otherwise, users will not be able to access.
                            </li>
                            <li className="my-3">
                                Authorized users must actively ping with the Protect Remote application through desktop, web browser, or mobile application to be able to
                                access.
                            </li>
                        </ol>
                    </>
                </GuideAccordionItem>
            </GuideAccordion>

            <GuideAccordion title="How to Integrate Software?">
                <GuideAccordionItem title="Step 1: Software Package Definition">
                    <>
                        <ol>
                            <li className="my-3">First, hover over the "Destinations" section, click on the "New Destination" button, and select "Software Package" to
                                define a new destination. After assigning a name to your application, you need to choose the relevant package from the Software Packages.
                            </li>
                            <li className="my-3">In the second step, you will find code information about how to perform the integration. Integrate the codes into your
                                software as specified in these steps.
                            </li>
                            <li className="my-3">Make sure to add the "Token" you received from the Protect Remote interface to your application as specified.</li>
                        </ol>
                    </>
                </GuideAccordionItem>
                <GuideAccordionItem title="Step 2: Team Creation">
                    <>
                        Access permissions within your organization are granted through teams rather than individual users. Therefore, for a user to have access rights,
                        they must be a member of a team. When a user cannot access a required area, the first thing to check is whether the user is a member of the team.
                        <ol className="ps-4 mt-4">
                            <li className="my-3">When you create a CMS / Wordpress destination, a team related to that destination is automatically created by default.
                                When you go to the "Teams" tab, you will see that a team named "Your Target Name Team" has been created.
                            </li>
                            <li className="my-3">All users who need access must be defined within this team. You can also create a new team and specify it separately in
                                the rules tab if you wish. (This will be explained in detail in the next item.)
                            </li>
                            <li className="my-3">Go to the "Users" tab and use the "Edit Teams" button next to the relevant user to add users to this team. When a user
                                cannot access the admin interface of the relevant site, the first thing to check is whether the user is a member of the authorized team.
                            </li>
                        </ol>
                    </>
                </GuideAccordionItem>
                <GuideAccordionItem title="Step 3: Rule Definition">
                    <>
                        Access to which applications can be determined by rule definitions.
                        <ol className="ps-4 mt-4">
                            <li className="my-3">When you create a software package destination, a rule related to that destination is automatically created by default.
                                When you go to the "Rules" tab, you will see that a rule named "Your Target Name Rule" has been created.
                            </li>
                            <li>You will see the "Destinations" and "Teams" definitions when you view the relevant rule. Here, you will see that the created destination
                                is matched with the relevant team.
                            </li>
                            <li className="my-3">From the "Destinations" selection, you can choose which applications this rule will be valid for.</li>
                            <li className="my-3">From the "Teams" selection, you can choose which teams this rule will be valid for. Here, it is important to remember
                                that the users you want to authorize must be members of this team. Otherwise, users will not be able to access.
                            </li>
                            <li className="my-3">Authorized users must actively ping with the Protect Remote application through desktop, web browser, or mobile
                                application to be able to access.
                            </li>
                        </ol>
                    </>
                </GuideAccordionItem>
            </GuideAccordion>
        </>
    )
}
