import React, {FC, KeyboardEventHandler, MutableRefObject, useEffect, useRef} from "react";
import {FieldInputProps} from "formik";
import {useIntl} from "react-intl";

interface Props {
    rows?: number
    fieldProps?: FieldInputProps<any> | undefined
    placeholderResourceName?: string,
    onKeyUp?: KeyboardEventHandler<HTMLTextAreaElement> | undefined,
    readOnly?: boolean,
    focus?: boolean
}

export const FormTextArea: FC<Props> = ({
                                            rows = 1,
                                            fieldProps,
                                            placeholderResourceName,
                                            onKeyUp,
                                            readOnly = false,
                                            focus = false
                                        }) => {

    const intl = useIntl()
    const ref = useRef() as MutableRefObject<HTMLTextAreaElement>;

    useEffect(() => {
        if (focus) {
            ref?.current && ref?.current?.focus()
        }
    }, []);
    
    return (
        <textarea
            className="form-control form-control-solid"
            rows={rows}
            {...(placeholderResourceName ? {placeholder: intl.formatMessage({id: placeholderResourceName})} : {})}
            {...(onKeyUp ? {onKeyUp: onKeyUp} : {})}
            {...(fieldProps ? {...fieldProps} : {})}
            readOnly={readOnly}
            ref={ref}
        />
    )
}