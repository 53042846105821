import React from "react";
import {RoleUsers} from "../detail/RoleUsers";
import {RoleInfo} from "./RoleInfo"
import {DetailPageTitle} from "./DetailPageTitle";
import {format} from "react-string-format";
import * as urls from "../../../../../../infrastructure/variables/urls";
import {Navigation} from "../../../destinations/components/detail/navigation/Navigation";
import {useSelector} from "react-redux";
import {organisationSelectors} from "../../../../organisationSelectors";

export const Detail = () => {

    const organisationId = useSelector(organisationSelectors.getSelectedOrganisationId())

    return (
        <>
            <DetailPageTitle/>
            <div className="d-flex flex-column flex-lg-row">
                <div className="flex-column flex-lg-row-auto w-100 w-lg-200px w-xl-300px mb-10">
                    <RoleInfo/>
                </div>
                <div className="flex-lg-row-fluid ms-lg-10">
                    <Navigation
                        back={{url: format(urls.ORGANISATION_ROLES_URL, organisationId)}}
                    />
                    <RoleUsers/>
                </div>
            </div>
        </>
    )
}