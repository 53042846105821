import {useIntl} from "react-intl";
import {useSelector} from "react-redux";
import React, {Fragment, useEffect, useState} from "react";
import {FormModal} from "../../../../../../components/forms/FormModal";
import {organisationSelectors} from "../../../../";
import {FormLoader} from "../../../../../../components/loaders/FormLoader";
import {FormCancelButton} from "../../../../../../components/forms/FormCancelButton";
import {FormSubmitButton} from "../../../../../../components/forms/FormSubmitButton";
import {rolesActions, rolesReducerActions, rolesSelectors} from "../../../../subpages/roles/";
import {toastify} from "../../../../../../libs/toastify";
import {usersActions} from "../../";
import {EditUserRolesModel} from "../../../roles/models";
import {format} from "react-string-format";
import * as urls from "../../../../../../infrastructure/variables/urls";
import {useNavigate} from "react-router-dom";
import {FormGroup, FormInput} from "../../../../../../components/forms";
import {useAppDispatch} from "../../../../../../infrastructure/hooks/reduxHooks";

export const EditUserRolesModal = () => {

    const intl = useIntl()
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const [showContentLoader, setShowContentLoader] = useState(false)
    const [loading, setLoading] = useState(false)
    const organisationId = useSelector(organisationSelectors.getSelectedOrganisationId())
    const organisationUserId = useSelector(rolesSelectors.getEditUserRolesModalOrganisationUserId())
    const organisationUserRoles = useSelector(rolesSelectors.getEditUserRolesModalRoles())
    const modalState = useSelector(rolesSelectors.getEditUserRolesModalState())
    const [showAddRolesContent, setShowAddRolesContent] = useState(false)
    const [filterText, setFilterText] = useState("")

    const closeModal = () => {
        dispatch(rolesActions.showEditUsersRoleModal({state: false}))
    }

    useEffect(() => {
        setShowContentLoader(true)
        dispatch(rolesActions.getUserRoles({organisationId, organisationUserId}))

        return () => {
            dispatch(rolesReducerActions.setEditUsersRolesModalUserRoles(null))
            dispatch(usersActions.refreshOrganisationUsersTable())
        }
    }, [])

    useEffect(() => {
        if (organisationUserRoles != null) {
            if (organisationUserRoles.length == 0) {
                setShowAddRolesContent(true)
            } else {
                setShowAddRolesContent(false)
            }

            setShowContentLoader(false)
        }
    }, [organisationUserRoles])


    const onChange = (roleId: string, checked: boolean) => {
        const newArr = (organisationUserRoles as EditUserRolesModel[]).map((role: any) => role.roleId == roleId ? {
            ...role,
            userHasRole: checked
        } : role);
        dispatch(rolesReducerActions.setEditUsersRolesModalUserRoles(newArr))
    }

    const saveRoles = async () => {
        const roleIds = (organisationUserRoles as EditUserRolesModel[]).filter((f: any) => f.userHasRole).map((x: any) => x.roleId);
        setLoading(true)
        const response = await rolesActions.updateUserRoles(organisationId as string, organisationUserId, roleIds)
        if (response.isSucceed) {
            setLoading(false)
            closeModal()
            toastify.success(intl.formatMessage({id: 'ORGANISATION.MODAL.EDIT_USER_ROLE.MESSAGE.UPDATED'}))
        }
    }

    return (
        <>
            <FormModal
                modalState={modalState}
                closeModal={closeModal}
                title={intl.formatMessage({id: 'ORGANISATION.MODAL.EDIT_USER_ROLE.TITLE'})}
            >
                <>
                    {showContentLoader && <FormLoader/>}

                    {!showContentLoader &&
                        (
                            showAddRolesContent ?
                                (
                                    <>
                                        <div className="text-center mb-4">
                                            <p className="text-gray-800 fs-5 fw-bold mb-13">
                                                {intl.formatMessage({id: 'ORGANISATION.MODAL.EDIT_USER_ROLE.MESSAGE.ROLES_NOT_FOUND'}, {
                                                    br: <br/>
                                                })}
                                                <br/><br/><a
                                                href="#">{intl.formatMessage({id: 'ORGANISATION.MODAL.EDIT_USER_ROLE.MESSAGE.MORE_INFO'})}</a>
                                            </p>
                                            <button type="button" className="fs-6 px-8 py-4 btn btn-primary"
                                                    onClick={() => {
                                                        navigate(format(urls.ORGANISATION_ROLES_URL, organisationId));
                                                        dispatch(rolesActions.showEditUsersRoleModal({state: false}))
                                                        dispatch(rolesActions.showOrganisationRoleModal({}))
                                                    }}
                                            >
                                                {intl.formatMessage({id: "ORGANISATION.MODAL.EDIT_USER_ROLE.BUTTON.CREATE_ROLE"})}
                                            </button>
                                        </div>
                                    </>
                                ) :
                                (
                                    <>
                                        {
                                            (organisationUserRoles && organisationUserRoles?.length > 5) &&
                                            <FormGroup labelResourceName={""} showLabel={false}>
                                                <FormInput
                                                    inputType="text"
                                                    placeholderResourceName="ORGANISATION.MODAL.EDIT_USER_ROLE.INPUT.FILTER_TEXT.PLACEHOLDER"
                                                    onKeyUp={(e) => setFilterText(e.currentTarget?.value)}
                                                ></FormInput>
                                            </FormGroup>
                                        }
                                        {
                                            organisationUserRoles?.filter(x => x.name?.toLocaleUpperCase().includes(filterText.toLocaleUpperCase())).map((role: any, index: any) => {
                                                return (
                                                    <Fragment key={role.roleId}>
                                                        {index != 0 &&
                                                            <div className="separator separator-dashed my-5"></div>}
                                                        <div className="d-flex">
                                                            <div
                                                                className="form-check form-check-custom form-check-solid flex-grow-1">
                                                                <label className="form-check-label"
                                                                       htmlFor={role.roleId}>
                                            <span
                                                className="bullet bullet-vertical h-25px float-start me-3"
                                                style={{backgroundColor: role.colorCode}}></span><span
                                                                    className="mt-1 float-start fw-bold">{role.name}</span>
                                                                </label>
                                                            </div>
                                                            <label
                                                                className="form-check form-switch form-check-custom form-check-solid">
                                                                <input className="form-check-input"
                                                                       id={role.roleId}
                                                                       type="checkbox"
                                                                       checked={role.userHasRole}
                                                                       onChange={(e) => onChange(role.roleId, e.currentTarget.checked)}
                                                                />
                                                                <span
                                                                    className="form-check-label fw-bold text-muted"></span>
                                                            </label>
                                                        </div>
                                                    </Fragment>
                                                )
                                            })}
                                        <div className="d-flex flex-center mt-15">
                                            <FormCancelButton onClick={closeModal}/>
                                            <FormSubmitButton
                                                loading={loading}
                                                onClick={saveRoles}
                                                buttonResourceName={"FORM.SAVE"}
                                            />
                                        </div>
                                    </>
                                )
                        )}
                </>
            </FormModal>
        </>
    )
}